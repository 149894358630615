/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DataTable2 from "app/components/shopperTable";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useNavigate, Navigate } from "react-router-dom";

import DataTable from "examples/Tables/DataTable";
import Tooltip from "@mui/material/Tooltip";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import AlertDialog from "app/components/confirmBox";
import MDSnackbar from "components/MDSnackbar";

import IP from "../IP";

const axios = require("axios");

function ViewStores() {
  const [data, setData] = useState({ columns: [], rows: [] });
  const [index, setIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [storeToDelName, setStoreToDelName] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [show, setShow] = useState(false);
  const [active, setActive] = useState();
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const [showSnackbar2, setShowSnackbar2] = useState(false);
  const toggleSnackbar2 = () => setShowSnackbar2(!showSnackbar2);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const navigate = useNavigate();

  async function changeStatus(id, status) {
    await axios
      .post(
        IP + "/stores/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        setActive(status);
        GetStores();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetStores() {
    //console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data.data);

        let storeColums = [
          {
            Header: language == 1 ? "Nombre de Tienda" : "Store Name",
            accessor: "name",
            width: "20%",
          },
          { Header: language == 1 ? "Dirección Física" : "Address", accessor: "address1" },
          { Header: language == 1 ? "País" : "Country", accessor: "country" },
          { Header: language == 1 ? "Ciudad" : "City", accessor: "city" },
          { Header: language == 1 ? "Tarifa de Envío" : "Delivery Fee", accessor: "delivery" },
          { Header: "Zipcode", accessor: "zipcode" },
          { Header: "Balance", accessor: "balance" },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];
        let storeRows = response.data.data.map((item) => {
          //  console.log(item);
          return {
            name: item.name,
            address1: item.address1,
            country: item.country.name,
            delivery: item.location.name,
            city: item.city.name,
            balance: parseFloat(item.store_balance ?? 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            zipcode: item.zipcode,
            active:
              item.active == "Active"
                ? language == 1
                  ? "Activo"
                  : "Active"
                : language == 1
                ? "Inactivo"
                : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      EditStore(item.id);
                    }}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>
                <Tooltip title={language == 1 ? "Pagos de Balance" : "Balance Payments"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      localStorage.setItem("StoreId", item.id);
                      navigate("/store-payments");
                    }}
                    iconOnly
                  >
                    <Icon>account_balance</Icon>
                  </MDButton>
                </Tooltip>
                {item.active == "Inactive" ? (
                  <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => {
                        changeStatus(item.id, true);
                      }}
                      iconOnly
                    >
                      <Icon>checkmark</Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={() => {
                        changeStatus(item.id, false);
                      }}
                      iconOnly
                    >
                      <Icon>do_not_disturb</Icon>
                    </MDButton>
                  </Tooltip>
                )}{" "}
                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      //  console.log("this");
                      setShowConfirm(true);
                      setStoreToDelName(item.name);
                      localStorage.setItem("stotodel", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: storeColums, rows: storeRows };

        //console.log(tableData);

        setData(tableData);
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  function EditStore(storeid) {
    localStorage.setItem("StoreId", storeid);
    setRedirect(true);
    console.log("hello");
  }

  async function DeleteStore() {
    await axios
      .delete(IP + "/stores/" + localStorage.getItem("stotodel"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        localStorage.removeItem("stotodel");
        GetStores();
      })
      .catch(function (error) {
        console.log(error);
        toggleSnackbar2();
        setTimeout(() => {
          setShowSnackbar2(false);
        }, 3000);
      });
  }

  useEffect(() => {
    GetStores();
  }, []);

  return (
    <DashboardLayout>
      {redirect && <Navigate push={true} to="/editStore" />}
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres borrar la tienda ''" + storeToDelName + "'' ?"
            : "Are you sure you want to delete the Store ''" + storeToDelName + "'' ?"
        }
        OkayFunction={DeleteStore}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="EMOS"
        content={
          language == 1
            ? `Tienda ${active ? "Activada" : "Desactivada"} Exitosamente!`
            : `Store ${active ? "Activated" : "Deactivated"} Successfully!`
        }
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={showSnackbar}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title="EMOS"
        content={
          language == 1
            ? `La tienda no se pudo borrar. Tiene transacciones registradas.`
            : `Could not delete store. It has transactions recorded.`
        }
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={showSnackbar2}
        close={toggleSnackbar2}
      />
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            navigate("/emos/createStores");
          }}
        >
          {language == 1 ? "Crear Tienda" : "Create Store"}
        </MDButton>
      </MDBox>
      <Card>
        <DataTable2
          table={data}
          index={index}
          setIndex={setIndex}
          pageEntries={10}
          entriesPerPage={true}
          canSearch
        />
      </Card>
    </DashboardLayout>
  );
}

export default ViewStores;
