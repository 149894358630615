/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";

const axios = require("axios");

function InvoicePaymentList() {
  const [menu, setMenu] = useState(null);
  const [data, setData] = useState([]);
  const [invoices, setInvoices] = useState({ columns: [], rows: [] });
  const [stores, setStores] = useState([]);
  const [total, setTotal] = useState(0)
  const [totalPaid, setTotalPaid] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState(0)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  async function GetStores() {
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        let store = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            store.push({ label: item.name, id: item.id });
          }
        });
        store.unshift({ label: language == 1 ? "Todas" : "All", id: 0 });

        setStores(store);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getInvoicePaymentData() {
    await axios
      .get(IP + "/payments/getPaymentsByInvoice/" + state?.InvoiceId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response?.data?.data);
        if (response.data.data.length > 0) {
          setData(response?.data?.data);
          setTotal(response?.data?.data[0].invoice.total_amount)
          setInvoiceNumber(response?.data?.data[0].invoice.invoice_number)
        }

        let columns = [
          {
            Header: language == 1 ? "Número de Transacción" : "Transaction Number",
            accessor: "invoice_number",
          },
          { Header: language == 1 ? "Metodo de Pago" : "Payment Method", accessor: "method" },
          { Header: language == 1 ? "Cantidad Total" : "Total Amount", accessor: "total" },
          { Header: language == 1 ? "Fecha de Pago" : "Payment Date", accessor: "date" },
          { Header: language == 1 ? "Acción" : "action", accessor: "action" },
        ];

        let allPayments = []

        let rows = response?.data?.data.map((item) => {
          
          allPayments.push(parseFloat(item.amount))
          
          return {
            invoice_number: item.payment.transaction_number,
            method: item.payment.method,
            total: "$" + item.amount,
            date: item.payment.date,
            action: (
              <Grid>
                <Tooltip title={language==1 ? "Editar" : "Edit"}>
                  <MDButton variant="gradient" color="info" iconOnly onClick={()=>{
                    localStorage.setItem("paymentId", item.payment.id)
                    navigate("/adjust-payment")
                  }}>
                      <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>{"  "}
                <MDButton variant="gradient" color="success" onClick={() => {
                  navigate('/payment-details')
                  localStorage.setItem("paymentId", item.payment.id)
                }}>
                  {language == 1 ? "Detalles" : "Details"}
                </MDButton>
              </Grid>
            ),
          };
        });

        let paid = allPayments.reduce((prev, current) => prev + current, 0);
        setTotalPaid(paid)

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setInvoices(tableData);
      })
      .catch((error) => {
        if (!error?.response?.data?.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function filterPayments(store) {
    console.log(store);
    let columns = [
      {
        Header: language == 1 ? "Número de Factura" : "Invoice Number",
        accessor: "invoice_number",
      },
      { Header: language == 1 ? "Completamente Pagado" : "Fully Paid", accessor: "paid" },
      { Header: language == 1 ? "Cantidad Total" : "Total Amount", accessor: "total" },
      { Header: language == 1 ? "Acción" : "action", accessor: "action" },
    ];

    if (store?.id != 0 && store) {
      let rows = data
        .filter((item) => {
          return item.sales.store_id == store.id;
        })
        .map((item) => {
          //console.log(item);
          return {
            invoice_number: item.invoice_number,
            paid:
              item.fully_paid == 1
                ? language == 1
                  ? "Pagado"
                  : "Paid"
                : language == 1
                ? "Pendiente"
                : "Pending",
            total: item.total_amount,
            action: (
              <Grid>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    navigate("/view-invoice");
                    localStorage.setItem("invoiceId", item.id);
                  }}
                >
                  {language == 1 ? "Ver" : "View"}
                </MDButton>{" "}
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => {
                    navigate("/emos/registerPayments", {
                      state: { storeId: item.sales.store_id },
                    });
                    // localStorage.setItem("invoiceId", item.id);
                  }}
                >
                  {language == 1 ? "Pagar" : "Pay"}
                </MDButton>
              </Grid>
            ),
          };
        });

      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setInvoices(tableData);
    }

    if (store?.id == 0 || !store) {
      let rows = data.map((item) => {
        //console.log(item);
        return {
          invoice_number: item.invoice_number,
          paid:
            item.fully_paid == 1
              ? language == 1
                ? "Pagado"
                : "Paid"
              : language == 1
              ? "Pendiente"
              : "Pending",
          total: item.total_amount,
          action: (
            <Grid>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  navigate("/view-invoice");
                  localStorage.setItem("invoiceId", item.id);
                }}
              >
                {language == 1 ? "Ver" : "View"}
              </MDButton>{" "}
              <MDButton
                variant="gradient"
                color="success"
                onClick={() => {
                  navigate("/emos/registerPayments", {
                    state: { storeId: item.sales.store_id },
                  });
                  // localStorage.setItem("invoiceId", item.id);
                }}
              >
                {language == 1 ? "Pagar" : "Pay"}
              </MDButton>
            </Grid>
          ),
        };
      });

      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setInvoices(tableData);
    }
  }

  useEffect(() => {
    getInvoicePaymentData();
    GetStores();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
          <MDBox mb={2}>
          <MDTypography variant="h6">{language == 1 ? "Número de Factura: " : "Invoice Number: "}{invoiceNumber}</MDTypography>
          <MDTypography variant="h6">{language == 1 ? "Total de la Factura: " : "Total Amount: "}${total}</MDTypography>
          <MDTypography variant="h6">
            {language == 1 ? "Total de Pagos: " : "Total Paid: "}${parseFloat(totalPaid).toFixed(2)}
          </MDTypography>
          <MDTypography variant="h6">
            {language == 1 ? "Total Pendiente: " : "Total Pending: "}${parseFloat(total - totalPaid).toFixed(2)}
          </MDTypography>
          </MDBox>
          {/* <Autocomplete
            sx={{ width: "15%", mb: -8, ml: 2 }}
            disablePortal
            value={selectedStore}
            onChange={(event, newValue) => {
              setSelectedStore(newValue);
              filterPayments(newValue);
            }}
            options={stores}
            renderInput={(params) => (
              <MDInput
                {...params}
                label={language == 1 ? "Tiendas" : "Stores"}
                variant="standard"
              />
            )}
          /> */}
          <DataTable table={invoices} entriesPerPage={true} />
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default InvoicePaymentList;
