import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ShippingNavbar from "app/components/shippingNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";
import PurchaseOrderModal from "app/components/purchaseOrderModal";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import swal from "sweetalert";
import Beep from "../../app/components/audio/beep.mp3";
import AlertDialog from "app/components/confirmBox";
import ImageViewer from "react-simple-image-viewer";

import IP from "app/IP";
import imageIP from "app/imageIP";
import DataTable2 from "app/components/shopperTable";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import VirtualizedAutocomplete from "app/components/VirtualizedAutocomplete";

const axios = require("axios");

function Shipping() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
  const [audio] = useState(new Audio(Beep));
  const [index, setIndex] = useState(0);
  const [highlight, setHighlight] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [date, setDate] = useState(currentDate);
  const [eda, setEda] = useState(currentDate);
  const [soNum, setSoNum] = useState("");
  const [store, setStore] = useState("");
  const [totalQty, setTotalQty] = useState();
  const [delivery, setDelivery] = useState("");
  const [taxTotal, setTaxTotal] = useState("");
  const [subtotal, setSubtotal] = useState("");
  const [tableData, setTableData] = useState({ rows: [], columns: [] });
  const [tableSelData, setTableSelData] = useState({ rows: [], columns: [] });
  const [vendors, setVendors] = useState([{}]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendorProducts, setVendorProducts] = useState([]);
  const [scannedProducts, setScannedProducts] = useState([]);
  const [images, setImages] = useState([]);
  const [hideConfirm, setHideConfirm] = useState(true);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [image, setImage] = useState([]);
  const [imgViewer, setImgViewer] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [prod, setProd] = useState("");
  const [dummy, setDummy] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [changeTable, setChangeTable] = useState(true);
  const [qtyScan, setQtyScan] = useState(1);
  const [qtyManual, setQtyManual] = useState(0);
  const [showManual, setShowManual] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [manualProd, setManualProd] = useState("");
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [scanReady, setScanReady] = useState(false);
  const [sweet, setSweet] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [upcScanned, setUpcScanned] = useState("");
  const [chooseProducts, setChooseProducts] = useState(false); //if multiple products are found with a similar upc, this opens a modal to choose between them
  const [foundProducts, setFoundProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [role, setRole] = useState(0);
  const [name, setName] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  //const [selectedDepartment, setSelectedDepartment] = useState();
  const selectedDepartment = useRef("");

  let timer2;

  const ref1 = useRef();

  const toggleChooseProduct = () => setChooseProducts(!chooseProducts);
  const toggleSnackbar = () => setShow(!show);
  const toggleSnackbar2 = () => {
    setShow2(!show2);
    setTimeout(() => {
      setShow2(false);
    }, 2500);
  };

  const closeImageViewer = () => setImgViewer(false);

  const navigate = useNavigate();
  const inputRef = useRef();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();

  async function getUserDetails() {
    await axios
      .get(IP + "/user/profile", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        //console.log(response.data.data);

        var user = response.data.data;
        //setUserName(user.username);
        setName(user.name);
        //setEmail(user.email);
        //setAddress(user.address);
        //setAddress2(user.address2);
        setRole(user.role);
        //setSelectedRole({ id: user.role.id, label: user.role.name });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const scrollToElement = (id) => {
    const container = document.getElementById(id);
    container.scrollIntoView({ behavior: "instant", block: "center", inline: "nearest" });
  };

  async function GetCategories() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/categories", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log("GET CATEGORIES:", response.data.data);
        let depList = response.data.data.filter((item) => {
          return item.active == "Active";
        });
        console.log("Department Ids:", depList);

        setDepartmentList(depList);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getShippedProducts() {
    console.log(localStorage.getItem("RecId"));
    let depID;
    if (selectedDepartment.current !== "") {
      depID = "?category_id=" + selectedDepartment.current.id;
    } else {
      depID = "";
    }
    //  = selectedDepartment.current !=
    // ? "?category_id=" + selectedDepartment.current.id
    // : ""

    await axios
      .get(IP + "/shippings/" + localStorage.getItem("ShipId") + "/shippings_detail" + depID, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.data.selected.length > 0) {
          let sum = 0;
          response.data.data.selected.map((item) => {
            sum += item.quantity_delivery;
          });
          if (sum == 0) {
            setHideConfirm(true);
          } else {
            setHideConfirm(false);
          }
          if (response.data.data.shipping.status.status_id == 6) setHideConfirm(true);
        } else {
          setHideConfirm(
            response.data.data.shipping.status.status_id == 6 ||
              response.data.data.selected.length == 0
          );
        }

        let vendorProductColumns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Empaque" : "Packaging", accessor: "package" },
          { Header: language == 1 ? "Departamento" : "Department", accessor: "dept" },
          { Header: language == 1 ? "Cantidad de Orden" : "Order Quantity", accessor: "qty" },
          {
            Header: language == 1 ? "Cantidad Pendiente" : "Quantity Pending",
            accessor: "available",
          },
          // { Header: "Subtotal", accessor: "subtotal" },

          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let qty = [];
        let prods = [];
        let scanProds = [];
        response.data.data.all.map((item) => {
          prods.push(item);
        });
        response.data.data.selected.map((item) => {
          scanProds.push(item);
        });

        let vendorProductRows = response.data.data.all.map((item) => {
          setStore(item.store.name);
          setSoNum(item.sales_order.so_number);
          setTaxTotal(item.sales_order.tax);
          setDelivery(item.sales_order.delivery_fee);
          setSubtotal(item.sales_order.subtotal);
          setDate(item.sales_order.date);
          qty.push(item.quantity);
          return {
            code: item.product.code,
            image: (
              <ProductCell
                image={imageIP + item.product.image}
                name=""
                // onClick={() => {
                //   if (item.product.image != "") {
                //     setImage([imageIP + item.product.image]);
                //     setImgViewer(true);
                //   }
                // }}
              />
            ),
            dept: item.product.subcategory.name,
            package: item.product.packaging,
            name: (
              <MDTypography
                id={item.product.id}
                fontWeight="light"
                variant="inherit"
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  localStorage.setItem("productId", item.product.id);
                  navigate("/edit-product");
                }}
              >
                {item.product.name}
              </MDTypography>
            ),
            upc: item.product.upc,
            tax: item.tax,
            available: item.quantity_pending,

            qty: item.quantity,
            subtotal: parseFloat(item.price * item.quantity).toFixed(2),
            prodid: item.product.id,
            action: (
              <>
                <Tooltip title={language == 1 ? "Reiniciar" : "Reset"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      sendRequestReset(
                        item.product.upc,
                        item.quantity_delivered,
                        prods.concat(scanProds)
                      );
                    }}
                    iconOnly
                  >
                    <Icon>refresh</Icon>
                  </MDButton>
                </Tooltip>{" "}
                <Tooltip title={language == 1 ? "Entrada Manual" : "Manual Entry"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      setShowManual(true);
                      setManualProd(item.product.upc);
                    }}
                    iconOnly
                  >
                    123
                  </MDButton>
                </Tooltip>
              </>
            ),
          };
        });

        // qty.reduce(
        //   (prev, current) => prev + current, 0
        // )
        let sum = 0;
        for (let i = 0; i < qty.length; i++) {
          sum = sum + qty[i];
        }
        setTotalQty(sum);

        // console.log(vendorProductRows);

        let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
        setTableData(tableDatax);

        let vendorProductColumnsSel = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Empaque" : "Packaging", accessor: "package" },
          { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty" },
          // { Header: "Subtotal", accessor: "subtotal" },
          //   { Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let vendorProductRowsSel = response.data.data.selected.map((item) => {
          return {
            image: (
              <MDAvatar
                src={imageIP + item.product.image}
                style={{ cursor: "pointer" }}
                size="xl"
                variant="rounded"
                onClick={() => {
                  if (item.product.image != "") {
                    setImage([imageIP + item.product.image]);
                    setImgViewer(true);
                  }
                }}
              />
            ),
            name: (
              <MDTypography
                fontWeight="light"
                variant="inherit"
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  localStorage.setItem("productId", item.product.id);
                  navigate("/edit-product");
                }}
              >
                {item.product.name}
              </MDTypography>
            ),
            upc: item.product.upc,
            code: item.product.code,
            package: item.product.packaging,
            available: item.quantity_pending,
            qty: item.quantity_delivery,
            subtotal: parseFloat(item.price * item.quantity).toFixed(2),
            tax: item.tax,
            qtyTotal: item.quantity,
            prodid: item.product.id,
            action: (
              <>
                <Tooltip title={language == 1 ? "Reiniciar" : "Reset"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      sendRequestReset(
                        item.product.upc,
                        item.quantity_delivery,
                        prods.concat(scanProds)
                      );
                    }}
                    iconOnly
                  >
                    <Icon>refresh</Icon>
                  </MDButton>
                </Tooltip>
              </>
            ),
          };
        });
        console.log(prods);
        setVendorProducts(prods);
        setScannedProducts(scanProds);
        setTableSelData({ columns: vendorProductColumnsSel, rows: vendorProductRowsSel });
      })
      .catch((error) => {
        if (!error?.response?.data?.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  // async function sendPurchaseOrder() {
  //   // getShippedProducts();
  //   setDisableButton(true);
  //   await axios
  //     .post(
  //       IP + "/purchase_orders",
  //       {
  //         date: date,
  //         eda: eda,
  //         vendor_id: selectedVendor.id,
  //         status_id: 1,
  //       },
  //       {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("token"),
  //           Accept: "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       //  console.log(response);
  //       localStorage.setItem("purchaseOrderId", response.data.data.id);
  //       toggleSnackbar();
  //       setTimeout(() => {
  //         navigate("/edit-purchase_order");
  //       }, 5000);
  //     })
  //     .catch((error) => {
  //       setErrors(error.response.data.data);
  //       if (!error.response.data.authenticated) {
  //         navigate("/signIn");
  //       }
  //       console.log(error);
  //     });

  //   setTimeout(() => {
  //     setDisableButton(false);
  //   }, 3000);
  // }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }
  async function getPurchaseOrder() {
    await axios
      .get(IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let orders = response.data.data;
        //  setDate(orders.date);
        //  setEda(orders.eda);
        ///  setSelectedVendor({ id: orders.vendor.id, label: orders.vendor.name });
        //   getShippedProducts(orders.vendor.id);
      });
  }

  function addToTable(input) {
    let columns = [{ Header: "UPC", accessor: "upc" }];
    let rows = [{ upc: input }].concat(tableData.rows);
    setTableData({ rows: rows, columns: columns });
  }

  async function sendRequest(input, direct) {
    let prodx = vendorProducts.filter((item) => {
      return direct ? item.product.upc == input : item.product.upc.includes(input);
    });

    let scanCheck = scannedProducts.filter((item) => {
      return item.product.upc.includes(input);
    });

    console.log("aqui");
    console.log(prodx);

    if (prodx.length == 1 || direct) {
      console.log("here");
      //let quantity = prod[0].quantityTotal;
      if (qtyScan !== "all" && prodx[0].quantity_pending >= qtyScan) {
        let qtyReceived = qtyScan; //valor se cambia con radio button.
        //let prodid = prod[0].prodid;

        await axios
          .post(
            IP + "/shippings/scanner/" + (direct ? input : prodx[0].product.upc),
            {
              // quantity: quantity,
              quantity_delivery: qtyReceived,
              // product_id: prodid,
              shipping_id: localStorage.getItem("ShipId"),
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            setHighlight(response.data.data.product.id);
            scrollToElement(response.data.data.product.id);
            getShippedProducts(images);
          })
          .catch((error) => {
            if (!error.response.data.authenticated) {
              navigate("/signIn");
            }
            console.log(error);
          });
      } else if (qtyScan == "all" && prodx[0].quantity_pending >= 1) {
        let qtyReceived = prodx[0].quantity_pending; //valor se cambia con radio button.
        //let prodid = prod[0].prodid;

        await axios
          .post(
            IP + "/shippings/scanner/" + (direct ? input : prodx[0].product.upc),
            {
              // quantity: quantity,
              quantity_delivery: qtyReceived,
              // product_id: prodid,
              shipping_id: localStorage.getItem("ShipId"),
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            getShippedProducts(images);
          })
          .catch((error) => {
            if (!error.response.data.authenticated) {
              navigate("/signIn");
            }
            console.log(error);
          });
      } else {
        if (prodx[0].quantity_pending >= 1 && qtyScan > prodx[0].quantity_pending) {
          if (qtyScan == 100) {
            setQtyScan(50);
          } else if (qtyScan == 50) {
            setQtyScan(25);
          } else if (qtyScan == 25) {
            setQtyScan(10);
          } else if (qtyScan == 10) {
            setQtyScan(5);
          } else if (qtyScan == 5) {
            setQtyScan(1);
          }
          swal(
            "Alerta! Se intento enviar una cantidad por encima de lo disponible del Item. La Cantidad de Scan se ha reducido.",
            "",
            "warning"
          );
        } else {
          swal(
            language == 1
              ? "El UPC " + input + " ya se ha escaneado en su totalidad"
              : "UPC " + input + " was already fully registered",
            "",
            "warning"
          );
          setUpcScanned(input);
        }
      }
    } else if (prodx.length > 1) {
      console.log("toggle choose product");
      if (
        prodx.filter((item) => {
          return item?.shipping == null;
        }).length == 1
      ) {
        sendRequest(
          prodx.filter((item) => {
            return item?.shipping == null;
          })[0].product.upc
        );
      } else {
        toggleChooseProduct();
        setFoundProducts(
          prodx.filter((item) => {
            return item.shipping == null;
          })
        );
        setSelectedProduct(prodx[0].product.upc);
      }
    } else if (prodx.length == 0 && scanCheck.length > 0) {
      swal(
        language == 1
          ? "El UPC " + input + " ya se ha escaneado en su totalidad"
          : "UPC " + input + " was already fully registered",
        "",
        "warning"
      );
    } else {
      swal(
        language == 1
          ? "El UPC " + input + " no fue encontrado"
          : "UPC " + input + " was not found",
        "",
        "error"
      );
    }
  }

  async function sendRequestReset(input, qty, prods) {
    let prodx = prods.filter((item) => {
      return item.product.upc == input;
    });

    console.log(input);
    console.log(prodx);

    if (prodx.length > 0) {
      let qtyReceived = parseInt(qty) * -1; //valor se cambia con radio button.
      console.log(qtyReceived);
      await axios
        .post(
          IP + "/shippings/scanner/" + input,
          {
            // quantity: quantity,
            quantity_delivery: qtyReceived,
            // product_id: prodid,
            shipping_id: localStorage.getItem("ShipId"),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          getShippedProducts(images);
        })
        .catch((error) => {
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
          console.log(error);
        });
    }
  }

  async function sendRequestManual(input, qty, prods) {
    let prodx = prods.filter((item) => {
      return item.product.upc == input;
    });

    console.log(prodx);
    console.log(prodx.length > 0);

    if (prodx.length > 0) {
      if (prodx[0].quantity >= qty) {
        let quantity = prodx[0]?.quantityTotal;
        let qtyReceived = parseInt(qty); //valor se cambia con radio button.
        let prodid = prodx[0]?.prodid;

        console.log(prodx);

        await axios
          .post(
            IP + "/shippings/scanner/" + input,
            {
              // quantity: quantity,
              quantity_delivery: qtyReceived,
              // product_id: prodid,
              shipping_id: localStorage.getItem("ShipId"),
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            getShippedProducts(images);
          })
          .catch((error) => {
            if (!error.response.data.authenticated) {
              navigate("/signIn");
            }
            console.log(error);
          });
      } else {
      }
    }
  }

  async function sendConfirmRequest() {
    await axios
      .post(
        IP + "/shippings/setShipped/" + localStorage.getItem("ShipId"),
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        setTimeout(() => {
          if (role == 1 || role == 8) navigate("/emos/shippingList");
          else navigate("/sales");
        }, 3000);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref]);

    return isIntersecting;
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const timer = () =>
    setTimeout(() => {
      setInputValue("");
    }, 200);

  const isVisible = true;
  //useIsVisible(ref1);

  useEffect(() => {
    inputRef.current?.focus();
  });

  useEffect(() => {
    let timex;
    if (sweet) {
      timex = setTimeout(() => {
        play();
        swal("Alerta!", "No se ha recibido un producto en más de 1 minuto!", "warning");
      }, 60000);
    }
    return () => clearTimeout(timex);
  }, [sweet]);

  const audioRef = useRef(null);
  const play = (url) => {
    audioRef.current.play();
  };

  useEffect(() => {
    GetCategories();
    getShippedProducts();
    getUserDetails();
  }, []);

  return (
    <DashboardLayout>
      <PurchaseOrderModal open={open} Product={prod} setOpen={setOpen} />
      {!imgViewer && (
        <ShippingNavbar
          shipping={true}
          setChangeTable={setChangeTable}
          date={date}
          soNum={soNum}
          store={store}
          totalQty={totalQty}
          hideConfirm={hideConfirm}
          setConfirm={setConfirm}
          setQtyScan={setQtyScan}
          qtyScan={qtyScan}
        />
      )}

      <audio src={Beep} ref={audioRef}></audio>
      {imgViewer && (
        <ImageViewer
          src={image}
          currentIndex={0}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
      <ThemeProvider theme={darkTheme}>
        <Dialog open={chooseProducts}>
          <DialogTitle>
            {language == 1 ? "Seleccione el producto" : "Choose the scanned product:"}
          </DialogTitle>
          <DialogContent>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedProduct}
              onChange={(e) => {
                setSelectedProduct(e.target.value);
              }}
            >
              {foundProducts.map((item) => {
                return (
                  <FormControlLabel
                    value={item.product.upc}
                    control={<Radio />}
                    label={item.product.name}
                  />
                );
              })}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                toggleChooseProduct();
              }}
            >
              {language == 1 ? "Cancelar" : "Cancel"}
            </Button>
            <Button
              onClick={() => {
                sendRequest(selectedProduct, true);
                setTimeout(() => {
                  setChooseProducts(false);
                }, 1000);
              }}
            >
              {language == 1 ? "Guardar" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "El Envío fue confirmado exitosamente!"
            : "The shipping was confirmed successfully!"
        }
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "El UPC " + upcScanned + " no fue encontrado"
            : "UPC " + upcScanned + " was not found"
        }
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={show2}
        close={toggleSnackbar2}
      />

      <AlertDialog
        Show={confirm}
        setShowConfirm={setConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres confirmar este envío ?"
            : "Are you sure you want to confirm this shipping ?"
        }
        OkayFunction={sendConfirmRequest}
      />

      {showManual ? (
        <ThemeProvider theme={darkTheme}>
          <Dialog open={showManual}>
            <DialogTitle>{language == 1 ? "Cantidad Enviada:" : "Shipped Quantity:"}</DialogTitle>

            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={qtyManual}
                label={language == 1 ? "Cantidad" : "Quantity"}
                type="number"
                onChange={(e) => {
                  if (e.target.value > 0 || e.target.value == "") {
                    setQtyManual(e.target.value);
                  }
                }}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setQtyManual(0);
                  setShowManual(false);
                  setManualProd("");
                }}
              >
                {language == 1 ? "Cancelar" : "Cancel"}
              </Button>
              <Button
                onClick={() => {
                  sendRequestManual(manualProd, qtyManual, vendorProducts);
                  setTimeout(() => {
                    setQtyManual(0);
                    setShowManual(false);
                    setManualProd("");
                  }, 1000);
                }}
              >
                {language == 1 ? "Guardar" : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      ) : null}
      {changeTable ? (
        <MDBox py={3} mb={20} height="65vh">
          {!scanReady ? (
            <>
              <MDBox display="flex" justifyContent="center" alignItems="flex-start" mb={2}>
                <MDButton
                  variant="gradient"
                  onClick={() => {
                    setScanReady(true);
                    setSweet(true);
                  }}
                  style={{
                    width:
                      width > 950 && width < 1000 && height > 1200 && height < 1500
                        ? "200px"
                        : null,
                    height:
                      width > 950 && width < 1000 && height > 1200 && height < 1500 ? "55px" : null,
                  }}
                  color="success"
                  size="large"
                >
                  {language == 1 ? "Preparar para Escanear" : "Prepare to Scan"}
                </MDButton>
              </MDBox>
            </>
          ) : null}
          {!showManual && scanReady ? (
            <>
              <MDBox display="flex" justifyContent="center" alignItems="flex-start" mb={2}>
                <h2 style={{ color: "gray" }}>
                  {language == 1 ? "Listo para Escanear!" : "Ready To Scan!"}{" "}
                </h2>
              </MDBox>
              <Grid>
                <input
                  label="Scan"
                  style={{ position: "absolute", marginTop: "2%", marginLeft: "2%" }}
                  fullWidth
                  value={inputValue}
                  ref={inputRef}
                  onBlur={(e) => {
                    inputRef.current.focus();
                  }}
                  onKeyDown={(e) => {
                    if (e.code == "Enter") {
                      sendRequest(e.target.value);
                      setSweet(false);
                      setInputValue("");
                    }
                  }}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    // clearTimeout(timer());
                    //  timer();
                  }}
                />
              </Grid>
            </>
          ) : null}
          <VirtualizedAutocomplete
            //multiple
            label="Filtro de Departamentos"
            id="checkboxes-tags-demo"
            style={{ width: 320 }}
            options={departmentList}
            onChange={(event, newValue) => {
              console.log("Selected Department:", newValue);
              selectedDepartment.current = newValue;
              getShippedProducts();
              //setSelectedDepartment(newValue);
            }}
            //disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            // renderOption={(props, option, { selected }) => (
            //   <li {...props}>
            //     <Checkbox
            //       //icon={icon}
            //       //checkedIcon={checkedIcon}
            //       //style={{ marginRight: 8 }}
            //       checked={selected}
            //     />
            //     {option.name}
            //   </li>
            // )}
            //style={{ width: 500 }}
            renderInput={(params) => (
              <Card>
                <TextField
                  {...params}
                  id="standard-multiline-flexible"
                  multiline
                  maxRows="4"
                  variant="outlined"
                  label="Filtro de Departamentos"
                  //fullWidth
                />
              </Card>
            )}
          />
          <Card>
            <MDBox p={3}>
              <DataTable2
                highlight={highlight}
                table={tableData}
                index={index}
                setIndex={setIndex}
                pageEntries={100}
                entriesPerPage={false}
              />
            </MDBox>
          </Card>
        </MDBox>
      ) : null}
      {!changeTable && (
        <MDBox py={3} mb={20} height="65vh">
          <h3 style={{ color: "gray" }}>Scanned Products </h3>

          <Card>
            <MDBox p={3}>
              <Grid>
                <DataTable height="50%" table={tableSelData} entriesPerPage={false} />
              </Grid>
              <Grid>
                <p style={{ float: "right", color: "white" }}>Total Quantity: 0</p>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Shipping;
