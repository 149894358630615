/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import IP from "../IP";

const axios = require("axios");

function UserChangePassword() {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const [errors, setErrors] = useState([]);
  const [newPassword, setNewPassword] = useState("");
  const [confPassword, setConfPassword] = useState("")
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const passwordRequirements =
    language == 1
      ? ["Un caracter especial", "Min 8 caracteres", "Un Número", "Una letra mayúscula y una minúscula"]
      : ["One special characters", "Min 8 characters", "One number", "One uppercase and one lowercase letter"];

  async function changePassword() {
    await axios
      .put(
        IP + "/user/change-password",
        {
          password: password,
          new_password: newPassword,
          new_password_confirmation: confPassword
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar()
        setTimeout(()=>{
          setShow(false)
        }, 4000)
        setPassword("")
        setNewPassword("")
        setConfPassword("")
        setShowConfPassword(false)
        setShowNewPassword(false)
        setShowPassword(false)
        setErrors([])
      })
      .catch(error=>{
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        setErrors(error.response.data.data);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }


  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <>
      <Card id="change-password">
        <MDSnackbar
          color="info"
          icon="notifications"
          title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
          content={language == 1 ? "Contraseña Cambiada Exitosamente!" : "Password Changed Successfully!"}
          dateTime={language==1 ? "Ahora" : "Now"}
          open={show}
          close={toggleSnackbar}
        />
        <MDBox p={3}>
          <MDTypography variant="h5">
            {language == 1 ? "Cambiar Contraseña" : "Change Password"}
          </MDTypography>
        </MDBox>
        <MDBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* <MDInput
                fullWidth
                label={language == 1 ? "Contraseña Actual" : "Current Password"}
                value={password}
                onChange={(e)=>{
                  setPassword(e.target.value)
                }}
                inputProps={{ type: "password", autoComplete: "" }}
              /> */}
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {language == 1 ? "Contraseña Actual" : "Current Password"}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    // console.log(e);
                    setPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        color="white"
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={language == 1 ? "Contraseña Actual" : "Current Password"}
                />
                <ShowError id={"password"} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {language == 1 ? "Nueva Contraseña" : "New Password"}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => {
                    // console.log(e);
                    setNewPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowNewPassword(!showNewPassword);
                        }}
                        color="white"
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={language == 1 ? "Nueva Contraseña" : "New Password"}
                />
              </FormControl>
              <ShowError id={"new_password"} />
            </Grid>
            <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {language == 1 ? "Confirmar Nueva Contraseña" : "Confirm New Password"}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showConfPassword ? "text" : "password"}
                  value={confPassword}
                  onChange={(e) => {
                    // console.log(e);
                    setConfPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowConfPassword(!showConfPassword);
                        }}
                        color="white"
                        edge="end"
                      >
                        {showConfPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={language == 1 ? "Confirmar Nueva Contraseña" : "Confirm New Password"}
                />
                {/* {((password != null) && (password != confPassword)) ? (
                  <MDTypography
                    variant="caption"
                    display="block"
                    gutterBottom
                    color="error"
                  >
                    {language == 1
                      ? "La confirmación y la contraseña deben ser iguales"
                      : "The password confirmation has to be the same as the new password."}
                  </MDTypography>
                ) : null} */}
              </FormControl>
            </Grid>
          </Grid>
          <MDBox mt={6} mb={1}>
            <MDTypography variant="h5">
              {language == 1 ? "Requisitos de Contraseña" : "Password requirements"}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="body2" color="text">
              {language == 1
                ? "Favor de seguir la guia para una contraseña segura."
                : "Please follow this guide for a strong password"}
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {renderPasswordRequirements}
            </MDBox>
            <MDBox ml="auto">
              <MDButton variant="gradient" color="info" onClick={changePassword}>
                {language == 1 ? "actualizar contraseña" : "update password"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

export default UserChangePassword;
