import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";
import PurchaseOrderModal from "app/components/purchaseOrderModal";
import CreateShipping from "./createShipping";
import AlertDialog from "app/components/confirmBox";

import IP from "app/IP";
import imageIP from "app/imageIP";
import { Icon } from "@mui/material";

const axios = require("axios");

function ShippingList() {
  const current = new Date();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${
    month < 10 ? `0${month}` : `${month}`
  }-${current.getDate()}`;
  const edaDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    current.getDate() + 1
  }`;

  const [tableData, setTableData] = useState({ rows: [], columns: [] });

  const [show, setShow] = useState(false);

  const [open, setOpen] = useState(false);
  const [prod, setProd] = useState("");

  const [changeTable, setChangeTable] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [shipConfirm, setShipConfirm] = useState(0);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();
  const inputRef = useRef();

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function addToTable(input) {
    let columns = [{ Header: "UPC", accessor: "upc" }];
    let rows = [{ upc: input }].concat(tableData.rows);
    setTableData({ rows: rows, columns: columns });
  }

  async function getShippings() {
    await axios
      .get(IP + "/shippings?sales_order_id=" + localStorage.getItem("SaleId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        let vendorProductColumns = [
          { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
          { Header: language == 1 ? "Tienda" : "Store", accessor: 'store' },
          { Header: language == 1 ? "Estatus" : "Status", accessor: "status" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let vendorProductRows = response.data.data.map((item) => {
          return {
            date: item.date,
            status: item.status.name,
            store: item.store.name,
            action:<>
              <Tooltip title={language == 1 ? "Descargar Reporte de Shipping" : "Download Shipping Report"}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  href={imageIP + "salesDownload/" + item.sales_order.sales_order_id}
                  iconOnly
                >
                  <Icon>download</Icon>
                </MDButton>
              </Tooltip>
              {" "}
              {(item.status.status_id == 6) && (
                <>
                  <MDButton
                    variant="gradient"
                    onClick={() => {
                      localStorage.setItem("ShipId", item.id);
                      navigate("/emos/shippingAdjustment");
                    }}
                    color="info"
                  >
                    {language == 1 ? "Ajustar" : "Adjust"}
                  </MDButton>{" "}
                  <MDButton
                    variant="gradient"
                    onClick={() => {
                      setShowConfirm(true);
                      setShipConfirm(item.id);
                    }}
                    color="warning"
                  >
                    {language == 1 ? "Confirmar" : "Confirm"}
                  </MDButton>
                </>)}
              {(item.status.status_id != 2 && item.status.status_id != 6) && 
                <MDButton
                  variant="gradient"
                  onClick={() => {
                    localStorage.setItem("ShipId", item.id);
                    navigate("/emos/shipping");
                  }}
                  color="success"
                >
                  {language == 1 ? "Escanear Productos" : "Scan Products"}
                </MDButton>
              }
            </>,
          };
        });

        let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
        // setVendorProducts(vendorProductRows);
        setTableData(tableDatax);
      })
      .catch((error) => {
        if (!error?.response?.data?.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function confirmShipping() {
    await axios
      .post(
        IP + "/shippings/setStatusCompleted/" + shipConfirm,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        toggleSnackbar();
        getShippings();
        setTimeout(() => {
          setShow(false);
        }, 3000);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  const timer = () =>
    setTimeout(() => {
      setInputValue("");
    }, 200);

  useEffect(() => {
    inputRef.current?.focus();
  });

  useEffect(() => {
    //getVendorProducts();

    getShippings();
  }, []);

  return (
    <DashboardLayout>
      <PurchaseOrderModal open={open} Product={prod} setOpen={setOpen} />
      <DashboardNavbar />
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres confirmar este envío?"
            : "Are you sure you want to confirm this Shipping?"
        }
        OkayFunction={confirmShipping}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "El Envío fue confirmado exitosamente. La factura fue creada."
            : "Shipping was confirmed successfully. Invoice Created."
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      {/* {changeTable ? (
        <CreateShipping RefreshTable={getShippings} setChangeTable={setChangeTable} />
      ) : null} */}
      <MDBox py={3} mb={20} height="65vh">
        {/* <Grid>
          <MDButton
            variant="gradient"
            onClick={() => {
              setChangeTable(true);
            }}
            color="info"
          >
            {language == 1 ? "Crear Nuevo Envío" : "Create New Shipping"}
          </MDButton>
        </Grid> */}
        <Card style={{ marginTop: "2%" }}>
          <MDBox p={3}>
            <Grid>
              <DataTable height="50%" table={tableData} entriesPerPage={false} />
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ShippingList;
