/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";

const axios = require("axios");

function InvoiceList() {
  const [menu, setMenu] = useState(null);
  const [data, setData] = useState([]);
  const [invoices, setInvoices] = useState({ columns: [], rows: [] });
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function GetStores() {
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        let store = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            store.push({ label: item.name, id: item.id });
          }
        });
        store.unshift({ label: language == 1 ? "Todas" : "All", id: 0 });

        setStores(store);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getInvoiceData() {
    await axios
      .get(IP + "/invoices", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setData(response.data.data);

        let columns = [
          {
            Header: language == 1 ? "Número de Factura" : "Invoice Number",
            accessor: "invoice_number",
          },
          { Header: language == 1 ? "Número de Orden" : "Order Number", accessor: "order" },
          { Header: language == 1 ? "Tienda" : "Store", accessor: "store" },
          { Header: language == 1 ? "Completamente Pagado" : "Fully Paid", accessor: "paid" },
          { Header: language == 1 ? "Cantidad Total" : "Total Amount", accessor: "total" },
          { Header: language == 1 ? "Acción" : "action", accessor: "action" },
        ];

        let rows = response.data.data.map((item) => {
          //console.log(item);
          return {
            invoice_number: item.invoice_number,
            order: item.sales.so_number,
            store: item.store.name,
            paid:
              item.fully_paid == 1
                ? language == 1
                  ? "Pagado"
                  : "Paid"
                : language == 1
                ? "Pendiente"
                : "Pending",
            total: item.total_amount,
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Ver" : "View"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      navigate("/view-invoice");
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>{" "}
                <Tooltip title={language == 1 ? "Imprimir" : "Print"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      navigate("/view-invoice", { state: { print: true } });
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>print</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                <Tooltip title={language == 1 ? "Descargar" : "Download"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      navigate("/view-invoice", { state: { download: true } });
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
            // action: (
            //   <Grid>
            //     <Tooltip title={language == 1 ? "Ver" : "View"}>
            //       <MDButton
            //         variant="gradient"
            //         color="info"
            //         onClick={() => {
            //           navigate("/view-invoice");
            //           localStorage.setItem("invoiceId", item.id);
            //         }}
            //         iconOnly
            //       >
            //         <Icon>visibility</Icon>
            //       </MDButton>
            //     </Tooltip>{" "}
            //     <Tooltip title={language == 1 ? "Imprimir" : "Print"}>
            //       <MDButton variant="gradient" color="warning" onClick={() => {
            //           navigate('/view-invoice', {state: {print: true}})
            //           localStorage.setItem("invoiceId", item.id)
            //       }} iconOnly>
            //         <Icon>print</Icon>
            //       </MDButton>
            //     </Tooltip>{" "}
            //     <Tooltip title={language == 1 ? "Descargar" : "Download"}>
            //       <MDButton variant="gradient" color="error" onClick={() => {
            //           navigate('/view-invoice', {state: {download: true}})
            //           localStorage.setItem("invoiceId", item.id)
            //         }}
            //         iconOnly
            //       >
            //         <Icon>download</Icon>
            //       </MDButton>
            //     </Tooltip>
            //     {/* <MDButton
            //       variant="gradient"
            //       color="success"
            //       onClick={() => {
            //         navigate("/emos/registerPayments", {
            //           state: { storeId: item.sales.store_id },
            //         });
            //         // localStorage.setItem("invoiceId", item.id);
            //       }}
            //     >
            //       {language == 1 ? "Pagar" : "Pay"}
            //     </MDButton> */}
            //   </Grid>
            // ),
          };
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setInvoices(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function filterPayments(store) {
    let columns = [
      {
        Header: language == 1 ? "Número de Factura" : "Invoice Number",
        accessor: "invoice_number",
      },
      { Header: language == 1 ? "Número de Orden" : "Order Number", accessor: "order" },
      { Header: language == 1 ? "Tienda" : "Store", accessor: "store" },
      { Header: language == 1 ? "Completamente Pagado" : "Fully Paid", accessor: "paid" },
      { Header: language == 1 ? "Cantidad Total" : "Total Amount", accessor: "total" },
      { Header: language == 1 ? "Acción" : "action", accessor: "action" },
    ];

    if (store?.id != 0) {
      let rows = data
        .filter((item) => {
          return item.store.store_id == store.id;
        })
        .map((item) => {
          //console.log(item);
          return {
            invoice_number: item.invoice_number,
            order: item.sales.so_number,
            store: item.store.name,
            paid:
              item.fully_paid == 1
                ? language == 1
                  ? "Pagado"
                  : "Paid"
                : language == 1
                ? "Pendiente"
                : "Pending",
            total: item.total_amount,
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Ver" : "View"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      navigate("/view-invoice");
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>{" "}
                <Tooltip title={language == 1 ? "Imprimir" : "Print"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      navigate("/view-invoice", { state: { print: true } });
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>print</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                <Tooltip title={language == 1 ? "Descargar" : "Download"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      navigate("/view-invoice", { state: { download: true } });
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setInvoices(tableData);
    }

    if (store?.id == 0 || !store) {
      let rows = data.map((item) => {
        //console.log(item);
        return {
          invoice_number: item.invoice_number,
          store: item.store.name,
          paid:
            item.fully_paid == 1
              ? language == 1
                ? "Pagado"
                : "Paid"
              : language == 1
              ? "Pendiente"
              : "Pending",
          total: item.total_amount,
          action: (
            <Grid>
              <Tooltip title={language == 1 ? "Ver" : "View"}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    navigate("/view-invoice");
                    localStorage.setItem("invoiceId", item.id);
                  }}
                  iconOnly
                >
                  <Icon>visibility</Icon>
                </MDButton>
              </Tooltip>{" "}
              <Tooltip title={language == 1 ? "Imprimir" : "Print"}>
                <MDButton
                  variant="gradient"
                  color="warning"
                  onClick={() => {
                    navigate("/view-invoice", { state: { print: true } });
                    localStorage.setItem("invoiceId", item.id);
                  }}
                  iconOnly
                >
                  <Icon>print</Icon>
                </MDButton>
              </Tooltip>
              {"  "}
              <Tooltip title={language == 1 ? "Descargar" : "Download"}>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    navigate("/view-invoice", { state: { download: true } });
                    localStorage.setItem("invoiceId", item.id);
                  }}
                  iconOnly
                >
                  <Icon>download</Icon>
                </MDButton>
              </Tooltip>
            </Grid>
          ),
        };
      });

      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setInvoices(tableData);
    }
  }

  useEffect(() => {
    getInvoiceData();
    GetStores();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <Autocomplete
            sx={{ width: "15%", mt: 2, ml: 2 }}
            disablePortal
            value={selectedStore}
            onChange={(event, newValue) => {
              setSelectedStore(newValue);
              filterPayments(newValue);
            }}
            options={stores}
            renderInput={(params) => (
              <TextField
                {...params}
                label={language == 1 ? "Tiendas" : "Stores"}
                variant="standard"
              />
            )}
          />
          <DataTable table={invoices} entriesPerPage={true} canSearch />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default InvoiceList;
