import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";
import PurchaseOrderModal from "app/components/purchaseOrderModal";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import swal from "sweetalert";
import Beep from "../../app/components/audio/beep.mp3";
import AlertDialog from "app/components/confirmBox";
import Autocomplete from "@mui/material/Autocomplete";

import IP from "app/IP";
import { Checkbox, Switch } from "@mui/material";

const axios = require("axios");

function AdjustShipping() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
  const [audio] = useState(new Audio(Beep));
  const [playing, setPlaying] = useState(false);
  const [date, setDate] = useState(currentDate);
  const [eda, setEda] = useState(currentDate);
  const [tableData, setTableData] = useState({ rows: [], columns: [] });
  const [tableSelData, setTableSelData] = useState({ rows: [], columns: [] });
  const [vendors, setVendors] = useState([{}]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendorProducts, setVendorProducts] = useState([]);
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [prod, setProd] = useState("");
  const [dummy, setDummy] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [qtyScan, setQtyScan] = useState(1);
  const [qtyManual, setQtyManual] = useState(0);
  const [available, setAvailable] = useState(0)
  const [availableQty, setAvailableQty] = useState(0)
  const [showManual, setShowManual] = useState(false);
  const [manualProd, setManualProd] = useState("");
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [scanReady, setScanReady] = useState(false);
  const [salesId, setSalesId] = useState(0)
  const [delivery, setDelivery] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [selectedAdjustment, setSelectedAdjustment] = useState({
    label: language == 1 ? "Añadir artículo que no esta en orden" : "Add item not in order",
    value: 4,
  });
  const [showWithhold, setShowWithhold] = useState(false);
  const [showWrongQty, setShowWrongQty] = useState(false);
  const [showQtyEdit, setShowQtyEdit] = useState(false)
  const [products, setProducts] = useState({ rows: [], columns: [] });
  const [showReturnWholeItem, setShowReturnWholeItem] = useState(false);
  const [delProdName, setDelProdName] = useState("");
  const [prodId, setProdId] = useState(0);
  const [showAdd, setShowAdd] = useState(false);

  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();
  const inputRef = useRef();

  async function GetVendors() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setVendors(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getShippedProducts() {
    console.log(localStorage.getItem("RecId"));
    await axios
      .get(IP + "/shippings/" + localStorage.getItem("ShipId") + "/shippings_detail", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("data")
        console.log(response.data);
        let vendorProductColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Pendiente" : "Pending", accessor: "available" },
          { Header: language == 1 ? "Cant. Enviada" : "Qty Shipped", accessor: "qty" },
          // { Header: language == 1 ? "Cant. Devuelta" : "Qty Returned", accessor: "qtyReturned" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let vendorProductRows = response.data.data.all.map((item) => {
          return {
            name: item.product.name,
            upc: item.product.upc,
            available: item.quantity_pending,
            qty: item.quantity_delivered,
            qtyTotal: item.quantity,
            qtyReturned: 0,
            prodid: item.product.id,
            action: (
              <>
                <Tooltip title={language==1 ? "Reiniciar" : "Reset"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      sendRequestReset(item.product.upc, item.quantity_delivered);
                    }}
                    iconOnly
                  >
                    <Icon>refresh</Icon>
                  </MDButton>
                </Tooltip>{" "}
                <Tooltip title={language==1 ? "Entrada Manual" : "Manual Entry"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      setShowManual(true);
                      setManualProd(item.id);
                    }}
                    iconOnly
                  >
                    123
                  </MDButton>
                </Tooltip>
              </>
            ),
          };
        });

        // console.log(vendorProductRows);

        let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
        setVendorProducts(vendorProductRows);
        //setTableData(tableDatax);

        let vendorProductColumnsSel = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Total Ordenado" : "Total Ordered", accessor: "available" },
          { Header: language == 1 ? "Cant. Enviada" : "Qty Shipped", accessor: "qty" },
          // { Header: language == 1 ? "Cant. Devuelta" : "Qty Returned", accessor: "qtyReturned" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        console.log("here");
        console.log(selectedAdjustment);

        let vendorProductRowsSel = response.data.data.selected.map((item) => {
          return {
            name: item.product.name,
            upc: item.product.upc,
            available: item.quantity,
            qty: item.quantity_delivery,
            qtyTotal: item.quantity,
            prodid: item.product.id,
            qtyReturned: item.quantity_returned ?? 0,
            action: (
              <>
                {selectedAdjustment?.value > 0 ? (
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      //   processAdjustment(Value?.value, item);
                    }}
                  >
                    {language == 1 ? "Ajustar" : "Adjust"}
                  </MDButton>
                ) : null}
              </>
            ),
          };
        });

        console.log(response.data.data?.shipping?.sales_order)
        setSalesId(response.data.data?.shipping?.sales_order?.sales_order_id)
        setDelivery(response.data.data?.shipping?.sales_order?.has_delivery)
        setTableData({ columns: vendorProductColumnsSel, rows: vendorProductRowsSel });
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getShippedProducts(Value) {
    console.log(localStorage.getItem("RecId"));
    await axios
      .get(IP + "/shippings/" + localStorage.getItem("ShipId") + "/shippings_detail", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        let vendorProductColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Pendiente" : "Pending", accessor: "available" },
          { Header: language == 1 ? "Cant. Enviada" : "Qty Shipped", accessor: "qty" },
          // { Header: language == 1 ? "Cant. Devuelta" : "Qty Returned", accessor: "qtyReturned" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let vendorProductRows = response.data.data.all.map((item) => {
          return {
            name: item.product.name,
            upc: item.product.upc,
            available: item.quantity_pending,
            qty: item.quantity_delivered,
            qtyTotal: item.quantity,
            qtyReturned: 0,
            prodid: item.product.id,
            action: (
              <>
                <Tooltip title={language==1 ? "Reiniciar" : "Reset"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      sendRequestReset(item.product.upc, item.quantity_delivered);
                    }}
                    iconOnly
                  >
                    <Icon>refresh</Icon>
                  </MDButton>
                </Tooltip>{" "}
                <Tooltip title={language==1 ? "Entrada Manual" : "Manual Entry"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      setShowManual(true);
                      setManualProd(item.id);
                    }}
                    iconOnly
                  >
                    123
                  </MDButton>
                </Tooltip>
              </>
            ),
          };
        });

        // console.log(vendorProductRows);

        let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
        setVendorProducts(vendorProductRows);
        //setTableData(tableDatax);

        let vendorProductColumnsSel = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: '25%' },
          { Header: "UPC", accessor: "upc", width: '20%' },
          { Header: language == 1 ? "Total Ordenado" : "Total Ordered", accessor: "available" },
          { Header: language == 1 ? "Cant. Enviada" : "Qty Shipped", accessor: "qty" },
          // { Header: language == 1 ? "Cant. Devuelta" : "Qty Returned", accessor: "qtyReturned" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: '25%' },
        ];

        let vendorProductRowsSel = response.data.data.selected.map((item) => {
          return {
            name: item.product.name,
            upc: item.product.upc,
            available: item.quantity,
            qty: item.quantity_delivery,
            qtyTotal: item.quantity,
            prodid: item.product.id,
            qtyReturned: item.quantity_returned ?? 0,
            action: (
              <MDBox sx={{float: 'right'}}>
                <Tooltip title={language==1 ? "Reiniciar Cantadidad" : "Reset Quantity"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      cancelReturn(item.id)
                    }}
                    iconOnly
                  >
                    <Icon>refresh</Icon>
                  </MDButton>
                </Tooltip>
                {" "}
                {Value?.value > 0 ? (
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      processAdjustment(Value?.value, item);
                    }}
                  >
                    { Value?.value != 5 
                      ?  language == 1 ? "Ajustar" : "Adjust" 
                      :  language == 1 ? "Devolver" : "Return"}
                  </MDButton>
                ) : null}
                {" "}
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={() => {
                    setShowReturnWholeItem(true);
                    setManualProd(item.id);
                    setDelProdName(item.product.name);
                  }}
                >
                  {language == 1 ? "Devolver" : "Return"}
                </MDButton>
                {" "}
                {Value?.value == 2 && 
                  <MDButton
                    variant="gradient"
                    color="light"
                    onClick={() => {
                      cancelReturn(item.id)
                    }}
                  >
                    {language == 1 ? "Cancelar" : "Cancel"}
                  </MDButton>
                }
              </MDBox>
            ),
          };
        });

        setSalesId(response.data.data?.shipping?.sales_order?.sales_order_id)
        setDelivery(response.data.data?.shipping?.sales_order?.has_delivery)
        setTableData({ columns: vendorProductColumnsSel, rows: vendorProductRowsSel });
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function changeDelivery() {
    await axios
      .get(
        IP + "/changeMethod/" + salesId,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false)
        setDelivery(!delivery)
      })
      .catch((error) => {
        // if (!error?.response?.data?.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function cancelReturn(id) {
    await axios
    .post(IP + "/shippings_detail/cancelAddQty/" + id, [], {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
      },
    })
    .then((response)=>{
      getShippedProducts(selectedAdjustment)
      getVendorProducts()
    })
    .catch((error) => {
      if (!error.response.data.authenticated) {
        navigate("/signIn");
      }
      console.log(error);
    });
  }

  function processAdjustment(option, item) {
    if (option == 1) {
      setShowManual(true);
      setManualProd(item.id);
    }

    if (option == 2) {
      setShowWithhold(true);
      setAvailable(item.product.available)
      setManualProd(item.id);
    }
    if (option == 3) {
      //console.log(item.quantity_delivery);
      setQtyManual(item.quantity_delivery);
      setShowWrongQty(true);
    }

    if (option == 4) {
      setShowQtyEdit(true);
      setAvailableQty(item.product.available + item.quantity_delivery)
      setAvailable(item.product.available)
      setManualProd(item.id);
      setQtyManual(item.quantity_delivery)
    }

    if (option == 5) {
      setShowReturnWholeItem(true);
      setManualProd(item.id);
      setDelProdName(item.product.name);
    }
  }

  async function getVendorProducts() {
    await axios
      .get(IP + "/products?all_products=true", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let vendorProductColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: language == 1 ? "Empaque" : "Packaging", accessor: "packaging" },
          { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
          { Header: "Diff.", accessor: "diff" },
          { Header: language == 1 ? "Cant." : "Qty", accessor: "qty" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];
        //test
        let vendorProductRows = response?.data?.data
          ?.filter((item) => {
            return item.active == "Active";
          })
          .map((item) => {
            return {
              name: item.name,
              upc: item.upc,
              code: item.code,
              packaging: item.packaging,
              available: item.available,
              diff: item.diff,
              price: item.cost,
              qty: 0,
              action: (
                <MDButton
                  color="info"
                  onClick={() => {
                    setShowAdd(true);
                    setProdId(item.id);
                    setDelProdName(item.name);
                    setAvailable(item.available)
                  }}
                  type="button"
                  variant="gradient"
                >
                  {language == 1 ? "Añadir" : "Add"}
                </MDButton>
              ),
            };
          });

        let tableData = { columns: vendorProductColumns ?? [], rows: vendorProductRows ?? [] };
        console.log(tableData);
        setProducts(tableData);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function sendPurchaseOrder() {
    // getShippedProducts();
    setDisableButton(true);
    await axios
      .post(
        IP + "/purchase_orders",
        {
          date: date,
          eda: eda,
          vendor_id: selectedVendor.id,
          status_id: 1,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        //  console.log(response);
        localStorage.setItem("purchaseOrderId", response.data.data.id);
        toggleSnackbar();
        setTimeout(() => {
          navigate("/edit-purchase_order");
        }, 5000);
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }
  
  async function getPurchaseOrder() {
    await axios
      .get(IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let orders = response.data.data;
        //  setDate(orders.date);
        //  setEda(orders.eda);
        ///  setSelectedVendor({ id: orders.vendor.id, label: orders.vendor.name });
        //   getShippedProducts(orders.vendor.id);
      });
  }

  function addToTable(input) {
    let columns = [{ Header: "UPC", accessor: "upc" }];
    let rows = [{ upc: input }].concat(tableData.rows);
    setTableData({ rows: rows, columns: columns });
  }

  async function sendRequest(input) {
    let prodx = vendorProducts.filter((item) => {
      return item.upc == input;
    });

    if (prodx.length > 0) {
      //let quantity = prod[0].quantityTotal;
      if (qtyScan !== "all" && prodx[0].available >= qtyScan) {
        let qtyReceived = qtyScan !== "all" ? qtyScan : prodx[0].available; //valor se cambia con radio button.
        //let prodid = prod[0].prodid;

        await axios
          .post(
            IP + "/shippings/scanner/" + input,
            {
              // quantity: quantity,
              quantity_delivery: qtyReceived,
              // product_id: prodid,
              shipping_id: localStorage.getItem("ShipId"),
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            //console.log(response.data);
            //   let vendorProductColumns = [
            //     { Header: "Name", accessor: "name" },
            //     { Header: "UPC", accessor: "upc" },
            //     { Header: "Pending", accessor: "available" },
            //     { Header: "Qty Received", accessor: "qty" },
            //     { Header: "Action", accessor: "action" },
            //   ];

            //   let vendorProductRows = response.data.data.all.map((item) => {
            //     return {
            //       name: item.product.name,
            //       upc: item.product.upc,
            //       available: item.quantity_pending,
            //       qty: item.quantity_received,
            //       qtyTotal: item.quantity,
            //       prodid: item.product.id,
            //       action: <></>,
            //     };
            //   });

            //   let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
            //   setVendorProducts(vendorProductRows);
            //   setTableData(tableDatax);
            // swal(
            //   "Recibido!",
            //   "Se guardo al sistema " + qtyReceived + " Unidades de " + prod[0].name,
            //   "success"
            // );
            getShippedProducts(selectedAdjustment);
          })
          .catch((error) => {
            if (!error.response.data.authenticated) {
              navigate("/signIn");
            }
            console.log(error);
          });
      } else if (qtyScan == "all") {
        let qtyReceived = qtyScan !== "all" ? qtyScan : prodx[0].available; //valor se cambia con radio button.
        //let prodid = prod[0].prodid;

        await axios
          .post(
            IP + "/shippings/scanner/" + input,
            {
              // quantity: quantity,
              quantity_delivery: qtyReceived,
              // product_id: prodid,
              shipping_id: localStorage.getItem("ShipId"),
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            //console.log(response.data);
            //   let vendorProductColumns = [
            //     { Header: "Name", accessor: "name" },
            //     { Header: "UPC", accessor: "upc" },
            //     { Header: "Pending", accessor: "available" },
            //     { Header: "Qty Received", accessor: "qty" },
            //     { Header: "Action", accessor: "action" },
            //   ];

            //   let vendorProductRows = response.data.data.all.map((item) => {
            //     return {
            //       name: item.product.name,
            //       upc: item.product.upc,
            //       available: item.quantity_pending,
            //       qty: item.quantity_received,
            //       qtyTotal: item.quantity,
            //       prodid: item.product.id,
            //       action: <></>,
            //     };
            //   });

            //   let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
            //   setVendorProducts(vendorProductRows);
            //   setTableData(tableDatax);
            // swal(
            //   "Recibido!",
            //   "Se guardo al sistema " + qtyReceived + " Unidades de " + prod[0].name,
            //   "success"
            // );
            getShippedProducts(selectedAdjustment);
          })
          .catch((error) => {
            if (!error.response.data.authenticated) {
              navigate("/signIn");
            }
            console.log(error);
          });
      } else {
        if (qtyScan == 25) {
          setQtyScan(10);
        } else if (qtyScan == 10) {
          setQtyScan(5);
        } else if (qtyScan == 5) {
          setQtyScan(1);
        }
        swal(
          "Alerta! Se intento enviar una cantidad por encima de lo disponible del Item. La Cantidad de Scan se ha reducido.",
          "",
          "warning"
        );
      }
    }
  }

  async function sendRequestReset(input, qty) {
    let prodx = vendorProducts.filter((item) => {
      return item.upc == input;
    });

    console.log(input);

    if (prodx.length > 0) {
      let qtyReceived = parseInt(qty) * -1; //valor se cambia con radio button.

      await axios
        .post(
          IP + "/shippings/scanner/" + input,
          {
            // quantity: quantity,
            quantity_delivery: qtyReceived,
            // product_id: prodid,
            shipping_id: localStorage.getItem("ShipId"),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          //console.log(response.data);
          //   let vendorProductColumns = [
          //     { Header: "Name", accessor: "name" },
          //     { Header: "UPC", accessor: "upc" },
          //     { Header: "Pending", accessor: "available" },
          //     { Header: "Qty Received", accessor: "qty" },
          //     { Header: "Action", accessor: "action" },
          //   ];

          //   let vendorProductRows = response.data.data.all.map((item) => {
          //     return {
          //       name: item.product.name,
          //       upc: item.product.upc,
          //       available: item.quantity_pending,
          //       qty: item.quantity_received,
          //       qtyTotal: item.quantity,
          //       prodid: item.product.id,
          //       action: <></>,
          //     };
          //   });

          //   let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
          //   setVendorProducts(vendorProductRows);
          //   setTableData(tableDatax);

          getShippedProducts(selectedAdjustment);
        })
        .catch((error) => {
          if (!error.response.data.authenticated) {
            navigate("/signIn");
          }
          console.log(error);
        });
    }
  }

  async function sendRequestManual(input, qty) {
    console.log("click");

    await axios
      .post(
        IP + "/shippings_detail/setReturnQuantity/" + manualProd,
        {
          quantity_returned: qtyManual,

          shipping_details_id: manualProd,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        //console.log(response.data);
        //   let vendorProductColumns = [
        //     { Header: "Name", accessor: "name" },
        //     { Header: "UPC", accessor: "upc" },
        //     { Header: "Pending", accessor: "available" },
        //     { Header: "Qty Received", accessor: "qty" },
        //     { Header: "Action", accessor: "action" },
        //   ];

        //   let vendorProductRows = response.data.data.all.map((item) => {
        //     return {
        //       name: item.product.name,
        //       upc: item.product.upc,
        //       available: item.quantity_pending,
        //       qty: item.quantity_received,
        //       qtyTotal: item.quantity,
        //       prodid: item.product.id,
        //       action: <></>,
        //     };
        //   });

        //   let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
        //   setVendorProducts(vendorProductRows);
        //   setTableData(tableDatax);

        getShippedProducts(selectedAdjustment);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function addQtyToItem(input, qty) {
    console.log("click");

    await axios
      .post(
        IP + "/shippings_detail/addQtyToItem/" + manualProd,
        {
          quantity_returned: qtyManual,

          shipping_details_id: manualProd,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        //console.log(response.data);
        //   let vendorProductColumns = [
        //     { Header: "Name", accessor: "name" },
        //     { Header: "UPC", accessor: "upc" },
        //     { Header: "Pending", accessor: "available" },
        //     { Header: "Qty Received", accessor: "qty" },
        //     { Header: "Action", accessor: "action" },
        //   ];

        //   let vendorProductRows = response.data.data.all.map((item) => {
        //     return {
        //       name: item.product.name,
        //       upc: item.product.upc,
        //       available: item.quantity_pending,
        //       qty: item.quantity_received,
        //       qtyTotal: item.quantity,
        //       prodid: item.product.id,
        //       action: <></>,
        //     };
        //   });

        //   let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
        //   setVendorProducts(vendorProductRows);
        //   setTableData(tableDatax);

        getShippedProducts(selectedAdjustment);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function editQty() {
    console.log("click");

    await axios
      .post(
        IP + "/shippings_detail/setItemQty/" + manualProd,
        {
          quantity: parseInt(qtyManual),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        //console.log(response.data);
        //   let vendorProductColumns = [
        //     { Header: "Name", accessor: "name" },
        //     { Header: "UPC", accessor: "upc" },
        //     { Header: "Pending", accessor: "available" },
        //     { Header: "Qty Received", accessor: "qty" },
        //     { Header: "Action", accessor: "action" },
        //   ];

        //   let vendorProductRows = response.data.data.all.map((item) => {
        //     return {
        //       name: item.product.name,
        //       upc: item.product.upc,
        //       available: item.quantity_pending,
        //       qty: item.quantity_received,
        //       qtyTotal: item.quantity,
        //       prodid: item.product.id,
        //       action: <></>,
        //     };
        //   });

        //   let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
        //   setVendorProducts(vendorProductRows);
        //   setTableData(tableDatax);

        getShippedProducts(selectedAdjustment);
        getVendorProducts()
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function addToOrder(input, qty) {
    console.log("click");

    await axios
      .post(
        IP + "/shippings_detail/addToOrder",
        {
          quantity_returned: qtyManual,

          shipping_details_id: manualProd,
          quantity: qtyManual,
          quantity_delivery: qtyManual,
          quantity_approved: qtyManual,
          product_id: prodId,
          shipping_id: localStorage.getItem("ShipId"),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        getShippedProducts(selectedAdjustment);
        getVendorProducts()
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function removeItem(input, qty) {
    console.log("click");

    await axios
      .post(
        IP + "/shippings_detail/removeItem",
        {
          quantity_returned: qtyManual,

          shipping_details_id: manualProd,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        //console.log(response.data);
        //   let vendorProductColumns = [
        //     { Header: "Name", accessor: "name" },
        //     { Header: "UPC", accessor: "upc" },
        //     { Header: "Pending", accessor: "available" },
        //     { Header: "Qty Received", accessor: "qty" },
        //     { Header: "Action", accessor: "action" },
        //   ];

        //   let vendorProductRows = response.data.data.all.map((item) => {
        //     return {
        //       name: item.product.name,
        //       upc: item.product.upc,
        //       available: item.quantity_pending,
        //       qty: item.quantity_received,
        //       qtyTotal: item.quantity,
        //       prodid: item.product.id,
        //       action: <></>,
        //     };
        //   });

        //   let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };
        //   setVendorProducts(vendorProductRows);
        //   setTableData(tableDatax);

        getShippedProducts(selectedAdjustment);
        getVendorProducts()
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendConfirmRequest() {
    await axios
      .post(
        IP + "/shippings/setShipped/" + localStorage.getItem("ShipId"),
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const timer = () =>
    setTimeout(() => {
      setInputValue("");
    }, 200);

  const audioRef = useRef(null);
  const play = (url) => {
    //   audioRef.current.play();
  };

  const AdjustmentOptions = [
    // {
    //   label:
    //     language == 1
    //       ? "Devolver cantidad artículo enviado de mas"
    //       : "Return item qty that was oversent",
    //   value: 1,
    // },
    {
      label: language == 1 ? "Retención del artículo enviado de mas" : "Withhold oversent item",
      value: 2,
    },
    {
      label: language == 1 ? "Añadir artículo que no esta en orden" : "Add item not in order",
      value: 4,
    },
    // {
    //   label: language == 1 ? "Artículo erroneo devolución" : "Return wrong item",
    //   value: 5,
    // },
  ];

  useEffect(() => {
    getShippedProducts({
      label: language == 1 ? "Añadir artículo que no esta en orden" : "Add item not in order",
      value: 4,
    });
    getVendorProducts();
    console.log(selectedAdjustment)
    // setSweet(true);
  }, []);

  return (
    <DashboardLayout>
      <PurchaseOrderModal open={open} Product={prod} setOpen={setOpen} />
      <DashboardNavbar />
      <AlertDialog
        Show={showReturnWholeItem}
        setShowConfirm={setShowReturnWholeItem}
        Message={
          language == 1
            ? "¿Estás seguro que quieres devolver este producto " + delProdName + "?"
            : "Are you sure you want to delete product " + delProdName + " from the order?"
        }
        OkayFunction={removeItem}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "El Envío fue confirmado exitosamente!"
            : "The shipping was confirmed successfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />

      <AlertDialog
        Show={confirm}
        setShowConfirm={setConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres confirmar este envío ?"
            : "Are you sure you want to confirm this shipping ?"
        }
        OkayFunction={sendConfirmRequest}
      />

      {showManual ? (
        <ThemeProvider theme={darkTheme}>
          <Dialog open={showManual}>
            <DialogTitle>
              {language == 1 ? "Cantidad Para Devolver:" : "Quantity To Return:"}
            </DialogTitle>

            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={qtyManual}
                label={language == 1 ? "Cantidad" : "Quantity"}
                type="number"
                onChange={(e) => {
                  if (e.target.value >= 0 || e.target.value == "") {
                    setQtyManual(e.target.value);
                  }
                }}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setQtyManual(0);
                  setShowManual(false);
                  setManualProd("");
                }}
              >
                {language == 1 ? "Cancelar" : "Cancel"}
              </Button>
              <Button
                onClick={() => {
                  sendRequestManual(manualProd, qtyManual);
                  setTimeout(() => {
                    setQtyManual(0);
                    setShowManual(false);
                    setManualProd("");
                  }, 1000);
                }}
              >
                {language == 1 ? "Guardar" : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      ) : null}

      {showWithhold ? (
        <ThemeProvider theme={darkTheme}>
          <Dialog open={showWithhold}>
            <DialogTitle>
              {language == 1 ? "Cantidad Para Aguantar:" : "Quantity To Withhold:"}
            </DialogTitle>

            <DialogContent>
              <MDTypography variant="subtitle1">
                {language == 1 ? "Disponible" : "Available"} {available}
              </MDTypography>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={qtyManual}
                label={language == 1 ? "Cantidad" : "Quantity"}
                type="number"
                onChange={(e) => {
                  if ((e.target.value >= 0 || e.target.value == "") && e.target.value <= available) {
                    setQtyManual(e.target.value);
                  }
                }}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setQtyManual(0);
                  setShowWithhold(false);
                  setManualProd("");
                }}
              >
                {language == 1 ? "Cancelar" : "Cancel"}
              </Button>
              <Button
                onClick={() => {
                  addQtyToItem(manualProd, qtyManual);
                  setTimeout(() => {
                    setQtyManual(0);
                    setShowWithhold(false);
                    setManualProd("");
                  }, 1000);
                }}
              >
                {language == 1 ? "Guardar" : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      ) : null}

      <ThemeProvider theme={darkTheme}>
        <Dialog open={showQtyEdit}>
          <DialogTitle>
            {language == 1 ? "Ajustar Cantidad Enviada:" : "Set Quantity Shipped:"}
          </DialogTitle>

          <DialogContent>
            <MDTypography variant="subtitle1">
              {language == 1 ? "Disponible" : "Available"} {available}
            </MDTypography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={qtyManual}
              label={language == 1 ? "Cantidad" : "Quantity"}
              type="number"
              onChange={(e) => {
                if ((e.target.value >= 0 || e.target.value == "") && e.target.value <= availableQty) {
                  setQtyManual(e.target.value);
                }
              }}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setQtyManual(0);
                setShowQtyEdit(false);
                setManualProd("");
              }}
            >
              {language == 1 ? "Cancelar" : "Cancel"}
            </Button>
            <Button
              onClick={() => {
                editQty()
                setTimeout(() => {
                  setQtyManual(0);
                  setShowQtyEdit(false);
                  setManualProd("");
                }, 1000);
              }}
            >
              {language == 1 ? "Guardar" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>

      {showWrongQty ? (
        <ThemeProvider theme={darkTheme}>
          <Dialog open={showWrongQty}>
            <DialogTitle>
              {language == 1
                ? "Cantidad Correcta de este Producto:"
                : "Correct Qty for this Product:"}
            </DialogTitle>

            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={qtyManual}
                label={language == 1 ? "Cantidad" : "Quantity"}
                type="number"
                onChange={(e) => {
                  if (e.target.value >= 0 || e.target.value == "") {
                    setQtyManual(e.target.value);
                  }
                }}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setQtyManual(0);
                  setShowWrongQty(false);
                  setManualProd("");
                }}
              >
                {language == 1 ? "Cancelar" : "Cancel"}
              </Button>
              <Button
                onClick={() => {
                  //To Do: Funcion de Withhold
                  // sendRequestManual(manualProd, qtyManual);
                  setTimeout(() => {
                    setQtyManual(0);
                    setShowWrongQty(false);
                    setManualProd("");
                  }, 1000);
                }}
              >
                {language == 1 ? "Guardar" : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      ) : null}

      {showAdd ? (
        <ThemeProvider theme={darkTheme}>
          <Dialog open={showAdd}>
            <DialogTitle>
              {language == 1
                ? "Cantidad Para " + delProdName + ": "
                : "Quantity for " + delProdName + ": "}
            </DialogTitle>

            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={qtyManual}
                label={language == 1 ? "Cantidad" : "Quantity"}
                type="number"
                onChange={(e) => {
                  if ((e.target.value >= 0 || e.target.value == "") && e.target.value <= available) {
                    setQtyManual(e.target.value);
                  }
                }}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setShowAdd(false)
                setQtyManual(0)
              }}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
              <Button
                disabled={qtyManual == 0}
                onClick={() => {
                  addToOrder(manualProd, qtyManual);
                  setTimeout(() => {
                    setQtyManual(0);
                    setShowAdd(false);
                    setManualProd("");
                  }, 1000);
                }}
              >
                {language == 1 ? "Guardar" : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      ) : null}

      <MDBox py={3} mb={20} height="65vh">
        <Card>
          <MDBox p={2}>
            {/* <Grid container>
              <Grid item xs={4}>
                <Autocomplete
                  // sx={{ width: "25%", ml: 1, mt: 1 }}
                  disablePortal
                  value={selectedAdjustment}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setSelectedAdjustment(newValue);
                    getShippedProducts(newValue);
                  }}
                  options={AdjustmentOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Tipo de Ajuste" : "Adjustment Type"}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item lg={.5} />
              <Grid item lg={4}>
                {" "}
                <MDButton
                  variant="gradient"
                  onClick={() => {
                    navigate('/emos/shippingList')
                  }}
                  color="info"
                >
                  {language == 1 ? "Cerrar" : "Close"}
                </MDButton>
              </Grid>
            </Grid> */}
            <Grid>
              <DataTable height="50%" table={tableData} entriesPerPage={false} canSearch />
            </Grid>
            {selectedAdjustment?.value == 4 ? (
              <>
                <label>
                  {language == 1
                    ? "Buscar productos para añadir a la orden"
                    : "Search for Products not in Order"}
                </label>
                <MDBox sx={{float: 'right', mr: 1}}>
                  {/* <MDTypography variant="h6">Delivery/Pickup</MDTypography> */}
                  <Checkbox
                    checked={delivery}
                    onChange={() => {
                      if (!loading)
                        changeDelivery()
                      setLoading(true)
                    }}
                    sx={{mr: 5}}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <MDButton
                    variant="gradient"
                    onClick={() => {
                      navigate('/emos/shippingList')
                    }}
                    color="light"
                  >
                    {language == 1 ? "Cerrar" : "Close"}
                  </MDButton>
                </MDBox>
                <MDTypography variant={"h6"} sx={{float: "right", mt: 1}}>Delivery</MDTypography>
                <hr />
                <DataTable table={products} entriesPerPage={false} canSearch />
              </>
            ) : null}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default AdjustShipping;
