/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import "bootstrap/dist/css/bootstrap.min.css";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";

const axios = require("axios");

function PurchaseOrderValidation() {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState("")
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const {state} = useLocation();

  const navigate = useNavigate();

  async function getPurchaseOrders() {
    await axios
      .get(IP + "/purchase_orders", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let statePO = false;
        setPurchaseOrders(
          response.data.data.map(item=> {
            if (state?.id == item.id) {
              statePO = {id: item.id, label: item.po_number}
              getData(parseInt(item.po_number, 10))
            }
            return {id: item.id, label: item.po_number}
          })
        )
        if (statePO)
          setSelectedPurchaseOrder(statePO)
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getData(po_number) {
    await axios
      .get(IP + "/validate-purchases?po_number=" + po_number, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let columns = [
          { Header: language == 1 ? "Número de Orden" : "PO Number", accessor: "number" },
          { Header: language == 1 ? "UPC Caja" : "UPC Box", accessor: "caja" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "unit" },
          { Header: language == 1 ? "Producto" : "Product", accessor: "product" },
          { Header: "Error", accessor: "error" }
        ];

        let rows = response.data.data.map((item) => {
          return {
            number: item.PONumber,
            caja: item.UPC_CAJA,
            unit: item.UPC_UNIT,
            product: item.description,
            error: item.error_message
          };
        });

        let data = { columns: columns, rows: rows };
        //console.log(tableData)
        setTableData(data);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    getPurchaseOrders()
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <MDBox my={3}>
          <Card>
            <Grid container spacing={1} ml={1} mt={1}>
              <Grid item lg={6}>
                <Autocomplete
                    disablePortal
                    value={selectedPurchaseOrder}
                    onChange={(event, newValue) => {
                        if (newValue == null)
                          setTableData({...tableData, rows: []})
                        else
                          getData(parseInt(newValue.label, 10))
                        setSelectedPurchaseOrder(newValue);
                    }}
                    options={purchaseOrders}
                    fullWidth
                    renderInput={(params) => (
                        <MDInput
                        {...params}
                        label={language == 1 ? "Ordenes" : "Orders"}
                        variant="standard"
                        />
                    )}
                />
              </Grid>
            </Grid>
            <DataTable table={tableData} entriesPerPage={true} canSearch />
          </Card>
        </MDBox>
    </DashboardLayout>
  );
}

export default PurchaseOrderValidation;
