/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProducts from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

import SignIn from "./app/login/signin";
import ViewStores from "app/stores";
import CreateStores from "app/stores/createStore";
import EditStore from "app/stores/editStore";
import TaxList from "app/taxes/taxlist";

import AddTax from "app/taxes/addtax";
import WarehouseList from "app/warehouses/warehouses";
import AddWarehouse from "app/warehouses/addWarehouse";
import EditWarehouse from "app/warehouses/editWarehouse";
import MeasurementList from "app/measurements/measurements";
import AddMeasurement from "app/measurements/addMeasurements";
import EditMeasurement from "app/measurements/editMeasurement";
import Cities from "app/cities/cities";
import AddCity from "app/cities/addcity";
import EditCity from "app/cities/editcity";
import Categories from "app/categories/categories";
import Addcategory from "app/categories/addCategory";
import EditCategory from "app/categories/editCategory";
import SubCategories from "app/subcategories/subcategories";
import AddSubCategory from "app/subcategories/addSubCategory";
import EditSubCategory from "app/subcategories/editSubCategory";
import ProductList from "app/products/products";
import AddProduct from "app/products/addProduct";
import EditProduct from "app/products/editProduct";

import ViewVendors from "app/vendors";
import CreateVendors from "app/vendors/createVendors";
import EditVendor from "app/vendors/editVendor";
import CreateUser from "app/users/createUser";
import EditUser from "app/users/editUser";
import ViewUsers from "app/users";
import UserProfile from "app/users/userProfile";
import UserProfileLayout from "app/users/userProfileLayout";
import Receiving from "app/receiving/receiving";
import ReceivingList from "app/receiving/receivingList";
import Shipping from "app/shipping/shipping";
import ShippingList from "app/shipping/shippingList";
import CreateShipping from "app/shipping/createShipping";
import ReceivingsList from "app/receivings/receivings";
import RegisterPayments from "app/registerPayments/registerPayments";
import ForgotPassword from "app/login/forgotPassword";
import ResetPassword from "app/login/resetPassword";
import ExpirationDateForm from "app/receiving/expirationDateForm";
import AdjustShipping from "app/shipping/adjustShipping";
import InvoiceListAdmin from "app/invoices/invoiceIndexAdmin";
import InvoicePaymentList from "app/invoices/invoicePaymentsIndex";
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import InvoiceList from "app/invoices/invoiceIndex";
import AccessDenied from "app/components/access/accessDenied";
import ViewPayments from "app/payments/viewPayments";

const routesR = [
  {
    hide: false,
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    module: "Dashboard",
    roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Stores",
    nombre: "Tiendas",
    key: "stores",
    module: "Stores",
    roles: [1],
    icon: <Icon fontSize="medium">home-city</Icon>,
    collapse: [
      {
        name: "View Stores",
        nombre: "Ver Tiendas",
        key: "viewstores2",
        route: "/emos/viewStores",
        component: <ViewStores />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Vendors",
    nombre: "Vendedores",
    module: "Vendors",
    key: "vendors",
    roles: [1],
    icon: <Icon fontSize="medium">warehouse</Icon>,
    collapse: [
      {
        name: "View Vendors",
        nombre: "Ver Vendedores",
        key: "viewvendors",
        route: "/emos/viewVendors",
        component: <ViewVendors />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Users",
    nombre: "Usuarios",
    module: "Users",
    key: "users",
    roles: [1],
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "View Users",
        nombre: "Ver Usuarios",
        key: "viewusers",
        route: "/emos/viewUsers",
        component: <ViewUsers />,
      },
    ],
  },
  {
    name: "Access Denied",
    key: "accessdenied",
    route: "/access-denied",
    component: <AccessDenied />,
  },
  {
    name: "Invoice Payments",
    key: "invoicepayments",
    module: "Invoice Admin",
    route: "/invoice-payments-admin",
    component: <InvoicePaymentList />,
  },
  {
    name: "Edit Store",
    key: "editStore",
    module: "Stores",
    route: "/editStore",
    component: <EditStore />,
  },
  {
    name: "Edit Vendor",
    key: "editVendor",
    module: "Vendors",
    route: "/editVendor",
    component: <EditVendor />,
  },
  {
    name: "Create Users",
    key: "createUsers",
    module: "Users",
    route: "/emos/createUsers",
    component: <CreateUser />,
  },
  {
    name: "Receiving",
    key: "receiving",
    module: "Vendors",
    route: "/receiving",
    component: <ReceivingsList />,
  },
  {
    name: "Expiration Dates",
    key: "expirationdates",
    module: "Stores",
    route: "/expiration-dates",
    component: <ExpirationDateForm />,
  },
  {
    name: "Edit User",
    key: "editUser",
    module: "Users",
    route: "/editUser",
    component: <EditUser />,
  },
  {
    name: "Create Stores",
    key: "createStores",
    module: "Stores",
    route: "/emos/createStores",
    component: <CreateStores />,
  },
  {
    name: "Create Vendor",
    key: "createVendor",
    module: "Vendors",
    route: "/emos/createVendors",
    component: <CreateVendors />,
  },
  {
    name: "User Profile",
    key: "userprof",
    route: "/userProfile",
    component: <UserProfileLayout />,
  },
  {
    name: "Forgot Password",
    key: "forgotpassword",
    route: "/forgotPassword",
    component: <ForgotPassword />,
  },
  {
    name: "Reset Password",
    key: "resetpassword",
    route: "/reset-password/:userToken",
    component: <ResetPassword />,
  },
  {
    name: "Receiving",
    key: "receivings",
    //hide: true,
    module: "Purchase Orders",
    route: "/emos/receiving",
    component: <Receiving />,
  },
  {
    name: "Receiving List",
    key: "receivinglist",
    //hide: true,
    module: "Purchase Orders",
    route: "/emos/receivingList",
    component: <ReceivingList />,
  },
  {
    name: "Shipping",
    key: "shipping",
    //hide: true,
    module: "Sales",
    route: "/emos/shipping",
    component: <Shipping />,
  },
  {
    name: "Shipping List",
    key: "shippinglist",
    //hide: true,
    module: "Sales",
    route: "/emos/shippingList",
    component: <ShippingList />,
  },
  {
    name: "Shipping Adjustment",
    key: "shippingadjustment",
    //hide: true,
    module: "Sales",
    route: "/emos/shippingAdjustment",
    component: <AdjustShipping />,
  },
  {
    name: "Create Shipping",
    key: "createshipping",
    //hide: true,
    module: "Sales",
    route: "/emos/createShipping",
    component: <CreateShipping />,
  },
  // {
  //   name: "Add Measurement",
  //   key: "addmeasurement",
  //   module: "Configuration",
  //   route: "/add-measurement",
  //   component: <AddMeasurement />,
  // },
  {
    hide: true,
    type: "collapse",
    name: "App",
    key: "App",
    roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "SignIn",
        key: "signIn",
        route: "/SignIn",
        component: <SignIn />,
      },
    ],
  },
];

export default routesR;
