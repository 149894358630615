import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import IP from "app/IP";

const axios = require("axios");

function CreateDelivery() {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState();
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();

  async function sendDelivery() {
    setDisableButton(true);
    await axios
      .post(
        IP + "/deliveries",
        { name: name, amount: amount, active: true },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          navigate("/delivery");
        }, 3000);
        console.log(response);
        toggleSnackbar();
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1 ? "Tarifa creada exitosamente!" : "Fee Created Succesfully"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox lineHeight={0}>
                  <MDTypography variant="h4">
                    {language == 1 ? "Crear Envío" : "Create Delivery"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    {language == 1
                      ? "Favor de entrar la información de la tarifa de envío"
                      : "Please enter delivery fee information."
                    }
                  </MDTypography>
                </MDBox>
                <MDBox mt={1.625}>
                  <Grid item xs={12} sm={"auto"}>
                    <MDInput
                      type="text"
                      label={language == 1 ? "Nombre" : "Name"}
                      variant="standard"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                    />
                    <ShowError id={"name"} />
                    <Grid container spacing={3}>
                    <Grid item xs={12} sm={20}>
                        <MDInput
                          type="number"
                          label={language == 1 ? "Cantidad" : "Amount"}
                          variant="standard"
                          value={amount}
                          onChange={(e) => {
                            if (e.target.value >= 0) {
                              setAmount(e.target.value)
                            }
                          }}
                          fullWidth
                        />
                        <ShowError id={"amount"} />
                      </Grid>
                    </Grid>
                    <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={() => navigate("/delivery")}
                      >
                        {language == 1 ? "Cancelar" : "Cancel"}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={sendDelivery}
                        disabled={disableButton}
                      >
                        {language == 1 ? "Guardar" : "Save"}
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateDelivery;
