/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DataTable2 from "app/components/shopperTable";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useNavigate, Navigate } from "react-router-dom";

import DataTable from "examples/Tables/DataTable";
import Tooltip from "@mui/material/Tooltip";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import AlertDialog from "app/components/confirmBox";
import MDSnackbar from "components/MDSnackbar";

import IP from "../IP";

const axios = require("axios");

function StorePayments() {
  const [data, setData] = useState({ columns: [], rows: [] });
  const [index, setIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [id, setId] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [show, setShow] = useState(false);
  const [active, setActive] = useState();
  const [initialBalance, setInitialBalance] = useState(0.00)
  const [balance, setBalance] = useState(0.00)
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const [showSnackbar2, setShowSnackbar2] = useState(false);
  const toggleSnackbar2 = () => setShowSnackbar2(!showSnackbar2);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const navigate = useNavigate();

  async function getPayments() {
    await axios
      .get(IP + "/storeBalancePayments/getByStore/" + localStorage.getItem("StoreId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setInitialBalance(response.data.data[0]?.store.initial_balance ?? 0.00)
        setBalance(response.data.data[0]?.store.balance ?? 0.00)
        let storeColums = [
          {
            Header: language == 1 ? "Nombre de Tienda" : "Store Name",
            accessor: "name",
            width: "20%",
          },
          { Header: language == 1 ? "Número de Factura" : "Invoice Number", accessor: "invoice" },
          { Header: language == 1 ? "Cantidad" : "Amount", accessor: "amount" },
          { Header: language == 1 ? "Paid Date" : "Paid Date", accessor: "date" },
          { Header: language == 1 ? "Acciones" : "Actions", accessor: "actions" },
        ];

        let paymentRows = response.data.data.map((item) => {
          return {
            name: item.store?.name,
            invoice: item.invoice_number,
            amount: item.amount,
            date: item.date,
            actions: (
              <>
                <Grid>
                  <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        localStorage.setItem("StorePayId", item.id);
                        navigate("/edit-store-payments");
                      }}
                      iconOnly
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                  </Tooltip>
                  {"  "}
                  <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                    <MDButton
                      variant="gradient"
                      color="error"
                      onClick={() => {
                        setShowConfirm(true)
                        setId(item.id)
                      }}
                      iconOnly
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </Tooltip>
                </Grid>
              </>
            ),
          };
        });
        setData({ rows: paymentRows, columns: storeColums });
      })
      .catch(function (error) {});
  }

  async function DeletePayment() {
    await axios
      .delete(IP + "/storeBalancePayments/" + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        getPayments();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getPayments();
  }, []);

  return (
    <DashboardLayout>
      {redirect && <Navigate push={true} to="/editStore" />}
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres borrar este pago?"
            : "Are you sure you want to delete this payment?"
        }
        OkayFunction={DeletePayment}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="EMOS"
        content={
          language == 1
            ? `Tienda ${active ? "Activada" : "Desactivada"} Exitosamente!`
            : `Store ${active ? "Activated" : "Deactivated"} Successfully!`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showSnackbar}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title="EMOS"
        content={
          language == 1
            ? `La tienda no se pudo borrar. Tiene transacciones registradas.`
            : `Could not delete store. It has transactions recorded.`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showSnackbar2}
        close={toggleSnackbar2}
      />
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            navigate("/create-store-payments");
          }}
        >
          {language == 1 ? "Crear Pago" : "Create Payment"}
        </MDButton>
      </MDBox>
      <Card>
        <MDBox p={2}>
        <MDTypography variant='h5'>
          {language == 1 ? "Balance Inicial" : "Initial Balance"}: ${parseFloat(initialBalance).toFixed(2)}
        </MDTypography>
        <MDTypography variant="h5">
          Balance: ${parseFloat(balance).toFixed(2)}
        </MDTypography>
        </MDBox>
        <DataTable2
          table={data}
          index={index}
          setIndex={setIndex}
          pageEntries={10}
          entriesPerPage={true}
          canSearch
        />
      </Card>
    </DashboardLayout>
  );
}

export default StorePayments;
