import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import IP from "app/IP";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const axios = require("axios");

export default function LanguageModal({ Show, setShow }) {
  const [open, setOpen] = React.useState(false);
  const [language, setLanguage] = React.useState(localStorage.getItem("Language"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function sendLang(langChoice) {
    await axios
      .post(
        IP + "/auth/setLang",
        { lang: langChoice },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  // console.log(Show);

  return (
    <div>
      {/*  <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <ThemeProvider theme={darkTheme}>
        <Dialog
          open={Show}
          onClose={() => {
            setShow(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {language == 1 ? "Cambiar Lenguaje" : "Change Language"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Button
                variant={language != 1 ? "contained" : "outlined"}
                type="button"
                disabled={language == 1}
                color="info"
                style={{ color: "white" }}
                onClick={() => {
                  setShow(false);
                  localStorage.setItem("Language", 1);
                  sendLang(1);
                }}
              >
                Español
              </Button>{" "}
              <Button
                style={{ color: "white" }}
                type="button"
                variant={language == 1 ? "contained" : "outlined"}
                disabled={language != 1}
                color="info"
                onClick={() => {
                  setShow(false);
                  localStorage.setItem("Language", 0);
                  sendLang(0);
                }}
              >
                English
              </Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
