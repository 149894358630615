/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
// Material Dashboard 2 React Components
import MDInput from "components/MDInput";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import Autocomplete from "@mui/material/Autocomplete";

import MDSnackbar from "components/MDSnackbar";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import IP from "../IP";
import { Navigate } from "react-router-dom";
import { Shower } from "@mui/icons-material";

const axios = require("axios");

function StorePaymentsEdit() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [data, setData] = useState({ columns: [], rows: [] });
  const [dataToShow, setDataToShow] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [storeName, setStoreName] = useState("");
  const [storeEmail, setStoreEmail] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [storeAddress2, setStoreAdress2] = useState("");
  const [storeNumber, setStoreNumber] = useState();
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [contact, setContact] = useState("");
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [deliveries, setDeliveries] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [pendingBalance, setPendingBalance] = useState(0.0);
  const [initialBalance, setInitialBalance] = useState(0.0);
  const [paymentDate, setPaymentDate] = useState(currentDate);
  const [paymentAmount, setpaymentAmount] = useState(0.0);
  const [storeBalance, setStoreBalance] = useState(0.00)
  const [invoice, setInvoice] = useState("");

  const toggleSnackbar = () => setShow(!show);
  const navigate = useNavigate();

  async function GetCities(id) {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/countries/" + id + "/states", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setCities(
          response.data.data.map((item) => {
            return { label: item.name, id: item.city_id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetCountries() {
    await axios
      .get(IP + "/countries", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setCountries(
          response.data.data.map((item) => {
            return { label: item.name, value: item.country_id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetDeliveries() {
    await axios
      .get(IP + "/deliveries", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        let deli = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            deli.push({ label: item.name, value: item.id });
          }
        });

        setDeliveries(deli);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getZones() {
    await axios
      .get(IP + "/zones", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setZones(
          response.data.data.map((item) => {
            return { id: item.zone_id, label: item.name };
          })
        );
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function CreateStore() {
    setDisableButton(true);
    console.log(selectedDelivery.id);
    await axios
      .post(
        IP + "/stores",
        {
          name: storeName,
          address1: storeAddress,
          address2: storeAddress2,
          zipcode: zipcode,
          phone: phone,
          contact_name: contact,
          contact_phone: phone2,
          contact_email: storeEmail,
          city_id: selectedCity?.id,
          credit_limit: 0,
          location_id: selectedDelivery?.value,
          zone_id: selectedZone?.id,
          active: true,
          store_number: storeNumber,
          country_id: selectedCountry?.value,
          pending_balance: pendingBalance,
          initial_balance: initialBalance,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        //console.log(response.data.data);
        toggleSnackbar();
        setTimeout(() => {
          navigate("/emos/viewStores");
        }, 3000);
      })
      .catch(function (error) {
        setDisableButton(false);
        console.log(error.response.data);
        setErrors(error.response.data.data);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  async function getPayment() {
    await axios
      .get(IP + "/storeBalancePayments/" + localStorage.getItem("StorePayId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);

        setPaymentDate(response.data.data?.date);
        setInvoice(response.data.data?.invoice_number);
        setpaymentAmount(response.data.data?.amount);
        setStoreBalance(response.data.data.store.balance + parseFloat(response.data.data?.amount))
      })
      .catch(function (error) {
        console.log(error.response.data);
      });
  }

  async function savePayment() {
    setDisableButton(true);
    await axios
      .post(
        IP + "/storeBalancePayments/" + localStorage.getItem("StorePayId"),
        {
          _method: "put",
          store_id: localStorage.getItem("StoreId"),
          amount: paymentAmount,
          invoice_number: invoice,
          date: paymentDate,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(function (response) {
        //console.log(response.data.data);
        toggleSnackbar();
        setTimeout(() => {
          navigate("/store-payments");
        }, 3000);
      })
      .catch(function (error) {
        setDisableButton(false);
        console.log(error.response.data);
        setErrors(error.response.data.data);
      });
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  useEffect(() => {
    // GetCountries();
    //GetDeliveries();
    //  getZones();

    getPayment();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content="Balance Payment Created Successfully!"
        dateTime="Now"
        open={show}
        close={toggleSnackbar}
      />
      <MDBox py={3} mb={30} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={12}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox>
                  <MDBox>
                    <MDBox lineHeight={0}>
                      <MDTypography variant="h5">
                        {language == 1 ? "Editar Pago de Balance" : "Edit Balance Payment"}
                      </MDTypography>
                      <MDTypography variant="button" color="text">
                        {language == 1
                          ? "Favor de Entrar la información del pago."
                          : "Please enter payment information."}
                      </MDTypography>
                    </MDBox>
                    <MDBox id="box1" mt={1.625}>
                      <Grid container spacing={3}>
                        <Grid item xs={2} sm={4}>
                          <MDInput
                            variant="standard"
                            type="date"
                            focused={true}
                            label={language == 1 ? "Fecha de Pago" : "Payment Date"}
                            fullWidth
                            value={paymentDate}
                            onChange={(e) => {
                              setPaymentDate(e.target.value);
                            }}
                          />
                          <ShowError id={"phone"} />
                        </Grid>
                        <Grid item xs={2} sm={4}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Cantidad de Pago" : "Payment Amount"}
                            fullWidth
                            value={paymentAmount}
                            onChange={(e) => {
                              console.log(storeBalance)
                              if (e.target.value >= 0 && e.target.value <= storeBalance) 
                                setpaymentAmount(e.target.value);
                            }}
                          />
                          <ShowError id={"contact_phone"} />
                        </Grid>
                        <Grid item xs={2} sm={4}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Número de Factura" : "Invoice Number"}
                            fullWidth
                            type="number"
                            value={invoice}
                            onChange={(e) => {
                              if (e.target.value >= 0)
                                setInvoice(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                  <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                    <MDButton
                      type="button"
                      variant="gradient"
                      className="float-sm-right"
                      color="light"
                      style={{ marginRight: "1%" }}
                      onClick={() => {
                        navigate("/store-payments");
                      }}
                    >
                      {language == 1 ? "Cancelar" : "Cancel"}
                    </MDButton>
                    <MDButton
                      type="button"
                      variant="gradient"
                      className="float-sm-right"
                      color="info"
                      disabled={disableButton}
                      onClick={savePayment}
                    >
                      {language == 1 ? "Guardar" : "Save"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default StorePaymentsEdit;
