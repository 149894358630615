import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";
import PurchaseOrderModal from "app/components/purchaseOrderModal";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import swal from "sweetalert";
import Beep from "../../app/components/audio/beep.mp3";
import AlertDialog from "app/components/confirmBox";

import IP from "app/IP";

const axios = require("axios");

function ExpirationDateForm() {
  const [tableData, setTableData] = useState({ rows: [], columns: [] });
  const [tableSelData, setTableSelData] = useState({ rows: [], columns: [] });
  const [data, setData] = useState([])

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [errors, setErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [prod, setProd] = useState("");

  const [changeTable, setChangeTable] = useState(true);

  const [qtyProd, setQtyProd] = useState(0);
  const [showManual, setShowManual] = useState(false);
  const [manualProd, setManualProd] = useState("");
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [showManualEdit, setShowManualEdit] = useState(false);
  const [productId, setProductId] = useState(0);

  const [expireDate, setExpireDate] = useState("");
  const [expireProductId, setExpireProductId] = useState(0);
  const [previousQty, setPreviousQty] = useState(0);
  const [showBox, setShowBox] = useState(false);

  const toggleSnackbar = () => setShow(!show);

  const toggleSnackbar2 = () => {
    setShow2(true);
    setTimeout(() => {
      setShow2(false);
    }, 3000);
  };

  const toggleSnackbar3 = () => {
    setShow3(true);
    setTimeout(() => {
      setShow3(false);
    }, 3000);
  };

  const toggleSnackbar4 = () => {
    setShow4(true);
    setTimeout(() => {
      setShow4(false);
    }, 3000);
  };

  const navigate = useNavigate();
  const inputRef = useRef();

  async function getExpirePendingProducts() {
    console.log(localStorage.getItem("RecId") + " recid");
    await axios
      .get(IP + "/expiration/index/" + localStorage.getItem("RecId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.data?.selected?.length == 0)
          setChangeTable(true)
        setData(response.data.data);
        let vendorProductColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          { Header: language == 1 ? "Cant. Recibida" : "Qty Received", accessor: "qty" },
          {
            Header: language == 1 ? "Pendiente Sin Fecha" : "Pending Without Dates",
            accessor: "available",
          },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let vendorProductRows = response.data.data.all
          .filter((prod) => {
            return prod.product.expires == 1;
          })
          .map((item) => {
            console.log(parseInt(item.quantity_register));
            return {
              name: item?.product?.name,
              upc: item?.product?.upc,
              available: parseInt(item.quantity_received) - parseInt(item.quantity_register),
              qty: item.quantity_received,
              qtyTotal: item.quantity,
              prodid: item.product.id,
              vendor: item?.product?.vendor?.name,
              action: (
                <>
                  <MDButton
                    variant="gradient"
                    style={{ float: "right" }}
                    onClick={() => {
                      setShowManual(true);
                      setManualProd(item?.product?.name);
                      setProductId(item?.product?.id);
                      setPreviousQty(parseInt(item.quantity_received) - parseInt(item.quantity_register));
                    }}
                    color="info"
                  >
                    {language == 1 ? "Añadir" : "Add"}
                  </MDButton>
                </>
              ),
            };
          });

        // console.log(vendorProductRows);

        let tableDatax = { columns: vendorProductColumns, rows: vendorProductRows };

        setTableData(tableDatax);

        let vendorProductColumnsSel = [
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },

          {
            Header: language == 1 ? "Cantidad Registrada" : "Quantity Registered",
            accessor: "pending",
          },
          { Header: language == 1 ? "Fecha de Expiración" : "Expiration Date", accessor: "date" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let vendorProductRowsSel = response.data.data.selected.map((item) => {
          return {
            name: item?.product_name,
            upc: item?.upc,
            pending: item.quantity_received,
            date: item.date,
            action: ( <>
              <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() => {
                    setShowManualEdit(true);
                    setManualProd(item?.product_name);
                    setExpireProductId(item.expire_product_id)
                    setProductId(item?.product_id);
                    setExpireDate(item.date);
                    setQtyProd(item.quantity_received);
                    setPreviousQty(item.receiving_process);
                    console.log("hello");
                  }}
                >
                  <Icon>edit</Icon>
                </MDButton>
              </Tooltip>
              {"  "}
              <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                <MDButton
                  variant="gradient"
                  color="error"
                  iconOnly
                  onClick={() => {
                    setShowDelete(true);
                    setExpireProductId(item.expire_product_id)
                    setManualProd(item?.product_name);
                    setProductId(item?.product_id);
                    setExpireDate(item.date);
                    setQtyProd(0);
                  }}
                >
                  <Icon>delete</Icon>
                </MDButton>
              </Tooltip>

            </>

            ),
          };
        });

        setTableSelData({ columns: vendorProductColumnsSel, rows: vendorProductRowsSel });
        // setTableSelData(tableDatax);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function sendProductExpirationDate() {
    await axios
      .post(
        IP + "/receivings/saveExpirationDate",
        {
          receiving_id: localStorage.getItem("RecId"),
          products: [{ product_id: productId, date: expireDate, quantity: qtyProd }],
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        setTimeout(() => {
          setShow(false);
        }, 3000);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendProductExpirationDateEdit() {
    console.log(parseInt(qtyProd) - parseInt(previousQty));
    await axios
      .post(
        IP + "/receivings/editExpirationDate/" + expireProductId,
        {
          receiving_id: localStorage.getItem("RecId"),
          products: [
            {
              product_id: productId,
              date: expireDate,
              quantity: parseInt(qtyProd),
            },
          ],
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (parseInt(qtyProd) > 0)
          toggleSnackbar();
        else
          setShowDeleteConfirm(true)
        setTimeout(() => {
          setShow(false);
        }, 3000);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function confirmDates() {
    await axios
      .post(
        IP + "/receivings/confirmExpirationDates",
        {
          receiving_id: localStorage.getItem("RecId"),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar3();
        setTimeout(() => {
          setShow3(false);
          navigate(-1);
        }, 3000);
      })
      .catch((error) => {
        if (error.response.data.data == 'There are still products pending expiration date') {
          toggleSnackbar4()
        }
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const timer = () =>
    setTimeout(() => {
      setInputValue("");
    }, 200);

  useEffect(() => {
    inputRef.current?.focus();
  });

  useEffect(() => {
    getExpirePendingProducts();
  }, []);

  return (
    <DashboardLayout>
      <PurchaseOrderModal open={open} Product={prod} setOpen={setOpen} />
      <DashboardNavbar />
      <AlertDialog
        Show={showBox}
        setShowConfirm={setShowBox}
        Message={
          language == 1
            ? "¿Estás seguro que quieres confirmar las fechas de expiración de esta orden?"
            : "Are you sure you want to confirm the expiration dates of this order?"
        }
        OkayFunction={confirmDates}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "Cantidad y Fecha Guardada Exitosamente!"
            : "Quantity and Date Saved Successfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "Cantidad y Fecha Borrada Exitosamente!"
            : "Quantity and Date Deleted Successfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showDeleteConfirm}
        close={()=>setShowDeleteConfirm(!showDeleteConfirm)}
      />
      <AlertDialog
        Show={show2}
        setShowConfirm={setShow2}
        Message={
          language == 1
            ? "El producto expira en menos de 9 meses, deseas recibirlo?"
            : "The product expires in less than 9 months, would you still like to receive it?"
        }
        OkayFunction={()=>{
          sendProductExpirationDate();
          setTimeout(() => {
            setQtyProd(0);
            setShowManual(false);
            setManualProd("");
            setProductId(0);
            setExpireDate();
            getExpirePendingProducts();
          }, 1000);
        }}
      />
      <AlertDialog
        Show={showDelete}
        setShowConfirm={setShowDelete}
        Message={
          language == 1
            ? "¿Estás seguro que quieres borrar esta expiración?"
            : "Are you sure you want to delete this expiration?"
        }
        OkayFunction={() => {
          sendProductExpirationDateEdit();
          setTimeout(() => {
            setQtyProd(0);
            setManualProd("");
            setProductId(0);
            setExpireDate();
            setPreviousQty(0);
            getExpirePendingProducts();
          }, 1000);
        }}
      />
      {/* <MDSnackbar
        color="error"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "La fecha de expiración tiene que ser más de 4 meses desde el dia de hoy."
            : "Expiration date has to be more than 4 months after the current date."
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={toggleSnackbar2}
      /> */}

      <MDSnackbar
        color="error"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "Aún quedan productos pendientes a una fecha de expiración."
            : "There are still products pending expiration date."
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show4}
        close={toggleSnackbar4}
      />

      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Orden Confirmada!" : "Order Confirmed!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show3}
        close={toggleSnackbar3}
      />

      {showManual ? (
        <ThemeProvider theme={darkTheme}>
          <Dialog open={showManual}>
            <DialogTitle>
              {language == 1 ? "Fecha de Expiración y Cantidad:" : "Expiration Date and Quantity:"}
            </DialogTitle>

            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={qtyProd}
                label={language == 1 ? "Cantidad" : "Quantity"}
                type="number"
                onChange={(e) => {
                  if (e.target.value > 0 || e.target.value == "") {
                    if (e.target.value > previousQty) {
                      setQtyProd(0);
                    } else setQtyProd(e.target.value);
                  }
                }}
                fullWidth
                variant="standard"
              />
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={language == 1 ? "Fecha" : "Date"}
                type="date"
                value={expireDate}
                onChange={(e) => {
                  setExpireDate(e.target.value);
                }}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowManual(false);
                  setManualProd("");
                  setProductId(0);
                  setQtyProd(0);
                  setExpireDate();
                }}
              >
                {language == 1 ? "Cancelar" : "Cancel"}
              </Button>
              <Button
                disabled={qtyProd == 0 || !expireDate}
                onClick={() => {
                  console.log("here")
                  var myCurrentDate = new Date();
                  var nineMonths = new Date(myCurrentDate.setMonth(myCurrentDate.getMonth() + 9));

                  if (new Date(expireDate) > nineMonths) {
                    sendProductExpirationDate();
                    setTimeout(() => {
                      setQtyProd(0);
                      setShowManual(false);
                      setManualProd("");
                      setProductId(0);
                      setExpireDate();
                      getExpirePendingProducts();
                    }, 1000);
                  }
                  else
                    setShow2(true)
                }}
              >
                {language == 1 ? "Guardar" : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      ) : null}

      {showManualEdit ? (
        <ThemeProvider theme={darkTheme}>
          <Dialog open={showManualEdit}>
            <DialogTitle>
              {language == 1
                ? "Editar Fecha de Expiración y Cantidad:"
                : "Edit Expiration Date and Quantity:"}
            </DialogTitle>

            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name2"
                value={qtyProd}
                label={language == 1 ? "Cantidad" : "Quantity"}
                type="number"
                onChange={(e) => {
                  if (e.target.value > 0 || e.target.value == "") {
                    if (e.target.value > previousQty) {
                      setQtyProd(0);
                    } else setQtyProd(e.target.value);
                  }
                }}
                fullWidth
                variant="standard"
              />
              <TextField
                autoFocus
                margin="dense"
                id="date2"
                label={language == 1 ? "Fecha" : "Date"}
                type="date"
                value={expireDate}
                onChange={(e) => {
                  setExpireDate(e.target.value);
                }}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowManualEdit(false);
                  setManualProd("");
                  setProductId(0);
                  setQtyProd(0);
                  setExpireDate();
                  setPreviousQty(0);
                }}
              >
                {language == 1 ? "Cancelar" : "Cancel"}
              </Button>
              <Button
                disabled={qtyProd == 0 || !expireDate}
                onClick={() => {
                  sendProductExpirationDateEdit();
                  setTimeout(() => {
                    setQtyProd(0);
                    setShowManualEdit(false);
                    setManualProd("");
                    setProductId(0);
                    setExpireDate();
                    setPreviousQty(0);
                    getExpirePendingProducts();
                  }, 1000);
                }}
              >
                {language == 1 ? "Guardar" : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      ) : null}

      <MDBox py={3} mb={20} height="65vh">
        <Card>
          <MDBox p={3}>
            {data?.selected?.length > 0 && <MDButton
              variant="gradient"
              style={{ float: "right" }}
              onClick={() => {
                setChangeTable(!changeTable);
              }}
              color={changeTable ? "secondary" : "info"}
            >
              {changeTable ? "Products With Dates" : "Order Products Without Dates"}
            </MDButton>}
            {data?.selected?.length > 0 && <MDButton
              variant="gradient"
              style={{ float: "right" }}
              onClick={() => {
                //setChangeTable(!changeTable);
                setShowBox(true);
              }}
              color={"warning"}
            >
              {language ? "Confirm Dates" : "Confirmar Fechas"}
            </MDButton>}
            <Grid>
              {changeTable ? (
                <DataTable height="50%" table={tableData} entriesPerPage={true} />
              ) : (
                <DataTable height="50%" table={tableSelData} entriesPerPage={true} />
              )}
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ExpirationDateForm;
