/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import { Autocomplete } from "@mui/material";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import MDInput from "components/MDInput";
import "bootstrap/dist/css/bootstrap.min.css";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { item } from "examples/Sidenav/styles/sidenavItem";
import { ExportToExcel } from "app/components/exportToExcel";

const axios = require("axios");

function ReportSalesByStore() {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [download, setDownload] = useState(true);
  const [errors, setErrors] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState();
  const [dateFrom, setDateFrom] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [sortBy, setSortBy] = useState(
    language == 1 ? { label: "Cantidad", id: 1 } : { label: "Quantity", id: 1 }
  );
  const [sortSelect, setSortSelect] = useState();
  const [data, setData] = useState([]);
  const [responseData, setResponseData] = useState([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  const sorting = [
    {
      id: 1,
      label: "Quantity",
    },
    {
      id: 2,
      label: "Total",
    },
  ];

  const sortingEsp = [
    {
      id: 1,
      label: "Cantidad",
    },
    {
      id: 2,
      label: "Total",
    },
  ];

  const createPDF = () => {
    setDownload(false);
    const pdf = new jsPDF("portrait", "px", "a0");
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("sales-by-store");
      setDownload(true);
    });
  };

  async function GetStores() {
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        let store = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            store.push({ label: item.name, id: item.id });
          }
        });
        store.unshift({ label: language == 1 ? "Todas" : "All", id: 0 });

        setStores(store);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getBestSellersData() {
    await axios
      .get(
        IP + "/reports/stores_by_sales?startDate=" + dateFrom + "&endDate=" + dateTo,

        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setResponseData(response.data.data);
        let columns = [
          { Header: language == 1 ? "Nombre de Tienda" : "Store Name", accessor: "store" },
          { Header: language == 1 ? "Cantidad de Bultos" : "Product Quantity", accessor: "qty" },
          { Header: language == 1 ? "Total Vendido" : "Total Sold", accessor: "total" },
        ];

        let rows = response.data.data.map((item) => {
          return {
            store: item.store.name,
            qty: item.quantity,
            date: item.date,
            total: item.total,
          };
        });

        let excelRows = response.data.data.map((item) => {
          if (language == 1) {
            return {
              "Nombre de la Tienda": item.store.name,
              "Cantidad de la Orden": item.quantity,
              Total: item.total,
            };
          } else {
            return {
              "Store Name": item.store.name,
              "Order Quantity": item.quantity,
              Total: item.total,
            };
          }
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
        setData(excelRows);
        setErrors([]);
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function filterByStore(store) {
    let columns = [
      { Header: language == 1 ? "Nombre de Tienda" : "Store Name", accessor: "store" },
      { Header: language == 1 ? "Cantidad de Bultos" : "Product Quantity", accessor: "qty" },
      { Header: language == 1 ? "Total Vendido" : "Total Sold", accessor: "total" },
    ];

    let rows = responseData
      .filter((item) => {
        if (store?.id != 0 && store != null) return item.store.id == store?.id;
        if (store?.id == 0 || store == null) return item;
      })
      .map((item) => {
        return {
          store: item.store.name,
          qty: item.quantity,
          date: item.date,
          total: item.total,
        };
      });

    let excelRows = responseData
      .filter((item) => {
        if (store?.id != 0 && store != null) return item.store.id == store?.id;
        if (store?.id == 0 || store == null) return item;
      })
      .map((item) => {
        if (language == 1) {
          return {
            "Nombre de la Tienda": item.store.name,
            "Cantidad de la Orden": item.quantity,
            Total: item.total,
          };
        } else {
          return {
            "Store Name": item.store.name,
            "Order Quantity": item.store.quantity,
            Total: item.store.total,
          };
        }
      });

    let tableData = { columns: columns, rows: rows };
    //console.log(tableData)
    setReport(tableData);
    setData(excelRows);
  }

  function sortQuantity() {
    let columns = [
      { Header: language == 1 ? "Nombre de Tienda" : "Store Name", accessor: "store" },
      { Header: language == 1 ? "Cantidad de Bultos" : "Product Quantity", accessor: "qty" },
      { Header: language == 1 ? "Total Vendido" : "Total Sold", accessor: "total" },
    ];

    let rows = (
      sortSelect == "ascending"
        ? responseData.sort((a, b) => (a.quantity > b.quantity ? 1 : -1))
        : responseData.sort((a, b) => (a.quantity < b.quantity ? 1 : -1))
    ).map((item) => {
      return {
        store: item.store.name,
        qty: item.quantity,
        date: item.date,
        total: item.total,
      };
    });

    let excelRows = (
      sortSelect == "ascending"
        ? responseData.sort((a, b) => (a.quantity > b.quantity ? 1 : -1))
        : responseData.sort((a, b) => (a.quantity < b.quantity ? 1 : -1))
    ).map((item) => {
      if (language == 1) {
        return {
          "Nombre de la Tienda": item.store.name,
          "Cantidad de la Orden": item.quantity,
          Total: item.total,
        };
      } else {
        return {
          "Store Name": item.store.name,
          "Order Quantity": item.store.quantity,
          Total: item.store.total,
        };
      }
    });

    let tableData = { columns: columns, rows: rows };
    //console.log(tableData)
    setReport(tableData);
    setData(excelRows);
  }

  function sortTotal() {
    let columns = [
      { Header: language == 1 ? "Nombre de Tienda" : "Store Name", accessor: "store" },
      { Header: language == 1 ? "Cantidad de Bultos" : "Product Quantity", accessor: "qty" },
      { Header: language == 1 ? "Total Vendido" : "Total Sold", accessor: "total" },
    ];

    let rows = (
      sortSelect == "ascending"
        ? responseData.sort((a, b) => (parseFloat(a.total) > parseFloat(b.total) ? 1 : -1))
        : responseData.sort((a, b) => (parseFloat(a.total) < parseFloat(b.total) ? 1 : -1))
    ).map((item) => {
      return {
        store: item.store.name,
        qty: item.quantity,
        date: item.date,
        total: item.total,
      };
    });

    let excelRows = (
      sortSelect == "ascending"
        ? responseData.sort((a, b) => (parseFloat(a.total) > parseFloat(b.total) ? 1 : -1))
        : responseData.sort((a, b) => (parseFloat(a.total) < parseFloat(b.total) ? 1 : -1))
    ).map((item) => {
      if (language == 1) {
        return {
          "Nombre de la Tienda": item.store.name,
          "Cantidad de la Orden": item.quantity,
          Total: item.total,
        };
      } else {
        return {
          "Store Name": item.store.name,
          "Order Quantity": item.store.quantity,
          Total: item.store.total,
        };
      }
    });

    let tableData = { columns: columns, rows: rows };
    //console.log(tableData)
    setReport(tableData);
    setData(excelRows);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    GetStores();
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <Autocomplete
              sx={{ mb: 1 }}
              disablePortal
              fullWidth
              value={sortBy}
              onChange={(event, newValue) => {
                setSortBy(newValue);
              }}
              options={language == 1 ? sortingEsp : sorting}
              renderInput={(params) => <TextField {...params} label="" variant="standard" />}
            />
            {sortBy ? (
              <FormControl>
                <RadioGroup
                  name="Sort Options"
                  onChange={(e) => {
                    if (e.target.value == "ascending") {
                      setSortSelect(e.target.value);
                    } else if (e.target.value == "descending") {
                      setSortSelect(e.target.value);
                    }
                  }}
                >
                  <FormControlLabel
                    value="descending"
                    control={<Radio />}
                    label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                  />
                  <FormControlLabel
                    value="ascending"
                    control={<Radio />}
                    label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                  />
                </RadioGroup>
              </FormControl>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(!open)}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                sortBy.id == 1 ? sortQuantity() : sortTotal();
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            {data.length > 0 ? (
              <>
                <MDBox ml={1}>
                  <ExportToExcel apiData={data} fileName="Sales_By_Store" />
                </MDBox>
                <MDBox ml={1}>
                  <MDButton variant="outlined" color="dark" onClick={createPDF}>
                    {language == 1 ? "Descargar PDF" : "Download PDF"}
                  </MDButton>
                </MDBox>
                <MDBox ml={1}>
                  <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
                    {language == 1 ? "Organizar" : "Sort"}&nbsp;
                    <Icon>keyboard_arrow_down</Icon>
                  </MDButton>
                </MDBox>
              </>
            ) : null}
          </MDBox>
        </MDBox>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h4" sx={{ mb: 1 }}>
              {language == 1 ? "Busqueda" : "Search"}
            </MDTypography>
            <Grid container spacing={1}>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Desde" : "From Date"}
                  fullWidth
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                  }}
                />
                <ShowError id={"startDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Hasta" : "To Date"}
                  fullWidth
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                  }}
                />
                <ShowError id={"endDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  onClick={getBestSellersData}
                >
                  {language == 1 ? "Busqueda" : "Search"}
                </MDButton>
              </Grid>
            </Grid>
            {responseData.length > 0 ? (
              <Grid container>
                <Grid item lg={2}>
                  <Autocomplete
                    disablePortal
                    value={selectedStore}
                    onChange={(event, newValue) => {
                      filterByStore(newValue);
                      setSelectedStore(newValue);
                    }}
                    style={{ marginTop: 20 }}
                    options={stores}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={language == 1 ? "Tiendas" : "Stores"}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}
            <DataTable table={report} entriesPerPage={true} canSearch />
          </MDBox>
        </Card>
        <div hidden={download}>
          <MDBox mt={50}>
            <div id="pdf">
              <h3>{language == 1 ? "Ventas por Tiendas" : "Sales by Store Report"}</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{language == 1 ? "Nombre de Tienda" : "Store Name"}</th>
                    <th>{language == 1 ? "Cantidad de Bultos" : "Order Quantity"}</th>
                    <th>{language == 1 ? "Total Vendido" : "Total Sold"}</th>
                  </tr>
                </thead>
                <tbody>
                  {responseData.map((item) => {
                    return (
                      <tr>
                        <td>{item.store.name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.total}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </MDBox>
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportSalesByStore;
