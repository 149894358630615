import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const ReadOnlyRow = ({ invoice, handleEditClick, handleDeleteClick }) => {
  const [language, setLanguage] = React.useState(localStorage.getItem("Language"));
  return (
    <TableRow>
      <DataTableBodyCell>{invoice.fullName}</DataTableBodyCell>
      <DataTableBodyCell>{invoice.invoice_number}</DataTableBodyCell>
      <DataTableBodyCell>{invoice.total}</DataTableBodyCell>
      <DataTableBodyCell>{invoice.amount}</DataTableBodyCell>
      <DataTableBodyCell>
        {invoice.total != 0 && <MDButton
          variant="gradient"
          color="info"
          type="button"
          onClick={(event) => handleEditClick(event, invoice)}
        >
          {language==1 ? "Editar" : "Edit"}
        </MDButton>}
      </DataTableBodyCell>
    </TableRow>
  );
};

export default ReadOnlyRow;
