import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import MDInput from "components/MDInput";
import { useEffect } from "react";

export default function ProductCard({
  open,
  showButton,
  subDept,
  product,
  upc,
  code,
  name,
  available,
  packaging,
  price,
  onSale,
  shopper,
  image,
  role,
  viewImage,
  minimum,
  prodId,
  reRun,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [sale, setSale] = useState([]);
  const [currentShopper, setCurrentShopper] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const nav = useNavigate()

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  function setOnSale() {
    setSale(
      onSale.filter((item) => {
        return item.product_id == prodId;
      })
    );
  }

  function setShopper() {
    setCurrentShopper(
      shopper.filter((item) => {
        return item.product_id == prodId;
      })
    );
  }

  const setUnderline = {
    textDecoration: isHover ? "underline" : null,
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    setOnSale();
    setShopper();
    console.log("rerun");
  }, [reRun]);

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Dialog open={openDialog}>
          <DialogTitle>{name}</DialogTitle>
          <DialogContent>
            <MDBox component="img" sx={{ cursor: "pointer", width: "270px", height: '270px' }} src={image} alt="" ml={17} />
            <TextField
              margin="dense"
              label="Sub Dept."
              type="text"
              fullWidth
              value={subDept}
              variant="standard"
            />
            <TextField
              margin="dense"
              label="UPC"
              type="text"
              fullWidth
              value={upc}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "UPC Unidad" : "UPC Unit"}
              type="text"
              fullWidth
              value={code}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Disponible" : "Available"}
              type="text"
              fullWidth
              value={available}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Empaque" : "Package"}
              type="text"
              fullWidth
              value={packaging}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Precio" : "Price"}
              type="text"
              fullWidth
              value={"$" + (sale.length > 0 ? sale[0].specialPrice : price)}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            {(role == 1 || role == 9) && 
              <Button onClick={()=>{nav('/edit-product'), localStorage.setItem('productId', prodId)}}>
                {language == 1 ? "Ver Producto" : "View Product"}
              </Button>
            }
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Grid item xs={12} md={6} lg={3}>
        <Card>
          <MDBox p={0.2}>
            <Grid container spacing={3}>
              <Grid item className="text-center" sx={{ mt: 2, mb: 1 }} xs={12} sm={12}>
                <MDBox
                  component="img"
                  src={image}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  mt={1}
                  sx={{ cursor: "pointer", width: "225px", height: "225px" }}
                />
              </Grid>
            </Grid>
            <div className="text-center">
              <MDTypography
                sx={{ cursor: "pointer", objectFit: "cover" }}
                style={setUnderline}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                variant="h6"
                onClick={() => setOpenDialog(true)}
              >
                {name}
              </MDTypography>
            </div>
            <div className="text-center">
              <MDTypography
                sx={{ cursor: "pointer", objectFit: "cover" }}
                style={setUnderline}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                variant="h6"
                onClick={() => setOpenDialog(true)}
              >
                {product.code != '' ? product.code : '\u00A0'}
              </MDTypography>
            </div>
            {/* {available<=minimum ? (
                <MDTypography sx={{ml: 1}} color="error" variant='h6'>
                    Low Stock!
                </MDTypography>
                ): null} */}
            {/* {onSale.map(item=>{}) (
              <MDTypography sx={{ ml: 1 }} color="info" variant="h6">
                {language == 1 ? "En Especial!" : "On Sale!"}
              </MDTypography>
            ) : (
              <MDBox mb={1.5} />
            )} */}

            {sale.length > 0 ? (
              currentShopper.length > 0 ? (
                <Grid container spacing={3}>
                  <Grid item xs={4} md={4} lg={5}>
                    <MDBox display="flex" justifyContent="left" alignItems="flex-start">
                      <MDTypography sx={{ ml: 1 }} color="info" variant="h6">
                        {language == 1 ? "En Oferta!" : "On Sale!"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={8} md={8} lg={7}>
                    <MDBox display="flex" mr={1.5} justifyContent="right" alignItems="flex-start">
                      <MDTypography color="error" variant="h6">
                        {currentShopper[0].isShopper ? "Regular" : ""}  
                      </MDTypography>
                      <MDTypography color="success" sx={{ml: currentShopper[0].isShopper ? 1 : 0}} variant='h6'>
                        {currentShopper[0].isExpress ? "Express" : ""} 
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              ) : (
                <MDTypography sx={{ ml: 1 }} color="info" variant="h6">
                  {language == 1 ? "En Oferta!" : "On Sale!"}
                </MDTypography>
              )
            ) : currentShopper.length > 0 ? (
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <MDBox display="flex" ml={1} justifyContent="left" alignItems="flex-start">
                    <MDTypography color="error" variant="h6">
                      {currentShopper[0].isShopper ? "Regular" : ""} 
                    </MDTypography>
                    <MDTypography color="success" sx={{ml: currentShopper[0].isShopper ? 1 : 0}} variant='h6'>
                      {currentShopper[0].isExpress ? "Express" : ""} 
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            ) : (
              <MDBox display="flex" mr={1.5} justifyContent="right" alignItems="flex-start">
                <MDTypography color="error" variant="h6">
                  <br />  
                </MDTypography>
              </MDBox>
            )}
            {sale.length > 0 ? ( <>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <MDBox display="flex" justifyContent="left" alignItems="flex-start">
                    <MDTypography
                      sx={{ ml: 1, textDecoration: "line-through" }}
                      variant="body1"
                      color="error"
                    >
                      {"$" + price}
                    </MDTypography>
                    <MDTypography sx={{ ml: 1 }} variant="body1" color="success">
                      {"$" + sale[0].specialPrice}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12}>
                  <MDBox ml={1} display="flex" justifyContent="left" alignItems="flex-start">
                    <MDTypography variant="subtitle2" style={{fontWeight: 900}}>{language==1 ? "Empaque " : "Package "} {packaging}</MDTypography>
                  </MDBox>
                  <MDBox ml={1} display="flex" justifyContent="left" alignItems="flex-start">
                    {available == 0 ? (
                      <MDTypography variant="subtitle1" color="error">
                        {language == 1 ? "Agotado" : "Unavailable"}
                      </MDTypography>
                    ) : (
                      <MDTypography variant="subtitle1" style={{fontWeight: 400}}>
                        {language == 1 ? "Disponible" : "Available"} {available}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
              </>
            ) : ( <>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <MDTypography sx={{ ml: 1 }} variant="body1" color="success">
                    {"$" + price}
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12}>
                  <Grid item xs={12} md={8} lg={8}>
                    <MDBox ml={1} display="flex" justifyContent="left" alignItems="flex-start">
                      <MDTypography variant="subtitle2" style={{fontWeight: 900}}>{language==1 ? "Empaque " : "Package "} {packaging}</MDTypography>
                    </MDBox>
                    <MDBox display="flex" justifyContent="left" alignItems="flex-start" ml={1}>
                      {available == 0 ? (
                        <MDTypography variant="subtitle1" color="error">
                          {language == 1 ? "Agotado" : "Unavailable"}
                        </MDTypography>
                      ) : (
                        <MDTypography variant="subtitle1" style={{fontWeight: 400}}>
                          {language == 1 ? "Disponible" : "Available"} {available}
                        </MDTypography>
                      )}
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
              </>
            )}

            {showButton ? <MDBox mb={1} /> : null}

            {showButton ? (
              <MDBox p={1}>
                <Grid container spacing={3}>
                  <Grid item xs={6} md={6} lg={6} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={4} md={3} lg={4}>
                        <MDBox mb={1} display="flex" justifyContent="right" alignItems="flex-start">
                          <MDButton
                            variant="text"
                            size="large"
                            color="dark"
                            onClick={() => {
                              if (quantity > 0) {
                                setQuantity(quantity - 1);
                              }
                            }}
                            iconOnly
                          >
                            <Icon>remove</Icon>
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <MDInput
                          variant="standard"
                          value={quantity}
                          onChange={(e) => {
                            e.target.value >= 0 && e.target.value <= available
                              ? setQuantity(e.target.value)
                              : null;
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <MDButton
                          variant="text"
                          size="large"
                          color="dark"
                          onClick={() => {
                            if (quantity < available) {
                              setQuantity(parseFloat(quantity) + 1);
                            }
                          }}
                          iconOnly
                        >
                          <Icon>add</Icon>
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <MDBox display="flex" justifyContent="left" alignItems="flex-start">
                      <MDButton
                        sx={{ mb: 2 }}
                        variant="gradient"
                        color="info"
                        disabled={available == 0 || quantity == 0}
                        onClick={() => {
                          open({
                            date: new Date().toISOString(),
                            subtotal: 0.0,
                            tax: 0.0,
                            store_id: 1,
                            status_id: 1,
                            active: 1,
                            product: {
                              product_id: prodId,
                              quantity: quantity,
                            },
                          });
                          setQuantity(0);
                        }}
                        // onClick={() => {
                        //   if (quantity > 0) {
                        //     open(quantity, prodId);
                        //     setQuantity(0)
                        //   }
                        // }}
                      >
                        <Icon>shopping_cart</Icon>
                        &nbsp;{language == 1 ? "Añadir al carrito" : "Add to cart"}
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            ) : (
              <MDBox mt={1} />
            )}
          </MDBox>
        </Card>
      </Grid>
    </>
  );
}
