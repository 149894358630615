/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import DataTable2 from "app/components/shopperTable";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function OnSaleList() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [onSale, setOnSale] = useState({ columns: [], rows: [] });
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false)
  const [error, setError] = useState("")
  const [showConfirm, setShowConfirm] = useState(false);
  const [active, setActive] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const toggleSnackbar = () => setShow(!show);

  async function getCurrentSale() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/onsale/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        getOnSaleData(response.data.data);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function DeleteOnSale() {
    await axios
      .delete(IP + "/on_sale/" + localStorage.getItem("onSaleDeleteId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        localStorage.removeItem("onSaleDeleteId");
        getCurrentSale();
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function changeStatus(id) {
    await axios
      .post(
        IP + "/onsale/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        getCurrentSale();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        setError(error.response?.data?.message)
        setShow2(true)
        console.log(error);
      });
  }

  async function getOnSaleData(current) {
    await axios
      .get(IP + "/on_sale", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let columns = [
          { Header: language == 1 ? "Periodo" : "Period", accessor: "since" },
          { Header: language == 1 ? "Productos" : "Products", accessor: "products" },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let rows = response.data.data.map((item) => {
          // console.log(item);
          return {
            since: item.startDate + '  --  ' + item.endDate,
            products: item.products,
            active: item.active == "Active" ? 
              language==1 ? "Activo" : "Active" :
              language==1 ? "Inactivo" : "Inactive",
            action: (
              <Grid>
                {item.active == "Inactive" ? (
                  <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(false);
                      }}
                      iconOnly
                    >
                      <Icon>check</Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(true);
                      }}
                      iconOnly
                    >
                      <Icon>do_not_disturb</Icon>
                    </MDButton>
                  </Tooltip>
                )}{" "}
                {new Date(item.endDate.replace(/-/g, '\/')) >= new Date(currentDate) && (
                  <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        localStorage.setItem("onSaleId", item.id);
                        navigate("/edit-on-sale");
                      }}
                      iconOnly
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                  </Tooltip>
                )}
                {" "}
                <Tooltip title={language == 1 ? "Descargar Excel" : "Download Excel"}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    href={imageIP + "onSaleExcel/" + item.id}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
                {/* <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    localStorage.setItem("onSaleDeleteId", item.id);
                    setShowConfirm(true);
                  }}
                  iconOnly
                >
                  <Icon>delete</Icon>
                </MDButton> */}
              </Grid>
            ),
          };
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setOnSale(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function nav() {
    navigate("/create-on-sale");
  }

  useEffect(() => {
    getCurrentSale();
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres borrar este especial?"
            : "Are you sure you want to delete this sale?"
        }
        OkayFunction={DeleteOnSale}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1
            ? `Especial ${active ? "Desactivado" : "Activado"} Exitosamente!`
            : `Sale ${active ? "Deactivated" : "Activated"} Succesfully!`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title=""
        content={error}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={()=>setShow2(!show2)}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={nav}>
            {language == 1 ? "Crear especial" : "create sale"}
          </MDButton>
        </MDBox>
        <Card>
          <DataTable2
            table={onSale}
            index={index}
            setIndex={setIndex}
            pageEntries={10}
            entriesPerPage={true}
            canSearch
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default OnSaleList;
