/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { flushSync } from "react-dom";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid, Typography } from "@mui/material";
import { Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomDatePicker from "components/CustomDatePicker";
import VirtualizedAutocomplete from "app/components/VirtualizedAutocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import swal from "sweetalert";
// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import DataTable2 from "app/components/shopperTable";
import {
  MaterialReactTable,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
} from "material-react-table";

//import { MaterialReactTable } from "material-react-table";

import IP from "app/IP";

const axios = require("axios");

function CreateContract() {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
  const [index, setIndex] = useState(0); //index for product table
  const [productData, setProductData] = useState({ columns: [], rows: [] }); //data for product table
  const [productEditMode, setProductEditMode] = useState(false); //set edit mode when selecting a product from the table
  const [familyID, setFamilyID] = useState(); //family ID for searching existing family of product
  const [contractProductIds, setContractProductIds] = useState([]); //contract products ids array for filtering
  const [contractProductData, setContractProductData] = useState([]); //contract product data that will be used to save onto the family products found

  //notifications
  const [contractCreateNotif, setContractCreateNotif] = useState(false);
  const [contractEditNotif, setContractEditNotif] = useState(false);
  const [productSaveNotif, setProductSaveNotif] = useState(false);
  const [productEditNotif, setProductEditNotif] = useState(false);

  //Header
  const [date, setDate] = useState();
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [company, setCompany] = useState("");
  const [order, setOrder] = useState("");
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [eta, setEta] = useState(null);
  const [dateOf, setDateOf] = useState();
  const [terms, setTerms] = useState("");
  const [buyer, setBuyer] = useState("");
  const [contact, setContact] = useState("");
  const [facturado, setFacturado] = useState("");
  const [entregado, setEntregado] = useState("");
  const [ordenado, setOrdenado] = useState("");
  const [recogido, setRecogido] = useState("");

  //Body
  const [methodROptions, setMethodROptions] = useState(["Unitario", "Dividido"]);
  const [methodEOptions, setMethodEOptions] = useState(["Unitario", "Dividido"]);
  const [upcList, setUpcList] = useState([]);
  const [selectedUpc, setSelectedUpc] = useState(""); //selected product from product table
  //const [selectedProduct, setSelectedProduct] = useState(""); //selected product from product table
  const [families, setFamilies] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState("");
  const [isFamily, setIsFamily] = useState(false);
  const [offerList, setOfferList] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState("");
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");
  const [order2, setOrder2] = useState("");
  const [c, setC] = useState("");
  const [g, setG] = useState("");
  const [man, setMan] = useState("");
  const [envio, setEnvio] = useState("");
  const [inter, setInter] = useState("");
  const [trans, setTrans] = useState("");
  const [tar, setTar] = useState("");
  const [pubAp, setPubAp] = useState("");
  const [vag, setVag] = useState("");
  const [add, setAdd] = useState("");
  const [imp, setImp] = useState("");
  const [pubFa, setPubFa] = useState("");
  const [fotoFa, setFotoFa] = useState("");
  const [notasAclara, setNotasAclara] = useState("");
  const [contractOffers, setContractOffers] = useState("");
  //const [cg, setCg] = useState("");
  const [offer, setOffer] = useState("");
  const [show, setShow] = useState("");
  const [extra, setExtra] = useState(false);
  const [approved, setApproved] = useState(false);
  const [ce, setCe] = useState("");
  const [methodR, setMethodR] = useState("");
  const [cn, setCn] = useState("");
  const [pgr, setPgr] = useState("");
  const [percentR, setPercentR] = useState("");
  const [pr, setPr] = useState("");
  const [methodE, setMethodE] = useState("");
  const [percentE, setPercentE] = useState("");
  const [cn2, setCn2] = useState("");
  const [pge, setPge] = useState("");
  const [pe, setPe] = useState("");
  const [ceu, setCeu] = useState("");
  const [methodRU, setMethodRU] = useState("");
  const [percentR2, setPercentR2] = useState("");
  const [cnu, setCnu] = useState("");
  const [pgru, setPgru] = useState("");
  const [pru, setPru] = useState("");
  const [methodEU, setMethodEU] = useState("");
  const [percentE2, setPercentE2] = useState("");
  const [cnu2, setCnu2] = useState("");
  const [pge2, setPge2] = useState("");
  const [peu, setPeu] = useState("");

  //Offers
  const [show2, setShow2] = useState(false); //Open dialog popup
  const [code, setCode] = useState("");
  const [selectedOfferType, setSelectedOfferType] = useState("");
  const [upc, setUPC] = useState("");
  const [offers, setOffers] = useState("");
  const [netWorth, setNetWorth] = useState("");
  const [offerC, setOfferC] = useState(0);
  const [offerG, setOfferG] = useState(0);
  const [by, setBy] = useState("");
  const [products, setProducts] = useState([]);
  // const [selectedProduct, setSelectedProduct] = useState("");
  const [offerFromDate, setOfferFromDate] = useState(null);
  const [offerToDate, setOfferToDate] = useState(null);
  //const [cg, setCg] = useState();
  const [percent, setPercent] = useState(""); //Offer percent
  //const [offer, setOffer] = useState();
  //const [fromDate, setFromDate] = useState();
  //const [toDate, setToDate] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openFamilyPopup, setOpenFamilyPopup] = useState(false);
  //end of offer

  //Print Checkboxes
  const [preliminaryGrid, setPreliminaryGrid] = useState(false);
  const [approvedGrid, setApprovedGrid] = useState(false);
  const [preWarehouseGrid, setPreWarehouseGrid] = useState(false);
  const [appWarehouseGrid, setAppWarehouseGrid] = useState(false);
  const [specialsGrid, setSpecialsGrid] = useState(false);
  const [advertisingContract, setAdvertisingContract] = useState(false);
  const [advertisingInvoice, setAdvertisingInvoice] = useState(false);
  const [photosBilled, setPhotosBilled] = useState(false);
  const [takingOrders, setTakingOrders] = useState(false);
  const [advertisingAgency, setAdvertisingAgency] = useState(false);

  const [relatedProductList, setRelatedProductList] = useState([]);
  const isAllSelectedProducts =
    relatedProductList.length > 0 && setSelectedRelatedProduct.length === relatedProductList.length;
  const [requirementsList, setRequirementsList] = useState([
    // { label: "sales price", value: 1 },
    // { label: "tax", value: 2 },
    // { label: "vendor", value: 3 },
    { label: "empaque", value: 1 },
    { label: "Tamaño", value: 3, disabled: true },
    { label: "Pesado", value: 6, disabled: true },
    { label: "Etiqueta ???", value: 7, disabled: true },
    { label: "Tarjeta", value: 8, disabled: true },
    { label: "Departamento", value: 9 }, //department is actually subcategory in our version of the database
    { label: "Suplidor", value: 10 },
    { label: "Costo Caja", value: 11, disabled: true },
    { label: "Precio Caja", value: 12, disabled: true },
    { label: "Costo Unidad", value: 13, disabled: true },
    { label: "Precio Unidad", value: 14, disabled: true },
    { label: "Especiales", value: 15, disabled: true },
    { label: "Impuestos", value: 16, disabled: true },
    { label: "Ofertas", value: 17, disabled: true },
    { label: "WIC", value: 19, disabled: true },
  ]); //Options for list of checkbox requirements
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const isAllSelected =
    requirementsList.length > 0 && selectedRequirements.length === requirementsList.length;
  const [menu, setMenu] = useState(null);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  //const [paymentData, setPaymentData] = useState({ columns: [], rows: [] });
  //const [date, setDate] = useState(currentDate);
  const [errors, setErrors] = useState([]);
  const [remainder, setRemainder] = useState(0.0);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  const handleClose = () => {
    setOpenDialog(false);
    setErrors([]);
  };

  const handleFamilyPopupClose = () => {
    setOpenFamilyPopup(false);
    setErrors([]);
  };

  const handleClickOpen = (mode) => {
    setOpenDialog(true); //opens dialog model
    setErrors([]);
  };

  //Clear basic fields
  const clearFields = () => {
    // setCode("");
    // setUPC("");
    // setCost("");
    setOffers("");
    setNetWorth("");
    setC("");
    setG("");
    setPercent("");
    setOffer("");

    setSelectedUpc("");
    setDescription("");
    setCode("");
    setUPC("");
    setCost("");

    setC("");
    setG("");
    setMan("");
    setEnvio("");
    setInter("");
    setTrans("");
    setTar("");
    setPubAp("");
    setVag("");
    setAdd("");
    setImp("");
    setPubFa("");
    setFotoFa("");
    setNotasAclara("");
    setContractOffers("");

    setCe("");
    setCn("");
    setPgr("");
    setMethodR("");
    setPercentR("");
    setPr("");
    setMethodE("");
    setPercentE("");
    setCn2("");
    setPge("");
    setPe("");
    setCeu("");
    setMethodRU("");
    setPercentR2("");
    setCnu("");
    setPgru("");
    setPru("");
    setMethodEU("");
    setPercentE2("");
    setCnu2("");
    setPge2("");
    setPeu("");
    setShow("");
    setExtra("");
    setApproved("");
  };

  async function GetVendors() {
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log("Get Vendors Response:", response.data.data);

        let vendor = response.data.data.map((item) => {
          return {
            id: item.id,
            label: item.vendor_number + " " + item.name,
            name: item.name,
            number: item.vendor_number,
          };
        });
        setSuppliers(vendor);

        let filterCompany = response.data.data
          .filter((item) => {
            return item.vendor_number == 505;
          })
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
        setCompany(filterCompany[0]);
      })
      .catch(function (error) {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log("Get Vendors ERROR:", error);
      });
  }

  async function getOfferFromProduct(prod_upc, prod_cost) {
    let prod_data = {
      upc: prod_upc.upc,
      product_id: prod_upc.product_id,
      from_date: fromDateRef.current,
      to_date: toDateRef.current,
    };
    console.log("prod_data:", prod_data);
    await axios
      .post(IP + "/offers/getOfferFromProduct", prod_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("getOfferFromProduct Response:", response);
        console.log("Upc list cost:", selectedUpc.cost);
        let offers = response.data.map((offer) => {
          return {
            id: offer.offer_id,
            label: offer.offer,
          };
        });
        console.log("offer map:", offers);
        console.log("Prod_cost:", prod_cost);
        if (offers.length > 0) {
          // let offerSum = response.data
          //   .reduce((total, currentValue) => (total = total + parseFloat(currentValue.offer)), 0)
          //   .toFixed(2);
          const offerInvoiceArray = response.data.filter((item) => item.offer_type_id === 1);
          const tempOfferArray = response.data.filter((item) => item.offer_type_id === 4);
          const billbackArray = response.data.filter((item) => item.offer_type_id === 2);
          console.log("offer Invoice:", offerInvoiceArray[0].offer);
          const offerInvoice = parseFloat(offerInvoiceArray[0]?.offer ?? 0);
          const tempOffer = parseFloat(tempOfferArray[0]?.offer ?? 0);
          const cajaGratis = response.data.filter((item) => item.offer_type_id === 3);
          const cajaGratisSum = cajaGratis[0]?.g + cajaGratis[0]?.c;
          const billback = parseFloat(billbackArray[0]?.offer ?? 0);
          console.log("baseOffer:", offerInvoice);
          console.log("tempOffer:", tempOffer);
          console.log("cajaGratis:", cajaGratis);
          console.log("cajaGratisSum:", cajaGratisSum);
          console.log("cajaGratis C:", cajaGratis[0]?.c);
          console.log("cajaGratis G:", cajaGratis[0]?.g);
          console.log("billback:", billback);
          let offerSum = (
            (offerInvoice || 0) -
            ((tempOffer || 0) * (cajaGratis?.[0]?.g ?? 1)) / (cajaGratisSum || 1) -
            (billback || 0)
          ).toFixed(2);

          let costDiscount = (
            ((parseFloat(prod_cost || 0) - (offerInvoice || 0) - (tempOffer || 0)) *
              (cajaGratis?.[0]?.g ?? 1)) /
              (cajaGratisSum || 1) -
            (billback || 0)
          ).toFixed(2);
          console.log("offer sum:", offerSum);
          console.log("Prod cost:", prod_cost);
          console.log("Cost dicounted:", costDiscount);
          setContractOffers(offerSum);
          console.log("Offer price:", parseFloat(offers[0].label));
          let round_result = ((costDiscount * 100) / 100).toFixed(2);
          console.log("Round Result:", costDiscount);
          // let result_percent = (round_result / prod_cost) * 100;
          // console.log("Result percent:", result_percent);
          // let round_result_percent = Math.round(result_percent * 1000) / 1000;
          let result_percent = ((round_result - round_result) / round_result) * 100;
          console.log("Result percent:", result_percent);
          let round_result_percent = Math.round(result_percent * 1000) / 1000;
          setOffers(offerSum);
          //setSelectedOffer(offers[].id)
          setNetWorth(costDiscount);
          // setC(0);
          // setG(0);
          // setMan("0.00");
          // setEnvio("0.00");
          // setInter("0.00");
          // setTrans("0.00");
          // setTar("0.00");
          // setPubAp("0.00");
          // setVag("0.00");
          // setAdd("0.00");
          // setImp("0.00");
          // setPubFa("0.00");
          // setFotoFa("0.00");
          setMethodR(methodROptions[0]);
          setMethodE(methodEOptions[0]);
          setMethodRU(methodROptions[0]);
          setMethodEU(methodEOptions[0]);
          setCe(costDiscount);
          setCeu(costDiscount);
          //setPr(round_result);
          //setPe(round_result);
          //setPercentR(round_result_percent);
          //setPercentE(round_result_percent);
          // if (offers[0]) {
          //   setOffers(offers[0].label);
          // } else {
          //   setOffers(0);
          // }
        } else {
          // setSelectedOffer("");
          setContractOffers("0.00");
          // setOfferList("");
          setOffers("0.00");
          setNetWorth(prod_cost);
          // setOrder2("");
          // setC(0);
          // setG(0);
          // setMan("0.00");
          // setEnvio("0.00");
          // setInter("0.00");
          // setTrans("0.00");
          // setTar("0.00");
          // setPubAp("0.00");
          // setVag("0.00");
          // setAdd("0.00");
          // setImp("0.00");
          // setPubFa("0.00");
          // setFotoFa("0.00");
          setMethodR(methodROptions[0]);
          setMethodE(methodEOptions[0]);
          setMethodRU(methodROptions[0]);
          setMethodEU(methodEOptions[0]);
          setCe("0.00");
          setPr("0.00");
          setPe("0.00");
          setPercentR("0.00");
          setPercentE("0.00");
          setCeu("0.00");
          setPru("0.00");
          setPeu("0.00");
          setPercentR2("0.00");
          setPercentE2("0.00");
        }
      })
      .catch((error) => {
        console.log("getOfferFromProduct error:", error);
      });
  }

  async function createContract(contract_data) {
    console.log("Contract data:", contract_data);
    await axios
      .post(IP + "/contract/createContract", contract_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setContractCreateNotif(true);
        //getContractProducts({ contract_id: state?.contract_data.contract_id });
        console.log("Create Contract Response:", response);
        //contract_data["contract_id"] = response.data.contract_id;
        //createContractProducts(contract_data);
        getCreatedContract({ contract_id: response.data.contract_id });
      })
      .catch((error) => {
        setContractCreateNotif(false);
        console.log("create contract error:", error);
        setErrors(error.response.data.data);
      });
  }

  async function createContractWithProduct(contract_data) {
    console.log("Contract data with product data:", contract_data);
    await axios
      .post(IP + "/contract/createContractWithProduct", contract_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setContractCreateNotif(true);
        //getContractProducts({ contract_id: state?.contract_data.contract_id });
        console.log("Create Contract With Product Response:", response);
        //contract_data["contract_id"] = response.data.contract_id;
        //createContractProducts(contract_data);
        getCreatedContract({ contract_id: response.data.contract_id });
      })
      .catch((error) => {
        setContractCreateNotif(false);
        console.log("create contract With Product error:", error);
        setErrors(error.response.data.data);
      });
  }

  async function createContractWithProductFamily(contract_data) {
    console.log("Contract data with product family:", contract_data);
    await axios
      .post(IP + "/contract/createContractWithProduct", contract_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        const contractDataID = response.data.contract.contract_id;
        setContractCreateNotif(true);
        //getContractProducts({ contract_id: state?.contract_data.contract_id });
        console.log("Create Contract with product family Response:", response);
        //contract_data["contract_id"] = response.data.contract_id;
        //createContractProducts(contract_data);
        setOpenFamilyPopup(!openFamilyPopup);
        getCreatedContract({ contract_id: contractDataID });
      })
      .catch((error) => {
        setContractCreateNotif(false);
        console.log("create contract with product family error:", error);
        setErrors(error.response.data.data);
      });
  }

  async function createContractProducts(contract_data) {
    console.log("Contract data:", contract_data);
    await axios
      .post(IP + "/contract/createContractProducts", contract_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Create Contract Products Response:", response);
        navigate("/contracts");
      })
      .catch((error) => {
        console.log("create contract products error:", error);
        setErrors(error.response.data.data);
      });
  }

  async function editContract(contract_data) {
    console.log("Contract data:", contract_data);
    await axios
      .post(IP + "/contract/editContract", contract_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setContractEditNotif(true);
        getContractProducts({ contract_id: state?.contract_data.contract_id });
        console.log("Edit Contract Response:", response);
        navigate("/contracts");
      })
      .catch((error) => {
        setContractEditNotif(false);
        console.log("edit contract error:", error);
      });
  }

  async function createOffer(offer_data) {
    //console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/offers/createOffer", offer_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("createOffer Response:", response);
        setShow2(!show2);
        setOpenDialog(false);
        getOfferFromProduct({ upc: upc, from_date: fromDate, to_date: toDate }, cost);
        //navigate("/families");
      })
      .catch((error) => {
        console.log("createOffer Error:", error);
        setErrors(error.response.data.data);
        //setErrors(error);
      });
  }

  //Get list of products
  async function getPurchaseOrder(po_number) {
    await axios
      .post(IP + "/contract/getPurchaseOrder", po_number, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("get Purchase order response:", response.data);
        const purchaseOrderMap = response.data.map((po) => {
          return {
            eta: po.estimated_date_of_arrival,
            date_of: po.date,
            product_id: po.product_id,
            upc: po.upc,
            product_name: po.product_name,
            vendor_id: po.vendor_id,
            vendor: po.vendor_id + " " + po.vendor_name,
            vendor_name: po.vendor_name,
            code: po.code,
            cost: po.cost,
          };
        });
        console.log("Purchase Order map:", purchaseOrderMap[0].eta);
        getOfferFromProduct(
          { upc: purchaseOrderMap[0].upc, from_date: fromDate, to_date: toDate },
          purchaseOrderMap[0].cost
        );
        //     id: prod.upc,
        //     label: prod.upc,
        //     product_id: prod.product_id,
        //     name: prod.name,
        //     code: prod.code,
        //     cost: prod.cost,

        setEta(dayjs(purchaseOrderMap[0].eta));
        setDateOf(dayjs(purchaseOrderMap[0].date_of));
        setSelectedSupplier({
          id: purchaseOrderMap[0].vendor_id,
          label: purchaseOrderMap[0].vendor,
          name: purchaseOrderMap[0].vendor_name,
          number: purchaseOrderMap[0].vendor_number,
        });
        setFacturado(purchaseOrderMap[0].vendor_number == 505 ? "Compañía" : "Suplidor");
        setEntregado(purchaseOrderMap[0].vendor_number == 505 ? "Compañía" : "Suplidor");
        setOrdenado(purchaseOrderMap[0].vendor_number == 505 ? "Compañía" : "Suplidor");
        setRecogido(purchaseOrderMap[0].vendor_number == 505 ? "Compañía" : "Suplidor");
        setSelectedUpc({
          id: purchaseOrderMap[0].upc,
          label: purchaseOrderMap[0].upc,
          product_id: purchaseOrderMap[0].product_id,
          name: purchaseOrderMap[0].product_name,
          code: purchaseOrderMap[0].code,
          cost: purchaseOrderMap[0].cost,
        });
        setCode(purchaseOrderMap[0].code);
        setCost(purchaseOrderMap[0].cost);
        setUPC(purchaseOrderMap[0].upc);
        //set rest of fields for now
      })
      .catch((error) => {
        console.log("get purchase order error:", error);
      });
  }

  async function getProductData() {
    await axios
      .get(IP + "/products?all_products=true", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let productColumns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
          { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
          // { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          { Header: language == 1 ? "Sub-Categoría" : "Sub-Category", accessor: "subcategory" },
          { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          { Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
          { Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          // { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = response.data.data.map((item) => {
          //console.log(item);
          return {
            //image: <ProductCell name="" image={imageIP + item.image} />,
            code: item.code,
            upcunit: item.upcUnit,
            upc: item.upc,
            name: item.name,
            available: item.available,
            vendor: item.vendor.name,
            cost: item.cost,
            price: item.sales_price,
            tax: item.tax.percent + "%",
            subcategory: item.subcategory.name,
            active:
              item.active == "Active"
                ? language == 1
                  ? "Activo"
                  : "Active"
                : language == 1
                ? "Inactivo"
                : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Escojer" : "Select"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      //localStorage.setItem("productId", item.id);
                      //navigate("/edit-product");
                    }}
                    iconOnly
                  >
                    <Icon>Select</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        //setProductData(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getContractProducts(contract_id) {
    console.log("Contract ID Parameter:", contract_id);
    await axios
      .post(IP + "/contract/getContractProducts", contract_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get contract product RESPONSE:", response);

        let contract_product_ids = response.data.map((item) => {
          return {
            product_id: item.product.product_id,
          };
        });

        setContractProductIds(contract_product_ids);
        // let upc_list = response.data.map((prod) => {
        //   return {
        //     id: prod.upc,
        //     //label: prod.upc,
        //     product_id: prod.product_id,
        //     name: prod.product.name,
        //     code: prod.product.code,
        //     cost: prod.cost,
        //   };
        // });
        //setUpcList(upc_list);

        let productColumns = [
          //{ Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          //{ Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
          //{ Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
          // { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          //{ Header: language == 1 ? "Sub-Categoría" : "Sub-Category", accessor: "subcategory" },
          { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          //{ Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
          //{ Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          // { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = response.data.map((item) => {
          //console.log(item);
          return {
            //image: <ProductCell name="" image={imageIP + item.image} />,
            code: item.product.code,
            //upcunit: item.upcUnit,
            upc: item.upc,
            name: item.product.name,
            //available: item.available,
            //vendor: item.vendor.name,
            cost: item.cost,
            //price: item.sales_price,
            //tax: item.tax.percent + "%",
            //subcategory: item.subcategory.name,
            active:
              item.active == "Active"
                ? language == 1
                  ? "Activo"
                  : "Active"
                : language == 1
                ? "Inactivo"
                : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Escojer" : "Select"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      //localStorage.setItem("productId", item.id);
                      //navigate("/edit-product");
                      console.log("selected upc:", item);
                      console.log(fromDate);
                      console.log(toDate);
                      console.log("FromDate && ToDate");
                      setProductEditMode(true);
                      setErrors([]);
                      getOfferFromProduct(
                        { upc: item.upc, product_id: item.product_id },
                        item.cost
                      );
                      if (item == null) {
                        setSelectedUpc("");
                        setDescription("");
                        setCode("");
                        setUPC("");
                        setCost("");

                        setC("");
                        setG("");
                        setMan("");
                        setEnvio("");
                        setInter("");
                        setTrans("");
                        setTar("");
                        setPubAp("");
                        setVag("");
                        setAdd("");
                        setImp("");
                        setPubFa("");
                        setFotoFa("");
                        setNotasAclara("");

                        setCn("");
                        setPgr("");
                        setPercentR("");
                        setPr("");
                        setMethodE("");
                        setPercentE("");
                        setCn2("");
                        setPge("");
                        setPe("");
                        setCeu("");
                        setMethodRU("");
                        setPercentR2("");
                        setCnu("");
                        setPgru("");
                        setPru("");
                        setMethodEU("");
                        setPercentE2("");
                        setCnu2("");
                        setPge2("");
                        setPeu("");
                        setShow("");
                        setExtra("");
                        setApproved("");
                      } else {
                        item["id"] = item.upc;
                        item["label"] = item.upc;
                        setSelectedUpc(item);
                        setDescription(item.product.name);
                        setCode(item.code);
                        setUPC(item.id);
                        setCost(item.cost);

                        setC(item.c);
                        setG(item.g);
                        setMan(item.man);
                        setEnvio(item.envio);
                        setInter(item.inter);
                        setTrans(item.trans);
                        setTar(item.tar);
                        setPubAp(item.pub_ap);
                        setVag(item.vag);
                        setAdd(item.add);
                        setImp(item.imp);
                        setPubFa(item.pub_fa);
                        setFotoFa(item.foto_fa);
                        setNotasAclara(item.notas_aclara);

                        setCn(item.cn_r);
                        setPgr(item.pgr);
                        setPercentR(item.percent_r);
                        setPr(item.pr);
                        setMethodE(item.method_e);
                        setPercentE(item.percent_e);
                        setCn2(item.cn_e);
                        setPge(item.pge);
                        setPe(item.pe);
                        setCeu(item.ceu);
                        setMethodRU(item.method_ru);
                        setPercentR2(item.percent_r2);
                        setCnu(item.cn_e2);
                        setPgru(item.pgr2);
                        setPru(item.pru);
                        setMethodEU(item.method_eu);
                        setPercentE2(item.percent_e2);
                        setCnu2(item.cn_r2);
                        setPge2(item.pge2);
                        setPeu(item.peu);
                        setShow(item.show);
                        setExtra(item.extra);
                        setApproved(item.approved);

                        console.log("Selected product from table:", item);
                      }
                    }}
                    iconOnly
                  >
                    <Icon>add</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProductData(tableData);
      })
      .catch((error) => {
        console.log("Get contract product ERROR:", error);
      });
  }

  async function getCreatedContract(contract_id) {
    await axios
      .post(IP + "/contract/findContract", contract_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Find contract response:", response);
        navigate("/contractMaintenance", {
          state: {
            contract_data: response.data[0],
            edit: true,
          },
        });
      })
      .catch((error) => {
        console.log("Find contract error:", error);
      });
  }

  async function addContractProducts(contract_data) {
    await axios
      .post(IP + "/contract/addContractProducts", contract_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setProductSaveNotif(true);
        clearFields();
        getContractProducts({ contract_id: state?.contract_data.contract_id });
        console.log("Add contract product RESPONSE:", response);
      })
      .catch((error) => {
        setProductSaveNotif(false);
        console.log("Error add contract product", error);
        setErrors(error.response.data.data);
      });
  }

  async function editContractProducts(contract_data) {
    await axios
      .post(IP + "/contract/editContractProducts", contract_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setProductEditNotif(true);
        clearFields();
        getContractProducts({ contract_id: state?.contract_data.contract_id });
        console.log("Edit contract product RESPONSE:", response);
      })
      .catch((error) => {
        setProductEditNotif(false);
        console.log("Error edit contract product", error);
        setErrors(error.response.data.data);
      });
  }

  //Get list of products
  async function getProducts(vendor) {
    await axios
      .post(IP + "/offers/getProductsByVendor", vendor, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Products/upc Response:", response);
        let upc_list = response.data.map((prod) => {
          return {
            id: prod.upc,
            label: prod.upc,
            product_id: prod.product_id,
            name: prod.name,
            code: prod.code,
            cost: prod.cost,
          };
        });
        setUpcList(upc_list);

        // let productColumns = [
        //   //{ Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
        //   { Header: language == 1 ? "Código" : "Code", accessor: "code" },
        //   //{ Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
        //   { Header: "UPC", accessor: "upc" },
        //   { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
        //   //{ Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
        //   // { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
        //   //{ Header: language == 1 ? "Sub-Categoría" : "Sub-Category", accessor: "subcategory" },
        //   { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
        //   //{ Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
        //   //{ Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
        //   // { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
        //   { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        // ];

        // let productRows = response.data.map((item) => {
        //   //console.log(item);
        //   return {
        //     //image: <ProductCell name="" image={imageIP + item.image} />,
        //     code: item.code,
        //     //upcunit: item.upcUnit,
        //     upc: item.upc,
        //     name: item.name,
        //     //available: item.available,
        //     //vendor: item.vendor.name,
        //     cost: item.cost,
        //     //price: item.sales_price,
        //     //tax: item.tax.percent + "%",
        //     //subcategory: item.subcategory.name,
        //     active:
        //       item.active == "Active"
        //         ? language == 1
        //           ? "Activo"
        //           : "Active"
        //         : language == 1
        //         ? "Inactivo"
        //         : "Inactive",
        //     action: (
        //       <Grid>
        //         <Tooltip title={language == 1 ? "Escojer" : "Select"}>
        //           <MDButton
        //             variant="gradient"
        //             color="info"
        //             onClick={() => {
        //               //localStorage.setItem("productId", item.id);
        //               //navigate("/edit-product");
        //               console.log("selected upc:", item);
        //               if (fromDate && toDate) {
        //                 getOfferFromProduct(
        //                   { upc: item.upc, from_date: fromDate, to_date: toDate },
        //                   item.cost
        //                 );
        //               }
        //               // if (item == null) {
        //               //   setSelectedUpc("");
        //               //   setDescription("");
        //               //   setCode("");
        //               //   setUPC("");
        //               //   setCost("");
        //               // } else {
        //               setSelectedUpc(item);
        //               setDescription(item.name);
        //               setCode(item.code);
        //               setUPC(item.id);
        //               setCost(item.cost);
        //               //}
        //             }}
        //             iconOnly
        //           >
        //             <Icon>add</Icon>
        //           </MDButton>
        //         </Tooltip>
        //       </Grid>
        //     ),
        //   };
        // });

        // let tableData = { columns: productColumns, rows: productRows };
        // //console.log(tableData)
        // setProductData(tableData);
      })
      .catch((error) => {
        console.log("products/upc error:", error);
      });
  }

  //Check if product has a family
  async function findProductFamily(product_data) {
    await axios
      .post(
        IP + "/product_families/findProductFamily",
        { product_id: product_data.product_id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response Find Product Family:", response);
        console.log("Contract Products Ids Array Hook:", contractProductIds);
        if (response.data.length > 0) {
          setFamilyID(response.data[0].family_id);
          setContractProductData(product_data);
          setOpenFamilyPopup(!openFamilyPopup);
          // getUniqueProductFamilies({
          //   family_id: response.data[0].family_id,
          //   product_id: contractProductIds,
          //   product_data: product_data,
          // });
        } else {
          addContractProducts(product_data);
        }
      })
      .catch((error) => {
        console.log("Error Find Product Family:", error);
      });
  }

  async function findProductFamilyOnContractCreate(product_data) {
    await axios
      .post(
        IP + "/product_families/findProductFamily",
        { product_id: product_data.product_id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response Find Product Family on Contract create:", response);
        console.log("Contract Products Ids Array Hook:", contractProductIds);
        if (response.data.length > 0) {
          setFamilyID(response.data[0].family_id);
          //setContractProductData(product_data);
          setOpenFamilyPopup(!openFamilyPopup);
        }
      })
      .catch((error) => {
        console.log("Error Find Product Family on Contract create:", error);
      });
  }

  //Get other products from family and add them
  async function getUniqueProductFamilies(product) {
    await axios
      .post(IP + "/product_families/getUniqueProductFamilies", product, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Response get unique product families:", response);
        console.log("Product Ids:", product.product_id);
        console.log("Contract Product Data:", product.product_data);
        setOpenFamilyPopup(!openFamilyPopup);
        clearFields();
        getContractProducts({ contract_id: state?.contract_data.contract_id });
        //setFamilyID(response.data[0].family_id);
      })
      .catch((error) => {
        console.log("Error get unique product families:", error);
      });
  }

  //Get list of families
  async function getFamilies(vendor) {
    await axios
      .post(IP + "/families/getFamiliesByVendor", vendor, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Families:", response);
        let family_info = response.data.map((item) => {
          return {
            id: item.family_id,
            label: item.family_id + " " + item.family_name,
            name: item.family_name,
            product_id: item.product_id,
            code: item.product_code,
            upc: item.product_upc,
            //description: item.description,
          };
        });
        setFamilies(family_info);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getFamilyCost(family_data) {
    await axios
      .post(
        IP + "/families/getFamilyCost",
        { product_id: family_data.product_id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("get family cost:", response);
        setCost(response.data[0].cost);
        getOfferFromProduct(
          { upc: family_data.upc, from_date: fromDate, to_date: toDate },
          response.data[0].cost
        );
      })
      .catch((error) => {
        console.log("Error family cost:", error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            {/* <label>{item}</label> */}
            <label>{language == 1 ? "El campo es requerido." : "The field is required."}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  //Inserts the checkbox values into the array of jsons
  const handleAllCheck = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values:", selectedRequirements);

    if (value === "all") {
      setSelectedRequirements(
        selectedRequirements.length === requirementsList.length
          ? []
          : requirementsList.map((val) => (!val.disabled ? val.value : null)) //This will not check any disabled checkboxes or checkboxes with disabled field.
        //.filter((val) => val !== null)
        //: requirementsList.map((val) => val.value)
      );
      return;
    }

    const list = [...selectedRequirements];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List:", list);
    setSelectedRequirements(list);
  };

  //Inserts the checkbox products values into the array of jsons
  const handleAllCheckProducts = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked 2:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values 2:", setSelectedRelatedProduct);

    if (value === "all") {
      setSelectedRelatedProduct(
        setSelectedRelatedProduct.length === relatedProductList.length
          ? []
          : relatedProductList.map((val) => val.value) //do NOT add ".value" to vendorList else you'll get undefined error
      );
      return;
    }

    const list = [...setSelectedRelatedProduct];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List 2:", list);
    setSelectedRelatedProduct(list);
  };

  useEffect(() => {
    console.log("Is state here?", state);
    GetVendors();
    //GetProducts
    getProducts({ vendor_id: state?.contract_data.vendor_id });
    getFamilies({ vendor_id: state?.contract_data.vendor_id });

    // state &&
    //   getOfferFromProduct(
    //     {
    //       upc: state?.contract_data.upc,
    //       from_date: state?.contract_data.from_date,
    //       to_date: state?.contract_data.to_date,
    //     },
    //     state?.contract_data.cost
    //   );

    setBy({ id: 1, label: "Regular" });

    if (state) {
      getContractProducts({ contract_id: state?.contract_data.contract_id });
      setDate(dayjs(state?.contract_data.date));
      //setSuppliers(state?.contract_data.suppliers);
      setSelectedSupplier({
        id: state?.contract_data.vendor_id,
        label: state?.contract_data.vendor.vendor_number + " " + state?.contract_data.vendor.name,
        name: state?.contract_data.vendor.name,
        number: state?.contract_data.vendor.vendor_number,
      });
      setFacturado(state?.contract_data.vendor.vendor_number == 505 ? "Compañía" : "Suplidor");
      setEntregado(state?.contract_data.vendor.vendor_number == 505 ? "Compañía" : "Suplidor");
      setOrdenado(state?.contract_data.vendor.vendor_number == 505 ? "Compañía" : "Suplidor");
      setRecogido(state?.contract_data.vendor.vendor_number == 505 ? "Compañía" : "Suplidor");
      setOrder(state?.contract_data.order);
      setFromDate(dayjs(state?.contract_data.from_date));
      setToDate(dayjs(state?.contract_data.to_date));
      fromDateRef.current = dayjs(state?.contract_data.from_date);
      toDateRef.current = dayjs(state?.contract_data.to_date);
      setEta(dayjs(state?.contract_data.eta));
      setDateOf(dayjs(state?.contract_data.date_of));
      setTerms(state?.contract_data.term);
      setBuyer(state?.contract_data.buyer);
      setContact(state?.contract_data.contact);
      //setUpcList(state?.contract_data.);
      // if (state?.contract_data.family_id) {
      //   setIsFamily(true);
      //   setUPC(state?.contract_data.upc);
      //   setSelectedFamily({
      //     id: state?.contract_data.family_id,
      //     label: state?.contract_data.family_id + " " + state?.contract_data.family.family_name,
      //     product_id: state?.contract_data.product_id,
      //   });
      // } else {
      //   setSelectedUpc({
      //     id: state?.contract_data.upc,
      //     label: state?.contract_data.upc,
      //     product_id: state?.contract_data.product_id,
      //   });
      // }
      ////setOfferList(state?.contract_data.);
      ////setSelectedOffer(state?.contract_data.);
      // setDescription(state?.contract_data.description);
      // setCost(state?.contract_data.cost);
      // setOrder2(state?.contract_data.order);
      // setC(state?.contract_data.c);
      // setG(state?.contract_data.g);
      // setMan(state?.contract_data.man);
      // setEnvio(state?.contract_data.envio);
      // setInter(state?.contract_data.inter);
      // setTrans(state?.contract_data.trans);
      // setTar(state?.contract_data.tar);
      // setPubAp(state?.contract_data.pub_ap);
      // setVag(state?.contract_data.vag);
      // setAdd(state?.contract_data.add);
      // setImp(state?.contract_data.imp);
      // setPubFa(state?.contract_data.pub_fa);
      // setFotoFa(state?.contract_data.foto_fa);
      // setOffer(state?.contract_data.offer);
      // setShow(state?.contract_data.show);
      // setExtra(state?.contract_data.extra);
      // setApproved(state?.contract_data.approved);
      // setCe(state?.contract_data.ce);
      // setMethodR(state?.contract_data.method_r);
      // setCn(state?.contract_data.cn_e);
      // setPgr(state?.contract_data.pgr);
      // setPercentR(state?.contract_data.percent_r);
      // setPr(state?.contract_data.pr);
      // setMethodE(state?.contract_data.method_e);
      // setPercentE(state?.contract_data.percent_e);
      // setCn2(state?.contract_data.cn_e2);
      // setPge(state?.contract_data.pge);
      // setPe(state?.contract_data.pe);
      // setCeu(state?.contract_data.ceu);
      // setMethodRU(state?.contract_data.method_r2);
      // setPercentR2(state?.contract_data.percent_r2);
      // setCnu(state?.contract_data.cn_r);
      // setPgru(state?.contract_data.pgr2);
      // setPru(state?.contract_data.pru);
      // setMethodEU(state?.contract_data.method_e2);
      // setPercentE2(state?.contract_data.percent_e2);
      // setCnu2(state?.contract_data.cn_r2);
      // setPge2(state?.contract_data.pge2);
      // setPeu(state?.contract_data.peu);
    }
    console.log("Contract edit state:", state);
    console.log(state?.contract_data.add);
  }, [state?.edit]);

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Oferta creado exitosamente!" : "Offer Created Successfully!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={show2}
        close={() => {
          setShow2(!show2);
        }}
      />
      <MDSnackbar
        color="success"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Contrato creado exitosamente!" : "Contrato Created Successfully!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={contractCreateNotif}
        close={() => {
          setContractCreateNotif(!contractCreateNotif);
        }}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Contrato editado exitosamente!" : "Contrato edited Successfully!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={contractEditNotif}
        close={() => {
          setContractEditNotif(!contractEditNotif);
        }}
      />
      <MDSnackbar
        color="success"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Producto fue guardado" : "Product saved"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={productSaveNotif}
        close={() => {
          setProductSaveNotif(!productSaveNotif);
        }}
      />
      <MDSnackbar
        color="success"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Producto fue editado" : "Product edited"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={productEditNotif}
        close={() => {
          setProductEditNotif(!productEditNotif);
        }}
      />
      <DashboardNavbar />
      <Card>
        <Dialog
          fullWidth="md"
          maxWidth="md"
          open={openFamilyPopup}
          onClose={handleFamilyPopupClose}
        >
          {" "}
          <DialogTitle>{language == 1 ? "Familia found" : "Family found"}</DialogTitle>
          <DialogContent dividers>
            <Grid lg={"auto"}>
              <Typography>This product has a family, do you wish to bring the family?</Typography>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenFamilyPopup(!openFamilyPopup);
                addContractProducts(contractProductData);
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                if (!state) {
                  createContractWithProductFamily({
                    contract_date: date,
                    vendor_id: selectedSupplier.id,
                    product_id: selectedUpc ? selectedUpc.product_id : null,
                    family_id: selectedFamily ? selectedFamily.id : null,
                    product_family_id: familyID,
                    order_id: order,
                    from_date: fromDate,
                    to_date: toDate,
                    eta: eta,
                    date_of: dateOf,
                    term: terms,
                    buyer: buyer,
                    contact: contact,
                    facturado: facturado == "Suplidor" ? selectedSupplier.name : company,
                    entregado: entregado == "Suplidor" ? selectedSupplier.name : company,
                    ordenado: ordenado == "Suplidor" ? selectedSupplier.name : company,
                    recogido: recogido == "Suplidor" ? selectedSupplier.name : company,
                    upc: selectedUpc ? selectedUpc.id : upc,
                    description: description,
                    cost: cost,
                    order: order2,
                    c: c,
                    g: g,
                    man: man,
                    envio: envio,
                    inter: inter,
                    trans: trans,
                    tar: tar,
                    pub_ap: pubAp,
                    vag: vag,
                    add: add,
                    imp: imp,
                    pub_fa: pubFa,
                    foto_fa: fotoFa,
                    notas_aclara: notasAclara,
                    offers: contractOffers,
                    offer_id: selectedOffer.id,
                    show: show,
                    extra: extra ? 1 : 0,
                    approved: approved ? 1 : 0,
                    ce: ce,
                    method_r: methodR,
                    percent_r: percentR,
                    cn_r: cn,
                    pgr: pgr,
                    pr: pr,
                    method_e: methodE,
                    percent_e: percentE,
                    cn_e: cn2,
                    pge: pge,
                    pe: pe,
                    ceu: ceu,
                    method_r2: methodRU,
                    percent_r2: percentR2,
                    cn_r2: cnu,
                    pgr2: pgru,
                    pru: pru,
                    cn_e2: cnu2,
                    pge2: pge2,
                    method_e2: methodEU,
                    percent_e2: percentE2,
                    peu: peu,
                  });
                } else {
                  getUniqueProductFamilies({
                    family_id: familyID,
                    product_id: contractProductIds,
                    product_data: contractProductData,
                  });
                }
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth="lg" maxWidth="lg" open={openDialog} onClose={handleClose}>
          {" "}
          <DialogTitle>{language == 1 ? "Crear Oferta" : "Create Offer"}</DialogTitle>
          <DialogContent dividers>
            <Grid item lg={8}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={[
                  { id: 1, label: "Office Invoice" },
                  { id: 2, label: "Billback" },
                  { id: 3, label: "C/G" },
                  { id: 4, label: "Off/Temp" },
                ]}
                getOptionsLabel={(options) => options.label || ""}
                value={selectedOfferType}
                onChange={(event, newValue) => {
                  console.log("selected offer type:", newValue);
                  if (newValue == null) {
                    setSelectedOfferType("");
                  } else {
                    setSelectedOfferType(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Tipo de Oferta" : "Offer type"}
                    variant="outlined"
                  />
                )}
              />
              <ShowError id={"offer_type_id"} />
            </Grid>
            <Grid item lg={8}>
              <MDInput
                variant="outlined"
                type="text"
                //disabled
                label={language == 1 ? "Suplidor" : "Supplier"}
                sx={{ m: 1, width: "100%" }}
                value={selectedSupplier.label}
                // onChange={(e) => {
                //   setCode(e.target.value);
                // }}
              />
              <ShowError id={"vendor_id"} />
            </Grid>
            <Grid item lg={8}>
              {isFamily ? (
                <MDInput
                  variant="outlined"
                  type="text"
                  //disabled
                  label={language == 1 ? "Familia" : "Family"}
                  sx={{ m: 1, width: "100%" }}
                  value={selectedFamily.name}
                  // onChange={(e) => {
                  //   setCode(e.target.value);
                  // }}
                />
              ) : (
                <MDInput
                  variant="outlined"
                  type="text"
                  //disabled
                  label={language == 1 ? "Producto" : "Product"}
                  sx={{ m: 1, width: "100%" }}
                  value={selectedUpc.name}
                  // onChange={(e) => {
                  //   setCode(e.target.value);
                  // }}
                />
              )}
              <ShowError id={"product_id"} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={4}>
                <MDInput
                  variant="outlined"
                  type="text"
                  //disabled
                  label={language == 1 ? "Codigo" : "Code"}
                  sx={{ m: 1, width: "100%" }}
                  value={code}
                  // onChange={(e) => {
                  //   setCode(e.target.value);
                  // }}
                />
                <ShowError id={"code"} />
              </Grid>
              <Grid item lg={4}>
                <MDInput
                  variant="outlined"
                  type="text"
                  label="UPC"
                  //disabled
                  sx={{ m: 1, width: "100%" }}
                  value={upc}
                  // onChange={(e) => {
                  //   setUPC(e.target.value);
                  // }}
                />
                <ShowError id={"upc"} />
              </Grid>
            </Grid>
            <Grid item lg={8}>
              <MDInput
                variant="outlined"
                type="text"
                label={language == 1 ? "Descripcion" : "Description"}
                sx={{ m: 1, width: "100%" }}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <ShowError id={"description"} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={3}>
                <MDInput
                  variant="outlined"
                  type="number"
                  label={language == 1 ? "Costo" : "Cost"}
                  sx={{ m: 1, width: "100%" }}
                  value={cost}
                  onBlur={(e) => {
                    if (e.target.value > 0 && offers > 0) {
                      let result = e.target.value - offers;
                      setNetWorth(result);
                    } else {
                      setNetWorth("");
                    }
                  }}
                  // onChange={(e) => {
                  //   setCost(e.target.value);
                  // }}
                />
                <ShowError id={"cost"} />
              </Grid>
              <Grid item lg={3}>
                <MDInput
                  variant="outlined"
                  type="number"
                  label={language == 1 ? "Ofertas" : "Offers"}
                  sx={{ m: 1, width: "100%" }}
                  value={offers}
                  onBlur={(e) => {
                    if (e.target.value > 0 && cost > 0) {
                      let result = cost - e.target.value;
                      setNetWorth(result);
                    } else {
                      setNetWorth("");
                    }
                  }}
                  onChange={(e) => {
                    setOffers(e.target.value);
                  }}
                />
                <ShowError id={"offers"} />
              </Grid>
              <Grid item lg={3}>
                <MDInput
                  variant="outlined"
                  type="number"
                  label={language == 1 ? "Costo Neto" : "Net worth"}
                  sx={{ m: 1, width: "100%" }}
                  value={netWorth}
                  // onChange={(e) => {
                  //   setNetWorth(e.target.value);
                  // }}
                />
                <ShowError id={"net_worth"} />
              </Grid>
              <Grid item lg={3}>
                <VirtualizedAutocomplete
                  sx={{ m: 1, width: "100%" }}
                  options={[{ id: 1, label: "Regular" }]}
                  getOptionsLabel={(options) => options.label || ""}
                  value={by}
                  onChange={(event, newValue) => {
                    console.log("selected by:", newValue);
                    setBy(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Por" : "By"}
                      variant="outlined"
                    />
                  )}
                />
                <ShowError id={"by_id"} />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: "30px" }}>
              <Grid item lg={1}>
                <MDInput
                  variant="outlined"
                  type="number"
                  label="C"
                  sx={{ m: 1, width: "100%" }}
                  value={offerC}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^0-9]*/g, "");
                    if (selectedOfferType.label == "C/G") {
                      setOfferC(value);
                    }
                  }}
                />
                <ShowError id={"c"} />
              </Grid>
              <Grid item lg={1}>
                <MDInput
                  variant="outlined"
                  type="number"
                  label="G"
                  sx={{ m: 1, width: "100%" }}
                  value={offerG}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^0-9]*/g, "");
                    if (selectedOfferType.label == "C/G") {
                      setOfferG(value);
                    }
                  }}
                  onBlur={(e) => {
                    if (selectedOfferType.label == "C/G") {
                      if (netWorth > 0 && offerC > 0 && offerG > 0) {
                        let c_g = parseInt(e.target.value) + 1;
                        console.log("c/G", c_g);
                        let result = netWorth / c_g;
                        let offer_result = Math.round(result * 1000) / 1000;
                        console.log("result", offer_result);
                        setOffer(offer_result);
                        let percent_result = (result / cost) * 100;
                        let round_result = Math.round(percent_result * 1000) / 1000;
                        console.log("Percent result", round_result);
                        setPercent(round_result);
                      } else {
                        setOffer("");
                        setPercent("");
                      }
                    }
                  }}
                />
                <ShowError id={"g"} />
              </Grid>
              <Grid item lg={2}>
                <MDInput
                  variant="outlined"
                  type="number"
                  label="%"
                  sx={{ m: 1, width: "100%" }}
                  value={percent}
                  onChange={(e) => {
                    setPercent(e.target.value);
                  }}
                />
                <ShowError id={"percent"} />
              </Grid>
              <Grid item lg={2}>
                <MDInput
                  variant="outlined"
                  type="number"
                  label={language == 1 ? "Oferta" : "Offer"}
                  sx={{ m: 1, width: "100%" }}
                  value={offer}
                  onChange={(e) => {
                    setOffer(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (e.target.value > 0 && cost > 0) {
                      let result = (e.target.value / cost) * 100;
                      let round_result = Math.round(result * 1000) / 1000;
                      setPercent(round_result);
                    } else {
                      setPercent("");
                    }
                  }}
                />
                <ShowError id={"offer"} />
              </Grid>
              <Grid item lg={3}>
                <CustomDatePicker
                  label={language == 1 ? "Desde" : "From"}
                  sx={{ m: 1, width: "100%" }}
                  disabled={!fromDate}
                  minDate={fromDate && dayjs(fromDate)}
                  maxDate={toDate && dayjs(toDate)}
                  value={offerFromDate}
                  onChange={(newValue) => {
                    setOfferFromDate(newValue);
                  }}
                />
                <ShowError id={"from_date"} />
              </Grid>
              <Grid item lg={3}>
                <CustomDatePicker
                  label={language == 1 ? "Hasta" : "To"}
                  sx={{ m: 1, width: "100%" }}
                  disabled={!toDate}
                  minDate={dayjs(fromDate)}
                  maxDate={dayjs(toDate)}
                  value={offerToDate}
                  onChange={(newValue) => {
                    setOfferToDate(newValue);
                  }}
                />
                <ShowError id={"to_date"} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              type="submit"
              onClick={() => {
                createOffer({
                  //store_id: selectedStore.id,
                  offer_type_id: selectedOfferType.id,
                  vendor_id: selectedSupplier.id,
                  product_id: isFamily ? selectedFamily.product_id : selectedUpc.product_id,
                  code: code,
                  upc: upc,
                  description: description,
                  cost: cost,
                  offers: offers,
                  net_worth: netWorth,
                  by_id: by.id,
                  c: offerC,
                  g: offerG,
                  percent: percent,
                  offer: offer,
                  from_date: offerFromDate,
                  to_date: offerToDate,
                });
              }}
            >
              {language == 1 ? "Crear" : "Create"}
            </Button>
          </DialogActions>
        </Dialog>
        <MDBox p={2}>
          {!state ? (
            <MDTypography variant="h4">
              {language == 1 ? "Crear Contrato" : "Create Contract"}
            </MDTypography>
          ) : (
            <MDTypography variant="h4">
              {language == 1 ? "Editar Contrato" : "Edit Contract"}
            </MDTypography>
          )}
          <Grid item lg={4}>
            {" "}
            <MDTypography variant="h5">{language == 1 ? "Encabezado" : "Header"}</MDTypography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4}>
              <CustomDatePicker
                label={language == 1 ? "Fecha" : "Date"}
                sx={{ m: 1, width: "100%" }}
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
              />
              <ShowError id={"contract_date"} />
            </Grid>
            <Grid item lg={4}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={suppliers}
                getOptionsLabel={(options) => options.label || ""}
                value={selectedSupplier}
                onChange={(event, newValue) => {
                  console.log("selected supplier:", newValue);
                  if (newValue == null) {
                    setSelectedSupplier("");
                    setFacturado("Suplidor");
                    setEntregado("Suplidor");
                    setOrdenado("Suplidor");
                    setRecogido("Suplidor");
                  } else {
                    setSelectedSupplier(newValue);
                    setFacturado(newValue.number == 505 ? "Compañía" : "Suplidor");
                    setEntregado(newValue.number == 505 ? "Compañía" : "Suplidor");
                    setOrdenado(newValue.number == 505 ? "Compañía" : "Suplidor");
                    setRecogido(newValue.number == 505 ? "Compañía" : "Suplidor");
                    getProducts({ vendor_id: newValue.id });
                    getFamilies({ vendor_id: newValue.id });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Suplidor" : "Supplier"}
                    variant="outlined"
                  />
                )}
              />
              <ShowError id={"vendor_id"} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <CustomDatePicker
                label={language == 1 ? "Desde" : "From"}
                sx={{ m: 1, width: "100%" }}
                value={fromDate}
                defaultValue={state?.contract_data.from_date}
                onChange={(newValue) => {
                  fromDateRef.current = newValue;
                  setFromDate(newValue);
                  setOfferFromDate(newValue);
                  setEta(null);
                  setDateOf(null);
                  if (selectedUpc && toDate) {
                    getOfferFromProduct(
                      {
                        upc: selectedUpc.upc,
                        product_id: selectedUpc.product_id,
                        from_date: newValue,
                        to_date: toDate,
                      },
                      cost
                    );
                  }
                  if (selectedFamily && toDate) {
                    getOfferFromProduct(
                      {
                        upc: selectedFamily.upc,
                        product_id: selectedUpc.product_id,
                        from_date: newValue,
                        to_date: toDate,
                      },
                      cost
                    );
                  }
                }}
              />
              <ShowError id={"from_date"} />
            </Grid>
            <Grid item lg={3}>
              <CustomDatePicker
                label={language == 1 ? "Hasta" : "To"}
                sx={{ m: 1, width: "100%" }}
                value={toDate}
                defaultValue={state?.contract_data.to_date}
                onChange={(newValue) => {
                  toDateRef.current = newValue;
                  setToDate(newValue);
                  setOfferToDate(newValue);
                  setEta(null);
                  setDateOf(null);
                  if (selectedUpc && fromDate) {
                    getOfferFromProduct(
                      {
                        upc: selectedUpc.upc,
                        product_id: selectedUpc.product_id,
                        from_date: fromDate,
                        to_date: newValue,
                      },
                      cost
                    );
                  }
                  if (selectedFamily && fromDate) {
                    getOfferFromProduct(
                      {
                        upc: selectedFamily.upc,
                        product_id: selectedUpc.product_id,
                        from_date: fromDate,
                        to_date: newValue,
                      },
                      cost
                    );
                  }
                }}
              />
              <ShowError id={"to_date"} />
            </Grid>
            <Grid item lg={4}>
              <MDInput
                //variant="standard"
                type="number"
                label={language == 1 ? "Orden" : "Order"}
                sx={{ m: 1, width: "100%" }}
                value={order}
                defaultValue={state?.contract_data.order}
                onChange={(e) => {
                  setOrder(e.target.value);
                }}
              />
              <ShowError id={"order_id"} />
            </Grid>
            <Grid item lg={"auto"}>
              <Tooltip title={language == 1 ? "Buscar Orden" : "Search Order"}>
                <MDButton
                  sx={{ mt: 1 }}
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    getPurchaseOrder({ po_number: order });
                  }}
                  iconOnly
                >
                  <Icon>search</Icon>
                </MDButton>
              </Tooltip>
            </Grid>
            <Grid item lg={4}>
              {/* <MDInput
                //variant="standard"
                type="text"
                label={language == 1 ? "Suplidor 3" : "Supplier 3"}
                sx={{ m: 1, width: "100%" }}
                value={selectedProduct.upc}
                onChange={(e) => {
                  //setFamilyUPC(e.target.value);
                  
                }}
              />
              <ShowError id={"upc"} /> */}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* <Grid item lg={3}>
              <CustomDatePicker
                label={language == 1 ? "Desde" : "From"}
                sx={{ m: 1, width: "100%" }}
                value={fromDate}
                defaultValue={state?.contract_data.from_date}
                // onBlur={() => {
                //   if (dayjs(startDate).isAfter(dayjs(endDate))) setStartDate("");
                // }}
                onChange={(newValue) => {
                  setFromDate(newValue);
                  setOfferFromDate(newValue);
                  setEta(null);
                  setDateOf(null);
                  if (selectedUpc && toDate) {
                    getOfferFromProduct(
                      { upc: selectedUpc.upc, from_date: newValue, to_date: toDate },
                      cost
                    );
                  }
                  if (selectedFamily && toDate) {
                    getOfferFromProduct(
                      { upc: selectedFamily.upc, from_date: newValue, to_date: toDate },
                      cost
                    );
                  }
                }}
              />
              <ShowError id={"from_date"} />
            </Grid>
            <Grid item lg={3}>
              <CustomDatePicker
                label={language == 1 ? "Hasta" : "To"}
                sx={{ m: 1, width: "100%" }}
                value={toDate}
                defaultValue={state?.contract_data.to_date}
                onChange={(newValue) => {
                  setToDate(newValue);
                  setOfferToDate(newValue);
                  setEta(null);
                  setDateOf(null);
                  if (selectedUpc && fromDate) {
                    getOfferFromProduct(
                      { upc: selectedUpc.upc, from_date: fromDate, to_date: newValue },
                      cost
                    );
                  }
                  if (selectedFamily && fromDate) {
                    getOfferFromProduct(
                      { upc: selectedFamily.upc, from_date: fromDate, to_date: newValue },
                      cost
                    );
                  }
                }}
              />
              <ShowError id={"to_date"} />
            </Grid> */}
            <Grid item lg={3}>
              <CustomDatePicker
                label="E.T.A."
                sx={{ m: 1, width: "100%" }}
                value={eta}
                defaultValue={state?.contract_data.eta}
                //minDate={dayjs(fromDate)}
                //maxDate={dayjs(toDate)}
                onChange={(newValue) => {
                  setEta(newValue);
                }}
              />
              <ShowError id={"eta"} />
            </Grid>
            <Grid item lg={3}>
              <CustomDatePicker
                label={language == 1 ? "Fecha de." : "Date Of."}
                sx={{ m: 1, width: "100%" }}
                value={dateOf}
                //minDate={dayjs(fromDate)}
                //maxDate={dayjs(toDate)}
                defaultValue={state?.contract_data.date_of}
                onChange={(newValue) => {
                  setDateOf(newValue);
                }}
              />
              <ShowError id={"date_of"} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "30px" }}>
            <Grid item lg={4}>
              <MDInput
                //variant="standard"
                type="text"
                label={language == 1 ? "Terminos" : "Terms"}
                sx={{ m: 1, width: "100%" }}
                value={terms}
                defaultValue={state?.contract_data.term}
                onChange={(e) => {
                  setTerms(e.target.value);
                }}
              />
              <ShowError id={"term"} />
            </Grid>
            <Grid item lg={4}>
              <MDInput
                //variant="standard"
                type="text"
                label={language == 1 ? "Comprador" : "Buyer"}
                sx={{ m: 1, width: "100%" }}
                value={buyer}
                defaultValue={state?.contract_data.buyer}
                onChange={(e) => {
                  setBuyer(e.target.value);
                }}
              />
              <ShowError id={"buyer"} />
            </Grid>
            <Grid item lg={4}>
              <MDInput
                //variant="standard"
                type="text"
                label={language == 1 ? "Contacto" : "Contact"}
                sx={{ m: 1, width: "100%" }}
                value={contact}
                defaultValue={state?.contract_data.contact}
                onChange={(e) => {
                  setContact(e.target.value);
                }}
              />
              <ShowError id={"contact"} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "30px" }}>
            <Grid item lg={2}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={["Suplidor", "Compania"]}
                getOptionsLabel={(options) => options.label || ""}
                value={facturado}
                onChange={(event, newValue) => {
                  setFacturado(newValue);
                  console.log("Company:", company);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Facturado" : "Invoiced"}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item lg={2}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={["Suplidor", "Compania"]}
                getOptionsLabel={(options) => options.label || ""}
                value={entregado}
                onChange={(event, newValue) => {
                  setEntregado(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Entregado" : "Delivered"}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item lg={2}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={["Suplidor", "Compania"]}
                getOptionsLabel={(options) => options.label || ""}
                value={ordenado}
                onChange={(event, newValue) => {
                  setOrdenado(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Ordenado" : "Ordered"}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item lg={2}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={["Suplidor", "Compania"]}
                getOptionsLabel={(options) => options.label || ""}
                value={recogido}
                onChange={(event, newValue) => {
                  setRecogido(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Recogido" : "Pickup"}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {/* <Grid item lg={2}>
              <MDInput
                //variant="standard"
                type="text"
                label={language == 1 ? "Porcentaje" : "Percentage"}
                sx={{ m: 1, width: "100%" }}
                //value={upc}
                //defaultValue={state?.contract_data.upc}
                // onChange={(e) => {
                // }}
              />
            </Grid>*/}
          </Grid>
          <Grid item lg={4}>
            {" "}
            <MDTypography variant="h5">{language == 1 ? "Cuerpo" : "Body"}</MDTypography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4}>
              {isFamily ? (
                <VirtualizedAutocomplete
                  sx={{ m: 1, width: "100%" }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: 300,
                      },
                    },
                  }}
                  options={families}
                  getOptionsLabel={(options) => options.label || ""}
                  value={selectedFamily}
                  onChange={(event, newValue) => {
                    console.log("selected product:", newValue);
                    if (newValue == null) {
                      setSelectedFamily("");
                      setDescription("");
                      setCode("");
                      setUPC("");
                      setCost("");
                    } else {
                      getFamilyCost({ product_id: newValue.product_id, upc: newValue.upc });
                      //getFamilyCost(newValue.product_id);
                      //getProductOfFamily({ product_id: newValue.product_id });
                      setSelectedFamily(newValue);
                      setDescription(newValue.name);
                      setCode(newValue.code);
                      setUPC(newValue.upc);
                      //setCost(newValue.cost);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Familia" : "Family"}
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                // <MDInput
                //   variant="outlined"
                //   type="text"
                //   label="UPC"
                //   //disabled
                //   sx={{ m: 1, width: "100%" }}
                //   value={selectedUpc.upc}
                //   // onChange={(e) => {
                //   //   setUPC(e.target.value);
                //   // }}
                // />
                <VirtualizedAutocomplete
                  sx={{ m: 1, width: "100%" }}
                  options={upcList}
                  getOptionsLabel={(options) => options.label || ""}
                  value={selectedUpc}
                  onChange={(event, newValue) => {
                    setProductEditMode(false);
                    console.log("selected upc:", newValue);
                    console.log(fromDate);
                    console.log(toDate);
                    if (newValue == null) {
                      setSelectedUpc("");
                      setDescription("");
                      setCode("");
                      setUPC("");
                      setCost("");
                      setSelectedOffer("");
                      setContractOffers("0.00");
                      setOfferList("");
                      setOffers("0.00");
                      //setNetWorth(prod_cost);
                      setOrder2("0.00");
                      setC(0);
                      setG(0);
                      setMan("");
                      setEnvio("");
                      setInter("");
                      setTrans("");
                      setTar("");
                      setPubAp("");
                      setVag("");
                      setAdd("");
                      setImp("");
                      setPubFa("");
                      setFotoFa("");
                      setNotasAclara("");
                      setOffers("");
                      setMethodR("");
                      setPercentR("");
                      setMethodE("");
                      setPercentE("");
                      setCn("");
                      setCn2("");
                      setCeu("");
                      setPr("");
                      setPe("");
                      setMethodRU("");
                      setPercentR2("");
                      setCnu("");
                      setPgr("");
                      setPgru("");
                      setPru("");
                      setMethodEU("");
                      setPercentE2("");
                      setCnu2("");
                      setPge("");
                      setPge2("");
                      setPeu("");
                      setShow("");
                      setExtra(0);
                      setApproved(0);
                    } else {
                      if (fromDate && toDate) {
                        getOfferFromProduct(
                          { upc: newValue.id, product_id: newValue.product_id },
                          newValue.cost
                        );
                      }
                      setSelectedUpc(newValue);
                      setDescription(newValue.name);
                      setCode(newValue.code);
                      setUPC(newValue.id);
                      setCost(newValue.cost);
                      setSelectedOffer("");
                      setContractOffers("0.00");
                      setOfferList("");
                      setOffers("0.00");
                      //setNetWorth(prod_cost);
                      setOrder2("0.00");
                      setC(0);
                      setG(0);
                      setMan("0.00");
                      setEnvio("0.00");
                      setInter("0.00");
                      setTrans("0.00");
                      setTar("0.00");
                      setPubAp("0.00");
                      setVag("0.00");
                      setAdd("0.00");
                      setImp("0.00");
                      setPubFa("0.00");
                      setFotoFa("0.00");
                      setNotasAclara("0.00");
                      setOffers("0.00");
                      setMethodR("Unitario");
                      setPercentR("0.00");
                      setMethodE("Unitario");
                      setPercentE("0.00");
                      setCn("0.00");
                      setCn2("0.00");
                      setCeu("0.00");
                      setPr("0.00");
                      setPe("0.00");
                      setMethodRU("Unitario");
                      setPercentR2("0.00");
                      setCnu("0.00");
                      setPgr("0.00");
                      setPgru("0.00");
                      setPru("0.00");
                      setMethodEU("Unitario");
                      setPercentE2("0.00");
                      setCnu2("0.00");
                      setPge("0.00");
                      setPge2("0.00");
                      setPeu("0.00");
                      setShow("Unidad");
                      setExtra(0);
                      setApproved(1);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="UPC" variant="outlined" />}
                />
              )}
              <ShowError id={"upc"} />
            </Grid>
            <Grid item lg={"auto"}>
              {/* <FormGroup sx={{ mt: 2, ml: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFamily}
                      onChange={() => {
                        setIsFamily(!isFamily);
                        setSelectedUpc("");
                        setSelectedFamily("");
                        setUPC("");
                        setDescription("");
                      }}
                    />
                  }
                  label="Is Family"
                />
              </FormGroup> */}
            </Grid>
            {isFamily && (
              <Grid item lg={3}>
                <MDInput
                  //variant="standard"
                  type="text"
                  label="UPC"
                  sx={{ m: 1, width: "100%" }}
                  value={upc}
                  defaultValue={state?.contract_data.upc}
                  // onChange={(e) => {
                  // }}
                />
                <ShowError id={"upc"} />
              </Grid>
            )}
            <Grid item lg={3}>
              <MDInput
                //variant="standard"
                type="text"
                label={language == 1 ? "Descripcion" : "Description"}
                sx={{ m: 1, width: "100%" }}
                value={description}
                defaultValue={state?.contract_data.description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <ShowError id={"description"} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={"auto"}>
              <MDInput
                //variant="standard"
                type="number"
                label={language == 1 ? "Costo" : "Cost"}
                sx={{ m: 1, width: "100%" }}
                value={cost}
                defaultValue={state?.contract_data.cost}
                onChange={(e) => {
                  setCost(e.target.value);
                }}
              />
              <ShowError id={"cost"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                //variant="standard"
                type="number"
                label={language == 1 ? "Orden" : "Order"}
                sx={{ m: 1, width: "100%" }}
                value={order2}
                defaultValue={state?.contract_data.order}
                onChange={(e) => {
                  setOrder2(e.target.value);
                }}
              />
              <ShowError id={"order"} />
            </Grid>
            {/* <Grid item lg={'auto'}>
              <MDInput
                //variant="standard"
                type="text"
                label="C/G"
                sx={{ m: 1, width: "100%" }}
                value={selectedProduct.upc}
                onChange={(e) => {
                  //setFamilyUPC(e.target.value);
                  
                }}
              />
              <ShowError id={"upc"} />
            </Grid> */}
            <Grid item lg={1}>
              <MDInput
                variant="outlined"
                type="number"
                label="C"
                sx={{ m: 1, width: "100%" }}
                value={c}
                defaultValue={state?.contract_data.c}
                onChange={(e) => {
                  setC(e.target.value);
                }}
              />
              <ShowError id={"c"} />
            </Grid>
            <Grid item lg={1}>
              <MDInput
                variant="outlined"
                type="number"
                label="G"
                sx={{ m: 1, width: "100%" }}
                value={g}
                defaultValue={state?.contract_data.g}
                onChange={(e) => {
                  setG(e.target.value);
                }}
              />
              <ShowError id={"g"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Man."
                sx={{ m: 1, width: "100%" }}
                value={man}
                defaultValue={state?.contract_data.man}
                onChange={(e) => {
                  setMan(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setMan(value.toFixed(2));
                }}
              />
              <ShowError id={"man"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Envio"
                sx={{ m: 1, width: "100%" }}
                value={envio}
                defaultValue={state?.contract_data.envio}
                onChange={(e) => {
                  setEnvio(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setEnvio(value.toFixed(2));
                }}
              />
              <ShowError id={"envio"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Inter."
                sx={{ m: 1, width: "100%" }}
                value={inter}
                defaultValue={state?.contract_data.inter}
                onChange={(e) => {
                  setInter(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setInter(value.toFixed(2));
                }}
              />
              <ShowError id={"inter"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Trans."
                sx={{ m: 1, width: "100%" }}
                value={trans}
                defaultValue={state?.contract_data.trans}
                onChange={(e) => {
                  setTrans(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setTrans(value.toFixed(2));
                }}
              />
              <ShowError id={"trans"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Tar."
                sx={{ m: 1, width: "100%" }}
                value={tar}
                defaultValue={state?.contract_data.tar}
                onChange={(e) => {
                  setTar(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setTar(value.toFixed(2));
                }}
              />
              <ShowError id={"tar"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Pub. Ap."
                sx={{ m: 1, width: "100%" }}
                value={pubAp}
                defaultValue={state?.contract_data.pub_ap}
                onChange={(e) => {
                  setPubAp(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setPubAp(value.toFixed(2));
                }}
              />
              <ShowError id={"pub_ap"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Vag."
                sx={{ m: 1, width: "100%" }}
                value={vag}
                defaultValue={state?.contract_data.vag}
                onChange={(e) => {
                  setVag(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setVag(value.toFixed(2));
                }}
              />
              <ShowError id={"vag"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Add."
                sx={{ m: 1, width: "100%" }}
                value={add}
                defaultValue={state?.contract_data.add}
                onChange={(e) => {
                  setAdd(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setAdd(value.toFixed(2));
                }}
              />
              <ShowError id={"add"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Imp."
                sx={{ m: 1, width: "100%" }}
                value={imp}
                defaultValue={state?.contract_data.imp}
                onChange={(e) => {
                  setImp(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setImp(value.toFixed(2));
                }}
              />
              <ShowError id={"imp"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Pub. Fa."
                sx={{ m: 1, width: "100%" }}
                value={pubFa}
                defaultValue={state?.contract_data.pub_fa}
                onChange={(e) => {
                  setPubFa(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setPubFa(value.toFixed(2));
                }}
              />
              <ShowError id={"pub_fa"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Foto. Fa."
                sx={{ m: 1, width: "100%" }}
                value={fotoFa}
                defaultValue={state?.contract_data.foto_fa}
                onChange={(e) => {
                  setFotoFa(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setFotoFa(value.toFixed(2));
                }}
              />
              <ShowError id={"foto_fa"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label="Notas Aclara"
                sx={{ m: 1, width: "100%" }}
                value={notasAclara}
                defaultValue={state?.contract_data.notas_aclara}
                onChange={(e) => {
                  setNotasAclara(e.target.value);
                }}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  setNotasAclara(value.toFixed(2));
                }}
              />
              <ShowError id={"foto_fa"} />
            </Grid>
            <Grid item lg={"auto"}>
              <MDInput
                variant="outlined"
                type="number"
                label={language == 1 ? "Ofertas" : "Offers"}
                sx={{ m: 1, width: "100%" }}
                value={contractOffers}
                //defaultValue={state?.contract_data.foto_fa}
              />
              <ShowError id={"offers"} />
            </Grid>
            {/* <Grid item lg={"auto"}>
              <Tooltip title={language == 1 ? "Crear Oferta" : "Create offer"}>
                <MDButton
                  sx={{ mt: 2 }}
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    handleClickOpen();
                  }}
                  iconOnly
                >
                  <Icon>add</Icon>
                </MDButton>
              </Tooltip>
            </Grid> */}
            <Grid item lg={"auto"}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={["Unidad", "Caja"]}
                getOptionsLabel={(options) => options.label || ""}
                value={show}
                onChange={(event, newValue) => {
                  setShow(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Mostrar" : "Show"}
                    variant="outlined"
                  />
                )}
              />
              <ShowError id={"show"} />
            </Grid>
            <Grid item lg={"auto"}>
              <FormGroup sx={{ mt: -1, ml: 2 }}>
                <FormControlLabel
                  control={<Checkbox checked={extra} onChange={() => setExtra(!extra)} />}
                  label="Extra"
                />
                <FormControlLabel
                  control={<Checkbox checked={approved} onChange={() => setApproved(!approved)} />}
                  label="Aprobado"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="C.E."
                sx={{ m: 1, width: "100%" }}
                value={ce}
                defaultValue={state?.contract_data.ce}
                onChange={(e) => {
                  const inputValue = parseFloat(e.target.value);
                  if (inputValue <= cost || isNaN(inputValue)) {
                    setCe(inputValue);
                  } else {
                    setCe("");
                  }
                }}
              />
              <ShowError id={"ce"} />
            </Grid>
            <Grid item xs={1.5}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={methodROptions}
                getOptionsLabel={(options) => options.label || ""}
                defaultValue={methodROptions[0]}
                value={methodR}
                onChange={(event, newValue) => {
                  setMethodR(newValue);
                  if (newValue == "Unitario") {
                    setCn(0);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Método R." : "Method R."}
                    variant="outlined"
                  />
                )}
              />
              <ShowError id={"method_r"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="Cn."
                sx={{ m: 1, width: "100%" }}
                value={cn}
                defaultValue={state?.contract_data.cn_e}
                onChange={(e) => {
                  setCn(e.target.value);
                  //setFamilyUPC(e.target.value);
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (pgr) {
                    const pr_result = (ce * value) / pgr;
                    setPr(pr_result.toFixed(2));
                    let result = ((ce - pr_result) / ce) * 100;
                    setPercentR(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"cn_e"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="P.G.R."
                sx={{ m: 1, width: "100%" }}
                value={pgr}
                defaultValue={state?.contract_data.pgr}
                onChange={(e) => {
                  setPgr(e.target.value);
                  //setFamilyUPC(e.target.value);
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (cn) {
                    const pr_result = (ce * cn) / value;
                    setPr(pr_result.toFixed(2));
                    let result = ((ce - pr_result) / ce) * 100;
                    console.log("Value:", ce - pr_result);
                    console.log("Percent R before", result);
                    setPercentR(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"pgr"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="%"
                sx={{ m: 1, width: "100%" }}
                value={percentR}
                defaultValue={state?.contract_data.percent_r}
                onChange={(e) => {
                  //setFamilyUPC(e.target.value);
                }}
              />
              <ShowError id={"percent_r"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="P.R."
                sx={{ m: 1, width: "100%" }}
                value={pr}
                defaultValue={state?.contract_data.pr}
                onChange={(e) => {
                  setPr(e.target.value);
                  //setFamilyUPC(e.target.value);
                }}
                onBlur={(e) => {
                  console.log("PR value:", e.target.value);
                  let value = parseFloat(e.target.value);
                  setPr(value.toFixed(2));
                  if (value !== null) {
                    let result = ((ce - value) / ce) * 100;
                    console.log("Value:", ce - value);
                    console.log("Percent R", result);
                    setPercentR(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"pr"} />
            </Grid>
            <Grid item xs={1.5}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={methodEOptions}
                getOptionsLabel={(options) => options.label || ""}
                defaultValue={methodEOptions[0]}
                value={methodE}
                onChange={(event, newValue) => {
                  setMethodE(newValue);
                  if (newValue == "Unitario") {
                    setCn2(0);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Método E." : "Method E."}
                    variant="outlined"
                  />
                )}
              />
              <ShowError id={"method_e"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="Cn."
                sx={{ m: 1, width: "100%" }}
                value={cn2}
                defaultValue={state?.contract_data.cn_e2}
                onChange={(e) => {
                  setCn2(e.target.value);
                  //setFamilyUPC(e.target.value);
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (pge) {
                    const pe_result = (ce * value) / pge;
                    setPe(pe_result.toFixed(2));
                    let result = ((ce - pe_result) / ce) * 100;
                    setPercentE(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"cn_e"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="P.G.E."
                sx={{ m: 1, width: "100%" }}
                value={pge}
                defaultValue={state?.contract_data.pge}
                onChange={(e) => {
                  setPge(e.target.value);
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (cn2) {
                    const pe_result = (ce * cn2) / value;
                    setPe(pe_result.toFixed(2));
                    let result = ((ce - pe_result) / ce) * 100;
                    setPercentE(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"pge"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="%"
                sx={{ m: 1, width: "100%" }}
                value={percentE}
                defaultValue={state?.contract_data.percent_e}
                onChange={(e) => {
                  //setFamilyUPC(e.target.value);
                }}
              />
              <ShowError id={"percent_e"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="P.E."
                sx={{ m: 1, width: "100%" }}
                value={pe}
                defaultValue={state?.contract_data.pe}
                onChange={(e) => {
                  setPe(e.target.value);
                }}
                onBlur={(e) => {
                  console.log("PE value:", e.target.value);
                  let value = parseFloat(e.target.value);
                  setPe(value.toFixed(2));
                  if (value !== null) {
                    let result = ((ce - value) / ce) * 100;
                    console.log("Value:", ce - value);
                    console.log("Percent E", result);
                    setPercentE(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"pe"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="C.E.U."
                sx={{ m: 1, width: "100%" }}
                value={ceu}
                defaultValue={state?.contract_data.ceu}
                onChange={(e) => {
                  const inputValue = parseFloat(e.target.value);
                  if (inputValue <= cost || isNaN(inputValue)) {
                    setCeu(inputValue);
                  } else {
                    setCeu("");
                  }
                }}
              />
              <ShowError id={"ceu"} />
            </Grid>
            <Grid item xs={1.5}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={["Unitario", "Dividido"]}
                getOptionsLabel={(options) => options.label || ""}
                value={methodRU}
                onChange={(event, newValue) => {
                  setMethodRU(newValue);
                  if (newValue == "Unitario") {
                    setCnu(0);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Método R." : "Method R."}
                    variant="outlined"
                  />
                )}
              />
              <ShowError id={"method_r2"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="Cn."
                sx={{ m: 1, width: "100%" }}
                value={cnu}
                defaultValue={state?.contract_data.cn_r}
                onChange={(e) => {
                  const inputValue = parseFloat(e.target.value); // Parse the input as a number
                  setCnu(inputValue);
                  //setFamilyUPC(e.target.value);
                }}
                onBlur={(e) => {
                  const inputValue = parseFloat(e.target.value); // Parse the input as a number
                  if (pgru) {
                    const pru_result = (ceu * inputValue) / pgru;
                    setPru(pru_result.toFixed(2));
                    let result = ((ceu - pru_result) / ceu) * 100;
                    setPercentR2(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"cn_r"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="P.G.R."
                sx={{ m: 1, width: "100%" }}
                value={pgru}
                defaultValue={state?.contract_data.pgr2}
                onChange={(e) => {
                  setPgru(e.target.value);
                  //setFamilyUPC(e.target.value);
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (cnu) {
                    const pru_result = (ceu * cnu) / value;
                    setPru(pru_result.toFixed(2));
                    let result = ((ceu - pru_result) / ceu) * 100;
                    setPercentR2(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"pgr2"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="%"
                sx={{ m: 1, width: "100%" }}
                value={percentR2}
                defaultValue={state?.contract_data.percent_r2}
                onChange={(e) => {
                  setPercentR2(e.target.value);
                }}
              />
              <ShowError id={"percent_r2"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="P.R.U."
                sx={{ m: 1, width: "100%" }}
                value={pru}
                defaultValue={state?.contract_data.pru}
                onChange={(e) => {
                  setPru(e.target.value);
                  //setFamilyUPC(e.target.value);
                }}
                onBlur={(e) => {
                  console.log("PRU value:", e.target.value);
                  let value = parseFloat(e.target.value);
                  setPru(value.toFixed(2));
                  if (value !== null) {
                    let result = ((ceu - value) / ceu) * 100;
                    console.log("Value:", ceu - value);
                    console.log("Percent R2", result);
                    setPercentR2(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"pru"} />
            </Grid>
            <Grid item lg={1.5}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={["Unitario", "Dividido"]}
                getOptionsLabel={(options) => options.label || ""}
                value={methodEU}
                onChange={(event, newValue) => {
                  setMethodEU(newValue);
                  if (newValue == "Unitario") {
                    setCnu2(0);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Método E." : "Method E."}
                    variant="outlined"
                  />
                )}
              />
              <ShowError id={"method_e2"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="Cn."
                sx={{ m: 1, width: "100%" }}
                value={cnu2}
                defaultValue={state?.contract_data.cn_r2}
                onChange={(e) => {
                  setCnu2(e.target.value);
                  //setFamilyUPC(e.target.value);
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (pge2) {
                    const peu_result = (ceu * value) / pge2;
                    setPeu(peu_result.toFixed(2));
                    let result = ((ceu - peu_result) / ceu) * 100;
                    setPercentE2(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"cn_r2"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="P.G.E"
                sx={{ m: 1, width: "100%" }}
                value={pge2}
                defaultValue={state?.contract_data.pge2}
                onChange={(e) => {
                  setPge2(e.target.value);
                  //setFamilyUPC(e.target.value);
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (cnu2) {
                    const peu_result = (ceu * cnu2) / value;
                    setPeu(peu_result.toFixed(2));
                    let result = ((ceu - peu_result) / ceu) * 100;
                    setPercentE2(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"pge2"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="%"
                sx={{ m: 1, width: "100%" }}
                value={percentE2}
                defaultValue={state?.contract_data.percent_e2}
                onChange={(e) => {
                  setPercentE2(e.target.value);
                }}
              />
              <ShowError id={"percent_e2"} />
            </Grid>
            <Grid item xs={1}>
              <MDInput
                //variant="standard"
                type="number"
                label="P.E.U."
                sx={{ m: 1, width: "100%" }}
                value={peu}
                defaultValue={state?.contract_data.peu}
                onChange={(e) => {
                  setPeu(e.target.value);
                  //setFamilyUPC(e.target.value);
                }}
                onBlur={(e) => {
                  console.log("PEU value:", e.target.value);
                  let value = parseFloat(e.target.value);
                  setPeu(value.toFixed(2));
                  if (value !== null) {
                    let result = ((ceu - value) / ceu) * 100;
                    console.log("Value:", ceu - value);
                    console.log("Percent E2", result);
                    setPercentE2(result.toFixed(2));
                  }
                }}
              />
              <ShowError id={"peu"} />
            </Grid>
          </Grid>
          {state ? (
            <Grid style={{ marginRight: "3%" }}>
              {!productEditMode ? (
                <MDButton
                  variant="gradient"
                  color="success"
                  style={{ marginBottom: "5%" }}
                  disabled={remainder != 0}
                  onClick={() => {
                    findProductFamily({
                      contract_id: state?.contract_data.contract_id,
                      vendor_id: selectedSupplier.id,
                      product_id: selectedUpc ? selectedUpc.product_id : selectedFamily.product_id,
                      upc: selectedUpc ? selectedUpc.id : upc,
                      description: description,
                      cost: cost,
                      order_id: order,
                      order: order2,
                      c: c,
                      g: g,
                      man: man,
                      envio: envio,
                      inter: inter,
                      trans: trans,
                      tar: tar,
                      pub_ap: pubAp,
                      vag: vag,
                      add: add,
                      imp: imp,
                      pub_fa: pubFa,
                      foto_fa: fotoFa,
                      notas_aclara: notasAclara,
                      offers: contractOffers,
                      offer_id: selectedOffer.id,
                      show: show,
                      extra: extra ? 1 : 0,
                      approved: approved ? 1 : 0,
                      ce: ce,
                      method_r: methodR,
                      percent_r: percentR,
                      cn_r: cn,
                      pgr: pgr,
                      pr: pr,
                      method_e: methodE,
                      percent_e: percentE,
                      cn_e: cn2,
                      pge: pge,
                      pe: pe,
                      ceu: ceu,
                      method_r2: methodRU,
                      percent_r2: percentR2,
                      cn_r2: cnu,
                      pgr2: pgru,
                      pru: pru,
                      cn_e2: cnu2,
                      pge2: pge2,
                      method_e2: methodEU,
                      percent_e2: percentE2,
                      peu: peu,
                    });
                    // addContractProducts({
                    //   contract_id: state?.contract_data.contract_id,
                    //   vendor_id: selectedSupplier.id,
                    //   product_id: selectedUpc ? selectedUpc.product_id : selectedFamily.product_id,
                    //   upc: selectedUpc ? selectedUpc.id : upc,
                    //   description: description,
                    //   cost: cost,
                    //   order_id: order,
                    //   order: order2,
                    //   c: c,
                    //   g: g,
                    //   man: man,
                    //   envio: envio,
                    //   inter: inter,
                    //   trans: trans,
                    //   tar: tar,
                    //   pub_ap: pubAp,
                    //   vag: vag,
                    //   add: add,
                    //   imp: imp,
                    //   pub_fa: pubFa,
                    //   foto_fa: fotoFa,
                    //   notas_aclara: notasAclara,
                    //   offers: contractOffers,
                    //   offer_id: selectedOffer.id,
                    //   show: show,
                    //   extra: extra ? 1 : 0,
                    //   approved: approved ? 1 : 0,
                    //   ce: ce,
                    //   method_r: methodR,
                    //   percent_r: percentR,
                    //   cn_r: cn,
                    //   pgr: pgr,
                    //   pr: pr,
                    //   method_e: methodE,
                    //   percent_e: percentE,
                    //   cn_e: cn2,
                    //   pge: pge,
                    //   pe: pe,
                    //   ceu: ceu,
                    //   method_r2: methodRU,
                    //   percent_r2: percentR2,
                    //   cn_r2: cnu,
                    //   pgr2: pgru,
                    //   pru: pru,
                    //   cn_e2: cnu2,
                    //   pge2: pge2,
                    //   method_e2: methodEU,
                    //   percent_e2: percentE2,
                    //   peu: peu,
                    // });
                  }}
                >
                  {language == 1 ? "Guardar Producto" : "Save Product"}
                </MDButton>
              ) : (
                <Grid style={{ marginRight: "3%" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{ marginBottom: "5%" }}
                    disabled={remainder != 0}
                    onClick={() => {
                      editContractProducts({
                        contract_id: state?.contract_data.contract_id,
                        vendor_id: selectedSupplier.id,
                        product_id: selectedUpc
                          ? selectedUpc.product_id
                          : selectedFamily.product_id,
                        upc: selectedUpc ? selectedUpc.id : upc,
                        description: description,
                        cost: cost,
                        order_id: order,
                        order: order2,
                        c: c,
                        g: g,
                        man: man,
                        envio: envio,
                        inter: inter,
                        trans: trans,
                        tar: tar,
                        pub_ap: pubAp,
                        vag: vag,
                        add: add,
                        imp: imp,
                        pub_fa: pubFa,
                        foto_fa: fotoFa,
                        notas_aclara: notasAclara,
                        offers: contractOffers,
                        offer_id: selectedOffer.id,
                        show: show,
                        extra: extra ? 1 : 0,
                        approved: approved ? 1 : 0,
                        ce: ce,
                        method_r: methodR,
                        percent_r: percentR,
                        cn_r: cn,
                        pgr: pgr,
                        pr: pr,
                        method_e: methodE,
                        percent_e: percentE,
                        cn_e: cn2,
                        pge: pge,
                        pe: pe,
                        ceu: ceu,
                        method_r2: methodRU,
                        percent_r2: percentR2,
                        cn_r2: cnu,
                        pgr2: pgru,
                        pru: pru,
                        cn_e2: cnu2,
                        pge2: pge2,
                        method_e2: methodEU,
                        percent_e2: percentE2,
                        peu: peu,
                      });
                    }}
                  >
                    {language == 1 ? "Editar Producto" : "Edit Product"}
                  </MDButton>
                </Grid>
              )}
            </Grid>
          ) : null}
          <Card>
            <DataTable2
              table={productData}
              index={index}
              setIndex={setIndex}
              pageEntries={10}
              entriesPerPage={true}
              canSearch
            />
          </Card>
          {/* <ShowError id={"products"} /> */}
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "3%",
            }}
          >
            {/* <Grid style={{ marginRight: "3%" }}>
              <MDButton
                variant="gradient"
                color="primary"
                style={{ marginBottom: "5%" }}
                onClick={() => setOpenReportMenu(!openReportMenu)}
              >
                {language == 1 ? "Imprimir" : "Print"}
              </MDButton>
            </Grid> */}
            <Grid style={{ marginRight: "3%" }}>
              <MDButton
                variant="gradient"
                color="light"
                style={{ marginBottom: "5%" }}
                disabled={remainder != 0}
                onClick={() => {
                  navigate("/contracts");
                }}
              >
                {language == 1 ? "Cancelar" : "Cancel"}
              </MDButton>
            </Grid>
            <Grid style={{ marginRight: "3%" }}>
              <MDButton
                variant="gradient"
                color="success"
                style={{ marginBottom: "5%" }}
                disabled={remainder != 0}
                onClick={() => {
                  navigate("/contractMaintenance");
                  if (!state) {
                    if (selectedUpc) {
                      findProductFamilyOnContractCreate({
                        contract_id: state?.contract_data.contract_id,
                        vendor_id: selectedSupplier.id,
                        product_id: selectedUpc
                          ? selectedUpc.product_id
                          : selectedFamily.product_id,
                        upc: selectedUpc ? selectedUpc.id : upc,
                        description: description,
                        cost: cost,
                        order_id: order,
                        order: order2,
                        c: c,
                        g: g,
                        man: man,
                        envio: envio,
                        inter: inter,
                        trans: trans,
                        tar: tar,
                        pub_ap: pubAp,
                        vag: vag,
                        add: add,
                        imp: imp,
                        pub_fa: pubFa,
                        foto_fa: fotoFa,
                        notas_aclara: notasAclara,
                        offers: contractOffers,
                        offer_id: selectedOffer.id,
                        show: show,
                        extra: extra ? 1 : 0,
                        approved: approved ? 1 : 0,
                        ce: ce,
                        method_r: methodR,
                        percent_r: percentR,
                        cn_r: cn,
                        pgr: pgr,
                        pr: pr,
                        method_e: methodE,
                        percent_e: percentE,
                        cn_e: cn2,
                        pge: pge,
                        pe: pe,
                        ceu: ceu,
                        method_r2: methodRU,
                        percent_r2: percentR2,
                        cn_r2: cnu,
                        pgr2: pgru,
                        pru: pru,
                        cn_e2: cnu2,
                        pge2: pge2,
                        method_e2: methodEU,
                        percent_e2: percentE2,
                        peu: peu,
                      });
                    } else {
                      console.log("Creating contract...");
                      createContract({
                        contract_date: date,
                        vendor_id: selectedSupplier.id,
                        product_id: selectedUpc ? selectedUpc.product_id : null,
                        family_id: selectedFamily ? selectedFamily.id : null,
                        order_id: order,
                        from_date: fromDate,
                        to_date: toDate,
                        eta: eta,
                        date_of: dateOf,
                        term: terms,
                        buyer: buyer,
                        contact: contact,
                        facturado: facturado == "Suplidor" ? selectedSupplier.name : company,
                        entregado: entregado == "Suplidor" ? selectedSupplier.name : company,
                        ordenado: ordenado == "Suplidor" ? selectedSupplier.name : company,
                        recogido: recogido == "Suplidor" ? selectedSupplier.name : company,
                        // upc: selectedUpc ? selectedUpc.id : upc,
                        // description: description,
                        // cost: cost,
                        // order: order2,
                        // c: c,
                        // g: g,
                        // man: man,
                        // envio: envio,
                        // inter: inter,
                        // trans: trans,
                        // tar: tar,
                        // pub_ap: pubAp,
                        // vag: vag,
                        // add: add,
                        // imp: imp,
                        // pub_fa: pubFa,
                        // foto_fa: fotoFa,
                        // notas_aclara: notasAclara,
                        // offers: contractOffers,
                        // offer_id: selectedOffer.id,
                        // show: show,
                        // extra: extra ? 1 : 0,
                        // approved: approved ? 1 : 0,
                        // ce: ce,
                        // method_r: methodR,
                        // percent_r: percentR,
                        // cn_r: cn,
                        // pgr: pgr,
                        // pr: pr,
                        // method_e: methodE,
                        // percent_e: percentE,
                        // cn_e: cn2,
                        // pge: pge,
                        // pe: pe,
                        // ceu: ceu,
                        // method_r2: methodRU,
                        // percent_r2: percentR2,
                        // cn_r2: cnu,
                        // pgr2: pgru,
                        // pru: pru,
                        // cn_e2: cnu2,
                        // pge2: pge2,
                        // method_e2: methodEU,
                        // percent_e2: percentE2,
                        // peu: peu,
                      });
                    }
                  } else {
                    editContract({
                      contract_id: state?.contract_data.contract_id,
                      contract_date: date,
                      vendor_id: selectedSupplier.id,
                      product_id: selectedUpc ? selectedUpc.product_id : selectedFamily.product_id,
                      family_id: selectedFamily ? selectedFamily.id : null,
                      order_id: order,
                      from_date: fromDate,
                      to_date: toDate,
                      eta: eta,
                      date_of: dateOf,
                      term: terms,
                      buyer: buyer,
                      contact: contact,
                      facturado: facturado == "Suplidor" ? selectedSupplier.name : company,
                      entregado: entregado == "Suplidor" ? selectedSupplier.name : company,
                      ordenado: ordenado == "Suplidor" ? selectedSupplier.name : company,
                      recogido: recogido == "Suplidor" ? selectedSupplier.name : company,
                      // upc: selectedUpc ? selectedUpc.id : upc,
                      // description: description,
                      // cost: cost,
                      // order: order2,
                      // c: c,
                      // g: g,
                      // man: man,
                      // envio: envio,
                      // inter: inter,
                      // trans: trans,
                      // tar: tar,
                      // pub_ap: pubAp,
                      // vag: vag,
                      // add: add,
                      // imp: imp,
                      // pub_fa: pubFa,
                      // foto_fa: fotoFa,
                      // notas_aclara: notasAclara,
                      // offers: contractOffers,
                      // offer_id: selectedOffer.id,
                      // show: show,
                      // extra: extra ? 1 : 0,
                      // approved: approved ? 1 : 0,
                      // ce: ce,
                      // method_r: methodR,
                      // percent_r: percentR,
                      // cn_r: cn,
                      // pgr: pgr,
                      // pr: pr,
                      // method_e: methodE,
                      // percent_e: percentE,
                      // cn_e: cn2,
                      // pge: pge,
                      // pe: pe,
                      // ceu: ceu,
                      // method_r2: methodRU,
                      // percent_r2: percentR2,
                      // cn_r2: cnu,
                      // pgr2: pgru,
                      // pru: pru,
                      // cn_e2: cnu2,
                      // pge2: pge2,
                      // method_e2: methodEU,
                      // percent_e2: percentE2,
                      // peu: peu,
                    });
                  }
                }}
              >
                {language == 1 ? "Guardar Contrato" : "Save Contract"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default CreateContract;
