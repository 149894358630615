/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DataTable2 from "app/components/shopperTable";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useNavigate, Navigate } from "react-router-dom";

import DataTable from "examples/Tables/DataTable";
import Tooltip from "@mui/material/Tooltip";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import AlertDialog from "app/components/confirmBox";
import MDSnackbar from "components/MDSnackbar";

import IP from "../IP";

const axios = require("axios");

function ViewPaymentsAdmin() {
  const [payments, setPayments] = useState({ columns: [], rows: [] });
  const [index, setIndex] = useState(0);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const navigate = useNavigate();

  async function GetPayments() {
    await axios
      .get(IP + "/payments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data.data);

        let columns = [
          {
            Header: language == 1 ? "Número de Referencia" : "Reference Number",
            accessor: "number",
          },
          { Header: language == 1 ? "Método de Pago" : "Payment Method", accessor: "method" },
          { Header: "Total", accessor: "total" },
          { Header: language == 1 ? "Fecha de Pago" : "Payment Date" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "25%" },
        ];
        let rows = response.data.data.map((item) => {
          //  console.log(item);
          return {
            number: item.transaction_number,
            method: item.payment_method,
            total: item.total,
            active: item.active,
            action: (
              <Grid container spacing={0.25}>
                <Grid item xs={12} lg={6}>
                  <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      iconOnly
                      onClick={() => {
                        localStorage.setItem("paymentId", item.id);
                        navigate("/adjust-payment");
                      }}
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                  </Tooltip>
                </Grid>
                <Grid item={12} lg={6}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      localStorage.setItem("paymentId", item.id);
                      navigate("/payment-details");
                    }}
                  >
                    {language == 1 ? "Detalles" : "Details"}
                  </MDButton>
                </Grid>
              </Grid>
            ),
          };
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setPayments(tableData);
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  useEffect(() => {
    GetPayments();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <DataTable2
          table={payments}
          index={index}
          setIndex={setIndex}
          pageEntries={10}
          entriesPerPage={true}
          canSearch
        />
      </Card>
    </DashboardLayout>
  );
}

export default ViewPaymentsAdmin;
