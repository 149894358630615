import { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { flushSync } from "react-dom";
import dayjs from "dayjs";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";
import { Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomDatePicker from "components/CustomDatePicker";
import VirtualizedAutocomplete from "app/components/VirtualizedAutocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import MDInput from "components/MDInput";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import DataTable2 from "app/components/shopperTable";

// API
import IP from "app/IP";
import axios from "axios";

const OfferModal = ({ open, onClose, language, editMode, vendors, offer_data, runFunction }) => {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
  const [errors, setErrors] = useState([]);
  const [openFamilyPopup, setOpenFamilyPopup] = useState(false);
  const [openProductList, setOpenProductList] = useState(false);
  const [show2, setShow2] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [familyID, setFamilyID] = useState();
  const [productList, setProductList] = useState([]);
  const [upcList, setUpcList] = useState([]);
  const [selectedUpc, setSelectedUpc] = useState("");
  const [selectedSupplierFilter, setSelectedSupplierFilter] = useState("");
  const [offerId, setOfferId] = useState();
  const [selectedOfferType, setSelectedOfferType] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [families, setFamilies] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState("");
  const [isFamily, setIsFamily] = useState(false);
  const [code, setCode] = useState("");
  const [upc, setUPC] = useState("");
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");
  const [offers, setOffers] = useState("");
  const [offerList, setOfferList] = useState([]);
  const [netWorth, setNetWorth] = useState("");
  const [by, setBy] = useState({ id: 1, label: "Regular" });
  const [cg, setCg] = useState();
  const [c, setC] = useState(0);
  const [g, setG] = useState(0);
  const [percent, setPercent] = useState("");
  const [offer, setOffer] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  //const [editMode, setEditMode] = useState(false);
  const [familyName, setFamilyName] = useState("");
  const [familyCode, setFamilyCode] = useState("");
  const [familyUPC, setFamilyUPC] = useState("");
  const [familyDescription, setFamilyDescription] = useState("");
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [selectedRelatedProduct, setSelectedRelatedProduct] = useState("");
  const isAllSelectedProducts =
    relatedProductList.length > 0 && setSelectedRelatedProduct.length === relatedProductList.length;
  const [index, setIndex] = useState(0);
  const [productData, setProductData] = useState({ columns: [], rows: [] });
  const [relatedProductData, setRelatedProductData] = useState({ columns: [], rows: [] });

  const handleFamilyPopupClose = () => {
    setOpenFamilyPopup(false);
    setErrors([]);
  };

  const handleProductListPopupClose = () => {
    setOpenProductList(!openProductList);
  };

  const handleClickOpen = () => {
    setOpenDialog(true); //opens dialog model
    //setEditMode(mode); //sets whether the dialog will be in create or edit mode.
  };

  const handleClose = () => {
    onClose();
    //setOpenDialog(false); //close dialog box
    setProductList([]);
    setErrors([]);
    setSelectedOfferType("");
    setSelectedProduct("");
    setSelectedFamily("");
    setSelectedSupplier("");
    setCode("");
    setUPC("");
    setDescription("");
    setCost("");
    setOffers("");
    setNetWorth("");
    setBy("");
    setC("");
    setG("");
    setPercent("");
    setOffer();
    setFromDate();
    setToDate();
    setIsFamily(false);
  };

  //Clear basic fields
  const clearFields = () => {
    setCode("");
    setUPC("");
    setCost("");
    setOffers("");
    setNetWorth("");
    setC("");
    setG("");
    setPercent("");
    setOffer("");
  };

  //Check if product has a family
  async function findProductFamily(product) {
    console.log("FindProductFamily ID?", product);
    await axios
      .post(
        IP + "/product_families/findProductFamily",
        { product_id: product },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response Find Product Family:", response);
        //console.log("Contract Products Ids Array Hook:", contractProductIds);
        if (response.data.length > 0) {
          setFamilyID(response.data[0].family_id);
          //setContractProductData(product_data);
          setOpenFamilyPopup(!openFamilyPopup);
        } else {
          //addContractProducts(product_data);
          createOffer({
            vendor_id: selectedSupplier.id,
            product_id: selectedProduct ? selectedProduct.id : selectedFamily.product_id,
            family_id: selectedFamily ? selectedFamily.id : null,
            offer_type_id: selectedOfferType.id,
            code: code,
            upc: upc,
            description: description,
            cost: cost,
            offers: offers,
            net_worth: netWorth,
            by_id: 0,
            c: c,
            g: g,
            percent: percent,
            offer: offer,
            from_date: fromDate,
            to_date: toDate,
          });
        }
      })
      .catch((error) => {
        console.log("Error Find Product Family:", error);
      });
  }

  async function storeUniqueProductFamilyOffers(offer) {
    await axios
      .post(IP + "/offers/storeUniqueProductFamilyOffers", offer, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("storeUniqueProductFamilyOffers Response:", response);
        //setOpenFamilyPopup(!openFamilyPopup);
        handleClose();
        runFunction();
        //getOffers();
      })
      .catch((error) => {
        console.log("storeUniqueProductFamilyOffers ERROR:", error);
      });
  }

  //Get list of families
  async function getProductOfFamily(product_id, offer_sum) {
    console.log("product id for get product of family:", product_id);
    await axios
      .post(IP + "/offers/getProductOfFamily", product_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("get Product of family:", response);
        let family_product_list = response.data.map((prod) => {
          return {
            id: prod.product_id,
            label: prod.product_id + " " + prod.name,
            name: prod.name,
            upc: prod.upc,
            code: prod.code,
            cost: prod.cost,
          };
        });
        console.log("Mapped product list from family:", family_product_list);
        setCost(family_product_list[0].cost);
        console.log("Offer Sum from Family:", offer_sum);
        console.log("Result of sum:", family_product_list[0].cost - offer_sum);
        //if (family_product_list[0].cost > 0 && offers > 0) {
        let result = family_product_list[0].cost - offer_sum;
        setNetWorth(result.toFixed(2));
        // } else {
        //   setNetWorth("");
        // }
        //setProducts(family_product_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getProductListFromOffer(offer_id) {
    await axios
      .post(IP + "/offers/getProductListFromOffer", offer_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Response getProductListFromOffer", response.data);

        let productListMap = response.data.map((prod) => {
          return {
            id: prod.product_id,
            upc: prod.product.upc,
            name: prod.product.name,
          };
        });

        setProductList(productListMap);
      })
      .catch((error) => {
        console.log("Error getProductListFromOffer", error);
      });
  }

  //Create an offer
  async function createOffer(offer_data) {
    await axios
      .post(IP + "/offers/createOffer", offer_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("createOffer Response:", response);
        setShow2(!show2);
        handleClose();
        runFunction();
        //getOffers();
        //navigate("/families");
      })
      .catch((error) => {
        console.log("createOffer Error:", error);
        setErrors(error.response.data.data);
        //setErrors(error);
      });
  }

  async function editOffer(offer_data) {
    //console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/offers/editOffer", offer_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("editOffer Response:", response);
        setShow2(!show2);
        handleClose();
        runFunction();
        //getOffers();
        //navigate("/families");
      })
      .catch((error) => {
        console.log("editOffer Error:", error);
        setErrors(error.response.data.data);
        //setErrors(error);
      });
  }

  //   async function GetVendors() {
  //     await axios
  //       .get(IP + "/vendors", {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("token"),
  //           Accept: "application/json",
  //         },
  //       })
  //       .then(function (response) {
  //         console.log("Get Vendors Response:", response.data.data);

  //         let vendor = response.data.data.map((item) => {
  //           return {
  //             id: item.id,
  //             label: item.id + " " + item.name,
  //           };
  //         });

  //         setSuppliers(vendor);
  //       })
  //       .catch(function (error) {
  //         if (!error.response.data.authenticated) {
  //           navigate("/signIn");
  //         }
  //         console.log("Get Vendors ERROR:", error);
  //       });
  //   }

  //Get products by vendor id
  async function getProducts(vendor) {
    await axios
      .post(IP + "/offers/getProductsByVendor", vendor, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Products Response:", response);
        let product_list = response.data.map((prod) => {
          return {
            id: prod.product_id,
            label: prod.product_id + " " + prod.name,
            name: prod.name,
            upc: prod.upc,
            code: prod.code,
            cost: prod.cost,
          };
        });
        console.log("Mapped product list:", product_list);
        setProducts(product_list);
        let upc_list = response.data.map((prod) => {
          return {
            id: prod.upc,
            label: prod.upc,
          };
        });
        setUpcList(upc_list);
      })
      .catch((error) => {
        console.log("products error:", error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            {/* <label>{item}</label> */}
            <label>{language == 1 ? "El campo es requerido." : "The field is required."}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  //Inserts the checkbox values into the array of jsons
  const handleAllCheck = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values:", selectedRequirements);

    if (value === "all") {
      setSelectedRequirements(
        selectedRequirements.length === requirementsList.length
          ? []
          : requirementsList.map((val) => (!val.disabled ? val.value : null)) //This will not check any disabled checkboxes or checkboxes with disabled field.
        //: requirementsList.map((val) => val.value)
      );
      return;
    }

    const list = [...selectedRequirements];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List:", list);
    setSelectedRequirements(list);
  };

  //Inserts the checkbox products values into the array of jsons
  const handleAllCheckProducts = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked 2:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values 2:", setSelectedRelatedProduct);

    if (value === "all") {
      setSelectedRelatedProduct(
        setSelectedRelatedProduct.length === relatedProductList.length
          ? []
          : relatedProductList.map((val) => val.value) //do NOT add ".value" to vendorList else you'll get undefined error
      );
      return;
    }

    const list = [...setSelectedRelatedProduct];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List 2:", list);
    setSelectedRelatedProduct(list);
  };

  useEffect(() => {
    if (open) {
      if (editMode) {
        setSelectedSupplier(offer_data.selectedSupplier),
          setSelectedProduct(offer_data.selectedProduct),
          setSelectedFamily(offer_data.selectedFamily),
          setSelectedOfferType(offer_data.selectedOfferType),
          setCode(offer_data.code),
          setUPC(offer_data.upc),
          setDescription(offer_data.description),
          setCost(offer_data.cost),
          setOffers(offer_data.offers),
          setNetWorth(offer_data.netWorth),
          setBy(0),
          setC(offer_data.c),
          setG(offer_data.g),
          setPercent(offer_data.percent),
          setOffer(offer_data.offer),
          setFromDate(offer_data.fromDate),
          setToDate(offer_data.toDate);
      } else {
        setSelectedSupplier(""),
          setSelectedProduct(""),
          setSelectedFamily(""),
          setSelectedOfferType(""),
          setCode(""),
          setUPC(""),
          setDescription(""),
          setCost(""),
          setOffers(""),
          setNetWorth(""),
          setBy(""),
          setC(""),
          setG(""),
          setPercent(""),
          setOffer(""),
          setFromDate(null),
          setToDate(null);
      }
    }
  }, [open, editMode, offer_data]);

  return (
    <>
      <Dialog
        fullWidth="md"
        maxWidth="md"
        open={openProductList}
        onClose={handleProductListPopupClose}
      >
        {" "}
        <DialogTitle>{language == 1 ? "Productos" : "Products"}</DialogTitle>
        <DialogContent dividers>
          <Grid lg={"auto"}>
            <List
              sx={{
                width: "100%",
                //maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 300,
                "& ul": { padding: 0 },
              }}
            >
              <li>
                {productList.map((item) => (
                  <ListItem key={item}>
                    <ListItemText primary={`${item.upc} ${item.name}`} />
                  </ListItem>
                ))}
              </li>
            </List>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog fullWidth="md" maxWidth="md" open={openFamilyPopup} onClose={handleFamilyPopupClose}>
        {" "}
        <DialogTitle>{language == 1 ? "Familia found" : "Family found"}</DialogTitle>
        <DialogContent dividers>
          <Grid lg={"auto"}>
            <MDTypography>
              This product has a family, do you wish to apply it to the family?
            </MDTypography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenFamilyPopup(!openFamilyPopup);
              createOffer({
                vendor_id: selectedSupplier.id,
                product_id: selectedProduct ? selectedProduct.id : selectedFamily.product_id,
                family_id: selectedFamily ? selectedFamily.id : null,
                offer_type_id: selectedOfferType.id,
                code: code,
                upc: upc,
                description: description,
                cost: cost,
                offers: offers,
                net_worth: netWorth,
                by_id: 0,
                c: c,
                g: g,
                percent: percent,
                offer: offer,
                from_date: fromDate,
                to_date: toDate,
              });
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              setOpenFamilyPopup(!openFamilyPopup);
              storeUniqueProductFamilyOffers({
                family_id: familyID,
                vendor_id: selectedSupplier.id,
                product_id: selectedProduct ? selectedProduct.id : selectedFamily.product_id,
                //family_id: selectedFamily ? selectedFamily.id : null,
                offer_type_id: selectedOfferType.id,
                code: code,
                upc: upc,
                description: description,
                cost: cost,
                offers: offers,
                net_worth: netWorth,
                by_id: 0,
                c: c,
                g: g,
                percent: percent,
                offer: offer,
                from_date: fromDate,
                to_date: toDate,
                //product_id: contractProductIds,
                //product_data: product_data,
              });
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth="lg" maxWidth="lg" open={open} onClose={onClose}>
        {" "}
        <DialogTitle>
          {language == 1
            ? editMode
              ? "Editar Oferta"
              : "Crear Oferta"
            : editMode
            ? "Edit Offer"
            : "Create Offer"}
        </DialogTitle>
        <DialogContent dividers spacing={2} sx={{ paddingRight: "30px" }}>
          <Grid item lg={8}>
            <VirtualizedAutocomplete
              sx={{ m: 1, width: "100%" }}
              options={[
                { id: 1, label: "Offer Invoice" },
                { id: 2, label: "Billback" },
                { id: 3, label: "C/G" },
                { id: 4, label: "Off/Temp" },
              ]}
              getOptionsLabel={(options) => options.label || ""}
              value={selectedOfferType}
              onChange={(event, newValue) => {
                console.log("selected offer type:", newValue);
                if (newValue == null) {
                  setSelectedOfferType("");
                } else {
                  setSelectedOfferType(newValue);
                  setC("");
                  setG("");
                  setOffer("");
                  setPercent("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={language == 1 ? "Tipo de Oferta" : "Offer type"}
                  variant="outlined"
                />
              )}
            />
            <ShowError id={"offer_type_id"} />
          </Grid>
          <Grid item lg={8}>
            <VirtualizedAutocomplete
              sx={{ m: 1, width: "100%" }}
              options={vendors}
              getOptionsLabel={(options) => options.label || ""}
              value={selectedSupplier}
              onChange={(event, newValue) => {
                console.log("selected supplier:", newValue);
                if (newValue == null) {
                  setSelectedSupplier("");
                  setSelectedProduct("");
                  setSelectedFamily("");
                  clearFields();
                } else {
                  setSelectedSupplier(newValue);
                  setSelectedProduct("");
                  setSelectedFamily("");
                  clearFields();
                  getProducts({ vendor_id: newValue.id });
                  //getFamilies({ vendor_id: newValue.id });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={language == 1 ? "Suplidor" : "Supplier"}
                  variant="outlined"
                />
              )}
            />
            <ShowError id={"vendor_id"} />
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={8}>
              {isFamily ? (
                <VirtualizedAutocomplete
                  sx={{ m: 1, width: "100%" }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: 300,
                      },
                    },
                  }}
                  options={families}
                  getOptionsLabel={(options) => options.label || ""}
                  value={selectedFamily}
                  onChange={(event, newValue) => {
                    console.log("selected product:", newValue);
                    if (newValue == null) {
                      setSelectedFamily("");
                      setCode("");
                      setUPC("");
                      setCost("");
                      clearFields();
                    } else {
                      clearFields();
                      let offerSum = offerList
                        .filter((item) => item.product_id == newValue.product_id)
                        .reduce(
                          (total, currentValue) => (total = total + parseFloat(currentValue.offer)),
                          0
                        );
                      console.log("OfferSum:", offerSum);
                      setOffers(offerSum.toFixed(2));
                      getProductOfFamily({ product_id: newValue.product_id }, offerSum);
                      setSelectedFamily(newValue);
                      setCode(newValue.code);
                      setUPC(newValue.upc);
                      //setCost(newValue.cost);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Familia" : "Family"}
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                <VirtualizedAutocomplete
                  sx={{ m: 1, width: "100%" }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: 300,
                      },
                    },
                  }}
                  options={products}
                  getOptionsLabel={(options) => options.label || ""}
                  value={selectedProduct}
                  onChange={(event, newValue) => {
                    console.log("selected product:", newValue);
                    if (newValue == null) {
                      setSelectedProduct("");
                      setCode("");
                      setUPC("");
                      setCost("");
                      clearFields();
                    } else {
                      setSelectedProduct(newValue);
                      clearFields();
                      let offerSum = offerList
                        .filter((item) => item.product_id == newValue.id)
                        .reduce(
                          (total, currentValue) => (total = total + parseFloat(currentValue.offer)),
                          0
                        );
                      console.log("Offer List:", offerList);
                      setOffers(offerSum.toFixed(2));
                      setNetWorth((newValue.cost - offerSum).toFixed(2));
                      setCode(newValue.code);
                      setUPC(newValue.upc);
                      setCost(newValue.cost);
                      // if (newValue.cost > 0 && offers > 0) {
                      //   let result = newValue.cost - offerSum;
                      //   setNetWorth(result);
                      // } else {
                      //   setNetWorth("");
                      // }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Producto" : "Product"}
                      variant="outlined"
                    />
                  )}
                />
              )}
              <ShowError id={"product_id"} />
            </Grid>
            {/* <Grid item lg={"auto"}>
              <FormGroup sx={{ mt: 2, ml: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFamily}
                      onChange={() => {
                        setIsFamily(!isFamily);
                      }}
                    />
                  }
                  label="Is Family"
                />
              </FormGroup>
            </Grid> */}
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4}>
              <MDInput
                variant="outlined"
                type="text"
                //disabled
                label={language == 1 ? "Codigo" : "Code"}
                sx={{ m: 1, width: "100%" }}
                value={code}
                // onChange={(e) => {
                //   setCode(e.target.value);
                // }}
              />
              <ShowError id={"code"} />
            </Grid>
            <Grid item lg={4}>
              <MDInput
                variant="outlined"
                type="text"
                label="UPC"
                //disabled
                sx={{ m: 1, width: "100%" }}
                value={upc}
                // onChange={(e) => {
                //   setUPC(e.target.value);
                // }}
              />
              <ShowError id={"upc"} />
            </Grid>
          </Grid>
          <Grid item lg={8}>
            <MDInput
              variant="outlined"
              type="text"
              label={language == 1 ? "Descripcion" : "Description"}
              sx={{ m: 1, width: "100%" }}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <ShowError id={"description"} />
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <MDInput
                variant="outlined"
                type="text"
                label={language == 1 ? "Costo" : "Cost"}
                sx={{ m: 1, width: "100%" }}
                value={cost}
              />
              <ShowError id={"cost"} />
            </Grid>
            <Grid item lg={3}>
              <MDInput
                variant="outlined"
                type="text"
                label={language == 1 ? "Ofertas" : "Offers"}
                sx={{ m: 1, width: "100%" }}
                value={offers}
              />
              <ShowError id={"offer"} />
            </Grid>
            <Grid item lg={3}>
              <MDInput
                variant="outlined"
                type="text"
                label={language == 1 ? "Costo Neto" : "Net worth"}
                sx={{ m: 1, width: "100%" }}
                value={netWorth}
                // onChange={(e) => {
                //   setNetWorth(e.target.value);
                // }}
              />
              <ShowError id={"net_worth"} />
            </Grid>
            {/* <Grid item lg={3}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={[{ id: 1, label: "Regular" }]}
                getOptionsLabel={(options) => options.label || ""}
                value={by}
                onChange={(event, newValue) => {
                  console.log("selected by:", newValue);
                  setBy(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={language == 1 ? "Por" : "By"} variant="outlined" />
                )}
              />
              <ShowError id={"by_id"} />
            </Grid> */}
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "30px" }}>
            {selectedOfferType.id == 3 ? (
              <>
                <Grid item lg={1}>
                  <MDInput
                    variant="outlined"
                    type="text"
                    label="C"
                    sx={{ m: 1, width: "100%" }}
                    value={c}
                    onChange={(e) => {
                      let value = e.target.value.replace(/[^0-9]*/g, "");
                      if (selectedOfferType.label == "C/G") {
                        setC(value);
                      }
                    }}
                  />
                  <ShowError id={"c"} />
                </Grid>
                <Grid item lg={1}>
                  <MDInput
                    variant="outlined"
                    type="text"
                    label="G"
                    sx={{ m: 1, width: "100%" }}
                    value={g}
                    onChange={(e) => {
                      let value = e.target.value.replace(/[^0-9]*/g, "");
                      if (selectedOfferType.label == "C/G") {
                        setG(value);
                      }
                    }}
                    onBlur={(e) => {
                      if (selectedOfferType.label == "C/G") {
                        if (netWorth > 0 && c > 0 && g > 0) {
                          //let c_g = parseInt(e.target.value) ;
                          //console.log("c/G", c_g);
                          let c_num = Number(c);
                          let g_num = Number(g);
                          console.log("net", netWorth);
                          console.log("c", c_num);
                          console.log("g", g_num);

                          let net = netWorth * g_num;
                          let totalbox = c_num + g_num;
                          let result = net / totalbox;

                          console.log("net res:", net);
                          console.log("totalbox", totalbox);
                          //let offer_result = Math.round(result * 1000) / 1000;
                          console.log("result", result);
                          setOffer(result);
                          let percent_result = (result / cost) * 100;
                          let round_result = Math.round(percent_result * 1000) / 1000;
                          console.log("Percent result", round_result);
                          setPercent(round_result);
                        } else {
                          setOffer("");
                          setPercent("");
                        }
                      }
                      if (selectedOfferType.label == "Off/Temp") {
                        if (netWorth > 0 && c > 0 && g > 0) {
                          let c_g = parseInt(e.target.value) + 1;
                          console.log("c/G", c_g);
                          let result = netWorth / c_g;
                          let offer_result = Math.round(result * 1000) / 1000;
                          console.log("result", offer_result);
                          setOffer(offer_result);
                        } else {
                          setOffer("");
                          setPercent("");
                        }
                      }
                    }}
                  />
                  <ShowError id={"g"} />
                </Grid>
              </>
            ) : null}
            <Grid item lg={2}>
              <MDInput
                variant="outlined"
                type="text"
                label="%"
                sx={{ m: 1, width: "100%" }}
                value={percent}
                onChange={(e) => {
                  setPercent(e.target.value);
                }}
              />
              <ShowError id={"percent"} />
            </Grid>
            <Grid item lg={2}>
              <MDInput
                variant="outlined"
                type="text"
                label={language == 1 ? "Oferta" : "Offer"}
                sx={{ m: 1, width: "100%" }}
                value={offer}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Prevent value from exceeding `cost`
                  if (inputValue <= cost || inputValue === "") {
                    setOffer(inputValue); // Only set the value if it's valid
                  } else {
                    // Optionally show an error message or reset the value
                    alert(`The offer value cannot be greater than the cost (${cost}).`);
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value > 0 && cost > 0) {
                    let result = (e.target.value / cost) * 100;
                    let round_result = Math.round(result * 1000) / 1000;
                    setPercent(round_result);
                  } else {
                    setPercent("");
                  }
                }}
              />
              <ShowError id={"offers"} />
            </Grid>
            <Grid item lg={3}>
              <CustomDatePicker
                label={language == 1 ? "Desde" : "From"}
                sx={{ m: 1, width: "100%" }}
                value={fromDate}
                maxDate={toDate}
                onChange={(newValue) => {
                  setFromDate(newValue);
                }}
              />
              <ShowError id={"from_date"} />
            </Grid>
            <Grid item lg={3}>
              <CustomDatePicker
                label={language == 1 ? "Hasta" : "To"}
                sx={{ m: 1, width: "100%" }}
                value={toDate}
                minDate={fromDate}
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
              />
              <ShowError id={"to_date"} />
            </Grid>
            {productList.length > 0 && (
              <Grid item lg={3}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    //handleClickOpen(false);
                    setOpenProductList(true);
                  }}
                >
                  {language == 1 ? "View products" : "Ver productos"}
                </MDButton>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
          <Button
            type="submit"
            onClick={() => {
              //If the dialog popup is open from the edit button it will run the following edit function otherwise it'll run the create one.
              if (editMode) {
                editOffer({
                  offer_id: offer_data.offer_id,
                  vendor_id: selectedSupplier.id,
                  product_id: selectedProduct ? selectedProduct.id : selectedFamily.product_id,
                  family_id: selectedFamily ? selectedFamily.id : null,
                  offer_type_id: selectedOfferType.id,
                  code: code,
                  upc: upc,
                  description: description,
                  cost: cost,
                  offers: offers,
                  net_worth: netWorth,
                  by_id: 0,
                  c: c,
                  g: g,
                  percent: percent,
                  offer: offer,
                  from_date: fromDate,
                  to_date: toDate,
                });
              } else {
                findProductFamily(selectedProduct.id);
                // createOffer({
                //   vendor_id: selectedSupplier.id,
                //   product_id: selectedProduct ? selectedProduct.id : selectedFamily.product_id,
                //   family_id: selectedFamily ? selectedFamily.id : null,
                //   offer_type_id: selectedOfferType.id,
                //   code: code,
                //   upc: upc,
                //   description: description,
                //   cost: cost,
                //   offers: offers,
                //   net_worth: netWorth,
                //   by_id: by.id,
                //   c: c,
                //   g: g,
                //   percent: percent,
                //   offer: offer,
                //   from_date: fromDate,
                //   to_date: toDate,
                // });
              }
            }}
          >
            {language == 1 ? (editMode ? "Guardar" : "Crear") : editMode ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OfferModal;
