import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";
import PurchaseOrderModal from "app/components/purchaseOrderModal";

import IP from "app/IP";

const axios = require("axios");

function CreateReceiving({ setChangeTable, RefreshTable }) {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [date, setDate] = useState(currentDate);
  const [vCode, setVCode] = useState("");
  const [tableData, setTableData] = useState({ rows: [], columns: [] });
  const [vendors, setVendors] = useState([{}]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendorProducts, setVendorProducts] = useState({ columns: [], rows: [] });
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [prod, setProd] = useState("");
  const [dummy, setDummy] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();
  const inputRef = useRef();

  async function GetVendors() {
    let venid = JSON.parse(localStorage.getItem("RecInfo"));
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors/" + venid.venid, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);

        setSelectedVendor(response.data.data.name);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }
  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  async function sendReceiving() {
    setDisableButton(true);
    let venids = JSON.parse(localStorage.getItem("RecInfo"));
    console.log(venids.venid + " id");
    await axios
      .post(
        IP + "/receivings",
        {
          date: date,
          vendor_order_number: vCode,
          purchase_order_id: localStorage.getItem("purchaseOrderId"),
          status_id: 1,
          vendor_id: venids.venid,
          active: 1,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        // localStorage.setItem("purchaseOrderId", response.data.data.id);
        // toggleSnackbar();
        setChangeTable(false);
        RefreshTable();
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  const timer = () =>
    setTimeout(() => {
      setInputValue("");
    }, 200);

  useEffect(() => {
    inputRef.current?.focus();
  });

  useEffect(() => {
    GetVendors();
  }, []);

  return (
    <Card>
      <MDBox p={3}>
        <MDBox>
          <MDBox>
            <MDBox lineHeight={0}>
              <MDTypography variant="h5">
                {language == 1 ? "Crear Recibido" : "Create Receiving"}
              </MDTypography>
              <MDTypography variant="button" color="text">
                {language == 1 ? "Favor de llenar los campos." : "Please enter information."}
              </MDTypography>
            </MDBox>
            <MDBox id="box1" mt={1.625}>
              <Grid id="grid1" container spacing={3}>
                <Grid id="grid1-1" item xs={12} sm={3}>
                  <MDInput
                    type="date"
                    variant="standard"
                    label={language==1 ? "Fecha" : "Date"}
                    fullWidth
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <ShowError id={"date"} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDInput
                    type="text"
                    variant="standard"
                    label={language == 1 ? "Número de Orden de Vendedor" : "Vendor Order Number"}
                    fullWidth
                    value={vCode}
                    onChange={(e) => {
                      setVCode(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <MDInput
                    style={{ width: "100%" }}
                    value={selectedVendor}
                    label={language == 1 ? "Vendedor" : "Vendor"}
                    variant="standard"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={5}>
                  <Autocomplete
                    disablePortal
                    value={selectedVendor}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setSelectedVendor(newValue);
                    }}
                    options={vendors}
                    renderInput={(params) => (
                      <MDInput {...params} label="Vendor" variant="standard" />
                    )}
                  />
                  <ShowError id={"vendor_id"} />
                </Grid> */}
              </Grid>
            </MDBox>
          </MDBox>
          <MDBox mt={1.625} display="flex" justifyContent="right">
            <MDButton
              variant="gradient"
              color="light"
              onClick={() => {
                setChangeTable(false);
              }}
            >
              {language == 1 ? "Cancelar" : "Cancel"}
            </MDButton>
            <MDButton
              type="button"
              variant="gradient"
              className="float-sm-right"
              color="info"
              disabled={disableButton}
              onClick={sendReceiving}
            >
              {language == 1 ? "Guardar" : "Save"}
            </MDButton>
            {/* <MDButton variant="gradient" color='light' onClick={()=> console.log(selectedVendor.id)}>Check</MDButton> */}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CreateReceiving;
