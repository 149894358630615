/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";
import "bootstrap/dist/css/bootstrap.min.css";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { item } from "examples/Sidenav/styles/sidenavItem";

const axios = require("axios");

function BestSellersOrders() {
  const [menu, setMenu] = useState(null);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [download, setDownload] = useState(true)
  const [vendors, setVendors] = useState([])
  const [selectedVendor, setSelectedVendor] = useState()
  const [sortSelect, setSortSelect] = useState("");
  const [data, setData] = useState();
  const [allData, setAllData] = useState([])
  const [responseData, setResponseData] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const navigate = useNavigate();

  const createPDF = () => {
    setDownload(false)
    const pdf = new jsPDF("landscape", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("low-stock.pdf");
      setDownload(true)
    });
  };

  async function getLowStockData() {
    await axios
      .get(IP + "/reports/low_stock", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        response.data.data.map(item=>{
          responseData.push(item)
        })
        setAllData(response.data.data)
        let columns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "10%" },
          { Header: "UPC", accessor: 'upc' },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Cantidad Minima" : "Minimum Quantity", accessor: "min" },
          { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "quantity" },
        ];

        let rows = response.data.data.map((item) => {
          return {
            image: <ProductCell name="" image={item.image} />,
            upc: item.upc,
            name: item.name,
            quantity: item.on_stock,
            min: item.minimum,
          };
        });

        setData(excelRows);

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    // getLowStockData();
    // GetVendors()
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={3}>
          {/* <MDBox display="flex">
            <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
              {language == 1 ? "Organizar" : "Sort"}&nbsp;
              <Icon>sort</Icon>
            </MDButton>
            <MDBox ml={1}>
              <ExportToExcel apiData={data} fileName="low-stock" />
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant='outlined' color='dark' onClick={createPDF}>{language==1 ? "Descargar" : "Download"} PDF</MDButton>
            </MDBox> 
          </MDBox> */}
        </MDBox>
          <Card>
            <DataTable table={report} entriesPerPage={true} canSearch />
          </Card>
          {/* <div hidden={download}>
        <MDBox mt={50}>
        <div id="pdf">
          <h3>{language==1 ? "Reporte de Productos con Poca Cantidad" : "Low Stock Products Report"}</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{language==1 ? "Nombre" : "Name"}</th>
                <th>{language==1 ? "Mínimo" : "Minimum"}</th>
                <th>{language==1 ? "Cantidad" : "Quantity"}</th>
              </tr>
            </thead>
            <tbody>
               {responseData.map(item=>{
                  return <tr>
                    <td>{item.name}</td>
                    <td>{item.minimum}</td>
                    <td>{item.on_stock}</td>
                  </tr>
               })} 
            </tbody>
          </table>
        </div>
        </MDBox>
        </div> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default BestSellersOrders;
