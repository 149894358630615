import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IP from "app/IP";
import axios from "axios";
//import { ReportServer } from "views/billback-pages/api/report-api";

const SyncMultiBusiness = () => {
  const navigate = useNavigate();

  async function getETL() {
    //console.log("PRODUCT_INFO:", );
    await axios
      .post(
        IP + "/mbs_etl",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("ETL RESPONSE:", response);
      })
      .catch((error) => {
        console.log("ETL ERROR:", error);
      });
  }

  useEffect(() => {
    getETL();
    //window.open(ReportServer + "ReportAging&rs:Command=Render");
    //navigate("/dashboards/analytics");
  }, []);
};

export default SyncMultiBusiness;
