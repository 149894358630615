/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const axios = require("axios");

function ReportPaymentsList() {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [download, setDownload] = useState(true);
  const [errors, setErrors] = useState([]);
  const [dateFrom, setDateFrom] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState();
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [open, setOpen] = useState(false);
  const [sortSelect, setSortSelect] = useState();
  const [data, setData] = useState([]);
  const [responseData, setResponseData] = useState([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  const createPDF = () => {
    setDownload(false);
    const pdf = new jsPDF("portrait", "px", "a0");
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("payments");
      setDownload(true);
    });
  };

  async function GetStores() {
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        let store = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            store.push({ label: item.name, id: item.id });
          }
        });
        store.unshift({ label: language == 1 ? "Todas" : "All", id: 0 });

        setStores(store);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getPaymentData() {
    await axios
      .get(
        IP +
          "/reports/payments?startDate=" +
          dateFrom +
          "&endDate=" +
          dateTo +
          "&store_id=" +
          selectedStore.id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setResponseData(response.data.data);
        let columns = [
          {
            Header: language == 1 ? "Número de Transacción" : "Transaction Number",
            accessor: "number",
          },
          { Header: language == 1 ? "Tiendas" : "Stores", accessor: "store" },
          { Header: language == 1 ? "Método de Pago" : "Payment Method", accessor: "method" },
          { Header: "Total", accessor: "total" },
          { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
        ];

        let rows = response.data.data.map((item) => {
          return {
            number: item.transaction_number,
            store: item.store.map((store, key) => {
              return store.name + (key == item.store.length - 1 ? "" : ", ");
            }),
            date: item.date,
            method: item.payment_method,
            total: item.total,
            active: item.active,
          };
        });

        let excelRows = response.data.data.map((item) => {
          if (language == 1) {
            return {
              "Número de Transacción": item.transaction_number,
              Tiendas: item.store.map((store, key) => {
                return store.name + (key == item.store.length - 1 ? "" : ", ");
              }),
              "Método de Pago": item.payment_method,
              Fecha: item.date,
              Total: item.total,
            };
          } else {
            return {
              "Transaction Number": item.transaction_number,
              Stores: item.store.map((store, key) => {
                return store.name + (key == item.store.length - 1 ? "" : ", ");
              }),
              "Payment Method": item.payment_method,
              Date: item.date,
              Total: item.total,
            };
          }
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
        setData(excelRows);
        setErrors([]);
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function sortPayments() {
    console.log(sortSelect);
    let columns = [
      {
        Header: language == 1 ? "Número de Transacción" : "Transaction Number",
        accessor: "number",
      },
      { Header: language == 1 ? "Tiendas" : "Stores", accessor: "store" },
      { Header: language == 1 ? "Método de Pago" : "Payment Method", accessor: "method" },
      { Header: "Total", accessor: "total" },
      { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
      { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
    ];

    let rows = (
      sortSelect == "ascending"
        ? responseData.sort((a, b) => (a.total < b.total ? 1 : -1))
        : responseData.sort((a, b) => (a.sortTotal > b.sortTotal ? 1 : -1))
    ).map((item) => {
      return {
        number: item.transaction_number,
        store: item.store.map((store, key) => {
          return store.name + (key == item.store.length - 1 ? "" : ", ");
        }),
        date: item.date,
        method: item.payment_method,
        total: item.total,
        active: item.active,
      };
    });

    let excelRows = (
      sortSelect == "ascending"
        ? responseData.sort((a, b) => (parseFloat(a.total) > parseFloat(b.total) ? 1 : -1))
        : responseData.sort((a, b) => (parseFloat(a.total) < parseFloat(b.total) ? 1 : -1))
    ).map((item) => {
      if (language == 1) {
        return {
          "Número de Transacción": item.transaction_number,
          Tiendas: item.store.map((store, key) => {
            return store.name + (key == item.store.length - 1 ? "" : ", ");
          }),
          "Método de Pago": item.payment_method,
          Fecha: item.date,
          Total: item.total,
        };
      } else {
        return {
          "Transaction Number": item.transaction_number,
          Stores: item.store.map((store, key) => {
            return store.name + (key == item.store.length - 1 ? "" : ", ");
          }),
          "Payment Method": item.payment_method,
          Date: item.date,
          Total: item.total,
        };
      }
    });

    let tableData = { columns: columns, rows: rows };
    //console.log(tableData)
    setReport(tableData);
    setData(excelRows);
    setErrors([]);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    GetStores();
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <MDTypography variant="body1">Total</MDTypography>
            <FormControl>
              <RadioGroup
                name="Sort Options"
                onChange={(e) => {
                  if (e.target.value == "ascending") {
                    setSortSelect(e.target.value);
                  } else if (e.target.value == "descending") {
                    setSortSelect(e.target.value);
                  }
                }}
              >
                <FormControlLabel
                  value="descending"
                  control={<Radio />}
                  label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                />
                <FormControlLabel
                  value="ascending"
                  control={<Radio />}
                  label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(!open)}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button onClick={sortPayments}>{language == 1 ? "Organizar" : "Sort"}</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            {data.length > 0 ? (
              <>
                <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
                  {language == 1 ? "Organizar" : "Sort"}&nbsp;
                  <Icon>keyboard_arrow_down</Icon>
                </MDButton>
                <MDBox ml={1}>
                  <ExportToExcel apiData={data} fileName="Payments" />
                </MDBox>
                <MDBox ml={1}>
                  <MDButton variant="outlined" color="dark" onClick={createPDF}>
                    {language == 1 ? "Descargar PDF" : "Download PDF"}
                  </MDButton>
                </MDBox>
              </>
            ) : null}
          </MDBox>
        </MDBox>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h4" sx={{ mb: 1 }}>
              {language == 1 ? "Busqueda" : "Search"}
            </MDTypography>
            <Grid container spacing={1}>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Desde" : "From Date"}
                  fullWidth
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                  }}
                />
                <ShowError id={"startDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Hasta" : "To Date"}
                  fullWidth
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                  }}
                />
                <ShowError id={"endDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <Autocomplete
                  disablePortal
                  value={selectedStore}
                  onChange={(event, newValue) => {
                    setSelectedStore(newValue);
                  }}
                  options={stores}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Tiendas" : "Stores"}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  onClick={getPaymentData}
                  disabled={!selectedStore}
                >
                  {language == 1 ? "Busqueda" : "Search"}
                </MDButton>
              </Grid>
            </Grid>
            <DataTable table={report} entriesPerPage={true} canSearch />
          </MDBox>
        </Card>
        <div hidden={download}>
          <MDBox mt={50}>
            <div id="pdf">
              <h3>{language == 1 ? "Reporte de Pagos" : "Payments Report"}</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{language == 1 ? "Número de Transacción" : "Transaction Number"}</th>
                    <th>{language == 1 ? "Método de Pago" : "Payment Method"}</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {responseData.map((item) => {
                    return (
                      <tr>
                        <td>{item.transaction_number}</td>
                        <td>{item.payment_method}</td>
                        <td>{item.total}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </MDBox>
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportPaymentsList;
