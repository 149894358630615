/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { flushSync } from "react-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import EditableRow from "./EditableRow";
import ReadOnlyRow from "./ReadOnlyRow";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import Pagination from "./pagination";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";

import IP from "app/IP";

const axios = require("axios");

function PaymentDetails() {
  const [menu, setMenu] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [paymentData, setPaymentData] = useState({ columns: [], rows: [] });
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [monto, setMonto] = useState(0.0);
  const [totalPay, setTotalPay] = useState(0.0);
  const [received, setReceived] = useState(0.0);
  const [remainder, setRemainder] = useState(0.0);
  const [invoices, setInvoices] = useState([]);
  const [editInvoice, setEditInvoice] = useState(0);
  const [start, SetStart] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("CHECK");
  const [transactionNumber, setTransactionNumber] = useState("");
  const [paginateData, setPaginateData] = useState({
    columns: [
      { Header: "", accessor: "name" },

      { Header: "Transaction Number.", accessor: "invoice_number" },
      { Header: "Pending", accessor: "total" },
      { Header: "Payment Amount", accessor: "amount" },
      { Header: "Actions", accessor: "actions" },
    ],
    rows: [],
  });
  const [editFormData, setEditFormData] = useState({
    fullName: "",
    address: "",
    phoneNumber: "",
    email: "",
  });

  let timer;

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  async function GetStores() {
    //console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setStores(
          response.data.data.map((item) => {
            return { value: item.id, label: item.name };
          })
        );
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  function DoCalc(event) {
    event.preventDefault();
    let inv = [];
    // inv.forEach((item) => {
    //   if (id == item.invoice_id) {
    //     item.amount = parseFloat(add);
    //   }
    // });
    console.log("do Calc");
    console.log(inv);
    var pay = parseFloat(0.0);
    // inv.forEach((item) => {
    //   console.log(item);
    //   if (item.amount > 0) {
    //     pay += parseFloat(item.amount);
    //   }
    // });
    //console.log("pay " + pay + "  add " + add + " id " + id);
    if (received >= 0 && pay >= 0) {
      setTotalPay(parseFloat(pay));
      setRemainder(parseFloat(received) - parseFloat(pay));
    } else {
      setTotalPay(0.0);
    }
    //setInvoices(inv);
  }

  async function GetInvoices(value) {
    await axios
      .get(IP + "/invoices/getByStore/" + value, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log("invoices");
        console.log(response.data.data);

        const invoiceCol = [
          { Header: "Check", accessor: "checkbox" },
          {
            Header: language == 1 ? "Número de Transacción" : "Transaction Number",
            accessor: "invoice_number",
          },
          { Header: language == 1 ? "Pendiente" : "Pending", accessor: "total_amount" },
          { Header: language == 1 ? "Pago" : "Payment", accessor: "payment" },
        ];

        let invoiceVar = response.data.data.map((item) => {
          return {
            ...item,
            amount: 0,
          };
        });

        setPaymentData({
          rows: invoiceVar.map((item) => {
            return {
              ...item,
              payment:
                editInvoice == item.invoice_id ? (
                  <>
                    <MDInput
                      id={"input" + item.invoice_id}
                      defaultValue={0.0}
                      name="paymentInput"
                      onChange={DoCalc}
                      variant="standard"
                    />
                    <MDButton
                      variant="gradient"
                      style={{ marginBottom: "5%" }}
                      onClick={() => {
                        setEditInvoice(0);
                      }}
                      color="info"
                    >
                      {language == 1 ? "Guardar" : "Save"}
                    </MDButton>
                  </>
                ) : (
                  <>
                    <Grid container spacing={4} style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      <Grid item>
                        <text>{item.amount}</text>
                      </Grid>
                      <Grid item>
                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            console.log(item.invoice_id);
                            setEditInvoice(item.invoice_id);
                          }}
                          iconOnly
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                      </Grid>
                    </Grid>
                  </>
                ),
              checkbox: send(item),
            };
          }),
          columns: invoiceCol,
        });
        let mon = 0.0;
        response.data.data.forEach((item) => {
          mon += parseFloat(item.total);
        });
        setMonto(mon);

        setInvoices(
          response.data.data.map((item) => {
            return {
              invoice_id: item.invoice_id,
              invoice_number: item.invoice_number,
              total: item.total,
              amount: 0,
              actions: false,
            };
          })
        );
        setPaginateData({
          columns: [
            { Header: "", accessor: "name" },

            { Header: "Transaction Number.", accessor: "invoice_number" },
            { Header: "Pending", accessor: "total" },
            { Header: "Payment Amount", accessor: "amount" },
            { Header: "Actions", accessor: "actions" },
          ],
          rows: response.data.data.map((item) => {
            return {
              invoice_id: item.invoice_id,
              invoice_number: item.invoice_number,
              total: item.total,
              amount: 0,
              actions: false,
            };
          }),
        });
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  function send(item) {
    return (
      <input
        defaultChecked={item.checked}
        onChange={(e) => {
          let x = paymentData;
          console.log(x);
          x.rows.map((xi) => {
            if (xi.id == item.id) {
              xi.checked = e.target.checked;
            }
            return xi;
          });
        }}
        type="checkbox"
      />
    );
  }

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = (event) => {
    console.log(editFormData);
    event.preventDefault();

    const editedInvoice = {
      invoice_id: editFormData.invoice_id,
      fullName: editFormData.fullName,
      invoice_number: editFormData.address,
      total: editFormData.phoneNumber,
      amount: editFormData.email,
      actions: false,
    };

    const newInvoice = [...invoices];

    const index = invoices.findIndex((invoice) => invoice.invoice_id === editFormData.invoice_id);

    newInvoice[index] = editedInvoice;

    setInvoices(newInvoice);
    setEditInvoice(null);
    setEditFormData(null);
    let sumPay = 0.0;

    newInvoice.forEach((item) => {
      if (item.amount > 0) sumPay += parseFloat(item.amount);
    });
    setTotalPay(sumPay);
    setRemainder(received - sumPay);
  };

  const handleEditClick = (event, invoice) => {
    console.log(invoices);
    event.preventDefault();
    setEditInvoice(invoice.invoice_id);

    const formValues = {
      invoice_id: invoice.invoice_id,
      fullName: invoice.fullName,
      address: invoice.invoice_number,
      phoneNumber: invoice.total,
      email: invoice.amount,
      actions: false,
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditInvoice(null);
  };

  function handlePaginate(startPag, endPag) {
    if (start != startPag) {
      SetStart(startPag);
      setInvoices(paginateData.rows.slice(startPag, endPag));
    }

    console.log("paginate " + startPag + " " + start);
  }

  async function submitPayment() {
    let paymentinvoices = invoices.filter((item) => item.amount > 0);

    let payment_id = 0;

    await axios
      .post(
        IP + "/payments",
        {
          transaction_number: transactionNumber,
          active: 1,
          payment_method: paymentMethod,
          total: totalPay,
          store_id: selectedStore.value,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(async function (response) {
        //console.log(response.data.data);
        payment_id = response.data.data.id;
        if (payment_id > 0) {
          await axios
            .post(
              IP + "/payments/" + payment_id + "/payment_details",
              {
                payments: paymentinvoices.map((item) => {
                  return {
                    invoice_id: item.invoice_id,
                    payment_id: payment_id,
                    amount: item.amount,
                  };
                }),
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                  Accept: "application/json",
                },
              }
            )
            .then(function (response) {
              //console.log(response.data.data);
              payment_id: response.data.data.id;
            })
            .catch(function (error) {
              // console.log(error.response.data.authenticated);
              if (!error.response.data.authenticated) {
                navigate("/signIn");
              }
            });
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  async function getPaymentDetails() {
    await axios
      .get(IP + "/payments/" + localStorage.getItem("paymentId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(async function (response) {
        console.log(response.data.data);

        let payment = response.data.data;

        console.log(
          payment.store.map((item) => {
            return { label: item.name, value: item.id };
          })
        );

        setStores([{ label: payment.store.name, value: payment.store.store_id }]);
        setSelectedStore(
          payment.store.map((item) => {
            return { label: item.name, value: item.id };
          })
        );
        setPaymentMethod(String(payment.payment_method).toUpperCase());
        setTransactionNumber(payment.transaction_number);
        setReceived(payment.total);
        // GetInvoices(payment.store.store_id);
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });

    await axios
      .get(IP + "/payments/" + localStorage.getItem("paymentId") + "/payment_details", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(async function (response) {
        let payDetail = response.data.data;

        let headerPay = [
          {
            Header: language == 1 ? "Número de Transacción" : "Transaction Number",
            accessor: "invoice_number",
          },
          { Header: language == 1 ? "Tienda" : "Store", accessor: "store" },
          { Header: language == 1 ? "Total de Factura" : "Invoice Total", accessor: "total" },
          {
            Header: language == 1 ? "Cantidad Ingresada" : "Amount Registered",
            accessor: "amount",
          },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let totalAmount = 0.0;

        let rowPay = payDetail.map((item) => {
          totalAmount += parseFloat(item.amount);
          return {
            invoice_number: item.invoice.invoice_number,
            store: item.invoice.store.name,
            total: item.invoice.total_amount,
            amount: item.amount,
            pending: parseFloat(item.invoice.total_amount - item.amount ?? 0).toFixed(2),
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Ver" : "View"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      navigate("/view-invoice");
                      localStorage.setItem("invoiceId", item.invoice.invoice_id);
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                {/* <Tooltip title={language == 1 ? "Imprimir" : "Print"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      navigate("/view-invoice", { state: { print: true } });
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>print</Icon>
                  </MDButton>
                </Tooltip>{" "} */}
                <Tooltip title={language == 1 ? "Descargar" : "Download"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      navigate("/view-invoice", { state: { download: true } });
                      localStorage.setItem("invoiceId", item.invoice.invoice_id);
                    }}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        setPaymentData({ rows: rowPay, columns: headerPay });
        setTotalPay(parseFloat(totalAmount).toFixed(2));
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
      });
  }

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres guardar estos pagos?"
            : "Are you sure you want to submit these payments?"
        }
        OkayFunction={submitPayment}
      />
      <DashboardNavbar />
      <Grid my={2}>
        <Card>
          <MDBox p={2}>
            <MDTypography variant="h4" sx={{ mb: 2 }}>
              {language == 1 ? "Detalles de Pagos" : "Payment Details"}
            </MDTypography>
            <Grid container style={{ marginLeft: "auto" }}>
              <Grid item lg={4}>
                <Autocomplete
                  multiple={true}
                  disablePortal
                  value={selectedStore}
                  options={stores}
                  readOnly
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Tiendas" : "Stores"}
                      style={{ width: "80%" }}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4}>
                <MDBox mt={-2}>
                  <FormControl>
                    <h6>{language == 1 ? "Tipo de Pago" : "Payment Type"}: </h6>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={paymentMethod}
                    >
                      <FormControlLabel
                        value={"CASH"}
                        control={<Radio />}
                        label={language == 1 ? "Efectivo" : "Cash"}
                      />
                      <FormControlLabel
                        value={"CHECK"}
                        control={<Radio />}
                        label={language == 1 ? "Cheque" : "Check"}
                      />
                      <FormControlLabel value={"ACH"} control={<Radio />} label="ACH" />
                      <FormControlLabel value={"OTROS"} control={<Radio />} label="OTROS" />
                    </RadioGroup>
                  </FormControl>
                </MDBox>
              </Grid>
              <Grid item lg={4}>
                <MDBox mt={"16px"}>
                  No. <MDInput value={transactionNumber} sx={{ width: "75%" }} variant="standard" />
                </MDBox>
              </Grid>
              <Grid item xs={2} lg={3}>
                <MDInput
                  label={language == 1 ? "Cantidad Recibida:" : "Quantity Received:"}
                  placeholder="$0.00"
                  value={received}
                  sx={{ mr: "10px" }}
                  onChange={(e) => {
                    setReceived(e.target.value);
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      if (received >= 0 && totalPay >= 0)
                        setRemainder(parseFloat(e.target.value) - parseFloat(totalPay));
                      else setRemainder(0.0);
                    }, 1000);
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={2} lg={3}>
                <MDInput
                  label={language == 1 ? "Pago Total:" : "Total Payment:"}
                  placeholder="$0.00"
                  sx={{ mr: "10px" }}
                  value={totalPay}
                  variant="standard"
                />
              </Grid>{" "}
              <Grid item xs={2} lg={3}>
                <MDInput
                  placeholder="$0.00"
                  label={language == 1 ? "Restante:" : "Remainder:"}
                  value={remainder}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid style={{ maxHeight: "400px", overflow: "auto" }}>
              <DataTable table={paymentData} />
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: "3%",
              }}
            >
              <Grid style={{ marginRight: "3%" }}></Grid>
            </Grid>
          </MDBox>
        </Card>
      </Grid>
    </DashboardLayout>
  );
}

export default PaymentDetails;
