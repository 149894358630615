/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import PdfTable from "app/components/pdfTable";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

// Invoice page components
import InvoiceLayout from "app/components/invoiceLayout";

// Images
import logoCT from "assets/images/logo-ct.png";
import logoCTDark from "assets/images/logo-ct-dark.png";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import Basic from "layouts/authentication/sign-in/basic";
import foto from "app/components/pictures/agranel2.png";

import IP from "app/IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function ViewInvoice() {
  const [invoice, setInvoice] = useState({ columns: [], rows: [] });
  const navigate = useNavigate();
  const [image, setImage] = useState('')
  const [controller] = useMaterialUIController();
  const [salesNum, setSalesNum] = useState(1000);
  const [phone, setPhone] = useState();
  const [contact, setContact] = useState("");
  const [shippingDate, setShippingDate] = useState("");
  const [invoiceNum, setInvoiceNum] = useState(1000);
  const [sub, setSub] = useState(0.0);
  const [tax, setTax] = useState(0.0);
  const [total, setTotal] = useState(0.0);
  const [exemptedTax, setExemptedTax] = useState(0.0)
  const [fee, setFee] = useState(0.0);
  const [storeBalance, setStoreBalance] = useState(0.0);
  const [newBalance, setNewBalance] = useState(0.0)
  const [totalQty, setTotalQty] = useState(0.0);
  const [printing, setPrinting] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [address1, setAddress1] = useState("");
  const [storeName, setStoreName] = useState("")
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [storeNumber, setStoreNumber] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const { darkMode } = controller;
  const { state } = useLocation();

  const downloadRef = useRef()
  const pdfRef = useRef()

  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };

  async function getInvoiceData() {
    await axios
      .get(IP + "/invoices/" + localStorage.getItem("invoiceId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        // console.log(response.data.data[1]);

        setSalesNum(response.data.data[0]?.sales.so_number);

        setShippingDate(response.data.data[0]?.shipping.date);
        setTotal(response.data.data[0]?.total_amount);
        setExemptedTax(response.data.data[0]?.sales.exempted_tax);
        setInvoiceNum(response.data.data[0]?.invoice_number);
        setAddress1(response.data.data[0]?.sales.store_address);
        setStoreName(response.data.data[0]?.store.name)
        setCity(response.data.data[0]?.sales.store_city);
        setRegion(response.data.data[0]?.sales.store_region);
        setStoreNumber(response.data.data[0]?.sales.store_number);
        setIssueDate(response.data.data[0]?.created_at);

        if (response.data?.data?.length > 1) {
          let columns = [
            { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
            { Header: "UPC", accessor: "upc" },
            { Header: language == 1 ? "Producto" : "Product", accessor: "name", width: "30%" },
            { Header: language == 1 ? "Cantidad Original" : "Original Quantity.", accessor: "qty_origin", width: "10%" },
            {
              Header: language == 1 ? "Cantidad de Envío" : "Shipping Quantity.",
              accessor: "qty",
              width: "10%",
            },
            { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "10%" },
            {
              Header: language == 1 ? "Porciento de Impuesto" : "Tax Percent",
              accessor: "tax",
              width: "10%",
            },
            { Header: "Total", accessor: "amount", width: "10%" },
          ];

          let subtotal = 0.0;
          let taxAmount = 0.0;

          let qty = [];

          let rows = response?.data?.data[1]?.map((item) => {
            qty.push(item.quantity_approved);
            //console.log(item);
            //subtotal += parseFloat(item.total);
            // taxAmount += parseFloat((parseFloat(item.quantity * item.product.sales_price).toFixed(2)) * (item.product.tax.percent/100)).toFixed(2)
            return {
              name: item.product.name,
              qty: item.quantity_approved,
              qty_origin: item.quantity,
              upcunit: item.product.upcUnit,
              upc: item.product.upc,
              price: "$" + item.price,
              tax: item.tax_percent + "%",
              amount:
                "$" +
                parseFloat(item.total).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
              tax_total:
                "$" +
                item.tax.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
              amountNum: parseFloat(item.price * item.quantity_approved),
              tax_total_num: parseFloat(item.tax),
            };
          });

          setTotalQty(qty.reduce((prev, current) => prev + current, 0));

          rows.forEach((item) => {
            subtotal += parseFloat(item.amountNum);
            taxAmount += parseFloat(item.tax_total_num);
          });

          let tableData = { columns: columns, rows: rows };
          //console.log(tableData)
          setInvoice(tableData);

          setSub(subtotal);
          setTax(taxAmount);
          setFee(response.data.data[0].sales.delivery_fee);
          setStoreBalance(response.data.data[0].store_balance);
          setNewBalance(response.data.data[0].new_balance)

          if (state?.download) {
            setTimeout(()=>{
              downloadRef.current.click()
            }, 2000)
          }
        }
      })
      .catch((error) => {
        if (!error.response?.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function printInvoice() {
    setPrinting(true);
    setTimeout(() => {
      window.print();
      setPrinting(false);
    }, 100);
  }

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      color: "white",
    },
    section: {
      margin: 10,
      padding: 10,
      color: 'black'
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });

  let Pdf = () => (
    <Document language={language == 1 ? "spanish" : "english"}>
    {/*render a single page*/}
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        {<Image src={foto} style={{height: '55px', width: '160px'}} />}
        <Text style={{fontSize: 15}}>400 Calle Union</Text>
        <Text style={{fontSize: 14, marginTop: 5}}>tel: +787-946-4208</Text>
        <Text style={{fontSize: 8, marginTop: 8}}>
          {language == 1
          ? "NOTA: NO SE ACEPTARÁN RECLAMACIONES LUEGO DE RECIBIR LA MERCANCIA Y FIRMAR FACTURA"
          : "NOTE: WE WILL NOT ACCEPT ANY CLAIMS AFTER THE MERCHANDISE HAS BEEN RECEIVED AND THE BILL HAS BEEN SIGNED"}
        </Text>
        <Text style={{fontSize: 14, marginTop: 12}}>
          {language == 1 ? "Total de Bultos: " : "Total Qty: "} {totalQty}
        </Text>
        <Text style={{fontSize: 12, marginTop: 12}}>
          {language == 1 ? "Número de Factura: " : "Invoice Number: "}
        </Text>
        <Text style={{fontSize: 10}}>
          {invoiceNum}
        </Text>
        {storeNumber && <>
          <Text style={{fontSize: 12, marginTop: 14}}>
            {language == 1 ? "Número de Cuenta: " : "Account Number: "}
          </Text>
          <Text style={{fontSize: 10}}>
            {storeNumber}
          </Text>
        </>}
        <Text style={{fontSize: 12, marginTop: 12}}>
          {language == 1 ? "Fecha de Emissión: " : "Issue Date: "}
        </Text>
        <Text style={{fontSize: 10}}>
          {issueDate}
        </Text>
        <View style={{flexDirection: 'row'}}>
          <View style={{flexDirection: 'column', width: '50%'}}>
            <Text style={{fontSize: 12, marginTop: 15,}}>
              {language == 1 ? "Cobrar a" : "Bill to"}:{" "}
            </Text>
            <Text style={{fontSize: 12}}>
              {storeName}
            </Text>
            <Text style={{fontSize: 12}}>{address1}</Text>
            <Text style={{fontSize: 12}}>
              {city}, {region}
            </Text>
            <Text style={{fontSize: 12, marginTop: 12}}>
              {language == 1 ? "Número de Venta" : "Sales Order No."}:
            </Text>
            <Text style={{fontSize: 12, marginBottom: 20}}>{salesNum}</Text>
          </View>
          {/* <View style={{flexDirection: 'column'}}>
            <Text>{"                                 "}</Text>
          </View> */}
          <View style={{flexDirection: 'column', width: '50%'}}>
            <Text style={{fontSize: 12, marginTop: 15}}>
              {language == 1 ? "Enviar a" : "Send to"}:{" "}
            </Text>
            <Text style={{fontSize: 12}}>
              {storeName}
            </Text>
            <Text style={{fontSize: 12}}>{address1}</Text>
            <Text style={{fontSize: 12}}>
              {city}, {region}
            </Text>
            <Text style={{fontSize: 12, marginTop: 12}}>
              {language == 1 ? "Fecha de Envío: " : "Shipping Date: "}
            </Text>
            <Text style={{fontSize: 10, marginBottom: 20}}>{shippingDate}</Text>
          </View>
        </View>
        <PdfTable data={invoice?.rows} />
        {/* <Image src={"https://emos.utreee.net/images/logo_agranel.png"} style={{width: '50%', height: '50%'}} /> */}
        <View style={{flexDirection: 'row-reverse'}}>
          <View style={{flexDirection: 'column'}}>
            <View style={{flexDirection: 'row-reverse'}}>
              <Text style={{fontSize: 12, marginTop: 10, borderBottom: '1px solid #EEE', paddingBottom: 8}} >
                Sub-Total: 
                ${sub.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </Text> 
            </View>
            <View style={{flexDirection: 'row-reverse'}}>
              <Text style={{fontSize: 12, marginTop: 8, borderBottom: '1px solid #EEE', paddingBottom: 8}} >
                {language == 1 ? "Total de Impuestos" : "Tax Total"}: 
                ${tax.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </Text> 
            </View>
            <View style={{flexDirection: 'row-reverse'}}>
              <Text style={{fontSize: 12, marginTop: 8, borderBottom: '1px solid #EEE', paddingBottom: 8}} >
                Total: 
                ${total.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </Text> 
            </View>
            <View style={{flexDirection: 'row-reverse'}}>
              <Text style={{fontSize: 12, marginTop: 8, borderBottom: '1px solid #EEE', paddingBottom: 8}} >
                {language == 1 ? "Balance Previo" : "Previous Balance"}: 
                ${(storeBalance).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </Text> 
            </View>
            <View style={{flexDirection: 'row-reverse'}}>
              <Text style={{fontSize: 12, marginTop: 8, borderBottom: '1px solid #EEE', paddingBottom: 8}}>
              {language == 1 ? "Balance Nuevo" : "New Balance"}: $
              {(parseFloat(storeBalance) + parseFloat(total)).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
  )

  async function fetchImage() {
    await axios
      .get(IP + "/invoices/logo", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(response=>{
        console.log(response)
      })
      .catch(error=>{
        console.log(error)
      })
  }

  useEffect(() => {
    fetchImage()
    getInvoiceData();
  }, []);

  return (
    <InvoiceLayout>
      <MDBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
        <Grid container>
          <Grid item xs={12} sm={10} md={8}>
            <div id="pdf">
              <Card>
                {/* Invoice header */}
                <MDBox p={3}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={4} lg={8}>
                      <MDBox
                        component="img"
                        src={imageIP + "/images/logo_agranel.png"}
                        width="35%"
                        p={1}
                        mb={1}
                      />
                      <MDTypography variant="h6" fontWeight="medium">
                        400 Calle Union, Guaynabo, PR 00969
                      </MDTypography>
                      <MDTypography variant="caption" color="error">
                        {language == 1
                          ? "NOTA: NO SE ACEPTARÁN RECLAMACIONES LUEGO DE RECIBIR LA MERCANCIA Y FIRMAR FACTURA"
                          : "NOTE: WE WILL NOT ACCEPT ANY CLAIMS AFTER THE MERCHANDISE HAS BEEN RECEIVED AND THE BILL HAS BEEN SIGNED"}
                      </MDTypography>
                      <MDBox mt={2}>
                        <MDTypography variant="h5">
                          {language == 1 ? "Total de Bultos: " : "Total Qty: "} {totalQty}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={7} lg={3}>
                      <MDBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
                        <MDBox mt={1}>
                          <MDTypography variant="h6" fontWeight="medium" color="secondary">
                            {language == 1 ? "Número de Factura: " : "Invoice Number: "}
                          </MDTypography>
                          <MDTypography variant="h6" fontWeight="medium">
                            {invoiceNum}
                          </MDTypography>
                        </MDBox>
                        {storeNumber && <MDBox mt={1}>
                          <MDTypography variant="h6" fontWeight="medium" color="secondary">
                            {language == 1 ? "Número de Cuenta: " : "Account Number: "}
                          </MDTypography>
                          <MDTypography variant="h6" fontWeight="medium">
                            {storeNumber}
                          </MDTypography>
                        </MDBox>}
                        <MDBox mt={1}>
                          <MDTypography variant="h6" fontWeight="medium" color="secondary">
                            {language == 1 ? "Fecha de Emissión: " : "Issue Date: "}
                          </MDTypography>
                          <MDTypography variant="h6" fontWeight="medium">
                            {issueDate}
                          </MDTypography>
                        </MDBox>
                        {/* <MDBox mt={1}>
                        <MDTypography variant="h6" fontWeight="medium">
                          PO to: Agranel Carolina Ideal
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={1}>
                        <MDTypography variant="body2" color={darkMode ? "text" : "secondary"}>
                          tel: +1 (787) 752-9120
                        </MDTypography>
                      </MDBox>  */}
                      </MDBox>
                    </Grid>
                    <Grid item lg={6}>
                      <MDBox mt={5}>
                        <MDTypography variant="body1">
                          {language == 1 ? "Cobrar a" : "Bill to"}: <text>{storeName}</text>
                        </MDTypography>
                        <text>{address1}</text>
                        <p>
                          {city}, {region}
                        </p>
                      </MDBox>
                    </Grid>
                    <Grid item lg={6}>
                      <MDBox mt={5}>
                        <MDTypography variant="body1">
                          {language == 1 ? "Enviar a: " : "Ship to: "} <text>{storeName}</text>
                        </MDTypography>
                        <text>{address1}</text>
                        <p>
                          {city}, {region}
                        </p>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox mt={{ xs: 2, md: 4 }}>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={12} md={4}>
                        <MDTypography
                          variant="h6"
                          color={darkMode ? "text" : "secondary"}
                          fontWeight="regular"
                        >
                          {language == 1 ? "Número de Venta" : "Sales Order No."}
                        </MDTypography>
                        <MDTypography variant="h5" fontWeight="medium">
                          {salesNum}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={7} lg={5}>
                        <MDBox
                          width="100%"
                          display="flex"
                          flexDirection={{ xs: "column", md: "row" }}
                          alignItems={{ xs: "flex-start", md: "center" }}
                          textAlign={{ xs: "left", md: "right" }}
                          mt={{ xs: 3, md: 0 }}
                        >
                          <MDBox width="100%">
                            <MDTypography
                              variant="h6"
                              color={darkMode ? "text" : "secondary"}
                              fontWeight="regular"
                            >
                              {language == 1 ? "Fecha de Envío: " : "Shipping Date: "}
                            </MDTypography>
                            <MDTypography variant="h6" fontWeight="medium">
                              {shippingDate}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDBox
                          width="100%"
                          display="flex"
                          flexDirection={{ xs: "column", md: "row" }}
                          alignItems={{ xs: "flex-start", md: "center" }}
                          textAlign={{ xs: "left", md: "right" }}
                        >
                          <MDBox width="50%">
                            <MDTypography
                              variant="h6"
                              color={darkMode ? "text" : "secondary"}
                              fontWeight="regular"
                            ></MDTypography>
                          </MDBox>
                          <MDBox width="50%">
                            <MDTypography variant="h6" fontWeight="medium"></MDTypography>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>

                {/* Invoice table */}
                <MDBox mr={3}>
                  <MDBox width="100%" overflow="auto">
                    <DataTable
                      table={invoice}
                      entriesPerPage={true}
                      showTotalEntries={false}
                      isSorted={false}
                    />
                    {/* <PDFViewer style={{...styles.viewer, marginLeft: -200}}>
                      <Pdf />
                    </PDFViewer> */}
                    <MDBox style={{ float: "right", color: "black", marginTop: "2%" }}>
                      <MDBox>
                        <MDTypography
                          variant="h6"
                          style={{ color: "black", display: "flex", justifyContent: "flex-end" }}
                        >
                          Sub-Total: $
                          {sub.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                        </MDTypography>
                      </MDBox>
                      <hr />
                      <MDBox style={{ display: "flex", justifyContent: "flex-end" }}>
                        <MDTypography variant="h6" style={{ color: "black" }}>
                          {language == 1 ? "Total de Impuestos:" : "Tax Total:"} $
                          {tax.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                        </MDTypography>
                      </MDBox>
                      {exemptedTax != 0 && <> <hr />
                        <MDBox style={{ display: "flex", justifyContent: "flex-end" }}>
                          <MDTypography variant="h6" style={{ color: "black" }}>
                            {language == 1 ? "Impuesto 1%:" : "1% Tax:"} $
                            {exemptedTax.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                          </MDTypography>
                        </MDBox>
                      </>}
                      {fee != 0 ? (
                        <>
                          <hr />
                          <MDBox style={{ display: "flex", justifyContent: "flex-end" }}>
                            <MDTypography variant="h6" style={{ color: "black" }}>
                              {language == 1 ? "Tarifa de Envío:" : "Delivery Fee:"} $
                              {fee.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            </MDTypography>
                          </MDBox>
                        </>
                      ) : null}
                      <hr />
                      <MDBox
                        style={{ color: "black", display: "flex", justifyContent: "flex-end" }}
                      >
                        <MDTypography variant="h6" style={{ color: "black" }}>
                          Total: $
                          {total.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                        </MDTypography>
                      </MDBox>
                      <hr />
                      <MDBox
                        style={{ color: "black", display: "flex", justifyContent: "flex-end" }}
                      >
                        <MDTypography variant="h6" style={{ color: "black" }}>
                          {language == 1 ? "Balance Previo" : "Previous Balance"}: $
                          {storeBalance.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                        </MDTypography>
                      </MDBox>
                      <hr />
                      <MDBox
                        style={{ color: "black", display: "flex", justifyContent: "flex-end" }}
                      >
                        <MDTypography variant="h6" style={{ color: "black" }}>
                          {language == 1 ? "Balance Nuevo" : "New Balance"}: $
                          {(newBalance).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>

                {/* <MDBox mr={3} mt={5} style={{ float: "right", color: "black"}}>
                <MDBox style={{display: "flex", justifyContent: "flex-end"}}>
                  <MDTypography variant='h6' style={{color: 'black'}}>{language==1 ? "Balance Previo: " : "Previous Balance: "}</MDTypography>
                </MDBox>
                <MDBox style={{display: "flex", justifyContent: "flex-end"}}>
                  <MDTypography variant='h6' style={{color: 'black'}}>{language==1 ? "Balance Nuevo: " : "New Balance: "}</MDTypography>
                </MDBox>
              </MDBox> */}

                {/* Invoice footer */}
                <MDBox p={3} mt={7}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      {/* <MDTypography variant="h5" fontWeight="medium">
                      Thank you for shopping AG DISTRIBUTORS LLC Please come again!
                    </MDTypography> */}
                      <MDBox mt={1} mb={2} lineHeight={0}>
                        <MDTypography variant="button" color={darkMode ? "text" : "secondary"}>
                          {language == 1 ? "Gracias por comprar con" : "Thank you for shopping"}{" "}
                          Utreee LLC.
                        </MDTypography>
                        <MDTypography variant="button" color={darkMode ? "text" : "secondary"}>
                          {language == 1 ? " Esperamos verlos denuevo!" : " Please come again!"}
                        </MDTypography>
                      </MDBox>
                      {/* <MDTypography
                      component="span"
                      variant="h6"
                      fontWeight="regular"
                      color={darkMode ? "text" : "secondary"}
                    >
                      email:{" "}
                      <MDTypography component="span" variant="h6" fontWeight="regular">
                        comprasagranel@gmail.com
                      </MDTypography>
                    </MDTypography> */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <MDBox
                        width="100%"
                        height={{ xs: "auto", md: "100%" }}
                        display="flex"
                        justifyContent={{ xs: "flex-start", md: "flex-end" }}
                        alignItems="flex-end"
                        mt={{ xs: 2, md: 0 }}
                      >
                        <MDButton
                          sx={{ mr: 1 }}
                          variant="gradient"
                          color="light"
                          onClick={() => navigate(-1)}
                        >
                          {language == 1 ? "Cerrar" : "Close"}
                        </MDButton>
                        {/* <MDButton sx={{mr: 1}} variant="gradient" color="info" onClick={printInvoice}>
                          {language == 1 ? "Imprimir" : "print"}
                        </MDButton>
                        {" "} */}
                        {/* <PDFDownloadLink document={<Pdf />} fileName={invoiceNum + ".pdf"} ref={downloadRef}>
                          <MDButton variant="gradient" color="info" ref={downloadRef}>
                            {language == 1 ? "Descargar" : "Download"}
                          </MDButton>
                        </PDFDownloadLink> */}
                        <MDButton variant="gradient" color="info" href={imageIP + "invoiceDownload/" + localStorage.getItem("invoiceId")}>
                          {language == 1 ? "Descargar" : "Download"}
                        </MDButton>
                        {"  "}
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </InvoiceLayout>
  );
}

export default ViewInvoice;
