/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

// Invoice page components
import InvoiceLayout from "app/components/invoiceLayout";

// Images
import logoCT from "assets/images/logo-ct.png";
import logoCTDark from "assets/images/logo-ct-dark.png";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import Basic from "layouts/authentication/sign-in/basic";
import foto from "app/components/pictures/agranel2.png";

import IP from "app/IP";

const axios = require("axios");

function SalesInvoice() {
  const [invoice, setInvoice] = useState({ columns: [], rows: [] });

  const [controller] = useMaterialUIController();
  const [sub, setSub] = useState(0.0);
  const [tax, setTax] = useState(0.0);
  const [total, setTotal] = useState(0.0);
  const { darkMode } = controller;

  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };

  async function getInvoiceData() {
    await axios
      .get(IP + "/sales_orders/" + localStorage.getItem("invoiceId") + "/so_detail", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.data.selected.length > 0) {
          let columns = [
            { Header: "Product", accessor: "name" },
            // { Header: "UPC", accessor: "upc" },
            // { Header: "Code", accessor: "code" },
            // { Header: "Packaging", accessor: "packaging" },
            { Header: "Qty.", accessor: "qty" },
            { Header: "Price", accessor: "price" },
            { Header: "Tax", accessor: "tax" },
            { Header: "Amount", accessor: "amount" },
          ];

          let subtotal = 0.0;

          let rows = response.data.data.selected.map((item) => {
            //console.log(item);
            subtotal += parseFloat(item.total);
            return {
              name: item.product.name,
              qty: item.quantity,
              price: "$" + item.price,
              tax: item.tax,
              amount: "$" + item.total,
            };
          });

          let tableData = { columns: columns, rows: rows };
          //console.log(tableData)
          setInvoice(tableData);

          let taxAmount = parseFloat(subtotal * 0.07).toFixed(2);
          setSub(subtotal);
          setTax(taxAmount);
          setTotal(parseFloat(parseFloat(subtotal) + parseFloat(taxAmount)).toFixed(2));
        }
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  useEffect(() => {
    getInvoiceData();
  }, []);

  return (
    <InvoiceLayout>
      <MDBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
        <Grid container>
          <Grid item xs={12} sm={10} md={8}>
            <Card>
              {/* Invoice header */}
              <MDBox p={3}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} md={4}>
                    <MDBox
                      component="img"
                      src={"https://emos.utreee.com/images/logo_image.png"}
                      width="70%"
                      p={1}
                      mb={1}
                    />
                    <MDTypography variant="h6" fontWeight="medium">
                      CQ66+2H3, Carr Puerto Rico 2, San Juan 00918
                    </MDTypography>
                    <MDBox mt={1} mb={2}>
                      <MDTypography
                        display="block"
                        variant="body2"
                        color={darkMode ? "text" : "secondary"}
                      >
                        tel: +1 (787) 946-4208
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={7} lg={3}>
                    <MDBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
                      <MDBox mt={1}>
                        <MDTypography variant="h6" fontWeight="medium">
                          PO to: Utreee
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={1}>
                        <MDTypography variant="body2" color={darkMode ? "text" : "secondary"}>
                          tel: +1 (787) 752-9120
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox mt={{ xs: 5, md: 10 }}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={4}>
                      <MDTypography
                        variant="h6"
                        color={darkMode ? "text" : "secondary"}
                        fontWeight="regular"
                      >
                        Purchase Order no
                      </MDTypography>
                      <MDTypography variant="h5" fontWeight="medium">
                        #0453119
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={7} lg={5}>
                      <MDBox
                        width="100%"
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        alignItems={{ xs: "flex-start", md: "center" }}
                        textAlign={{ xs: "left", md: "right" }}
                        mt={{ xs: 3, md: 0 }}
                      >
                        <MDBox width="50%">
                          <MDTypography
                            variant="h6"
                            color={darkMode ? "text" : "secondary"}
                            fontWeight="regular"
                          >
                            Purchase Order date:
                          </MDTypography>
                        </MDBox>
                        <MDBox width="50%">
                          <MDTypography variant="h6" fontWeight="medium">
                            06/03/2019
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        width="100%"
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        alignItems={{ xs: "flex-start", md: "center" }}
                        textAlign={{ xs: "left", md: "right" }}
                      >
                        <MDBox width="50%">
                          <MDTypography
                            variant="h6"
                            color={darkMode ? "text" : "secondary"}
                            fontWeight="regular"
                          ></MDTypography>
                        </MDBox>
                        <MDBox width="50%">
                          <MDTypography variant="h6" fontWeight="medium"></MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>

              {/* Invoice table */}
              <MDBox p={3}>
                <MDBox width="100%" overflow="auto">
                  <DataTable table={invoice} entriesPerPage={true} showTotalEntries={false} />
                  <MDBox style={{ float: "right", color: "black", marginTop: "1%" }}>
                    <MDBox>
                      <p style={{ color: "black", display: "flex", justifyContent: "flex-end" }}>
                        Sub-Total: ${sub}
                      </p>
                    </MDBox>
                    <hr />
                    <MDBox style={{ display: "flex", justifyContent: "flex-end" }}>
                      <p style={{ color: "black" }}>7% Tax: ${tax}</p>
                    </MDBox>
                    <hr />
                    <MDBox style={{ color: "black", display: "flex", justifyContent: "flex-end" }}>
                      <p style={{ color: "black" }}>Total: ${total}</p>
                    </MDBox>
                    <hr />
                  </MDBox>
                </MDBox>
              </MDBox>
              {/* <MDBox p={3}>
        <MDBox width="100%" overflow="auto">
          <Table sx={{ minWidth: "32rem" }}>
            <MDBox component="thead">
              <TableRow>
                <MDBox
                  component="th"
                  width={{ xs: "45%", md: "50%" }}
                  py={1.5}
                  px={1}
                  textAlign="left"
                  sx={borderBottom}
                >
                  <MDTypography variant="h6" color="text" fontWeight="medium">
                    Product
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="th"
                  py={1.5}
                  pl={3}
                  pr={1}
                  textAlign="left"
                  sx={borderBottom}
                >
                  <MDTypography variant="h6" color="text" fontWeight="medium">
                    Qty
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="th"
                  py={1.5}
                  pl={3}
                  pr={1}
                  textAlign="left"
                  sx={borderBottom}
                >
                  <MDTypography variant="h6" color="text" fontWeight="medium">
                    Price
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="th"
                  py={1.5}
                  pl={3}
                  pr={1}
                  textAlign="left"
                  sx={borderBottom}
                >
                  <MDTypography variant="h6" color="text" fontWeight="medium">
                    Amount
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="th"
                  py={1.5}
                  pl={3}
                  pr={1}
                  textAlign="left"
                  sx={borderBottom}
                >
                  <MDTypography variant="h6" color="text" fontWeight="medium">
                    Tax
                  </MDTypography>
                </MDBox>
              </TableRow>
            </MDBox>
            <TableBody>
              <TableRow>
                <MDBox component="td" textAlign="left" p={1} sx={borderBottom}>
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    Premium Support
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  sx={borderBottom}
                >
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    1
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  sx={borderBottom}
                >
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    $ 9.00
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  sx={borderBottom}
                >
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    $ 9.00
                  </MDTypography>
                </MDBox>
              </TableRow>
              <TableRow>
                <MDBox component="td" textAlign="left" p={1} sx={borderBottom}>
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    Material Dashboard 2 PRO
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  sx={borderBottom}
                >
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    3
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  sx={borderBottom}
                >
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    $ 100.00
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  sx={borderBottom}
                >
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    $ 300.00
                  </MDTypography>
                </MDBox>
              </TableRow>
              <TableRow>
                <MDBox component="td" textAlign="left" p={1}>
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    Parts for service
                  </MDTypography>
                </MDBox>
                <MDBox component="td" textAlign="left" py={1} pr={1} pl={3}>
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    1
                  </MDTypography>
                </MDBox>
                <MDBox component="td" textAlign="left" py={1} pr={1} pl={3}>
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    $ 89.00
                  </MDTypography>
                </MDBox>
                <MDBox component="td" textAlign="left" py={1} pr={1} pl={3}>
                  <MDTypography variant="body2" color="text" fontWeight="regular">
                    $ 89.00
                  </MDTypography>
                </MDBox>
              </TableRow>
              <TableRow>
                <MDBox component="td" textAlign="left" p={1} sx={borderBottom} />
                <MDBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  sx={borderBottom}
                />
                <MDBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  sx={borderBottom}
                >
                  <MDTypography variant="h5">Total</MDTypography>
                </MDBox>
                <MDBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  sx={borderBottom}
                >
                  <MDTypography variant="h5">$ 698</MDTypography>
                </MDBox>
              </TableRow>
            </TableBody>
          </Table>
        </MDBox>
      </MDBox> */}

              {/* Invoice footer */}
              <MDBox p={3} mt={7}>
                <Grid container>
                  <Grid item xs={12} lg={5}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Thank you!
                    </MDTypography>
                    <MDBox mt={1} mb={2} lineHeight={0}>
                      <MDTypography variant="button" color={darkMode ? "text" : "secondary"}>
                        If you encounter any issues related to the purchase order you can contact us
                        at:
                      </MDTypography>
                    </MDBox>
                    <MDTypography
                      component="span"
                      variant="h6"
                      fontWeight="regular"
                      color={darkMode ? "text" : "secondary"}
                    >
                      email:{" "}
                      <MDTypography component="span" variant="h6" fontWeight="regular">
                        
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <MDBox
                      width="100%"
                      height={{ xs: "auto", md: "100%" }}
                      display="flex"
                      justifyContent={{ xs: "flex-start", md: "flex-end" }}
                      alignItems="flex-end"
                      mt={{ xs: 2, md: 0 }}
                    >
                      <MDButton variant="gradient" color="info" onClick={() => window.print()}>
                        print
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </InvoiceLayout>
  );
}

export default SalesInvoice;
