/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { flushSync } from "react-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid, FormGroup } from "@mui/material";
import { Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VirtualizedAutocomplete from "app/components/VirtualizedAutocomplete";
import TextField from "@mui/material/TextField";
import CustomDatePicker from "components/CustomDatePicker";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
//import { styled } from "@mui/material/styles";

import swal from "sweetalert";
// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import DataTable2 from "app/components/shopperTable";

import IP from "app/IP";
import dayjs from "dayjs";
import { item } from "examples/Sidenav/styles/sidenavItem";

const axios = require("axios");

function Contracts() {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
  const [validFrom, setValidFrom] = useState(null);
  const [validTo, setValidTo] = useState(null);
  const [fromContract, setFromContract] = useState(null);
  const [toContract, setToContract] = useState(null);
  const [contractIdList, setContractIdList] = useState([]);
  const [download, setDownload] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openReportMenu, setOpenReportMenu] = useState(false);
  const [familyName, setFamilyName] = useState("");
  const [familyCode, setFamilyCode] = useState("");
  const [familyUPC, setFamilyUPC] = useState("");
  const [familyDescription, setFamilyDescription] = useState("");
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [selectedRelatedProduct, setSelectedRelatedProduct] = useState("");
  const isAllSelectedProducts =
    relatedProductList.length > 0 && setSelectedRelatedProduct.length === relatedProductList.length;
  const [index, setIndex] = useState(0);
  const [productData, setProductData] = useState({ columns: [], rows: [] });
  const [productDataPdf, setProductDataPdf] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [contractDataPdf, setContractDataPdf] = useState([]);
  const [relatedProductData, setRelatedProductData] = useState({ columns: [], rows: [] });
  const [productPrice, setProductPrice] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({
    productId: "",
    code: "",
    upc: "",
    name: "",
  });
  const [requirementsList, setRequirementsList] = useState([
    // { label: "sales price", value: 1 },
    // { label: "tax", value: 2 },
    // { label: "vendor", value: 3 },
    { label: "empaque", value: 1 },
    { label: "Tamaño", value: 3, disabled: true },
    { label: "Pesado", value: 6, disabled: true },
    { label: "Etiqueta ???", value: 7, disabled: true },
    { label: "Tarjeta", value: 8, disabled: true },
    { label: "Departamento", value: 9 }, //department is actually subcategory in our version of the database
    { label: "Suplidor", value: 10 },
    { label: "Costo Caja", value: 11, disabled: true },
    { label: "Precio Caja", value: 12, disabled: true },
    { label: "Costo Unidad", value: 13, disabled: true },
    { label: "Precio Unidad", value: 14, disabled: true },
    { label: "Especiales", value: 15, disabled: true },
    { label: "Impuestos", value: 16, disabled: true },
    { label: "Ofertas", value: 17, disabled: true },
    { label: "WIC", value: 19, disabled: true },
  ]); //Options for list of checkbox requirements

  //Print Checkboxes
  const [preliminaryGrid, setPreliminaryGrid] = useState(false);
  const [approvedGrid, setApprovedGrid] = useState(false);
  const [preWarehouseGrid, setPreWarehouseGrid] = useState(false);
  const [appWarehouseGrid, setAppWarehouseGrid] = useState(false);
  const [specialsGrid, setSpecialsGrid] = useState(false);
  const [publicityContract, setPublicityContract] = useState(false);
  const [publicityInvoice, setPublicityInvoice] = useState(false);
  const [clarifyNotes, setClarifyNotes] = useState(false);
  const [takingOrders, setTakingOrders] = useState(false);
  const [publicityAgency, setPublicityAgency] = useState(false);
  //end of Checkboxes

  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const isAllSelected =
    requirementsList.length > 0 && selectedRequirements.length === requirementsList.length;
  const [menu, setMenu] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  //const [paymentData, setPaymentData] = useState({ columns: [], rows: [] });
  const [date, setDate] = useState(currentDate);
  const [errors, setErrors] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [monto, setMonto] = useState(0.0);
  const [totalPay, setTotalPay] = useState(0.0);
  const [received, setReceived] = useState(0.0);
  const [remainder, setRemainder] = useState(0.0);
  const [invoices, setInvoices] = useState([]);
  const [editInvoice, setEditInvoice] = useState(0);
  const [start, SetStart] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("CHECK");
  const [transactionNumber, setTransactionNumber] = useState("");
  // const [paginateData, setPaginateData] = useState({
  //   columns: [
  //     { Header: "Invoice Number.", accessor: "invoice_number" },
  //     { Header: "Store", accessor: "store_name" },
  //     { Header: "Pending", accessor: "total" },
  //     { Header: "Payment Amount", accessor: "amount" },
  //     { Header: "Actions", accessor: "actions" },
  //   ],
  //   rows: [],
  // });
  const [editFormData, setEditFormData] = useState({
    fullName: "",
    address: "",
    phoneNumber: "",
    email: "",
  });

  const [pdfTableData, setPdfTableData] = useState([
    [
      "____",
      "____",
      "7462275402232",
      "Quaker Crema de Maíz",
      "24/11.99/onz",
      "$3.49",
      "$2.69",
      "$56.29",
      "$1.00",
      "$0.00",
      "0/0",
      "$55.29",
      "$2.30",
      "14.50%",
      "false",
    ],
    [
      "_____",
      "_____",
      "0003000007100",
      "Quaker Farina",
      "12/16/onz",
      "$3.29",
      "$2.69",
      "$28.32",
      "$1.00",
      "$0.00",
      "0/0",
      "$27.32",
      "$2.28",
      "15.24%",
      "true",
    ],
    [
      "_____",
      "_____",
      "0003000010573",
      "Quaker Farina Canela",
      "12/16/onz",
      "$3.29",
      "$2.69",
      "$28.32",
      "$1.00",
      "$0.00",
      "0/0",
      "$27.32",
      "$2.28",
      "15.24%",
      "true",
    ],
  ]);

  let timer;

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  // [
  //         "0000003598",
  //         dayjs().format("MM/DD/YYYY"),
  //         dayjs().format("MM/DD/YYYY"),
  //         dayjs().format("MM/DD/YYYY"),
  //         "",
  //         "",
  //         "",
  //       ],

  const createPublicityInvoiceReportPDF = (contractData) => {
    let pdf = new jsPDF();
    let totalPagesExp = "{total_pages_count_string}";

    console.log("Contract Data PDF:", contractData);

    var productDataFormatted = [
      ...productDataPdf.map((item) => [
        item.upc,
        item.description,
        item.pub_fa, // Aplicar Anuncio
        item.notas_aclara, // Aplicar Notas
        //item.pub_fa, // Cantidad
      ]),
      //[{ content: `Total = $${total}`, colSpan: 2, styles: { fillColor: [239, 154, 154] } }],
    ];

    jsPDF.autoTableSetDefaults({
      headStyles: { fillColor: [180, 180, 180], textColor: [0, 0, 0] },
      columnStyles: {
        1: { cellWidth: 70 },
        // etc
      },
    });

    contractData.map((contract) => {
      if (contract.contract_products.length > 0) {
        let totalPubFa = contract.contract_products.reduce(
          (sum, el) => (el.pub_fa ? sum + parseFloat(el.pub_fa) : 0),
          0
        );
        let totalNotasAclara = contract.contract_products.reduce(
          (sum, el) => (el.notas_aclara ? sum + parseFloat(el.notas_aclara) : 0),
          0
        );
        let contractProducts = contract.contract_products.map((item) => {
          return {
            upc: item.upc,
            desc: item.description,
            pubfa: item.pub_fa, // Aplicar Anuncio
            notas: item.notas_aclara, // Aplicar Notas
          };
        });

        contractProducts.push(
          [
            {
              content: "Total A Facturar Contrato: ",
              colSpan: 2,
              styles: {
                halign: "right",
                fontStyle: "bold",
              },
            },
            {
              content: totalPubFa.toFixed(2),
              styles: {
                fontStyle: "bold",
              },
            },
            {
              content: totalNotasAclara.toFixed(2),
              styles: {
                fontStyle: "bold",
              },
            },
          ],
          [
            {
              content: "Total A Facturar Suplidor: ",
              colSpan: 2,
              styles: {
                halign: "right",
                fontStyle: "bold",
              },
            },
            {
              content: totalPubFa.toFixed(2),
              styles: {
                fontStyle: "bold",
              },
            },
            {
              content: totalNotasAclara.toFixed(2),
              styles: {
                fontStyle: "bold",
              },
            },
          ],
          [
            {
              content: "Total A Facturar: ",
              colSpan: 2,
              styles: {
                halign: "right",
                fontStyle: "bold",
              },
            },
            {
              content: totalPubFa.toFixed(2),
              styles: {
                fontStyle: "bold",
              },
            },
            {
              content: totalNotasAclara.toFixed(2),
              styles: {
                fontStyle: "bold",
              },
            },
          ]
        );

        pdf.autoTable({
          theme: "plain",
          styles: {
            font: "times",
          },
          tableWidth: "auto",
          columnWidth: "wrap",
          pageBreak: "avoid",
          rowPageBreak: "avoid",
          margin: { top: 30 },
          //startY: pdf.autoTableEndPosY() + 10,
          //head: [["UPC", "Descripcion", "Aplicar Anuncio", "Aplicar Notas"]],
          head: [
            [
              {
                content: contract.vendor.vendor_id,
                colSpan: 1,
                styles: { fontStyle: "normal", fontSize: 8 },
              },
              {
                content: contract.vendor.name,
                colSpan: 3,
                styles: { fontStyle: "normal", fontSize: 8 },
              },
            ],
            [
              {
                content: "Contrato: 0000003598",
                colSpan: 1,
                styles: { fontStyle: "normal", fontSize: 8 },
              },
              {
                content: "Fecha: " + dayjs(contract.contract_date).format("MM/DD/YYYY"),
                colSpan: 1,
                styles: { fontStyle: "normal", fontSize: 8 },
              },
              {
                content: "De: " + contract.from_date,
                colSpan: 1,
                styles: { fontStyle: "normal", fontSize: 8 },
              },
              {
                content: "A: " + contract.to_date,
                colSpan: 1,
                styles: { fontStyle: "normal", fontSize: 8 },
              },
            ],
            [
              {
                content: "UPC",
              },
              {
                content: "Description",
              },
              {
                content: "Aplicar Anuncio",
              },
              {
                content: "Aplicar Notas",
              },
            ],
          ],
          columns: [
            { header: "UPC", dataKey: "upc" },
            { header: "Description", dataKey: "desc" },
            { header: "Aplicar Anuncio", dataKey: "pubfa" },
            { header: "Aplicar Notas", dataKey: "notas" },
          ],
          body: contractProducts, //setting this as a variable so that the extra rows can be pushed after mapping
          // body: [
          //   ["0098871321065", "Betis Aceite de Oliva Puro (botella)", "300.00", "0.00", "300.00"],
          //   ["0075366004849", "Coloso Tentaculos Calamar 5/P Marine", "300.00", "0.00", "300.00"],
          //   ["0803275000696", "Honey Monster Marshmallow Fruty OH", "300.00", "0.00", "300.00"],
          //   ["0027000419045", "Hunt's Chocolate Pudding Sugar Free", "300.00", "0.00", "300.00"],
          //   ["7441007800928", "Pro Pet Cachorro Comida para Perros", "300.00", "0.00", "300.00"],
          //   ["0003000007100", "Quaker Farina", "300.00", "0.00", "300.00"],
          //   ["", "", "1,800.00", "0.00", "1,800.00"],
          //   ["", "", "1,800.00", "0.00", "1,800.00"],
          //   ["", "", "1,800.00", "0.00", "1,800.00"],
          //   // ...
          // ],
          willDrawPage: function (data) {
            // Header
            pdf.setFontSize(10).setTextColor(40).setFont("times");
            pdf.text("Fecha: " + dayjs().format("MM/DD/YYYY"), 15, 10);
            pdf.setFontSize(10).setTextColor(40);
            pdf.text("Supermercados Agranel, INC", 80, 15);
            pdf.setFontSize(15).setTextColor(40);
            pdf.text("Listado De Publicidad A Facturar", 15, 25);
          },
          didDrawPage: function (data) {
            // Footer
            let str = "Page " + pdf.internal.getNumberOfPages();
            // Total page number plugin only available in jspdf v1.0+
            if (typeof pdf.putTotalPages === "function") {
              str = str + " of " + totalPagesExp;
            }
            pdf.setFontSize(10);

            // jsPDF 1.4+ uses getHeight, <1.4 uses .height
            let pageSize = pdf.internal.pageSize;
            let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            pdf.text(str, data.settings.margin.left, pageHeight - 10);
          },
        });
      } //end of if validation
    }); //end of map

    // Total page number plugin only available in jspdf v1.0+
    if (typeof pdf.putTotalPages === "function") {
      pdf.putTotalPages(totalPagesExp);
    }

    //pdf.save("listado-publicidad.pdf"); //download pdf
    pdf.output("dataurlnewwindow"); //open pdf preview in new window
    setDownload(true);
  };

  //Create the pdf of the grid(cuadriculado) report.
  const createGridReportPreliminaryPDF = (contractData) => {
    setDownload(false);
    console.log("Generate PDF trigger!");
    //const pdf = new jsPDF("landscape", "px", "a0"); //px
    let fromDate = validFrom ? dayjs(validFrom).format("MM/DD/YYYY") : "--/--/----";
    let toDate = validTo ? dayjs(validTo).format("MM/DD/YYYY") : "--/--/----";

    let pdf = new jsPDF("landscape");
    let totalPagesExp = "{total_pages_count_string}";

    pdf.setFontSize(10).setTextColor(40);
    pdf.text("Fecha: " + dayjs().format("MM/DD/YYYY"), 5, 10);
    pdf.setFontSize(10).setTextColor(40);
    pdf.text("Supermercados Agranel, INC", 120, 15);
    pdf.setFontSize(15).setTextColor(40).setFont("times");
    pdf.text("Cuadriculado Preliminar Para Especial Del " + fromDate + " Al " + toDate, 70, 25);

    let dataIndex;
    let pdfTableIndex = [];

    console.log("Contract Data before map:", contractData);

    let productDataFormatted = productDataPdf.map((item) => {
      return {
        ord1: "___",
        ord2: "___",
        upc: item.upc,
        desc: item.description,
        emp: item.product.packaging,
        reg: item.pru, // Regular price field
        esp: item.peu, // Special price field
        cost: "$" + item.cost, // Cost field
        off: item.product.offers[0] ? item.product.offers[0].offer : "0.00", // Offer field
        bb: "0.00", // B/B field
        cg: item.product.offers[0]
          ? item.product.offers[0].c + " / " + item.product.offers[0].g
          : "0 / 0", // C/G field
        lc: item.ce, // L/C field
        lu: item.ceu, // L/U field
        //item.offer[0].percent,
        percent: item.percent_r2, // % field
        extra: item.extra,
        approve: item.approved,
      };
    });

    jsPDF.autoTableSetDefaults({
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      bodyStyles: {
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
    });

    pdf.autoTable({ startY: pdf.autoTableEndPosY() + 30 });

    console.log("Contract Data PDF:", contractData);

    //let pdfTables = contractData.map((contract) => {
    contractData.map((contract) => {
      if (contract.contract_products.length > 0) {
        //for (let j = 1; j < productDataFormatted.length; j++) {
        //let pageNumber = pdf.internal.getNumberOfPages();
        //console.log("Contract map data:", contract);

        pdf.autoTable({
          theme: "grid",
          tableWidth: "auto",
          columnWidth: "wrap",
          //startY: pdf.autoTableEndPosY() + 15,
          startY: pdf.autoTableEndPosY(),
          pageBreak: "avoid",
          styles: {
            font: "times",
          },
          // head: [
          //   [
          //     {
          //       content: "",
          //       colSpan: 1,
          //     },
          //     {
          //       content: "",
          //       colSpan: 1,
          //     },
          //     {
          //       content: "Ordenado Por: " + contractData.vendor.name,
          //       colSpan: 1,
          //       styles: { halign: "center", fillColor: [22, 160, 133] },
          //     },
          //     {
          //       content: "Entregado Por: " + contractData.vendor.name,
          //       colSpan: 2,
          //       styles: { halign: "center", fillColor: [22, 160, 133] },
          //     },
          //   ],
          //   [
          //     "Ord.1",
          //     "Ord.2",
          //     "Upc",
          //     "Descripción",
          //     "Empaque",
          //     "Regular",
          //     "Especial",
          //     "Costo",
          //     "Off",
          //     "B/B",
          //     "C/G",
          //     "L/C",
          //     "L/U",
          //     "%",
          //   ],
          //body: productDataFormatted,
          head: [
            [
              {
                content: "Ordenado Por: " + contract.vendor.name,
                colSpan: 3,
                styles: { fontStyle: "normal", fontSize: 8 },
                // styles: { halign: "center", fillColor: [22, 160, 133] },
              },
              {
                content: "Entregado Por: " + contract.vendor.name,
                colSpan: 1,
                styles: { fontStyle: "normal", fontSize: 8 },
              },
              {
                content: "Facturado Por: " + contract.vendor.name,
                colSpan: 10,
                styles: { fontStyle: "normal", fontSize: 8 },
              },
              // {
              //   content: "Facturado Por: " + contract.vendor.name,
              //   colSpan: 5,
              //   styles: { fontStyle: "normal", fontSize: 8 },
              // },
            ],
            [
              {
                content: "Contrato: " + contract.contract_id,
                colSpan: 3,
                styles: { fontStyle: "normal", fontSize: 8 },
              },
              {
                content: "Contacto: " + contract.contact,
                colSpan: 1,
                styles: { fontStyle: "normal", fontSize: 8 },
                // styles: { halign: "center", fillColor: [22, 160, 133] },
              },
              {
                content: "Telefono: ",
                colSpan: 10,
                styles: { fontStyle: "normal", fontSize: 8 },
                // styles: { halign: "center", fillColor: [22, 160, 133] },
              },
            ],
            [
              {
                content: "Ord.1",
              },
              {
                content: "Ord.2",
              },
              {
                content: "Upc",
              },
              {
                content: "Descripción",
              },
              {
                content: "Empaque",
              },
              {
                content: "Regular",
              },
              {
                content: "Especial",
              },
              {
                content: "Costo",
              },
              {
                content: "Off",
              },
              {
                content: "B/B",
              },
              {
                content: "C/G",
              },
              {
                content: "L/C",
              },
              {
                content: "L/U",
              },
              {
                content: "%",
              },
            ],
          ],
          columns: contract.contract_products
            ? [
                {
                  dataKey: "ord1",
                  header: "Ord.1",
                },
                {
                  dataKey: "ord2",
                  header: "Ord.2",
                },
                {
                  dataKey: "upc",
                  header: "Upc",
                },
                {
                  dataKey: "desc",
                  header: "Descripción",
                },
                {
                  dataKey: "emp",
                  header: "Empaque",
                },
                {
                  dataKey: "reg",
                  header: "Regular",
                },
                {
                  dataKey: "esp",
                  header: "Especial",
                },
                {
                  dataKey: "cost",
                  header: "Costo",
                },
                {
                  dataKey: "off",
                  header: "Off",
                },
                {
                  dataKey: "bb",
                  header: "B/B",
                },
                {
                  dataKey: "cg",
                  header: "C/G",
                },
                {
                  dataKey: "lc",
                  header: "L/C",
                },
                {
                  dataKey: "lu",
                  header: "L/U",
                },
                {
                  dataKey: "percent",
                  header: "%",
                },
              ]
            : [],
          body: contract.contract_products.map((item) => {
            return {
              ord1: "___",
              ord2: "___",
              upc: item.upc,
              desc: item.description,
              emp: item.product.packaging,
              reg: item.pru, // Regular price field
              esp: item.peu, // Special price field
              cost: "$" + item.cost, // Cost field
              off: item.product.offers[0] ? item.product.offers[0].offer : "0.00", // Offer field
              bb: "0.00", // B/B field
              cg: item.product.offers[0]
                ? item.product.offers[0].c + " / " + item.product.offers[0].g
                : "0 / 0", // C/G field
              lc: item.ce, // L/C field
              lu: item.ceu, // L/U field
              //item.offer[0].percent,
              percent: item.percent_r2, // % field
              extra: item.extra,
              approve: item.approved,
            };
          }),
          // willDrawPage: function (data) {
          //   // Header
          // },
          willDrawCell: function (data) {
            //console.log("Data from WillDrawCell:", data);
            for (let i = 0; i < contractData.length; i++) {
              if (data.row.raw.extra == 1) {
                pdf.setFillColor(180, 180, 180);
              }
              if (data.row.raw.approve == 1) {
                pdf.setFillColor(180, 180, 180);
              }
            } //end of loop
          },
          didDrawPage: function (data) {
            // Footer
            let str = "Page " + pdf.internal.getNumberOfPages();
            // Total page number plugin only available in jspdf v1.0+
            if (typeof pdf.putTotalPages === "function") {
              str = str + " of " + totalPagesExp;
            }
            pdf.setFontSize(10);

            // jsPDF 1.4+ uses getHeight, <1.4 uses .height
            let pageSize = pdf.internal.pageSize;
            let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            pdf.text(str, data.settings.margin.left, pageHeight - 10);
          },
        });
      } //end of if validation
    }); //end of map

    // Total page number plugin only available in jspdf v1.0+
    if (typeof pdf.putTotalPages === "function") {
      pdf.putTotalPages(totalPagesExp);
    }

    //pdf.save("cuadriculado-preliminar.pdf"); //download pdf
    pdf.output("dataurlnewwindow"); //open pdf preview in new window
    setDownload(true);
  };

  //Create the pdf of the grid(cuadriculado) report.
  const createConfirmationOfSpecials = (contractData) => {
    setDownload(false);
    console.log("Generate PDF trigger!");
    //const pdf = new jsPDF("landscape", "px", "a0"); //px
    let fromDate = validFrom ? dayjs(validFrom).format("MM/DD/YYYY") : "--/--/----";
    let toDate = validTo ? dayjs(validTo).format("MM/DD/YYYY") : "--/--/----";

    let pdf = new jsPDF();
    let totalPagesExp = "{total_pages_count_string}";

    let pageSize = pdf.internal.pageSize;
    let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    let paragraph =
      "Si el suplidor firmante de este contrato, por la razón que fuera, incumple el mismo al no entregar parte o " +
      "todos los productos programados en especial en Supermercados Agranel, INC sin avisar de antemano a " +
      "la Oficina Central con (10) días calendarios de anticipación a la fecha del especial, Supermercados " +
      "Agranel, INC se reserva el derecho de substituir el o los productos faltantes por otros similares y " +
      "penalizará al mismo. (Ver Contrato)";

    let dataIndex;
    let pdfTableIndex = [];
    let paragraphFormat = pdf.splitTextToSize(paragraph, pageWidth, {});

    let productDataFormatted = productDataPdf.map((item) => [
      item.upc,
      item.description,
      item.product.packaging,
      "$" + item.cost,
      item.product.offers[0] ? item.product.offers[0].offer : "0.00",
      "0.00", //billback
      item.product.offers[0] ? item.product.offers[0].c + "/" + item.product.offers[0].g : "0/0",
      "0",
    ]);

    jsPDF.autoTableSetDefaults({
      headStyles: {
        headStyles: { textColor: [0, 0, 0], lineColor: [180, 180, 180], lineWidth: 0.5 },
      },
      bodyStyles: {
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        //lineWidth: 0.1,
      },
    });

    contractData.map((contract) => {
      if (contract.contract_products.length > 0) {
        pdf.autoTable({
          theme: "plain",
          styles: {
            font: "times",
          },
          tableWidth: "auto",
          columnWidth: "wrap",
          pageBreak: "always",
          rowPageBreak: "avoid",
          margin: { top: 90 },
          //head: [["UPC", "Producto", "Empaque", "Costo", "OFF", "B/B", "C/G", "O/A"]],
          columns: contract.contract_products
            ? [
                {
                  dataKey: "upc",
                  header: "Upc",
                },
                {
                  dataKey: "desc",
                  header: "Producto",
                },
                {
                  dataKey: "emp",
                  header: "Empaque",
                },
                {
                  dataKey: "cost",
                  header: "Costo",
                },
                {
                  dataKey: "off",
                  header: "Off",
                },
                {
                  dataKey: "bb",
                  header: "B/B",
                },
                {
                  dataKey: "cg",
                  header: "C/G",
                },
                {
                  dataKey: "oa",
                  header: "O/A",
                },
              ]
            : [],
          body: contract.contract_products.map((item) => {
            return {
              upc: item.upc,
              desc: item.description,
              emp: item.product.packaging,
              cost: "$" + item.cost,
              off: item.product.offers[0] ? item.product.offers[0].offer : "0.00",
              bb: "0.00", //billback
              cg: item.product.offers[0]
                ? item.product.offers[0].c + "/" + item.product.offers[0].g
                : "0/0",
              oa: "0",
            };
          }),
          // body: [
          //   [
          //     "0098871012093",
          //     "Betis Aceite de Oliva Extra Light (botella)",
          //     "12/17",
          //     "$116.55",
          //     "$16.00",
          //   ],
          //   [
          //     "0098871014042",
          //     "Betis Aceite de Oliva Extra Virgin Botella",
          //     "12/17",
          //     "$121.70",
          //     "$16.20",
          //   ],
          //   ["0098871321065", "Betis Aceite de Oliva Puro (botella)", "12/17", "$116.55", "$16.00"],
          //   [
          //     "0803275305951",
          //     "Honey Monster Marshmallow Caribbean Treasure",
          //     "32/10",
          //     "$70.20",
          //     "$2.65",
          //   ],
          //   ["0803275000689", "Honey Monster Marshmallow Choco Blast", "32/10", "$70.20", "$2.65"],
          //   ["0803275000696", "Honey Monster Marshmallow Fruty OH", "32/10", "$70.20", "$2.65"],
          //   ["00207000419045", "Hunt's Chocolate Pudding Sugar Free", "12 / 4/3.25", "$27.10", "$2.54"],
          //   ["00207000415423", "Hunt's Snack Pack Pudding Chocolate", "12 / 4/3.25", "$27.10", "$2.54"],
          //   [
          //     "2002700041920",
          //     "Hunt's Snack Pack Cherry Gel Sugar Free",
          //     "12 / 4/3.25",
          //     "$27.10",
          //     "$2.54",
          //   ],
          // ],
          willDrawPage: function (data) {
            // Header
            pdf.setFontSize(12).setTextColor(40).setFont("times", "bold");
            pdf.text("Supermercados Agranel, INC", 15, 10);
            pdf.setFontSize(10).setTextColor(40).setFont("times", "normal");
            pdf.text("Calle Union NO. 400 APT 518", 15, 15);
            pdf.text("No. de Contrato: 0000003593", 150, 10);
            pdf.text(
              "Fecha de Contrato: " + dayjs(contract.contract_date).format("MM/DD/YYYY"),
              150,
              15
            );
            pdf.setFontSize(10).setTextColor(40).setFont("times", "normal");
            pdf.text("Suplidor: " + contract.vendor.name, 15, 25);
            pdf.text("Contacto: " + contract.contact, 15, 30);
            pdf.text("P.O. BOX 364548", 15, 35);
            pdf.setFontSize(9).setTextColor(40);
            pdf.text("SAN JUAN, PR 00936-4548", 15, 55);
            pdf.text("Tel: 787-461-4140", 15, 60);
            pdf.text("Fax:", 50, 60);
            pdf.text("Comprador: " + contract.buyer, 15, 65);
            pdf.setFontSize(12).setTextColor(40).setFont("times", "bold");
            pdf.text("ASUNTO: Confirmación de productos Aprobados", 15, 75);
            pdf.setFontSize(12).setTextColor(40).setFont("times", "bold");
            pdf.text("Especiales Válidos del " + fromDate + " al " + toDate, 65, 85);
            //Footer
            pdf.setFontSize(12).setTextColor(40).setFont("times", "normal");
            pdf.text(paragraphFormat, 15, 200);
            pdf.setFontSize(10).setTextColor(40);
            pdf.text("_______________________________________________", 15, 255);
            pdf.text("FIRMA DEL COMPRADOR", 35, 260);
            pdf.setFontSize(10).setTextColor(40);
            pdf.text("_______________________________________________", 110, 255);
            pdf.text("FIRMA DEL SUPLIDOR", 130, 260);
            pdf.text("________________________________", 123, 275);
            pdf.text("FECHA", 145, 280);
          },
          didDrawPage: function (data) {
            // Footer
            let str = "Page " + (pdf.internal.getNumberOfPages() - 1); //we subtract 1 to the current pages since the first page is being deleted (because it's blank)
            // Total page number plugin only available in jspdf v1.0+
            if (typeof pdf.putTotalPages === "function") {
              str = str + " of " + totalPagesExp;
            }
            pdf.setFontSize(10);

            // jsPDF 1.4+ uses getHeight, <1.4 uses .height
            let pageSize = pdf.internal.pageSize;
            let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            pdf.text(str, data.settings.margin.left, pageHeight - 10);
          },
        });
      } //end of if validation
    }); //end of map

    pdf.deletePage(1); //delete page is here because when using pageBreak: always it creates a blank page at the start so we delete it.

    // Total page number plugin only available in jspdf v1.0+
    if (typeof pdf.putTotalPages === "function") {
      pdf.putTotalPages(totalPagesExp);
    }

    console.log("PDF table array length:", pdfTableData.length);
    console.log("Data row index:", dataIndex);
    console.log("PDF table index:", pdfTableIndex);
    //pdf.save("cuadriculado-preliminar.pdf"); //download pdf
    pdf.output("dataurlnewwindow"); //open pdf preview in new window
    setDownload(true);
  };

  //Create the pdf of the grid(cuadriculado) report.
  const createPublicityContractPDF = (contractData) => {
    setDownload(false);
    console.log("Generate PDF trigger!");
    console.log("ValidFromDate:", validFrom);
    console.log("ValidToDate:", validTo);
    //const pdf = new jsPDF("landscape", "px", "a0"); //px
    let fromDate = validFrom ? dayjs(validFrom).format("MM/DD/YYYY") : "--/--/----";
    let toDate = validTo ? dayjs(validTo).format("MM/DD/YYYY") : "--/--/----";

    let pdf = new jsPDF();
    let totalPagesExp = "{total_pages_count_string}";

    let pageSize = pdf.internal.pageSize;
    let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    let paragraph =
      "El Suplidor aportara por concepto de anuncio cooperativo la cantidad de $------, -.-- por caja y/o en todo lo comprado." +
      "Esta aportacion sera pagada en su totalidad en o antes de 30 dias, a partir de la publicidad y presentacion de prueba." +
      "A los 30 dias, de no recibir el pago, Supermercados Agranel, INC lo descontara de su remesa o de facturas pendientes de cualquiera de sus asociades." +
      "Cualquier cambio de precios u ofertas de lo estipulado en este contrato debera ser notificado personalmente con 15 dias de antelacion." +
      "Supermercados Agranel, INC no garantiza la compra de cantidad especifica de cajas para el pago de dicha publicidad cooperativa, a menos que este contrato lo indique." +
      "En todas las aportaciones por cajas, la compra se considerara desde una semana antes del especial y una semana despues del mismo." +
      "En caso de que el suplidor no cumpla con las entregas a tiendas por falta de intevtario en los productos aprobados, Supermercados Agranel, INC le duplicara la aportacion especificada por product como penalidad.";

    let dataIndex;
    let pdfTableIndex = [];
    let paragraphFormat = pdf.splitTextToSize(paragraph, pageWidth, {});

    jsPDF.autoTableSetDefaults({
      headStyles: {
        headStyles: { textColor: [0, 0, 0], lineColor: [180, 180, 180], lineWidth: 0.5 },
      },
      bodyStyles: {
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        //lineWidth: 0.1,
      },
    });

    contractData.map((contract) => {
      if (contract.contract_products.length > 0) {
        pdf.autoTable({
          theme: "plain",
          styles: {
            font: "times",
          },
          tableWidth: "auto",
          columnWidth: "wrap",
          pageBreak: "always",
          rowPageBreak: "avoid",
          margin: { top: 90 },
          //head: [["UPC", "Descripción", "Empaque", "Costo", "OFF", "B/B", "C/G", "O/A"]],
          columns: [
            { header: "UPC", dataKey: "upc" },
            { header: "Descripción", dataKey: "desc" },
            { header: "Empaque", dataKey: "emp" },
            { header: "Costo", dataKey: "cost" },
            { header: "OFF", dataKey: "off" },
            { header: "B/B", dataKey: "bb" },
            { header: "C/G", dataKey: "cg" },
            { header: "O/A", dataKey: "oa" },
          ],
          body: contract.contract_products.map((item) => {
            return {
              upc: item.upc,
              desc: item.description,
              emp: item.product.packaging,
              cost: "$" + item.cost,
              off: item.product.offers[0] ? item.product.offers[0].offer : "0.00",
              bb: "0.00", //billback
              cg: item.product.offers[0]
                ? item.product.offers[0].c + "/" + item.product.offers[0].g
                : "0/0",
              oa: "0",
            };
          }),
          // body: [
          //   [
          //     "0098871012093",
          //     "Betis Aceite de Oliva Extra Light (botella)",
          //     "12/17",
          //     "$116.55",
          //     "$16.00",
          //   ],
          //   [
          //     "0098871014042",
          //     "Betis Aceite de Oliva Extra Virgin Botella",
          //     "12/17",
          //     "$121.70",
          //     "$16.20",
          //   ],
          //   ["0098871321065", "Betis Aceite de Oliva Puro (botella)", "12/17", "$116.55", "$16.00"],
          //   [
          //     "0803275305951",
          //     "Honey Monster Marshmallow Caribbean Treasure",
          //     "32/10",
          //     "$70.20",
          //     "$2.65",
          //   ],
          //   ["0803275000689", "Honey Monster Marshmallow Choco Blast", "32/10", "$70.20", "$2.65"],
          //   ["0803275000696", "Honey Monster Marshmallow Fruty OH", "32/10", "$70.20", "$2.65"],
          //   ["00207000419045", "Hunt's Chocolate Pudding Sugar Free", "12 / 4/3.25", "$27.10", "$2.54"],
          //   ["00207000415423", "Hunt's Snack Pack Pudding Chocolate", "12 / 4/3.25", "$27.10", "$2.54"],
          //   [
          //     "2002700041920",
          //     "Hunt's Snack Pack Cherry Gel Sugar Free",
          //     "12 / 4/3.25",
          //     "$27.10",
          //     "$2.54",
          //   ],
          // ],
          //html: "#grid-table",
          // styles: {
          //   lineColor: [44, 62, 80],
          //   lineWidth: 1,
          // },
          willDrawPage: function (data) {
            // Header
            pdf.setFontSize(12).setTextColor(40).setFont("times", "bold");
            pdf.text("Supermercados Agranel, INC", 15, 10);
            pdf.setFontSize(10).setTextColor(40).setFont("times", "normal");
            pdf.text("Calle Union NO. 400 APT 518", 15, 15);
            pdf.text("No. de Contrato: 0000003593", 150, 10);
            pdf.text(
              "Fecha de Contrato: " + dayjs(contract.contract_date).format("MM/DD/YYYY"),
              150,
              15
            );
            // pdf.setFontSize(10).setTextColor(40);
            // pdf.text("Supermercados Agranel, INC", 120, 15);
            pdf.setFontSize(18).setTextColor(40).setFont("times");
            pdf.text("Contrato De Publicidad Para Especiales", 55, 23);
            pdf.setFontSize(12).setTextColor(40).setFont("times", "bold");
            pdf.text("Válido desde: " + fromDate + " hasta: " + toDate, 65, 28);
            pdf.setFontSize(10).setTextColor(40).setFont("times", "normal");
            pdf.text("Suplidor: " + contract.vendor.name, 15, 45);
            pdf.text("Contacto: " + contract.contact, 15, 50);
            pdf.text("P.O. BOX 364548", 15, 55);
            pdf.setFontSize(9).setTextColor(40);
            pdf.text("SAN JUAN, PR 00936-4548", 15, 75);
            pdf.text("Tel: 787-461-4140", 15, 80);
            pdf.text("Fax:", 50, 80);
            pdf.text("Comprador: " + contract.buyer, 15, 85);
            pdf.text("Término de pago:", 125, 60);
            pdf.text("Neto 30 dias", 165, 60);
            pdf.text("Ordenado a:", 125, 65);
            //pdf.text("Ballester Hermanos", 165, 65);
            pdf.text(contract.vendor.name, 165, 65);
            pdf.text("Facturado por:", 125, 70);
            pdf.text(contract.vendor.name, 165, 70);
            pdf.text("Entregado por:", 125, 75);
            pdf.text(contract.vendor.name, 165, 75);
            pdf.text("Fecha estimada de entrega:", 125, 80);
            pdf.setFontSize(12).setTextColor(40);
            pdf.text(paragraphFormat, 15, 180);
            pdf.setFontSize(10).setTextColor(40);
            pdf.text("_______________________________________________", 15, 255);
            pdf.text("FIRMA DEL COMPRADOR", 35, 260);
            pdf.setFontSize(10).setTextColor(40);
            pdf.text("_______________________________________________", 110, 255);
            pdf.text("FIRMA DEL SUPLIDOR", 130, 260);
            pdf.text("________________________________", 123, 275);
            pdf.text("FECHA", 145, 280);
          },
          willDrawCell: function (data) {
            // for (let i = 0; i < pdfTableData.length; i++) {
            //   if (data.row.raw.extra.includes("true")) {
            //     pdf.setFillColor(180, 180, 180);
            //   }
            // } //end of loop
          },
          didDrawPage: function (data) {
            // Footer
            let str = "Page " + (pdf.internal.getNumberOfPages() - 1); //we subtract 1 to the current pages since the first page is being deleted (because it's blank)
            // Total page number plugin only available in jspdf v1.0+
            if (typeof pdf.putTotalPages === "function") {
              str = str + " of " + totalPagesExp;
            }
            pdf.setFontSize(10);

            // jsPDF 1.4+ uses getHeight, <1.4 uses .height
            let pageSize = pdf.internal.pageSize;
            let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            pdf.text(str, data.settings.margin.left, pageHeight - 10);
          },
        });
      } //end of if validation
    }); //end of map

    pdf.deletePage(1); //delete page is here because when using pageBreak: always it creates a blank page at the start so we delete it.

    // Total page number plugin only available in jspdf v1.0+
    if (typeof pdf.putTotalPages === "function") {
      pdf.putTotalPages(totalPagesExp);
    }

    console.log("PDF table array length:", pdfTableData.length);
    console.log("Data row index:", dataIndex);
    console.log("PDF table index:", pdfTableIndex);
    //pdf.save("cuadriculado-preliminar.pdf"); //download pdf
    pdf.output("dataurlnewwindow"); //open pdf preview in new window
    setDownload(true);
  };

  //Create the pdf of the grid(cuadriculado) report.
  const createAgencyPublicityPDF = (contractData) => {
    setDownload(false);
    console.log("Generate PDF trigger!");
    console.log("ValidFromDate:", validFrom);
    console.log("ValidToDate:", validTo);

    let fromDate = validFrom ? dayjs(validFrom).format("MM/DD/YYYY") : "--/--/----";

    let toDate = validTo ? dayjs(validTo).format("MM/DD/YYYY") : "--/--/----";

    //const pdf = new jsPDF("landscape", "px", "a0"); //px
    let pdf = new jsPDF();
    let totalPagesExp = "{total_pages_count_string}";

    let dataIndex;
    let pdfTableIndex = [];

    let productDataFormatted = productDataPdf.map((item) => [
      item.description,
      item.product.packaging,
      item.pru, // Regular price field
      item.peu, // Special price field
      item.extra, // colors row of record if set to 1 (true)
    ]);

    jsPDF.autoTableSetDefaults({
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      bodyStyles: {
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
    });

    //pdf.autoTable({ startY: pdf.autoTableEndPosY() + 30 });

    contractData.map((contract) => {
      if (contract.contract_products.length > 0) {
        pdf.autoTable({
          theme: "grid",
          styles: {
            font: "times",
          },
          tableWidth: "auto",
          columnWidth: "wrap",
          pageBreak: "avoid",
          rowPageBreak: "avoid",
          margin: { top: 30 },
          //head: [["Descripción" , "Empaque", "Precio Regular", "Precio Especial"]],
          columns: [
            { header: "Descripción", dataKey: "desc" },
            { header: "Empaque", dataKey: "emp" },
            { header: "Precio Regular", dataKey: "pru" },
            { header: "Precio Especial", dataKey: "peu" },
          ],
          body: contract.contract_products.map((item) => {
            return {
              desc: item.description,
              emp: item.product.packaging,
              pru: item.pru, // Regular price field
              peu: item.peu, // Special price field
              extra: item.extra, // colors row of record if set to 1 (true)
            };
          }),
          //html: "#grid-table",
          // styles: {
          //   lineColor: [44, 62, 80],
          //   lineWidth: 1,
          // },
          willDrawPage: function (data) {
            // Header
            pdf.setFontSize(10).setTextColor(40);
            pdf.text("Fecha: " + dayjs().format("MM/DD/YYYY"), 5, 10);
            pdf.setFontSize(10).setTextColor(40);
            pdf.text("Supermercados Agranel, INC", 80, 15);
            pdf.setFontSize(15).setTextColor(40).setFont("times", "bold");
            pdf.text("Especiales Válidos del " + fromDate + " al " + toDate, 50, 25);
          },
          willDrawCell: function (data) {
            for (let i = 0; i < pdfTableData.length; i++) {
              if (data.row.raw.extra == 1) {
                pdf.setFillColor(180, 180, 180);
              }
            } //end of loop
          },
          didDrawPage: function (data) {
            // Footer
            let str = "Page " + pdf.internal.getNumberOfPages();
            // Total page number plugin only available in jspdf v1.0+
            if (typeof pdf.putTotalPages === "function") {
              str = str + " of " + totalPagesExp;
            }
            pdf.setFontSize(10);

            // jsPDF 1.4+ uses getHeight, <1.4 uses .height
            let pageSize = pdf.internal.pageSize;
            let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            pdf.text(str, data.settings.margin.left, pageHeight - 10);
          },
        });
      } //end of if validation
    }); //end of map

    // Total page number plugin only available in jspdf v1.0+
    if (typeof pdf.putTotalPages === "function") {
      pdf.putTotalPages(totalPagesExp);
    }

    console.log("PDF table array length:", pdfTableData.length);
    console.log("Data row index:", dataIndex);
    console.log("PDF table index:", pdfTableIndex);
    //pdf.save("cuadriculado-preliminar.pdf"); //download pdf
    pdf.output("dataurlnewwindow"); //open pdf preview in new window
    setDownload(true);
  };

  //Get the Products that are related to the given fields of the base product selected
  async function getRelatedProducts(product_info) {
    console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/families/getRelatedProducts", product_info, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("list of products:", response);
        let productColumns = [
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
          { Header: language == 1 ? "Empaque" : "Packaging", accessor: "packaging" },
          // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = response.data.map((item) => {
          return {
            code: item.code,
            upcunit: item.upcUnit,
            upc: item.upc,
            name: item.name,
            packaging: item.packaging,
            cost: item.cost,
            price: item.sales_price,
            active:
              item.active == "Active"
                ? language == 1
                  ? "Activo"
                  : "Active"
                : language == 1
                ? "Inactivo"
                : "Inactive",
          };
        });

        setRelatedProductData({ columns: productColumns, rows: productRows });
      })
      .catch((error) => {
        console.log("product list error:", error);
      });
  }

  //Get list of Contracts by date for report
  async function filterContractsByDate(filters) {
    await axios
      .post(IP + "/contract/filterContractsByDate", filters, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get filterContractsByDate:", response);
        const contractList = response.data.map((item) => {
          return {
            id: item.contract_id,
            label: item.contract_id,
          };
        });
        setContractIdList(contractList);
      })
      .catch((error) => {
        console.log("Error filterContractsByDate:", error);
      });
  }

  //Get list of Contracts by date and ID for report
  async function filterReports(filters) {
    await axios
      .post(IP + "/contract/filterReports", filters, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get filterReports:", response);
        setContractDataPdf(response.data);
        if (preliminaryGrid) {
          console.log("Grid preliminary true");
          createGridReportPreliminaryPDF(response.data);
        }
        if (publicityInvoice) {
          console.log("Publicity Invoice true");
          createPublicityInvoiceReportPDF(response.data);
        }
        if (specialsGrid) {
          console.log("Confirmation of specials true");
          createConfirmationOfSpecials(response.data);
        }
        if (publicityContract) {
          console.log("Publicity Contract true");
          createPublicityContractPDF(response.data);
        }
        if (publicityAgency) {
          console.log("Agency Publicity true");
          createAgencyPublicityPDF(response.data);
        }
      })
      .catch((error) => {
        console.log("Error filterReports:", error);
      });
  }

  //Get list of Contracts
  async function getContracts() {
    await axios
      .get(IP + "/contract/getContracts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Contracts:", response);

        const contractList = response.data.map((item) => {
          return {
            id: item.contract_id,
            label: item.contract_id,
          };
        });
        setContractIdList(contractList);

        setContractDataPdf(response.data);

        let contractColumns = [
          //{ Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: "ID", accessor: "id" },
          //{ Header: language == 1 ? "Descripcion" : "Description", accessor: "desc" },
          { Header: language == 1 ? "Suplidor" : "Supplier", accessor: "supplier" },
          //{ Header: language == 1 ? "Sub-Categoría" : "Sub-Category", accessor: "subcategory" },
          //{ Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          //{ Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
          //{ Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          //{ Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let contractRows = response.data.map((item) => {
          //console.log(item);
          return {
            //image: <ProductCell name="" image={imageIP + item.image} />,
            id: item.contract_id,
            supplier: item.vendor.name,
            // upc: item.product_upc,
            // description: item.description,
            // upcunit: item.upcUnit,
            // available: item.available,
            // vendor: item.vendor.name,
            // cost: item.cost,
            // price: item.sales_price,
            // tax: item.tax.percent + "%",
            // subcategory: item.subcategory.name,
            // active:
            //   item.active == "Active"
            //     ? language == 1
            //       ? "Activo"
            //       : "Active"
            //     : language == 1
            //     ? "Inactivo"
            //     : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    iconOnly
                    onClick={() => {
                      navigate("/contractMaintenance", {
                        state: {
                          contract_data: item,
                          edit: true,
                        },
                      });
                      // navigate("/editfamily", {
                      //   state: {
                      //     familyId: item.family_id,
                      //     productId: item.product_id,
                      //     family_name: item.family_name,
                      //     name: item.product_name,
                      //     code: item.product_code,
                      //     upc: item.product_upc,
                      //     description: item.description,
                      //     packaging: item.packaging,
                      //     subcategory_id: item.subcategory_id,
                      //     vendor_id: item.vendor_id,
                      //     packaging_check: item.packaging_check,
                      //     supplier_check: item.supplier_check,
                      //     subcategory_check: item.subcategory_check,
                      //   },
                      // });
                    }}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>{" "}
              </Grid>
            ),
          };
        });

        let tableData = { columns: contractColumns, rows: contractRows };
        setContractData(tableData);
        //console.log(tableData)
        setProductData(tableData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getContractProducts(contract_id) {
    console.log("Contract ID Parameter:", contract_id);

    await axios
      .post(IP + "/contract/getContractProducts", contract_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get contract product RESPONSE:", response.data);

        //let productData = yourdata.map(item=> [item.id, item.nom, item.quantité, item.prix])
        //"contract_products_id": 2,
        //"contract_id": 4,
        //"vendor_id": 3837,
        //"product_id": 459,
        //"family_id": null,
        //"offer_id": null,
        // upc,
        // description,
        // cost,
        // order,
        // c,
        // g,
        // man,
        // envio,
        // inter,
        // trans,
        // tar,
        // pub_ap,
        // vag,
        // add,
        // imp,
        // pub_fa,
        // foto_fa,
        // offers,
        // show,
        // extra,
        // approved,
        // ce,
        // cn_r,
        // pgr,
        // method_r,
        // percent_r,
        // pr,
        // cn_e,
        // pge,
        // method_e,
        // percent_e,
        // pe,
        // ceu,
        // cn_r2,
        // pgr2,
        // method_r2,
        // percent_r2,
        // pru,
        // cn_e2,
        // pge2,
        // method_e2,
        // percent_e2,
        // peu,
        //product {
        let productDataArray = response.data.map((item) => {
          return {
            code: item.product.code,
            upc: item.product.upc,
            upcUnit: item.product.upcUnit,
            name: item.product.name,
            description: item.product.description,
            packaging: item.product.packaging,
            minimum: item.product.minimum,
            maximum: item.product.maximum,
            stock: item.product.stock,
            in_qty: item.product.in_qty,
            out_qty: item.product.out_qty,
            available: item.product.available,
            diff: item.product.diff,
            cost: item.product.cost,
            sales_price: item.product.sales_price,
            suggested_price: item.product.suggested_price,
            is_food: item.product.is_food,
            subcategory_id: item.product.subcategory_id,
            tax_id: item.product.tax_id,
            vendor_id: item.product.vendor_id,
            warehouse_id: item.product.warehouse_id,
            active: item.product.active,
            expires: item.product.expires,
            deleted: item.product.deleted,
            almacen: item.product.almacen,
            offer: item.product.offers,
          };
        });

        console.log("PRODUCT DATA FOR PDFs:", productDataArray);
        //}

        let productColumns = [
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
          { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = response.data.map((item) => {
          //console.log(item);
          return {
            //image: <ProductCell name="" image={imageIP + item.image} />,
            code: item.product.code,
            //upcunit: item.upcUnit,
            upc: item.upc,
            name: item.product.name,
            //available: item.available,
            //vendor: item.vendor.name,
            cost: item.cost,
            //price: item.sales_price,
            //tax: item.tax.percent + "%",
            //subcategory: item.subcategory.name,
            active:
              item.active == "Active"
                ? language == 1
                  ? "Activo"
                  : "Active"
                : language == 1
                ? "Inactivo"
                : "Inactive",
          };
        });

        //let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProductDataPdf(response.data);
      })
      .catch((error) => {
        console.log("Get contract product ERROR:", error);
      });
  }

  //Get list of families
  async function getFamilies() {
    await axios
      .get(IP + "/families/getFamilies", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Families:", response);

        let productColumns = [
          //{ Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre" : "Description", accessor: "description" },
          // { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          //{ Header: language == 1 ? "Sub-Categoría" : "Sub-Category", accessor: "subcategory" },
          //{ Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          //{ Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
          //{ Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          //{ Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = response.data.map((item) => {
          //console.log(item);
          return {
            //image: <ProductCell name="" image={imageIP + item.image} />,
            name: item.family_name,
            code: item.product_code,
            upc: item.product_upc,
            description: item.description,
            // upcunit: item.upcUnit,
            // available: item.available,
            // vendor: item.vendor.name,
            // cost: item.cost,
            // price: item.sales_price,
            // tax: item.tax.percent + "%",
            // subcategory: item.subcategory.name,
            // active:
            //   item.active == "Active"
            //     ? language == 1
            //       ? "Activo"
            //       : "Active"
            //     : language == 1
            //     ? "Inactivo"
            //     : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Escojer" : "Select"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      navigate("/editfamily", {
                        state: {
                          familyId: item.family_id,
                          productId: item.product_id,
                          family_name: item.family_name,
                          name: item.product_name,
                          code: item.product_code,
                          upc: item.product_upc,
                          description: item.description,
                          packaging: item.packaging,
                          subcategory_id: item.subcategory_id,
                          vendor_id: item.vendor_id,
                          packaging_check: item.packaging_check,
                          supplier_check: item.supplier_check,
                          subcategory_check: item.subcategory_check,
                        },
                      });
                    }}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProductData(tableData);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  //Inserts the checkbox values into the array of jsons
  const handleAllCheck = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values:", selectedRequirements);

    if (value === "all") {
      setSelectedRequirements(
        selectedRequirements.length === requirementsList.length
          ? []
          : requirementsList.map((val) => (!val.disabled ? val.value : null)) //This will not check any disabled checkboxes or checkboxes with disabled field.
        //: requirementsList.map((val) => val.value)
      );
      return;
    }

    const list = [...selectedRequirements];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List:", list);
    setSelectedRequirements(list);
  };

  //Inserts the checkbox products values into the array of jsons
  const handleAllCheckProducts = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked 2:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values 2:", setSelectedRelatedProduct);

    if (value === "all") {
      setSelectedRelatedProduct(
        setSelectedRelatedProduct.length === relatedProductList.length
          ? []
          : relatedProductList.map((val) => val.value) //do NOT add ".value" to vendorList else you'll get undefined error
      );
      return;
    }

    const list = [...setSelectedRelatedProduct];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List 2:", list);
    setSelectedRelatedProduct(list);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleReportMenuClose = () => {
    setOpenReportMenu(false);
    setErrors([]);
  };

  useEffect(() => {
    //getFamilies();
    getContracts();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDTypography variant="h5" fontWeight="medium">
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              navigate("/contractMaintenance");
              //handleClickOpen();
            }}
          >
            {language == 1 ? "Crear Contrato" : "Create Contract"}
          </MDButton>
        </MDTypography>
        <Tooltip title={language == 1 ? "Imprimir" : "Print"}>
          <MDButton
            variant="gradient"
            color="primary"
            iconOnly
            style={{ marginBottom: "5%" }}
            onClick={() => {
              console.log("selected contract:", item);
              //getContractProducts({ contract_id: item.contract_id });
              setOpenReportMenu(!openReportMenu);
              //setOpenReportMenu(!openReportMenu);
            }}
          >
            <Icon>print</Icon>
            {/* {language == 1 ? "Imprimir" : "Print"} */}
          </MDButton>
        </Tooltip>
      </MDBox>
      <Card>
        <MDBox p={2}>
          <MDTypography variant="h5">{language == 1 ? "Contratos" : "Contracts"}</MDTypography>
          <Grid item lg={12} sx={{ marginBottom: "20px" }}>
            <DataTable2
              table={productData}
              index={index}
              setIndex={setIndex}
              pageEntries={10}
              entriesPerPage={true}
              canSearch
            />
          </Grid>
        </MDBox>
      </Card>
      {/* <Button
        onClick={() => {
          createGridReportPreliminaryPDF();
        }}
      >
        Print
      </Button> */}

      <Dialog fullWidth="md" maxWidth="md" open={openReportMenu} onClose={handleReportMenuClose}>
        {" "}
        <DialogTitle>{language == 1 ? "Reportes de Cuadriculados" : "Create Offer"}</DialogTitle>
        <DialogContent dividers>
          <Grid lg={"auto"}>
            <FormGroup sx={{ mt: -1, ml: 2 }}>
              <Grid container justifyContent="space-between">
                <Grid lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preliminaryGrid}
                        onChange={() => setPreliminaryGrid(!preliminaryGrid)}
                      />
                    }
                    label={language == 1 ? "Cuadriculado Preliminar" : "Preliminary Grid"}
                  />
                </Grid>
                {/* <Grid lg={1} sx={{ marginTop: "5px" }}>
                    <Typography variant="body2" fontWeight="bold">
                      Formato
                    </Typography>
                  </Grid> */}
                {/* <Grid lg={4}>
                  <VirtualizedAutocomplete
                    sx={{ width: "50%" }}
                    size="small"
                    options={[
                      { id: 1, label: "1" },
                      { id: 2, label: "2" },
                      { id: 3, label: "3" },
                      { id: 4, label: "4" },
                    ]}
                    defaultValue={{ id: 1, label: "1" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={language == 1 ? "Formato" : "Format"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid> */}
              </Grid>
              {/* <Grid container justifyContent="space-between">
                <Grid lg={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={approvedGrid}
                        onChange={() => setApprovedGrid(!approvedGrid)}
                      />
                    }
                    label={language == 1 ? "Cuadriculado Aprobado" : "Approved Grid"}
                  />
                </Grid>
                <Grid lg={4}>
                  <VirtualizedAutocomplete
                    sx={{ width: "50%" }}
                    size="small"
                    options={[
                      { id: 1, label: "1" },
                      { id: 2, label: "2" },
                      { id: 3, label: "3" },
                      { id: 4, label: "4" },
                    ]}
                    defaultValue={{ id: 1, label: "1" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={language == 1 ? "Formato" : "Format"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid> */}
              {/* <Grid container justifyContent="space-between">
                <Grid lg={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preWarehouseGrid}
                        onChange={() => setPreWarehouseGrid(!preWarehouseGrid)}
                      />
                    }
                    label={
                      language == 1
                        ? "Cuadriculado De Almacen Preliminar"
                        : "Preliminary Warehouse Grid"
                    }
                  />
                </Grid>
                <Grid lg={4}>
                  <VirtualizedAutocomplete
                    sx={{ width: "50%" }}
                    size="small"
                    options={[
                      { id: 1, label: "1" },
                      { id: 2, label: "2" },
                      { id: 3, label: "3" },
                      { id: 4, label: "4" },
                    ]}
                    defaultValue={{ id: 1, label: "1" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={language == 1 ? "Formato" : "Format"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid> */}
              {/* <Grid container justifyContent="space-between">
                <Grid lg={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={appWarehouseGrid}
                        onChange={() => setAppWarehouseGrid(!appWarehouseGrid)}
                      />
                    }
                    label={
                      language == 1 ? "Cuadriculado De Almacen Aprobado" : "Approved Warehouse Grid"
                    }
                  />
                </Grid>
                <Grid lg={4}>
                  <VirtualizedAutocomplete
                    sx={{ width: "50%" }}
                    size="small"
                    options={[
                      { id: 1, label: "1" },
                      { id: 2, label: "2" },
                      { id: 3, label: "3" },
                      { id: 4, label: "4" },
                    ]}
                    defaultValue={{ id: 1, label: "1" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={language == 1 ? "Formato" : "Format"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={specialsGrid}
                    onChange={() => setSpecialsGrid(!specialsGrid)}
                  />
                }
                label={language == 1 ? "Confirmación De Especiales" : "Confirmation of Specials"}
              />
              <Grid container justifyContent="space-between">
                <Grid lg={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={publicityContract}
                        onChange={() => setPublicityContract(!publicityContract)}
                      />
                    }
                    label={language == 1 ? "Contrato De Publicidad" : "Publicity Contract"}
                  />
                </Grid>
                {/* <Grid lg={1} sx={{ marginTop: "5px" }}>
                    <Typography variant="body2" fontWeight="bold">
                      Formato
                    </Typography>
                  </Grid> */}
                {/* <Grid lg={4}>
                  <VirtualizedAutocomplete
                    sx={{ width: "50%" }}
                    size="small"
                    options={[
                      { id: 1, label: "1" },
                      { id: 2, label: "2" },
                      { id: 3, label: "3" },
                      { id: 4, label: "4" },
                    ]}
                    defaultValue={{ id: 1, label: "1" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={language == 1 ? "Formato" : "Format"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid> */}
              </Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={publicityInvoice}
                    onChange={() => setPublicityInvoice(!publicityInvoice)}
                  />
                }
                label={language == 1 ? "Publicidad A Facturar" : "Advertising To Be Invoiced"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clarifyNotes}
                    onChange={() => setClarifyNotes(!clarifyNotes)}
                  />
                }
                // label={language == 1 ? "Fotos A Facturar" : "Photos To Be Billed"}
                label={language == 1 ? "Notas Aclara" : "Clarify Notes"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={takingOrders}
                    onChange={() => setTakingOrders(!takingOrders)}
                  />
                }
                label={language == 1 ? "Toma De Ordenes" : "Taking Orders"}
              />
              <Grid container justifyContent="space-between">
                <Grid lg={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={publicityAgency}
                        onChange={() => setPublicityAgency(!publicityAgency)}
                      />
                    }
                    label={language == 1 ? "Agencia Publicidad" : "Publicity Agency"}
                  />
                </Grid>
                {/* <Grid lg={1} sx={{ marginTop: "5px" }}>
                    <Typography variant="body2" fontWeight="bold">
                      Formato
                    </Typography>
                  </Grid> */}
                {/* <Grid lg={4}>
                  <VirtualizedAutocomplete
                    sx={{ width: "50%" }}
                    size="small"
                    options={[
                      { id: 1, label: "1" },
                      { id: 2, label: "2" },
                      { id: 3, label: "3" },
                      { id: 4, label: "4" },
                    ]}
                    value={{ id: 1, label: "1" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={language == 1 ? "Formato" : "Format"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid> */}
              </Grid>
            </FormGroup>
          </Grid>
          <Grid lg={"auto"}>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Grid lg={4}>
                <CustomDatePicker
                  label={language == 1 ? "Valido De:" : "Valid From:"}
                  sx={{ m: 1, width: "100%" }}
                  value={validFrom}
                  fullWidth
                  //minDate={dayjs(fromDate)}
                  //maxDate={dayjs(toDate)}
                  defaultValue={state?.contract_data.date_of}
                  onChange={(newValue) => {
                    setValidFrom(newValue);
                  }}
                  onBlur={(newValue) => {
                    if (newValue && validTo) {
                      filterContractsByDate({
                        fromDate: validFrom,
                        toDate: validTo,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid lg={4}>
                <CustomDatePicker
                  label={language == 1 ? "Valido A:" : "Valid To:"}
                  sx={{ m: 1, width: "100%" }}
                  value={validTo}
                  fullWidth
                  //minDate={dayjs(fromDate)}
                  //maxDate={dayjs(toDate)}
                  onChange={(newValue) => {
                    setValidTo(newValue);
                    if (validFrom && newValue) {
                      filterContractsByDate({
                        fromDate: validFrom,
                        toDate: validTo,
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid lg={4}>
                <VirtualizedAutocomplete
                  sx={{ m: 1, width: "100%" }}
                  options={contractIdList}
                  getOptionsLabel={(options) => options.id || ""}
                  value={fromContract}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      console.log("FromContract:", newValue.id);
                      console.log("ToContract:", toContract);
                      setFromContract(newValue.id);
                      if (toContract === null || (toContract !== "" && toContract >= newValue.id)) {
                        setFromContract(newValue.id);
                      } else {
                        setFromContract("");
                      }
                    } else {
                      setFromContract("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Del Contrato" : "From Contract"}
                      variant="outlined"
                    />
                  )}
                />
                {/* <MDInput
                  //variant="standard"
                  label="De la Orden:"
                  //sx={{ m: 1, width: "50%" }}
                  fullWidth
                  value={fromContract}
                  onChange={(e) => {
                    setFromContract(e.target.value);
                    //setCn(e.target.value);
                  }}
                /> */}
              </Grid>
              <Grid lg={4}>
                <VirtualizedAutocomplete
                  sx={{ m: 1, width: "100%" }}
                  options={contractIdList}
                  getOptionsLabel={(options) => options.id || ""}
                  value={toContract}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      console.log("FromContract:", fromContract);
                      console.log("ToContract:", newValue.id);
                      setToContract(newValue.id);
                      if (newValue.id >= fromContract) {
                        setToContract(newValue.id);
                      } else {
                        setToContract(null);
                      }
                    } else {
                      setToContract(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Del Contrato" : "From Contract"}
                      variant="outlined"
                    />
                  )}
                />
                {/* <MDInput
                  //variant="standard"
                  label="A la Orden:"
                  //sx={{ m: 1, width: "50%" }}
                  fullWidth
                  value={toContract}
                  onChange={(e) => {
                    setToContract(e.target.value);
                    //setCn(e.target.value);
                  }}
                /> */}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button>Imprimir</Button> */}
          <Button
            onClick={() => {
              console.log("*Preliminary click*");
              filterReports({
                fromDate: validFrom,
                toDate: validTo,
                fromId: fromContract,
                toId: toContract,
              });
            }}
          >
            Preliminar
          </Button>
          {/* <Button>Configurar</Button> */}
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Contracts;
