/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ImgsViewer from "react-images-viewer";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import ProductCard from "app/components/productCard";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import chairSteel from "assets/images/ecommerce/chair-steel.jpeg";
import chairPink from "assets/images/ecommerce/chair-pink.jpeg";
import chairWood from "assets/images/ecommerce/chair-wood.jpeg";
import blackChair from "assets/images/ecommerce/black-chair.jpeg";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";
import { item } from "examples/Sidenav/styles/sidenavItem";

import clorox from "app/components/pictures/clorox.jpg";
import azucar from "app/components/pictures/azucar.png";
import gasolina from "app/components/pictures/gasolina.webp";
import noodles from "app/components/pictures/noodles2.jpeg";
import habichuelas from "app/components/pictures/habichuelas.jpeg";
import punch from "app/components/pictures/punch.webp";
import aceite from "app/components/pictures/aceite.jpg";
import takis from "app/components/pictures/takis.png";

import "./style.css";

const axios = require("axios");

function SalesEcommerce() {
  const [menu, setMenu] = useState(null);
  const [products, setProducts] = useState({ columns: [], rows: [] });
  const [imgsViewer, setImgsViewer] = useState(false);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [check, setCheck] = useState(false);
  const [reRun, setReRun] = useState(false);
  const [options, setOptions] = useState("");
  const [productCards, setProductCards] = useState();
  const [viewableImage, setViewableImage] = useState();
  const [currentImage, setCurrentImage] = useState(blackChair);
  const [image1, setImage1] = useState(chairPink);
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState(chairWood);
  const [subcategories, setSubcategories] = useState([{}]);
  const [selectedSubcategory, setSelectedSubcategory] = useState({ label: "", id: "" });
  const [search, setSearch] = useState("");
  const [data, setData] = useState();
  const [filterSelect, setFilterSelect] = useState();
  const [currentSale, setCurrentSale] = useState([]);
  const [currentShopper, setCurrentShopper] = useState([]);
  const [success, setSuccess] = useState(true);
  const [categories, setCategories] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [sortBy, setSortBy] = useState(
    language == 1 ? { label: "Precio", id: 1 } : { label: "Price", id: 1 }
  );
  const [sortSelect, setSortSelect] = useState();

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);

  const handleSetCurrentImage = ({ currentTarget }) => {
    // console.log(currentTarget.src);
    setViewableImage(currentTarget.src);
    setImgsViewer(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
    setCheck(false);
    setSuccess(true);
  };

  const cards2 = [];

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const sorting = [
    {
      id: 1,
      option: "Price",
    },
    {
      id: 2,
      option: "Available",
    },
  ];

  const sortingEsp = [
    {
      id: 1,
      option: "Precio",
    },
    {
      id: 2,
      option: "Cantidad Disponible",
    },
  ];

  function sortOptions() {
    language == 1
      ? setOptions(
          sortingEsp.map((item) => {
            return {
              label: item.option,
              id: item.id,
            };
          })
        )
      : setOptions(
          sorting.map((item) => {
            return {
              label: item.option,
              id: item.id,
            };
          })
        );
  }

  const navigate = useNavigate();

  async function GetSubcategories() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/subcategories", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        let sub = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            sub.push({ label: item.name, id: item.id });
          }
        });
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getOnSale() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/onsale/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        response.data.data.map((item) => {
          // if (item.active == "Active") {
          //   currentSale.push(item);
          // } else {
          //   console.log("Inactive Sale")
          // }
          currentSale.push(item);
        });
        console.log(currentSale);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getShopper() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/shopper/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        response.data.data.map((item) => {
          // if(item.active == "Active") {
          //   currentShopper.push(item);
          // } else {
          //   console.log("Inactive Shopper")
          // }
          currentShopper.push(item);
        });
        console.log(currentShopper);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getProductData() {
    setSuccess(true);
    setCheck(false);
    // setShow(!show)
    // console.log(show);
    setReRun(false);
    await axios
      .get(IP + "/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setData(
          response.data.data.filter((item) => {
            return item.active == "Active";
          })
        );
        let ecommerceColumns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
          { Header: "Sub Dept.", accessor: "subdept", width: "10%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
          { Header: language == 1 ? "Descripción" : "Description", accessor: "name", width: "15%" },
          {
            Header: language == 1 ? "Disponible" : "Available",
            accessor: "availability",
            width: "7%",
          },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
        ];

        let ecommerceRows = response.data.data
          .filter((item) => {
            return item.active == "Active";
          })
          .map((item) => {
            //console.log(item);
            return {
              // image: <MDAvatar src={item.image} size='xxl' variant='square' onClick={handleSetCurrentImage} />,
              image: <ProductCell name="" image={item.image} />,
              subdept: item.subcategory.name,
              upc: item.upc,
              code: item.code,
              name: item.name,
              availability: item.available,
              package: item.packaging,
              price: item.sales_price,
            };
          });

        let tableData = { columns: ecommerceColumns, rows: ecommerceRows };
        //console.log(tableData)
        setProducts(tableData);
        let cards = [];
        response.data.data.forEach((element) => {
          if (element.active == "Active") {
            cards.push(
              <ProductCard
                showButton={false}
                open={() => setOpen(true)}
                name={element.name}
                subDept={element.subcategory.name}
                upc={element.upc}
                code={element.code}
                available={element.available}
                packaging={element.packaging}
                price={element.sales_price}
                minimum={element.minimum}
                image={element.image}
                prodId={element.id}
                reRun={true}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
          }
        });
        setProductCards(cards);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getProductsWithCategories(categoryid) {
    setSuccess(true);
    setCheck(false);
    // setShow(!show)
    // console.log(show);
    setReRun(false);
    await axios
      .get(IP + "/products?category_id=" + categoryid, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setData(
          response.data.data.filter((item) => {
            return item.active == "Active";
          })
        );
        let ecommerceColumns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
          { Header: "Sub Dept.", accessor: "subdept", width: "10%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
          { Header: language == 1 ? "Descripción" : "Description", accessor: "name", width: "15%" },
          {
            Header: language == 1 ? "Disponible" : "Available",
            accessor: "availability",
            width: "7%",
          },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
        ];

        let ecommerceRows = response.data.data
          .filter((item) => {
            return item.active == "Active";
          })
          .map((item) => {
            //console.log(item);
            return {
              // image: <MDAvatar src={item.image} size='xxl' variant='square' onClick={handleSetCurrentImage} />,
              image: <ProductCell name="" image={item.image} />,
              subdept: item.subcategory.name,
              upc: item.upc,
              code: item.code,
              name: item.name,
              availability: item.available,
              package: item.packaging,
              price: item.sales_price,
            };
          });

        let tableData = { columns: ecommerceColumns, rows: ecommerceRows };
        //console.log(tableData)
        setProducts(tableData);
        let cards = [];
        response.data.data.forEach((element) => {
          if (element.active == "Active") {
            cards.push(
              <ProductCard
                showButton={false}
                open={() => setOpen(true)}
                name={element.name}
                subDept={element.subcategory.name}
                upc={element.upc}
                code={element.code}
                available={element.available}
                packaging={element.packaging}
                price={element.sales_price}
                minimum={element.minimum}
                image={element.image}
                prodId={element.id}
                reRun={true}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
          }
        });
        setProductCards(cards);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getSalesId() {
    await axios
      .post(
        IP + "/sales_orders",
        {
          date: "2022-08-08",
          subtotal: 1,
          tax: 1,
          status_id: 1,
          active: 1,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        localStorage.setItem("SalesOrderId", response.data.data.id);
        navigate("/ecommerce-purchase");
        //   console.log(response);
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function searchProducts(value) {
    setReRun(true);
    let cards = [];
    data
      .filter((item) => {
        if (item.name.toLowerCase().match(value.toLowerCase())) {
          return item;
        }
      })
      .map((searchItem) => {
        if (show == false) {
          if (reRun) {
            cards.push(
              <ProductCard
                showButton={false}
                open={() => setOpen(true)}
                name={searchItem.name}
                subDept={searchItem.subcategory.name}
                upc={searchItem.upc}
                code={searchItem.code}
                available={searchItem.available}
                packaging={searchItem.packaging}
                price={searchItem.sales_price}
                minimum={searchItem.minimum}
                image={searchItem.image}
                prodId={searchItem.id}
                reRun={true}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
          } else {
            cards.push(
              <ProductCard
                showButton={false}
                open={() => setOpen(true)}
                name={searchItem.name}
                subDept={searchItem.subcategory.name}
                upc={searchItem.upc}
                code={searchItem.code}
                available={searchItem.available}
                packaging={searchItem.packaging}
                price={searchItem.sales_price}
                minimum={searchItem.minimum}
                image={searchItem.image}
                prodId={searchItem.id}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
          }
          setProductCards(cards);
        } else {
          let columns = [
            { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
            { Header: "Sub Dept.", accessor: "subdept", width: "10%" },
            { Header: "UPC", accessor: "upc", width: "5%" },
            { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
            {
              Header: language == 1 ? "Descripción" : "Description",
              accessor: "name",
              width: "15%",
            },
            {
              Header: language == 1 ? "Disponible" : "Available",
              accessor: "availability",
              width: "7%",
            },
            { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
            { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
          ];

          cards2.push(searchItem);

          let rows = cards2.map((item) => {
            return {
              image: <ProductCell name="" image={item.image} />,
              subdept: item.subcategory.name,
              upc: item.upc,
              code: item.code,
              name: item.name,
              availability: item.available,
              package: item.packaging,
              price: item.sales_price,
            };
          });

          let tableData = { columns: columns, rows: rows };
          setProducts(tableData);
        }
      });
  }

  function filterLowStock() {
    setReRun(true);
    let cards = [];
    data
      .filter((item) => item.available <= item.minimum)
      .map((filteredItem) => {
        // console.log(filteredItem);
        if (!show) {
          if (reRun) {
            cards.push(
              <ProductCard
                showButton={false}
                open={() => setOpen(true)}
                name={filteredItem.name}
                subDept={filteredItem.subcategory.name}
                upc={filteredItem.upc}
                code={filteredItem.code}
                available={filteredItem.available}
                packaging={filteredItem.packaging}
                price={filteredItem.sales_price}
                minimum={filteredItem.minimum}
                image={filteredItem.image}
                prodId={filteredItem.id}
                reRun={true}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
          } else {
            cards.push(
              <ProductCard
                showButton={false}
                open={() => setOpen(true)}
                name={filteredItem.name}
                subDept={filteredItem.subcategory.name}
                upc={filteredItem.upc}
                code={filteredItem.code}
                available={filteredItem.available}
                packaging={filteredItem.packaging}
                price={filteredItem.sales_price}
                minimum={filteredItem.minimum}
                image={filteredItem.image}
                prodId={filteredItem.id}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
          }
          setProductCards(cards);
        } else if (show) {
          let columns = [
            { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
            { Header: "Sub Dept.", accessor: "subdept", width: "10%" },
            { Header: "UPC", accessor: "upc", width: "5%" },
            { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
            {
              Header: language == 1 ? "Descripción" : "Description",
              accessor: "name",
              width: "15%",
            },
            {
              Header: language == 1 ? "Disponible" : "Available",
              accessor: "availability",
              width: "7%",
            },
            { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
            { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
          ];

          cards2.push(filteredItem);

          let rows = cards2.map((item) => {
            return {
              image: <ProductCell name="" image={item.image} />,
              subdept: item.subcategory.name,
              upc: item.upc,
              code: item.code,
              name: item.name,
              availability: item.available,
              package: item.packaging,
              price: item.sales_price,
            };
          });

          let tableData = { columns: columns, rows: rows };
          console.log(tableData);
          setProducts(tableData);
        }
      });
  }

  function filterOnSale() {
    console.log(currentSale);
    setReRun(true);
    let cards = [];
    data
      .filter((item) => {
        let list = currentSale.filter((product) => product.product_id == item.id);
        return list.length > 0;
      })
      .map((filteredItem) => {
        // console.log(filteredItem);
        if (!show) {
          if (reRun) {
            cards.push(
              <ProductCard
                showButton={false}
                open={() => setOpen(true)}
                name={filteredItem.name}
                subDept={filteredItem.subcategory.name}
                upc={filteredItem.upc}
                code={filteredItem.code}
                available={filteredItem.available}
                packaging={filteredItem.packaging}
                price={filteredItem.sales_price}
                minimum={filteredItem.minimum}
                image={filteredItem.image}
                prodId={filteredItem.id}
                reRun={true}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
          } else {
            cards.push(
              <ProductCard
                showButton={false}
                open={() => setOpen(true)}
                name={filteredItem.name}
                subDept={filteredItem.subcategory.name}
                upc={filteredItem.upc}
                code={filteredItem.code}
                available={filteredItem.available}
                packaging={filteredItem.packaging}
                price={filteredItem.sales_price}
                minimum={filteredItem.minimum}
                image={filteredItem.image}
                prodId={filteredItem.id}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
          }
          setProductCards(cards);
        } else if (show) {
          let columns = [
            { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
            { Header: "Sub Dept.", accessor: "subdept", width: "10%" },
            { Header: "UPC", accessor: "upc", width: "5%" },
            { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
            {
              Header: language == 1 ? "Descripción" : "Description",
              accessor: "name",
              width: "15%",
            },
            {
              Header: language == 1 ? "Disponible" : "Available",
              accessor: "availability",
              width: "7%",
            },
            { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
            { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
          ];

          cards2.push(filteredItem);

          let rows = cards2.map((item) => {
            return {
              image: <ProductCell name="" image={item.image} />,
              subdept: item.subcategory.name,
              upc: item.upc,
              code: item.code,
              name: item.name,
              availability: item.available,
              package: item.packaging,
              price: item.sales_price,
            };
          });

          let tableData = { columns: columns, rows: rows };
          console.log(tableData);
          setProducts(tableData);
        }
      });
  }

  function filterShopper() {
    console.log(currentShopper);
    setReRun(true);
    let cards = [];
    console.log(currentShopper);
    data
      .filter((item) => {
        let list = currentShopper.filter((product) => product.product_id == item.id);
        return list.length > 0;
      })
      .map((filteredItem) => {
        // console.log(filteredItem);
        if (!show) {
          if (reRun) {
            cards.push(
              <ProductCard
                showButton={false}
                open={() => setOpen(true)}
                name={filteredItem.name}
                subDept={filteredItem.subcategory.name}
                upc={filteredItem.upc}
                code={filteredItem.code}
                available={filteredItem.available}
                packaging={filteredItem.packaging}
                price={filteredItem.sales_price}
                minimum={filteredItem.minimum}
                image={filteredItem.image}
                prodId={filteredItem.id}
                reRun={true}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
          } else {
            cards.push(
              <ProductCard
                showButton={false}
                open={() => setOpen(true)}
                name={filteredItem.name}
                subDept={filteredItem.subcategory.name}
                upc={filteredItem.upc}
                code={filteredItem.code}
                available={filteredItem.available}
                packaging={filteredItem.packaging}
                price={filteredItem.sales_price}
                minimum={filteredItem.minimum}
                image={filteredItem.image}
                prodId={filteredItem.id}
                onSale={currentSale}
                shopper={currentShopper}
                viewImage={handleSetCurrentImage}
              />
            );
          }
          setProductCards(cards);
        } else if (show) {
          let columns = [
            { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
            { Header: "Sub Dept.", accessor: "subdept", width: "10%" },
            { Header: "UPC", accessor: "upc", width: "5%" },
            { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
            {
              Header: language == 1 ? "Descripción" : "Description",
              accessor: "name",
              width: "15%",
            },
            {
              Header: language == 1 ? "Disponible" : "Available",
              accessor: "availability",
              width: "7%",
            },
            { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
            { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
          ];

          cards2.push(filteredItem);

          let rows = cards2.map((item) => {
            return {
              image: <ProductCell name="" image={item.image} />,
              subdept: item.subcategory.name,
              upc: item.upc,
              code: item.code,
              name: item.name,
              availability: item.available,
              package: item.packaging,
              price: item.sales_price,
            };
          });

          let tableData = { columns: columns, rows: rows };
          console.log(tableData);
          setProducts(tableData);
        }
      });
  }

  function filterSubDept() {
    setReRun(true);
    let cards = [];
    if (selectedSubcategory) {
      setSuccess(true);
      data
        .filter((item) => {
          return item.subcategory.id == selectedSubcategory.id;
        })
        .map((filteredItem) => {
          if (!show) {
            if (reRun) {
              cards.push(
                <ProductCard
                  showButton={false}
                  name={filteredItem.name}
                  subDept={filteredItem.subcategory.name}
                  upc={filteredItem.upc}
                  code={filteredItem.code}
                  available={filteredItem.available}
                  packaging={filteredItem.packaging}
                  price={filteredItem.sales_price}
                  minimum={filteredItem.minimum}
                  image={filteredItem.image}
                  prodId={filteredItem.id}
                  reRun={true}
                  onSale={currentSale}
                  shopper={currentShopper}
                  viewImage={handleSetCurrentImage}
                />
              );
            } else {
              cards.push(
                <ProductCard
                  showButton={false}
                  name={filteredItem.name}
                  subDept={filteredItem.subcategory.name}
                  upc={filteredItem.upc}
                  code={filteredItem.code}
                  available={filteredItem.available}
                  packaging={filteredItem.packaging}
                  price={filteredItem.sales_price}
                  minimum={filteredItem.minimum}
                  image={filteredItem.image}
                  prodId={filteredItem.id}
                  onSale={currentSale}
                  shopper={currentShopper}
                  viewImage={handleSetCurrentImage}
                />
              );
            }
            setProductCards(cards);
          } else if (show) {
            let columns = [
              { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "2%" },
              { Header: "Sub Dept.", accessor: "subdept", width: "10%" },
              { Header: "UPC", accessor: "upc", width: "5%" },
              { Header: language == 1 ? "Código" : "Code", accessor: "code", width: "5%" },
              {
                Header: language == 1 ? "Descripción" : "Description",
                accessor: "name",
                width: "15%",
              },
              {
                Header: language == 1 ? "Disponible" : "Available",
                accessor: "availability",
                width: "7%",
              },
              { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
              { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
            ];

            cards2.push(filteredItem);

            let rows = cards2.map((item) => {
              return {
                image: <ProductCell name="" image={item.image} />,
                subdept: item.subcategory.name,
                upc: item.upc,
                code: item.code,
                name: item.name,
                availability: item.available,
                package: item.packaging,
                price: item.sales_price,
              };
            });

            let tableData = { columns: columns, rows: rows };
            console.log(tableData);
            setProducts(tableData);
          }
        });
    } else {
      setSuccess(false);
    }
  }

  function sortPriceAscend() {
    setReRun(true);
    let cards = [];

    let saleIds = currentSale.map((item) => {
      return item.product_id;
    });

    let prices = [];

    data.map((item) => {
      if (saleIds.includes(item.id)) {
        prices.push({
          price: currentSale.filter((sale) => {
            return sale.product_id == item.id;
          })[0].specialPrice,
          id: item.id,
        });
      } else {
        prices.push({ price: item.sales_price, id: item.id });
      }
    });

    prices
      .sort((a, b) => (parseFloat(a.price) > parseFloat(b.price) ? 1 : -1))
      .map((item) => {
        let prod = data.filter((item2) => {
          return item.id == item2.id;
        });
        if (reRun) {
          cards.push(
            <ProductCard
              showButton={false}
              open={() => setOpen(true)}
              name={prod[0].name}
              subDept={prod[0].subcategory.name}
              upc={prod[0].upc}
              code={prod[0].code}
              available={prod[0].available}
              packaging={prod[0].packaging}
              price={prod[0].sales_price}
              minimum={prod[0].minimum}
              image={prod[0].image}
              prodId={prod[0].id}
              reRun={true}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );
        } else {
          cards.push(
            <ProductCard
              showButton={false}
              open={() => setOpen(true)}
              name={prod[0].name}
              subDept={prod[0].subcategory.name}
              upc={prod[0].upc}
              code={prod[0].code}
              available={prod[0].available}
              packaging={prod[0].packaging}
              price={prod[0].sales_price}
              minimum={prod[0].minimum}
              image={prod[0].image}
              prodId={prod[0].id}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );
        }
        setProductCards(cards);
      });
  }

  function sortPriceDescend() {
    setReRun(true);
    let cards = [];

    let saleIds = currentSale.map((item) => {
      return item.product_id;
    });

    let prices = [];

    data.map((item) => {
      if (saleIds.includes(item.id)) {
        prices.push({
          price: currentSale.filter((sale) => {
            return sale.product_id == item.id;
          })[0].specialPrice,
          id: item.id,
        });
      } else {
        prices.push({ price: item.sales_price, id: item.id });
      }
    });

    prices
      .sort((a, b) => (parseFloat(a.price) < parseFloat(b.price) ? 1 : -1))
      .map((item) => {
        let prod = data.filter((item2) => {
          return item.id == item2.id;
        });
        if (reRun) {
          cards.push(
            <ProductCard
              showButton={false}
              open={() => setOpen(true)}
              name={prod[0].name}
              subDept={prod[0].subcategory.name}
              upc={prod[0].upc}
              code={prod[0].code}
              available={prod[0].available}
              packaging={prod[0].packaging}
              price={prod[0].sales_price}
              minimum={prod[0].minimum}
              image={prod[0].image}
              prodId={prod[0].id}
              reRun={true}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );
        } else {
          cards.push(
            <ProductCard
              showButton={false}
              open={() => setOpen(true)}
              name={prod[0].name}
              subDept={prod[0].subcategory.name}
              upc={prod[0].upc}
              code={prod[0].code}
              available={prod[0].available}
              packaging={prod[0].packaging}
              price={prod[0].sales_price}
              minimum={prod[0].minimum}
              image={prod[0].image}
              prodId={prod[0].id}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );
        }
        setProductCards(cards);
      });
  }

  function sortStockAscend() {
    setReRun(true);
    let cards = [];
    data
      .sort((a, b) => (a.available > b.available ? 1 : -1))
      .map((item) => {
        if (reRun) {
          cards.push(
            <ProductCard
              showButton={false}
              open={() => setOpen(true)}
              name={item.name}
              subDept={item.subcategory.name}
              upc={item.upc}
              code={item.code}
              available={item.available}
              packaging={item.packaging}
              price={item.sales_price}
              minimum={item.minimum}
              image={item.image}
              prodId={item.id}
              reRun={true}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );
        } else {
          cards.push(
            <ProductCard
              showButton={false}
              open={() => setOpen(true)}
              name={item.name}
              subDept={item.subcategory.name}
              upc={item.upc}
              code={item.code}
              available={item.available}
              packaging={item.packaging}
              price={item.sales_price}
              minimum={item.minimum}
              image={item.image}
              prodId={item.id}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );
        }
        setProductCards(cards);
      });
  }

  function sortStockDescend() {
    setReRun(true);
    let cards = [];
    data
      .sort((a, b) => (a.available < b.available ? 1 : -1))
      .map((item) => {
        if (reRun) {
          cards.push(
            <ProductCard
              showButton={false}
              open={() => setOpen(true)}
              name={item.name}
              subDept={item.subcategory.name}
              upc={item.upc}
              code={item.code}
              available={item.available}
              packaging={item.packaging}
              price={item.sales_price}
              minimum={item.minimum}
              image={item.image}
              prodId={item.id}
              reRun={true}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );
        } else {
          cards.push(
            <ProductCard
              showButton={false}
              open={() => setOpen(true)}
              name={item.name}
              subDept={item.subcategory.name}
              upc={item.upc}
              code={item.code}
              available={item.available}
              packaging={item.packaging}
              price={item.sales_price}
              minimum={item.minimum}
              image={item.image}
              prodId={item.id}
              onSale={currentSale}
              shopper={currentShopper}
              viewImage={handleSetCurrentImage}
            />
          );
        }
        setProductCards(cards);
      });
  }

  // function changeImage() {
  //   setCurrentImage(image1);
  //   setImage1(image2);
  //   setImage2(image3);
  //   setImage3(currentImage);
  // }

  var items = [
    {
      image: chairPink,
    },
    {
      image: chairSteel,
    },
    {
      image: chairWood,
    },
  ];

  async function GetCategories() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/categories", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setCategories(response.data.data);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    GetSubcategories();
    sortOptions();
    getProductData();
    getOnSale();
    getShopper();
    GetCategories();
  }, []);

  return (
    <DashboardLayout>
      <ImgsViewer
        imgs={[{ src: viewableImage }]}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        backdropCloseable
      />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>Sort by:</DialogTitle>
          <DialogContent>
            <Autocomplete
              sx={{ mb: 1 }}
              disablePortal
              fullWidth
              value={sortBy}
              onChange={(event, newValue) => {
                setSortBy(newValue);
              }}
              options={options}
              renderInput={(params) => <TextField {...params} label="" variant="standard" />}
            />
            {sortBy ? (
              <FormControl>
                <RadioGroup
                  name="Sort Options"
                  onChange={(e) => {
                    if (e.target.value == "ascending") {
                      setSortSelect(e.target.value);
                    } else if (e.target.value == "descending") {
                      setSortSelect(e.target.value);
                    }
                  }}
                >
                  <FormControlLabel
                    value="descending"
                    control={<Radio />}
                    label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                  />
                  <FormControlLabel
                    value="ascending"
                    control={<Radio />}
                    label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                  />
                </RadioGroup>
              </FormControl>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending" && sortBy.id == 1) {
                  sortPriceAscend();
                } else if (sortSelect == "descending" && sortBy.id == 1) {
                  sortPriceDescend();
                } else if (sortSelect == "ascending" && sortBy.id == 2) {
                  sortStockAscend();
                } else if (sortSelect == "descending" && sortBy.id == 2) {
                  sortStockDescend();
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open2}>
          <DialogTitle>Filter</DialogTitle>
          <DialogContent>
            <MDBox p={1}>
              <FormControl>
                <RadioGroup
                  name="Filter Options"
                  onChange={(e) => {
                    if (e.target.value == "lowstock") {
                      setCheck(false);
                      setFilterSelect(e.target.value);
                    } else if (e.target.value == "subdept") {
                      setCheck(true);
                      setFilterSelect(e.target.value);
                    } else if (e.target.value == "discount") {
                      setCheck(false);
                      setFilterSelect(e.target.value);
                    } else if (e.target.value == "shopper") {
                      setCheck(false);
                      setFilterSelect(e.target.value);
                    } else {
                      setCheck(false);
                    }
                  }}
                >
                  <FormControlLabel
                    value="subdept"
                    control={<Radio />}
                    label={language == 1 ? "Sub-Departamento" : "Sub-Department"}
                  />
                  {check ? (
                    <Autocomplete
                      disablePortal
                      value={selectedSubcategory}
                      onChange={(event, newValue) => {
                        setSelectedSubcategory(newValue);
                      }}
                      options={subcategories}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={language == 1 ? "Sub-Departamento" : "Sub-Department"}
                          variant="standard"
                        />
                      )}
                    />
                  ) : null}
                  {success ? null : (
                    <MDTypography color="error" variant="button">
                      {language == 1 ? "Seleccione un sub-departamento" : "Select a sub-department"}
                    </MDTypography>
                  )}
                  <FormControlLabel
                    value="discount"
                    control={<Radio />}
                    label={language == 1 ? "Productos en especial" : "Products on sale"}
                  />
                  <FormControlLabel
                    value="shopper"
                    control={<Radio />}
                    label={language == 1 ? "Productos en el shopper" : "Shopper Products"}
                  />
                  <FormControlLabel
                    value="lowstock"
                    control={<Radio />}
                    label={language == 1 ? "Poca cantidad" : "Low stock"}
                  />
                </RadioGroup>
              </FormControl>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button onClick={getProductData}>
              {language == 1 ? "Remover filtro" : "Remove Filter"}
            </Button>
            <Button
              onClick={() => {
                console.log(filterSelect);
                if (filterSelect == "lowstock") {
                  filterLowStock();
                  setFilterSelect();
                } else if (filterSelect == "subdept") {
                  filterSubDept();
                  setFilterSelect();
                } else if (filterSelect == "discount") {
                  filterOnSale();
                  setFilterSelect();
                } else if (filterSelect == "shopper") {
                  filterShopper();
                  setFilterSelect();
                }
              }}
            >
              {language == 1 ? "Aplicar filtro" : "Apply Filter"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <DashboardNavbar />
      <Card sx={{ mb: 2, backgroundColor: "#3a3a31" }}>
        <Grid container spacing={3}>
          {categories.length > 0 &&
            categories.map((item) => {
              return (
                <Grid item xs={12} lg={2}>
                  <MDButton
                    sx={{ mb: 1, mt: 1, ml: 5 }}
                    onClick={() => {
                      getProductsWithCategories(item.id);
                    }}
                    className="btncom"
                    variant="text"
                  >
                    {language == 1 ? item.nombre : item.name}
                  </MDButton>
                </Grid>
              );
            })}
        </Grid>
      </Card>
      <Card sx={{ mb: 2, overflow: "visible" }}>
        <MDBox mb={-2} mt={-1}>
          <Grid container spacing={0}>
            {/* <Grid item xs={12} lg={4}>
        <MDBox
        component="img"
        src={image3}
        alt="Product Image"
        shadow="lg"
        borderRadius="lg"
        height='25rem'
        width="50%"
        sx={{ cursor: "pointer", objectFit: "cover" }}
        onLoad={()=>setTimeout(changeImage, 5000)}
        onClick={handleSetCurrentImage}
      />
      </Grid> */}
            <Grid item xs={12} lg={4}>
              <MDBox
                component="img"
                src={""}
                mb={2}
                alt="Product Image"
                shadow="lg"
                borderRadius="lg"
                // height='100%'
                // width="100%"
                sx={{ cursor: "pointer", objectFit: "cover" }}
                //onLoad={()=>setTimeout(changeImage, 5000)}
                onClick={handleSetCurrentImage}
              />
            </Grid>
            {/* <Grid item xs={12} lg={4}>
        <MDBox
        component="img"
        src={image1}
        alt="Product Image"
        shadow="lg"
        borderRadius="lg"
        height='25rem'
        width="50%"
        sx={{ cursor: "pointer", objectFit: "cover" }}
        onLoad={()=>setTimeout(changeImage, 5000)}
        onClick={handleSetCurrentImage}
      />
      </Grid> */}
          </Grid>
        </MDBox>
      </Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
        <Grid container spacing={10}>
          <Grid item xs={12} lg={3}>
            <MDButton variant="gradient" color="info" onClick={getSalesId}>
              {language == 1 ? "Crear orden" : "create order"}
            </MDButton>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDBox display="flex" justifyContent="left" alignItems="flex-start">
              <MDInput
                label={language == 1 ? "Buscar..." : "Search..."}
                type="search"
                variant="outlined"
                onChange={(e) => searchProducts(e.target.value)}
                fullWidth
              />
              {/* <MDButton
                sx={{ ml: -4.5, mt: 0.4 }}
                variant="text"
                color="dark"
                onClick={search ? searchProducts : getProductData}
                iconOnly
              >
                <Icon>search</Icon>
              </MDButton> */}
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MDBox display="flex" justifyContent="right" alignItems="flex-start">
              <MDButton
                sx={{ mt: -0.5 }}
                title="Cards"
                color="dark"
                size="large"
                variant="text"
                onClick={() => setShow(false)}
                iconOnly
              >
                <Icon>view_compact</Icon>
              </MDButton>
              <MDButton
                sx={{ mt: -0.5, ml: -2.5 }}
                title="List"
                color="dark"
                size="large"
                variant="text"
                onClick={() => setShow(true)}
                iconOnly
              >
                <Icon>menu</Icon>
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {!show ? (
        <>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <MDBox mt={1} display="flex" justifyContent="left" alignItems="flex-start">
              <MDButton variant="outlined" color="dark" size="small" onClick={() => setOpen(true)}>
                <Icon>sort</Icon>
                &nbsp;{language == 1 ? "Organizar" : "Sort"}
              </MDButton>
              <MDButton
                sx={{ ml: 0.5 }}
                variant="outlined"
                color="dark"
                size="small"
                onClick={() => setOpen2(true)}
              >
                {language == 1 ? "Filtrar" : "Filter"}&nbsp;
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </>
      ) : (
        <MDBox mt={1} display="flex" justifyContent="space-between" alignItems="flex-start">
          <MDButton variant="outlined" color="dark" size="small" onClick={() => setOpen2(true)}>
            {language == 1 ? "Filtrar" : "Filter"}&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
        </MDBox>
      )}
      <MDBox mt={2}>
        {show ? (
          <Card>
            <MDBox mt={1} />
            <DataTable table={products} entriesPerPage={false} />
          </Card>
        ) : (
          <Grid container spacing={2}>
            {productCards}
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default SalesEcommerce;
