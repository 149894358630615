/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { flushSync } from "react-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";
import { Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import VirtualizedAutocomplete from "app/components/VirtualizedAutocomplete";
//import { styled } from "@mui/material/styles";

import swal from "sweetalert";
// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import DataTable2 from "app/components/shopperTable";

import IP from "app/IP";

const axios = require("axios");

function EditFamily() {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
  //const [familyName, setFamilyName] = useState("");
  const [familyCode, setFamilyCode] = useState("");
  const [familyUPC, setFamilyUPC] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [familyDescription, setFamilyDescription] = useState("");
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [productTableVisible, setProductTableVisible] = useState(false);
  const [matchingProductResults, setMatchingProductResults] = useState(false);
  const [relatedProductOptions, setRelatedProductOptions] = useState([]);
  const [selectedProductOptions, setSelectedProductOptions] = useState([]);
  const [productFamilyData, setProductFamilyData] = useState([]);
  const [selectedRelatedProducts, setSelectedRelatedProducts] = useState([]);
  const [index, setIndex] = useState(0);
  const [productData, setProductData] = useState({ columns: [], rows: [] });
  const [relatedProductData, setRelatedProductData] = useState({ columns: [], rows: [] });
  const [productPrice, setProductPrice] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({
    id: "",
    code: "",
    upc: "",
    name: "",
    description: "",
  });
  const [requirementsList, setRequirementsList] = useState([
    // { label: "sales price", value: 1 },
    // { label: "tax", value: 2 },
    // { label: "vendor", value: 3 },
    { label: "empaque", value: 1 },
    { label: "Tamaño", value: 3, disabled: true },
    { label: "Pesado", value: 6, disabled: true },
    { label: "Etiqueta ???", value: 7, disabled: true },
    { label: "Tarjeta", value: 8, disabled: true },
    { label: "Departamento", value: 9 }, //department is actually subcategory in our version of the database
    { label: "Suplidor", value: 10 },
    { label: "Costo Caja", value: 11, disabled: true },
    { label: "Precio Caja", value: 12, disabled: true },
    { label: "Costo Unidad", value: 13, disabled: true },
    { label: "Precio Unidad", value: 14, disabled: true },
    { label: "Especiales", value: 15, disabled: true },
    { label: "Impuestos", value: 16, disabled: true },
    { label: "Ofertas", value: 17, disabled: true },
    { label: "WIC", value: 19, disabled: true },
  ]); //Options for list of checkbox requirements
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const isAllSelected =
    requirementsList.length > 0 && selectedRequirements.length === requirementsList.length;
  const [menu, setMenu] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  //const [paymentData, setPaymentData] = useState({ columns: [], rows: [] });
  const [date, setDate] = useState(currentDate);
  const [errors, setErrors] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [monto, setMonto] = useState(0.0);
  const [totalPay, setTotalPay] = useState(0.0);
  const [received, setReceived] = useState(0.0);
  const [remainder, setRemainder] = useState(0.0);
  const [invoices, setInvoices] = useState([]);
  const [editInvoice, setEditInvoice] = useState(0);
  const [start, SetStart] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("CHECK");
  const [transactionNumber, setTransactionNumber] = useState("");
  // const [paginateData, setPaginateData] = useState({
  //   columns: [
  //     { Header: "Invoice Number.", accessor: "invoice_number" },
  //     { Header: "Store", accessor: "store_name" },
  //     { Header: "Pending", accessor: "total" },
  //     { Header: "Payment Amount", accessor: "amount" },
  //     { Header: "Actions", accessor: "actions" },
  //   ],
  //   rows: [],
  // });
  const [editFormData, setEditFormData] = useState({
    fullName: "",
    address: "",
    phoneNumber: "",
    email: "",
  });

  let timer;

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  //Get the Products that are related to the given fields of the base product selected
  async function getRelatedProducts(product_info) {
    console.log("REQUIREMENTS:", selectedRequirements);
    console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/families/getRelatedProducts", product_info, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("list of products:", response.data);
        let productColumns = [
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
          { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
          // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        // let productRows = response.data.map((item) => {
        //   return {
        //     id: item.product_id,
        //     code: item.code,
        //     upcunit: item.upcUnit,
        //     upc: item.upc,
        //     name: item.name,
        //     available: item.available,
        //     cost: item.cost,
        //     price: item.sales_price,
        //     active:
        //       item.active == "Active"
        //         ? language == 1
        //           ? "Activo"
        //           : "Active"
        //         : language == 1
        //         ? "Inactivo"
        //         : "Inactive",
        //   };
        // });

        let productRows = response.data.map((item) => {
          return {
            id: item.product_id,
            name: item.name,
          };
        });
        console.log("Mapping products!!!! 2", productRows);
        //setRelatedProductData({ columns: productColumns, rows: productRows });
        setRelatedProductOptions(productRows);
        if (productRows.length <= 0 && selectedRequirements.length > 0) {
          setMatchingProductResults(true);
        } else {
          setMatchingProductResults(false);
        }
      })
      .catch((error) => {
        console.log("product list error:", error);
      });
  }

  async function updateFamily(family_info) {
    //console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/families/editFamily", family_info, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("updateFamily Response:", response);
        navigate("/families");
        //console.log("SELECTED RELATED PRODUCTS", selectedRelatedProducts);
        //console.log("SELECTED REQUIREMENTS", selectedRequirements);
        //let familyID = response.data.family_id;
        //let selectedProductsArray = selectedRelatedProducts
        // let selectedProductsMap = selectedRelatedProducts.map((prod) => {
        //   return {
        //     product_id: prod.id,
        //     family_id: state.familyId,
        //   };
        // });
        // let selectedRequirementsMap = selectedRequirements
        //   .map((req) => {
        //     return {
        //       requirement_id: req,
        //       family_id: state.familyId,
        //     };
        //   })
        //   .filter((req) => req.requirement_id !== null);
        // console.log("SELECTED RELATED PRODUCTS 2", selectedProductsMap);
        // console.log("SELECTED REQUIREMENTS 2", selectedRequirementsMap);
        // saveProductFamily(selectedProductsMap);
        // saveFamilyRequirements(selectedRequirementsMap);
      })
      .catch((error) => {
        console.log("updateFamily Error:", error);
        setErrors(error.response.data.data);
      });
  }

  async function saveProductFamily(product_info) {
    deleteProductFamilies();
    console.log("PRODUCT_INFO:", product_info);
    //console.log("FAMILY_INFO:", family_id);
    await axios
      .post(IP + "/product_families/saveProductFamily", product_info, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("saveProductFamily Response:", response);
        //navigate("/families");
      })
      .catch((error) => {
        console.log("saveProductFamily Error:", error);
      });
  }

  async function deleteProductFamilies() {
    await axios
      .post(IP + "/product_families/deleteProductFamilies", state.familyId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("deleteProductFamilies Response:", response);
        //navigate("/families");
      })
      .catch((error) => {
        console.log("deleteProductFamilies Error:", error);
      });
  }

  async function deleteFamilyRequirements(product_info) {
    await axios
      .post(IP + "/product_families/deleteFamilyRequirements", product_info, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("deleteFamilyRequirements Response:", response);
        //navigate("/families");
      })
      .catch((error) => {
        console.log("deleteFamilyRequirements Error:", error);
      });
  }

  async function saveFamilyRequirements(requirements, family_id) {
    deleteFamilyRequirements();
    console.log("Requirements_INFO:", requirements);
    await axios
      .post(IP + "/product_families/saveFamilyRequirements", requirements, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Save Family Requirement response:", response);
      })
      .catch((error) => {
        console.log("Save Family Requirement error:", error);
      });
  }

  async function getProductFamilies(family_id) {
    await axios
      .post(
        IP + "/product_families/getProductFamilies",
        { family_id: family_id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("getProductFamilies response", response);
        let productFamily = response.data.map((item) => {
          return {
            product_id: item.product_id,
          };
        });
        console.log("Product Family:", productFamily);
        //setProductFamilyData(productFamily);
        getProductListByFamilies(productFamily);
      })
      .catch((error) => {
        console.log("getProductFamilies error", error);
      });
  }

  async function getProductListByFamilies(product_id) {
    await axios
      .post(
        IP + "/product_families/getProductListByFamilies",
        { product_id: product_id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("getProductListByFamilies response", response);
        let products = response.data.map((item) => {
          return { id: item.product_id, name: item.name };
        });
        console.log("Mapped products!!!!", products);
        setSelectedProductOptions(products);
        setSelectedRelatedProducts(products);
      })
      .catch((error) => {
        console.log("getProductListByFamilies error", error);
      });
  }

  async function getFamilyRequirements(family_id) {
    await axios
      .post(
        IP + "/product_families/getFamilyRequirements",
        { family_id: family_id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("getFamilyRequirements response", response);
        let familyRequirement = response.data.map((item) => {
          return {
            requirement_id: item.requirement_id,
          };
        });
        console.log("Requirement Family:", familyRequirement);
        //setProductFamilyData(productFamily);
        getRequirementsByFamily(familyRequirement);
      })
      .catch((error) => {
        console.log("getFamilyRequirements error", error);
      });
  }

  async function getRequirementsByFamily(requirement_id) {
    await axios
      .post(
        IP + "/product_families/getRequirementsByFamily",
        { requirement_id: requirement_id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("getRequirementsByFamily response", response);
        let requirements = response.data.map((item) => {
          return item.requirement_id;
        });

        console.log(
          "Comparing requirements and list:",
          requirements.length === requirementsList.length
        );
        console.log("Requirement list:", requirementsList);
        console.log(
          requirementsList.filter((val) => requirements.includes(val.value)).map((val) => val.value)
        );

        setSelectedRequirements(
          // requirements.length === requirementsList.length
          //   ? []
          //   :
          requirementsList.filter((val) => requirements.includes(val.value)).map((val) => val.value)
          //.map((val) => (!val.disabled ? val.value : null))
          //.filter((e) => requirements.includes(e.value)) //This will not check any disabled checkboxes or checkboxes with disabled field.
        );

        console.log({
          productID: state.productId,
          code: state.code,
          upc: state.upc,
          name: state.name,
        });

        getRelatedProducts({
          //requirements: selectedRequirements,
          productID: state.productId,
          code: state.code,
          upc: state.upc,
          name: state.name,
          packaging: requirements.includes(1) && state.packaging,
          //minimum: selectedRequirements.includes(2) && selectedProduct.minimum,
          //maximum: selectedRequirements.includes(3) && selectedProduct.maximum,
          //inQty: selectedRequirements.includes(4) && selectedProduct.in_qty,
          //outQty: selectedRequirements.includes(5) && selectedProduct.out_qty,
          // available: selectedRequirements.includes(6) && selectedProduct.available,
          // diff: selectedRequirements.includes(7) && selectedProduct.diff,
          // cost: selectedRequirements.includes(8) && selectedProduct.cost,
          subcategoryID: requirements.includes(9) && state.subcategory_id,
          supplier: requirements.includes(10) && state.vendor_id,
          // isFood: selectedRequirements.includes(11) && selectedProduct.is_food,
          // subcategoryID:
          //   selectedRequirements.includes(12) && selectedProduct.subcategory_id,
          // taxID: selectedRequirements.includes(13) && selectedProduct.tax_id,
          // supplier: selectedRequirements.includes(14) && selectedProduct.vendor_id,
          // warehouseID: selectedRequirements.includes(15) && selectedProduct.warehouse_id,
        });
        console.log("requirements:", requirements);
        //setProductFamilyData(productFamily);
        //getProductData(requirements);
      })
      .catch((error) => {
        console.log("getRequirementsByFamily error", error);
      });
  }

  //Get list of products
  async function getProducts() {
    await axios
      .get(IP + "/products?all_products=true", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let productColumns = [
          //{ Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
          { Header: language == 1 ? "Empaque" : "Packaging", accessor: "packaging" },
          // { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          //{ Header: language == 1 ? "Sub-Categoría" : "Sub-Category", accessor: "subcategory" },
          //{ Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          //{ Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
          //{ Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          //{ Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = response.data.data.map((item) => {
          //console.log(item);
          return {
            //image: <ProductCell name="" image={imageIP + item.image} />,
            code: item.code,
            upcunit: item.upcUnit,
            upc: item.upc,
            name: item.name,
            packaging: item.packaging,
            vendor: item.vendor.name,
            cost: item.cost,
            price: item.sales_price,
            tax: item.tax.percent + "%",
            subcategory: item.subcategory.name,
            active:
              item.active == "Active"
                ? language == 1
                  ? "Activo"
                  : "Active"
                : language == 1
                ? "Inactivo"
                : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Escojer" : "Select"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      console.log("Item fields:", item);
                      console.log("Item code:", item.code);
                      console.log("Vendor id:", item.vendor.id);
                      console.log("requirements:", selectedRequirements);
                      console.log("Related products:", relatedProductOptions);
                      console.log("Selected product options:", selectedProductOptions);
                      //getProductFamilies(state.familyId);
                      setRelatedProductOptions([]);
                      setSelectedProductOptions([]);
                      getRelatedProducts({
                        //requirements: selectedRequirements,
                        familyID: state.familyId,
                        productID: item.id,
                        code: item.code,
                        upc: item.upc,
                        name: item.name,
                        packaging: selectedRequirements.includes(1) && item.packaging,
                        //minimum: selectedRequirements.includes(2) && selectedProduct.minimum,
                        //maximum: selectedRequirements.includes(3) && selectedProduct.maximum,
                        //inQty: selectedRequirements.includes(4) && selectedProduct.in_qty,
                        //outQty: selectedRequirements.includes(5) && selectedProduct.out_qty,
                        // available: selectedRequirements.includes(6) && selectedProduct.available,
                        // diff: selectedRequirements.includes(7) && selectedProduct.diff,
                        // cost: selectedRequirements.includes(8) && selectedProduct.cost,
                        subcategoryID: selectedRequirements.includes(9) && item.subcategory_id,
                        supplier: selectedRequirements.includes(10) && item.vendor_id,
                        // isFood: selectedRequirements.includes(11) && selectedProduct.is_food,
                        // subcategoryID:
                        //   selectedRequirements.includes(12) && selectedProduct.subcategory_id,
                        // taxID: selectedRequirements.includes(13) && selectedProduct.tax_id,
                        // supplier: selectedRequirements.includes(14) && selectedProduct.vendor_id,
                        // warehouseID: selectedRequirements.includes(15) && selectedProduct.warehouse_id,
                      });
                      setSelectedProduct({
                        id: item.id,
                        code: item.code,
                        upc: item.upc,
                        name: item.name,
                        description: selectedProduct.description,
                        packaging: item.packaging,
                        minimum: item.minimum,
                        maximum: item.maximum,
                        inQty: item.in_qty,
                        outQty: item.out_qty,
                        available: item.available,
                        diff: item.diff,
                        cost: item.cost,
                        salesPrice: item.sales_price,
                        suggestedPrice: item.suggested_price,
                        isFood: item.is_food,
                        subcategory_id: item.subcategory.id,
                        tax_id: item.tax_id,
                        vendor_id: item.vendor.id,
                        warehouse_id: item.warehouse_id,
                      });
                      setProductTableVisible(false);
                    }}
                    iconOnly
                  >
                    <Icon>add</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProductData(tableData);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  //Inserts the checkbox values into the array of jsons
  const handleAllCheck = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values:", selectedRequirements);

    if (value === "all") {
      setSelectedRequirements(
        selectedRequirements.length === requirementsList.length
          ? []
          : requirementsList.map((val) => (!val.disabled ? val.value : null)) //This will not check any disabled checkboxes or checkboxes with disabled field.
        //: requirementsList.map((val) => val.value)
      );
      return;
    }

    const list = [...selectedRequirements];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List:", list);
    setSelectedRequirements(list);
  };

  // async function getProductData(requirements) {
  //   console.log("REQUIREMENTS 2:", requirements);
  //   await axios
  //     .get(IP + "/products/" + state.productId, {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //         Accept: "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       console.log("get Product Data response:", response.data.data);
  //       let productData = response.data.data;
  //       getRelatedProducts({
  //         productID: productData.id,
  //         code: productData.code,
  //         upc: productData.upc,
  //         name: productData.name,
  //         packaging: requirements.includes(1) && productData.packaging,
  //         //minimum: selectedRequirements.includes(2) && selectedProduct.minimum,
  //         //maximum: selectedRequirements.includes(3) && selectedProduct.maximum,
  //         //inQty: selectedRequirements.includes(4) && selectedProduct.in_qty,
  //         //outQty: selectedRequirements.includes(5) && selectedProduct.out_qty,
  //         // available: selectedRequirements.includes(6) && selectedProduct.available,
  //         // diff: selectedRequirements.includes(7) && selectedProduct.diff,
  //         // cost: selectedRequirements.includes(8) && selectedProduct.cost,
  //         subcategoryID: requirements.includes(9) && selectedProduct.subcategory_id,
  //         supplier: requirements.includes(10) && selectedProduct.vendor_id,
  //         // isFood: selectedRequirements.includes(11) && selectedProduct.is_food,
  //         // subcategoryID:
  //         //   selectedRequirements.includes(12) && selectedProduct.subcategory_id,
  //         // taxID: selectedRequirements.includes(13) && selectedProduct.tax_id,
  //         // supplier: selectedRequirements.includes(14) && selectedProduct.vendor_id,
  //         // warehouseID: selectedRequirements.includes(15) && selectedProduct.warehouse_id,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log("get Product Data error:", error);
  //     });
  // }

  useEffect(() => {
    getProducts();
    getProductFamilies(state.familyId);
    getFamilyRequirements(state.familyId);
    console.log("Family ID:", state.familyId);
    console.log("Product ID:", state.productId);
    setSelectedProduct({
      familyId: state.familyId,
      id: state.productId,
      code: state.code,
      upc: state.upc,
      name: state.name,
      packaging: state.packaging,
      subcategory_id: state.subcategory_id,
      vendor_id: state.vendor_id,
      //description: state.description,
    });
    setFamilyName(state.family_name);
    setFamilyDescription(state.description);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox p={2}>
          <MDTypography variant="h5">
            {language == 1 ? "Edit Familia de Productos" : "Edit Product Family"}
          </MDTypography>
          <Grid item lg={4}>
            <MDInput
              variant="standard"
              type="text"
              label={language == 1 ? "Family Name" : "Nombre de Familia"}
              sx={{ width: "100%" }}
              value={familyName}
              onChange={(e) => {
                setFamilyName(e.target.value);
                //setDate(e.target.value);
              }}
            />
            <ShowError id={"family_name"} />
          </Grid>
          <Grid item lg={4}>
            <MDInput
              variant="standard"
              type="text"
              label={language == 1 ? "Base Product" : "Producto"}
              sx={{ width: "100%" }}
              value={selectedProduct.name}
            />
            <ShowError id={"name"} />
          </Grid>
          <Grid container spacing={3} display="flex">
            <Grid item lg={4}>
              <MDInput
                variant="standard"
                type="text"
                label={language == 1 ? "Code" : "Codigo"}
                sx={{ width: "100%" }}
                value={selectedProduct.code}
                // onChange={(e) => {
                //   setFamilyCode(e.target.value);
                //   //setDate(e.target.value);
                // }}
              />
              <ShowError id={"code"} />
            </Grid>
            <Grid item lg={4}>
              <MDInput
                variant="standard"
                type="text"
                label="UPC"
                sx={{ width: "100%" }}
                value={selectedProduct.upc}
                // onChange={(e) => {
                //   setFamilyUPC(e.target.value);
                //   //setDate(e.target.value);
                // }}
              />
              <ShowError id={"upc"} />
            </Grid>
          </Grid>
          <Grid item lg={8} sx={{ marginBottom: "30px" }}>
            <MDInput
              variant="standard"
              type="text"
              label={language == 1 ? "Description" : "Descripcion"}
              sx={{ width: "100%" }}
              value={familyDescription}
              onChange={(e) => {
                setFamilyDescription(e.target.value);
                //setDate(e.target.value);
              }}
            />
            <ShowError id={"description"} />
          </Grid>
          {/* <Grid item lg={4}>
            <Autocomplete
              multiple={true}
              disablePortal
              onChange={(event, newValue) => {
                //setSelectedStore(newValue);
                setSelectedProduct(newValue);
                //GetInvoices(newValue);
                console.log("Selected Product:", newValue);
              }}
              value={selectedProduct}
              //options={stores}
              options={productList}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label={language == 1 ? "Producto" : "Base Product"}
                  fullWidth
                  variant="standard"
                />
              )}
            />
          </Grid> */}
          <Grid item lg={12} sx={{ marginBottom: "20px" }}>
            {productTableVisible ? (
              <>
                <MDTypography variant="h5">
                  {language == 1 ? "Seleccione un producto como base" : "Select a base product"}
                </MDTypography>
                <DataTable2
                  table={productData}
                  index={index}
                  setIndex={setIndex}
                  pageEntries={10}
                  entriesPerPage={true}
                  canSearch
                />
              </>
            ) : (
              <MDButton
                variant="gradient"
                style={{ marginBottom: "5%" }}
                disabled={remainder != 0}
                onClick={() => {
                  setProductTableVisible(true);
                  setSelectedProduct({
                    id: "",
                    name: "",
                    code: "",
                    upc: "",
                    description: selectedProduct.description,
                  });
                  setSelectedProductOptions([]);
                  setSelectedRelatedProducts([]);
                }}
                color="success"
              >
                {language == 1 ? "Cambiar producto" : "Change base product"}
              </MDButton>
            )}
          </Grid>
          {!productTableVisible ? (
            <>
              <Grid item lg={8} sx={{ marginBottom: "20px" }}>
                <MDTypography variant="h5">
                  {language == 1 ? "Requisitos" : "Requirements"}
                </MDTypography>
                <FormControlLabel
                  label="All"
                  control={
                    <Checkbox
                      value="all"
                      // sx={{
                      //   color: grey[500],
                      // }}
                      onChange={handleAllCheck}
                      checked={isAllSelected}
                      disableRipple
                    />
                  }
                />
                <List
                  sx={{
                    display: "flex",
                    flexFlow: "column wrap",
                    //columnCount: 4,
                    //gap: "0 10px",
                    height: 300, // set the height limit to your liking
                    width: 300,
                    //overflow: "auto",
                    //border: 1,
                    //borderRadius: 2,
                  }}
                >
                  {requirementsList.map((option) => {
                    return (
                      <ListItem key={option.value} disablePadding>
                        <ListItemButton
                          //onClick={handleToggle(value.value)}
                          // onClick={(e) => {
                          //   console.log("ListItemButton Click:", option.value);
                          //   handleAllCheck(option);
                          // }}
                          disabled={option.disabled}
                          dense
                        >
                          <Checkbox
                            // sx={{
                            //   color: grey[500],
                            // }}
                            disabled={option.disabled}
                            value={option.value}
                            onChange={handleAllCheck}
                            checked={
                              selectedRequirements.includes(option.value) //verify if the array contains the given value so that it'll appear checked in the list
                            }
                            //tabIndex={-1}
                            disableRipple
                          />
                          <ListItemText id={option.value} primary={option.label} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
                <ShowError id={"requirements"} />
              </Grid>
              <Grid item lg={4}>
                {/* <FormControlLabel
              label="All"
              control={
                <Checkbox
                  value="all"
                  // sx={{
                  //   color: grey[500],
                  // }}
                  onChange={handleAllCheckProducts}
                  checked={isAllSelectedProducts}
                  disableRipple
                />
              }
            />
             <List
              sx={{
                overflow: "auto",
                border: 1,
                borderRadius: 2,
              }}
            >
              {relatedProductList.map((option) => {
                return (
                  <ListItem key={option.value} disablePadding>
                    <ListItemButton
                      //onClick={handleToggle(value.value)}
                      dense
                    >
                      <Checkbox
                        // sx={{
                        //   color: grey[500],
                        // }}
                        value={option.value}
                        onChange={handleAllCheckProducts}
                        checked={
                          selectedRelatedProduct.includes(option.value) //verify if the array contains the given value so that it'll appear checked in the list
                        }
                        //tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText id={option.value} primary={option.label} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List> */}
                {/* <Autocomplete
              multiple={true}
              disablePortal
              onChange={(event, newValue) => {
                //setSelectedStore(newValue);
                setSelectedProduct(newValue);
                //GetInvoices(newValue);
                console.log("Selected Product:", newValue);
              }}
              value={selectedProduct}
              //options={stores}
              options={relatedProductList}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label={language == 1 ? "Producto" : "Base Product"}
                  fullWidth
                  variant="standard"
                />
              )}
            /> */}
              </Grid>
              {relatedProductOptions.length > 0 ? (
                <Grid item lg={12} sx={{ marginBottom: "20px" }}>
                  <>
                    {/* <MDTypography variant="h5">
                {language == 1
                  ? "Productos basados en los requerimientos"
                  : "Products based on requirements"}
              </MDTypography> */}
                    {/* <DataTable2
                table={relatedProductData}
                index={index}
                setIndex={setIndex}
                pageEntries={10}
                entriesPerPage={true}
                canSearch
              /> */}
                    {/* <MaterialReactTable
                //globalFilterFn="contains"
                columns={relatedProductData.columns}
                data={relatedProductData.rows}
                enableRowSelection
                // onRowSelectionChange={setRowSelection}
                // renderTopToolbarCustomActions={({ table }) => {
                //   //setSelectedProductID(
                //   selectedProductIDref.current = table.getSelectedRowModel().flatRows; //get selected row data (not just index)
                //   //)
                // }}
                renderToolbarInternalActions={({ table }) => (
                  <>
                    <MRT_ToggleGlobalFilterButton table={table} />
                    <MRT_ToggleFiltersButton table={table} />
                    <MRT_ToggleDensePaddingButton table={table} />
                  </>
                )}
                //autoResetAll={false}
              /> */}
                    <VirtualizedAutocomplete
                      multiple
                      label="Related Products"
                      id="checkboxes-tags-demo"
                      style={{ width: 320 }}
                      options={relatedProductOptions}
                      defaultValue={selectedProductOptions}
                      onChange={(event, newValue) => {
                        console.log("Selected related product:", newValue);
                        setSelectedRelatedProducts(newValue);
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="standard-multiline-flexible"
                          multiline
                          maxRows="4"
                          variant="outlined"
                          label="Related Products"
                        />
                      )}
                    />
                  </>
                  <ShowError id={"products"} />
                </Grid>
              ) : null}
              {matchingProductResults ? (
                <Grid item lg={4} style={{ marginRight: "3%" }}>
                  <MDTypography variant="h5">
                    {language == 1 ? "No hay " : "No matching products"}
                  </MDTypography>
                </Grid>
              ) : null}
              <Grid style={{ marginRight: "3%" }}>
                <MDButton
                  variant="gradient"
                  style={{ marginBottom: "5%" }}
                  disabled={remainder != 0}
                  onClick={() => {
                    console.log("SELECTED REQUIREMENTS:", selectedRequirements);
                    console.log("SELECTED PRODUCTS:", selectedProduct);
                    console.log(selectedProduct.id);
                    setRelatedProductOptions([]);
                    getRelatedProducts({
                      //requirements: selectedRequirements,
                      familyID: state.familyId,
                      productID: selectedProduct.id,
                      code: selectedProduct.code,
                      upc: selectedProduct.upc,
                      name: selectedProduct.name,
                      packaging: selectedRequirements.includes(1) && selectedProduct.packaging,
                      //minimum: selectedRequirements.includes(2) && selectedProduct.minimum,
                      //maximum: selectedRequirements.includes(3) && selectedProduct.maximum,
                      //inQty: selectedRequirements.includes(4) && selectedProduct.in_qty,
                      //outQty: selectedRequirements.includes(5) && selectedProduct.out_qty,
                      // available: selectedRequirements.includes(6) && selectedProduct.available,
                      // diff: selectedRequirements.includes(7) && selectedProduct.diff,
                      // cost: selectedRequirements.includes(8) && selectedProduct.cost,
                      subcategoryID:
                        selectedRequirements.includes(9) && selectedProduct.subcategory_id,
                      supplier: selectedRequirements.includes(10) && selectedProduct.vendor_id,
                      // isFood: selectedRequirements.includes(11) && selectedProduct.is_food,
                      // subcategoryID:
                      //   selectedRequirements.includes(12) && selectedProduct.subcategory_id,
                      // taxID: selectedRequirements.includes(13) && selectedProduct.tax_id,
                      // supplier: selectedRequirements.includes(14) && selectedProduct.vendor_id,
                      // warehouseID: selectedRequirements.includes(15) && selectedProduct.warehouse_id,
                    });
                  }}
                  color="success"
                >
                  {language == 1 ? "Buscar productos" : "Search related products"}
                </MDButton>
              </Grid>
            </>
          ) : null}
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "3%",
            }}
          >
            <Grid style={{ marginRight: "3%" }}>
              <MDButton
                variant="gradient"
                style={{ marginBottom: "5%" }}
                disabled={remainder != 0}
                onClick={() => {
                  updateFamily({
                    family_name: familyName,
                    family_id: state.familyId,
                    product_id: selectedProduct.id,
                    name: selectedProduct.name,
                    code: selectedProduct.code,
                    upc: selectedProduct.upc,
                    description: familyDescription,
                    supplier: selectedProduct.vendor_id,
                    packaging: selectedProduct.packaging,
                    subcategory_id: selectedProduct.subcategory_id,
                    products: selectedRelatedProducts,
                    requirements: selectedRequirements,
                    //supplier: selectedRequirements.includes(10) ? selectedProduct.supplier : null,
                    //packaging: selectedRequirements.includes(1) ? selectedProduct.packaging : null,
                    // department: selectedRequirements.includes(9)
                    //   ? selectedProduct.subcategoryID
                    //   : null,
                  });
                }}
                color="success"
              >
                {language == 1 ? "Guardar Familia" : "Save Family"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default EditFamily;
