import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Icon } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";
import FormField from "layouts/pages/users/new-user/components/FormField";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import PurchaseOrderModal from "app/components/purchaseOrderModal";
import AlertDialog from "app/components/confirmBox";
import AlertDialog2 from "app/components/confirmBoxDeliveryPickup";
import AlertDialogPurchase from "app/components/confirmBoxPurchaseOrder";
import DynamicPurchasePopup from "app/components/dynamicPurchasePopup";
import IP from "app/IP";

const axios = require("axios");

function EditPurchaseOrder() {
  const [date, setDate] = useState("");
  const [eda, setEda] = useState("");
  const [productCheck, setProductCheck] = useState([]);
  const [added, setAdded] = useState(false);
  const [vendorId, setVendorId] = useState();
  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendorProducts, setVendorProducts] = useState({ columns: [], rows: [] });
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [prod, setProd] = useState("");
  const [changeTable, setChangeTable] = useState(true);
  const [purchaseOProducts, setPurchaseOProducts] = useState([]);
  const [isQtyEdit, setIsQtyEdit] = useState(false);
  const [prodToDel, setProdToDel] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [changeStatus, setChangeStatus] = useState(false);
  const [transport, setTransport] = useState("D");
  const [currentTotal, setCurrentTotal] = useState(0.0);
  const [deliveries, setDeliveries] = useState([]);
  const [showmsg2, setShowMsg2] = useState("");
  const [noProdsNotif, setNoProdsNotif] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const toggleSnackbar = () => setShow(!show);
  const toggleSnackbar2 = () => setShowMsg2(!showmsg2);
  const toggleSnackbar3 = () => setNoProdsNotif(!noProdsNotif);
  const [additionalFees, setAdditionalFees] = useState(0.0);
  const navigate = useNavigate();

  async function getVendorProducts(vendorid) {
    localStorage.setItem("vendorId", selectedVendor.id);
    await axios
      .get(IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId") + "/po_details", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Vendor Products:", response.data.data);
        setProductCheck(response.data.data.selected);
        let vendorProductColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: language == 1 ? "Cant. Max." : "Qty. Max", accessor: "maximum" },
          { Header: language == 1 ? "Cant. Min." : "Qty. Min", accessor: "minimum" },
          { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
          { Header: "Diff.", accessor: "diff" },
          { Header: language == 1 ? "Cant." : "Qty", accessor: "qty" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price" },
          { Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];
        //test
        let vendorProductRows = response?.data?.data?.all
          ?.filter((item) => {
            return item.active == "Active";
          })
          .map((item) => {
            //console.log(item);
            return {
              name: item.name,
              upc: item.upc,
              upcunit: item.upcUnit,
              maximum: item.maximum,
              minimum: item.minimum,
              available: item.available,
              diff: item.diff,
              price: item.cost,
              tax: parseFloat(item.cost * (item.tax.percent / 100)).toFixed(2),
              qty: 0,
              action: (
                <MDButton
                  color="info"
                  onClick={() => {
                    setOpen(true);
                    setProd(item);
                    setIsQtyEdit(false);
                  }}
                  type="button"
                  variant="gradient"
                >
                  {language == 1 ? "Añadir" : "Add"}
                </MDButton>
              ),
            };
          });

        console.log(vendorProductRows);

        let tableData = { columns: vendorProductColumns ?? [], rows: vendorProductRows ?? [] };
        setVendorProducts(tableData);

        let purchaseOColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price" },
          { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty" },
          { Header: "Sub-Total", accessor: "subtotal" },
          { Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          { Header: "Total", accessor: "total", width: "15%" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let purchaseORows = response?.data?.data?.selected?.map((item) => {
          return {
            name: item.product.name,
            upc: item.product.upc,
            upcunit: item.product.upcUnit,
            tax: parseFloat(item.tax).toFixed(2),
            price: item.cost,
            qty: item.quantity,
            subtotal: parseFloat(item.quantity * item.cost).toFixed(2),
            total: parseFloat(item.total).toFixed(2),
            action: (
              <>
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    color="info"
                    onClick={() => {
                      setOpen(true);
                      setProd(item);
                      setIsQtyEdit(true);
                    }}
                    type="button"
                    variant="gradient"
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>
                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                  <MDButton
                    color="error"
                    onClick={() => {
                      setProdToDel(item);
                      setConfirm(true);
                    }}
                    type="button"
                    variant="gradient"
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>
              </>
            ),
          };
        });

        let sub = response.data.data.selected.map((item) => {
          return parseFloat(item.quantity * item.cost);
        });

        let subtotal = sub.reduce((prev, current) => prev + current, 0);

        let taxTotal = response.data.data.selected
          .map((item) => {
            return parseFloat(item.tax);
          })
          .reduce((prev, current) => prev + current, 0);

        let prices = response?.data?.data?.selected?.map((item) => {
          return parseFloat(item.total);
        });

        let totalPrice = prices?.reduce((prev, current) => prev + current, 0);
        setCurrentTotal(totalPrice);

        let totalQty = response?.data?.data?.selected?.reduce(
          (prev, current) => prev + current.quantity,
          0
        );

        purchaseORows.push({
          name: "",
          price: "",
          qty: language == 1 ? "Total de bultos: " + totalQty : "Total Quantity: " + totalQty,
          subtotal: "Sub-Total: $" + parseFloat(subtotal).toFixed(2),
          tax:
            language == 1
              ? "Total de Impuestos: $" + parseFloat(taxTotal).toFixed(2)
              : "Tax Total: $" + parseFloat(taxTotal).toFixed(2),
          total:
            language == 1
              ? "Precio Total: $" + parseFloat(totalPrice).toFixed(2)
              : "Total Price: $" + parseFloat(totalPrice).toFixed(2),
          action: "",
        });

        let tableDataPO = { columns: purchaseOColumns ?? [], rows: purchaseORows ?? [] };
        setPurchaseOProducts(tableDataPO);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function getPurchaseOrder() {
    await axios
      .get(IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Purchase order request:", response.data.data);
        let orders = response.data.data;
        setDate(orders.date);
        setEda(orders.eda);
        setSelectedVendor(orders.vendor.name);
        setVendorId(orders.vendor.id);
        getVendorProducts(orders.vendor.id);
      });
  }

  async function sendPurchaseOrder() {
    setDisableButton(true);
    await axios
      .put(
        IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId"),
        {
          date: date,
          eda: eda,
          vendor_id: vendorId,
          status_id: 1,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        setErrors([]);
        toggleSnackbar();
        getVendorProducts();
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  async function deleteOrderDetailProduct() {
    setConfirm(false);
    await axios
      .delete(IP + "/po_details/" + prodToDel.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("remove");
        getPurchaseOrder();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  async function getDeliveryOptions() {
    await axios
      .get(IP + "/deliveries", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("test");
        console.log(response.data.data?.length > 0);
        if (response.data.data?.length > 0) {
          setDeliveryOptions(
            response.data.data.map((item) => {
              return { value: item.id, label: item.name };
            })
          );
        }
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function CambiarEstatus() {
    await axios
      .post(
        IP + "/submit/purchase",
        {
          purchase_order_id: localStorage.getItem("purchaseOrderId"),
          transport: transport,
          additional_fee: additionalFees,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar2();
        setTimeout(() => {
          navigate("/purchase_orders");
        }, 2000);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  const getTotal = () => {
    return currentTotal;
  };

  useEffect(() => {
    getPurchaseOrder();
    getDeliveryOptions();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PurchaseOrderModal
        open={open}
        setAdded={setAdded}
        currentTotal={getTotal()}
        setCurrentTotal={setCurrentTotal}
        Product={prod}
        setOpen={setOpen}
        setVendorProducts={setVendorProducts}
        setPurchaseOProducts={setPurchaseOProducts}
        isEdit={isQtyEdit}
        setIsQtyEdit={setIsQtyEdit}
        setProd={setProd}
        deleteProd={deleteOrderDetailProduct}
        setProdToDel={setProdToDel}
      />
      <AlertDialog
        Show={confirm}
        setShowConfirm={setConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres sacar el producto '" + prodToDel?.product?.name + "' ?"
            : "Are you sure you want to remove the product '" + prodToDel?.product?.name + "' ?"
        }
        OkayFunction={deleteOrderDetailProduct}
      />
      <DynamicPurchasePopup
        Show={openPopup}
        setShowConfirm={setOpenPopup}
        data={{
          po_id: localStorage.getItem("purchaseOrderId"),
          vendor_id: vendorId,
        }}
        OkayFunction={getPurchaseOrder}
      />
      <AlertDialogPurchase
        Show={changeStatus}
        setShowConfirm={setChangeStatus}
        Message={
          language == 1
            ? "¿Estás seguro que quieres que se confirme esta Orden de Compra?"
            : "Are you sure you want confirm this Purchase Order?"
        }
        selected={transport}
        setSelected={setTransport}
        total={parseFloat(currentTotal).toFixed(2)}
        OkayFunction={CambiarEstatus}
        AdditionalFee={additionalFees}
        setFee={setAdditionalFees}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Cambio hecho exitosamente!" : "Change Succesful!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Orden Confirmada!" : "Order Confirmed!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={showmsg2}
        close={toggleSnackbar2}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "No se encontraron productos" : "No products found"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={noProdsNotif}
        close={toggleSnackbar3}
      />
      <MDBox py={3} mb={20} height="65vh">
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDBox>
              <MDBox>
                <MDBox lineHeight={0}>
                  <MDTypography variant="h5">
                    {language == 1 ? "Editar Orden de Compra" : "Edit Purchase Order"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    {language == 1
                      ? "Favor de entrar la información de la orden."
                      : "Please enter order information."}
                  </MDTypography>
                </MDBox>
                <MDBox id="box1" mt={1.625}>
                  <Grid id="grid1" container spacing={3}>
                    <Grid id="grid1-1" item xs={12} sm={3}>
                      <MDInput
                        type="date"
                        variant="standard"
                        label={language == 1 ? "Fecha" : "Date"}
                        fullWidth
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                      <ShowError id={"date"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        type="date"
                        variant="standard"
                        label="EDA"
                        fullWidth
                        value={eda}
                        onChange={(e) => {
                          setEda(e.target.value);
                        }}
                      />
                      <ShowError id={"eda"} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <MDInput
                        value={selectedVendor}
                        type="text"
                        label={language == 1 ? "Vendedor" : "Vendor"}
                        variant="standard"
                        fullWidth
                      />
                      <ShowError id={"vendor_id"} />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <MDBox mt={1.625} display="flex" justifyContent="right">
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={() => {
                    localStorage.removeItem("purchaseOrderId");
                    localStorage.removeItem("vendorId");
                    navigate("/purchase_orders");
                  }}
                >
                  {language == 1 ? "Cancelar" : "Cancel"}
                </MDButton>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  onClick={sendPurchaseOrder}
                  disabled={disableButton}
                >
                  {language == 1 ? "Guardar" : "Save"}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
        <Grid>
          <MDBox id="box1" mt={2}>
            <Card>
              <MDBox id="box1" mt={-4.5}>
                {productCheck[0] || added ? (
                  !changeTable ? (
                    <MDButton
                      sx={{ ml: 2, mb: -14 }}
                      type="button"
                      variant="gradient"
                      onClick={() => {
                        setChangeTable(true);
                      }}
                      className="float-sm-right"
                      color="info"
                    >
                      {language == 1 ? "Añadir Productos" : "Add Products"}
                    </MDButton>
                  ) : (
                    <MDButton
                      sx={{ mb: -14, ml: 2 }}
                      type="button"
                      variant="gradient"
                      className="float-sm-right"
                      color="info"
                      onClick={() => {
                        setChangeTable(false);
                      }}
                    >
                      <Icon>shopping_cart</Icon>
                      &nbsp;{language == 1 ? "Carrito" : "PO Cart"}
                    </MDButton>
                  )
                ) : null}{" "}
                {productCheck[0] || added ? (
                  <MDButton
                    sx={{ mb: -14 }}
                    type="button"
                    variant="gradient"
                    className="float-sm-right"
                    color="warning"
                    onClick={() => {
                      setChangeStatus(true);
                    }}
                  >
                    <Icon>shopping_cart</Icon>
                    {language == 1 ? "Confirmar Orden" : "Confirm Order"}
                  </MDButton>
                ) : null}{" "}
                <MDButton
                  sx={{ mb: -14 }}
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="dark"
                  onClick={() => {
                    setOpenPopup(!openPopup);
                  }}
                >
                  <Icon>shopping_cart</Icon>
                  {language == 1 ? "Compras Dinamicas" : "Dynamic Purchases"}
                </MDButton>
              </MDBox>
              {changeTable ? (
                <DataTable table={vendorProducts} entriesPerPage={false} canSearch />
              ) : (
                <DataTable table={purchaseOProducts} entriesPerPage={false} canSearch />
              )}
            </Card>
          </MDBox>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditPurchaseOrder;
