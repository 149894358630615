/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable2 from "app/components/shopperTable";

import IP from "app/IP";

const axios = require("axios");

function SubCategories() {
  const [menu, setMenu] = useState(null);
  const [subCategory, setSubCategory] = useState({ columns: [], rows: [] });
  const [showConfirm, setShowConfirm] = useState(false);
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(0);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [show3, setShow3] = useState(false);
  const toggleSnackbar3 = () => setShow3(!show3);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function DeleteSubCategory() {
    await axios
      .delete(IP + "/subcategories/" + localStorage.getItem("subcategoryDeleteId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        localStorage.removeItem("subcategoryDeleteId");
        getSubCategory();
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        setShow2(true);
        console.log(error);
      });
  }

  async function changeStatus(id) {
    await axios
      .post(
        IP + "/subcategories/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        getSubCategory();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error.response.data.data);
        if (language == 1 ? error.response.data.data == 'La subcategoría pertenece a una categoría inactiva' : error.response.data.data == 'Subcategory has Inactive Category')
          toggleSnackbar3();
        setTimeout(() => {
          setShow3(false);
        }, 3000);
      });
  }

  async function getSubCategory() {
    await axios
      .get(IP + "/subcategories", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          //Accept: "application/json",
        },
      })
      .then((response) => {
        let subCategoryColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Categoría" : "Category", accessor: "category" },
          { Header: language == 1 ? "Posición" : "Position", accessor: "position", width: '8%' },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let subCategoryRows = response.data.data.map((item) => {
          //console.log(item);
          return {
            name: item.name,
            category: item.category.name,
            position: item.position,
            active: item.active == "Active" ? 
              language==1 ? "Activo" : "Active" :
              language==1 ? "Inactivo" : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      localStorage.setItem("subCategoryId", item.id);
                      navigate("/edit-subcategory");
                    }}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>{" "}
                {item.active == "Inactive" ? (
                  <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(false);
                      }}
                      iconOnly
                    >
                      <Icon>check</Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(true);
                      }}
                      iconOnly
                    >
                      <Icon>do_not_disturb</Icon>
                    </MDButton>
                  </Tooltip>
                )}{" "}
                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      localStorage.setItem("subcategoryDeleteId", item.id);
                      setShowConfirm(true);
                    }}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: subCategoryColumns, rows: subCategoryRows };
        //console.log(tableData)
        setSubCategory(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    getSubCategory();
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres borrar esta sub-categoría?"
            : "Are you sure you want to delete the sub-category?"
        }
        OkayFunction={DeleteSubCategory}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1
            ? `Sub-categoría ${active ? "Desactivada" : "Activada"} Exitosamente!`
            : `Sub-Category ${active ? "Deactivated" : "Activated"} Succesfully!`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title=""
        content={
          language == 1
            ? "La sub-categoría esta atachada a un producto; no se pudo borrar."
            : "This sub-category is attached to a product; could not be deleted."
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={() => setShow2(!show2)}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title=""
        content={
          language == 1
            ? `No se pudo activar la subcategoria, la categoria attachada esta desactivada.`
            : `Sub-category could not be activated. Its category is inactive.`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show3}
        close={toggleSnackbar3}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={() => navigate("/create-subcategory")}>
            {language == 1 ? "Crear Sub-Categoría" : "Create Sub-Category"}
          </MDButton>
        </MDBox>
        <Card>
          <DataTable2
            table={subCategory}
            index={index}
            setIndex={setIndex}
            pageEntries={10}
            entriesPerPage={true}
            canSearch
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default SubCategories;
