/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { ExportToExcel } from "app/components/exportToExcel";

import IP from "app/IP";

const axios = require("axios");

function WarehouseList() {
  const [menu, setMenu] = useState(null);
  const [warehouseData, setWarehouseData] = useState({ columns: [], rows: [] });
  const [showConfirm, setShowConfirm] = useState(false);
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function DeleteWarehouse() {
    await axios
      .delete(IP + "/warehouses/" + localStorage.getItem("warehouseDeleteId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        localStorage.removeItem("warehouseDeleteId");
        getWarehouseData();
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function changeStatus(id) {
    await axios
      .post(
        IP + "/warehouses/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        getWarehouseData();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getWarehouseData() {
    await axios
      .get(IP + "/warehouses", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let warehouseColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Teléfono" : "Phone", accessor: "phone" },
          { Header: language == 1 ? "Nombre de Contacto" : "Contact Name", accessor: "contact" },
          {
            Header: language == 1 ? "Teléfono de Contacto" : "Contact Phone",
            accessor: "contact_phone",
          },
          {
            Header: language == 1 ? "Email de Contacto" : "Contact Email",
            accessor: "contact_email",
          },
          { Header: language == 1 ? "Ciudad" : "City", accessor: "city_name" },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "25%" },
        ];

        let warehouseRows = response.data.data.map((item) => {
          //console.log(item);
          return {
            name: item.name,
            phone: item.phone,
            contact: item.contact_name,
            contact_phone: item.contact_phone,
            contact_email: item.contact_email,
            city_name: item.city.name,
            active: item.active,
            action: (
              <Grid>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("warehouseId", item.id);
                    navigate("/edit-warehouse");
                  }}
                  iconOnly
                >
                  <Icon>edit</Icon>
                </MDButton>
                {item.active == "Inactive" ? (
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      changeStatus(item.id);
                    }}
                  >
                    <Icon>check</Icon>
                  </MDButton>
                ) : (
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      changeStatus(item.id);
                    }}
                    iconOnly
                  >
                    <Icon>do_not_disturb</Icon>
                  </MDButton>
                )}
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    localStorage.setItem("warehouseDeleteId", item.id);
                    setShowConfirm(true);
                  }}
                  iconOnly
                >
                  <Icon>delete</Icon>
                </MDButton>
              </Grid>
            ),
          };
        });

        let tableData = { columns: warehouseColumns, rows: warehouseRows };
        //console.log(tableData)
        setWarehouseData(tableData);

        let warehouseExcel = response.data.data.map((item) => {
          //console.log(item);
          if (language == 1) {
            return {
              Nombre: item.name,
              Teléfono: item.phone,
              Contacto: item.contact_name,
              "Teléfono Contacto": item.contact_phone,
              "Email Contacto": item.contact_email,
              Ciudad: item.city.name,
            };
          } else {
            return {
              Name: item.name,
              Phone: item.phone,
              Contact: item.contact_name,
              "Contact Phone": item.contact_phone,
              "Contact Email": item.contact_email,
              "City Name": item.city.name,
            };
          }
        });

        setData(warehouseExcel);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function nav() {
    navigate("/create-warehouse");
  }

  useEffect(() => {
    getWarehouseData();
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres remover este almacén?"
            : "Are you sure you want to delete this warehouse?"
        }
        OkayFunction={DeleteWarehouse}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1 ? "El almacen ha sido activado!" : "Warehouse Activated Succesfully!"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={nav}>
            {language == 1 ? "Crear Almacén" : "Create Warehouse"}
          </MDButton>
          <MDBox display="flex">
            <MDBox ml={1}>
              {/* <ExportToExcel apiData={data} fileName={language == 1 ? "Almacenes" : "Warehouses"} /> */}
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={warehouseData} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default WarehouseList;
