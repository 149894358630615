import { useEffect, useState } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormGroup from "@mui/material/FormGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import VirtualizedAutocomplete from "app/components/VirtualizedAutocomplete";
import CustomDatePicker from "components/CustomDatePicker";
import IP from "app/IP";
import axios from "axios";

export default function DynamicPurchasePopup({
  Show,
  setShowConfirm,
  data,
  Message,
  OkayFunction,
  CancelFunction,
}) {
  const [open, setOpen] = React.useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [language, setLanguage] = React.useState(localStorage.getItem("Language"));
  //Checkbox options
  const [purchaseRequest, setPurchaseRequest] = useState(false);
  const [openPO, setOpenPO] = useState(false);
  //Calculation methods
  const [selectedCalcMethod, setSelectedCalcMethod] = useState("");
  const [calculationMethod, setCalculationMethod] = useState([
    { label: "Maximum", value: "max" },
    { label: "Replenishment", value: "replenish" },
    { label: "Days of supply", value: "supply" },
  ]);
  //Sales Dates
  const [salesFrom, setSalesFrom] = useState(null);
  const [salesTo, setSalesTo] = useState(null);
  //Days of supply
  const [historyFactor, setHistoryFactor] = useState("");
  const [daysOfSupply, setDaysOfSupply] = useState("");
  const [errors, setErrors] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // console.log(Show);

  async function dynamicPurchase() {
    console.log("Data for dynamic purchase:", data);
    setShowLoading(true);
    await axios
      .post(
        IP + "/dynamicPurchase",
        {
          po_id: data.po_id,
          vendor_id: data.vendor_id,
          purchase_request: purchaseRequest,
          open_po: openPO,
          calc_method: selectedCalcMethod.value,
          sales_from: salesFrom,
          sales_to: salesTo,
          history_factor: historyFactor,
          days_of_supply: daysOfSupply,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Dynamic Purchase Response:", response);
        setShowLoading(false);
        setShowConfirm(false);
        setErrors([]);
        OkayFunction();
        // if (response.data.length > 0) {
        //   OkayFunction();
        // } else {
        //   OkayFunction("error");
        // }
      })
      .catch((error) => {
        console.log("Dynamic Purchase Error:", error);
        setErrors(error.response.data.data);
        setShowLoading(false);
        setShowConfirm(false);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            {/* <label>{item}</label> */}
            <label>{language == 1 ? "El campo es requerido." : "The field is required."}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  return (
    <div>
      {/*  <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={showLoading}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {" "}
          <p style={{ color: "black" }}>
            {language
              ? "Processing purchases please wait..."
              : "Procesando compras por favor espere..."}
          </p>
        </DialogTitle>
        <DialogContent>
          <CircularProgress sx={{ display: "flex", marginLeft: "30%" }} size={80} color="info" />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={Show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {" "}
          <p style={{ color: "black" }}>
            {language == 1 ? "Compras Dinamicas" : "Dynamic Purchases"}
          </p>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            <p style={{ color: "black" }}>{Message}</p>
          </DialogContentText> */}
          {/* <Card> */}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ md: 3 }}
            sx={{
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {/* <FormGroup sx={{ mt: -1, ml: 2 }}> */}
            <Grid item lg={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={purchaseRequest}
                    onChange={() => {
                      //setPurchaseRequest(!purchaseRequest), setOpenPO(false);
                      setPurchaseRequest(!purchaseRequest);
                    }}
                  />
                }
                label={language == 1 ? "Solicitudes de compras" : "Purchase requests"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={openPO}
                    onChange={() => {
                      //setPurchaseRequest(false), setOpenPO(!openPO);
                      setOpenPO(!openPO);
                    }}
                  />
                }
                label={language == 1 ? "Abrir PO's" : "Open PO's"}
              />
              {/* </FormGroup> */}
            </Grid>
            <Grid item lg={6}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                size="small"
                options={calculationMethod}
                getOptionsLabel={(options) => options.label || ""}
                value={selectedCalcMethod}
                onChange={(event, newValue) => {
                  setSelectedCalcMethod(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Metodo de Calculo" : "Calculation Method"}
                    variant="outlined"
                  />
                )}
              />
              <Grid
                container
                item
                justifyContent={"space-between"}
                //lg={8}
                // sx={{
                //   justifyContent: "space-around",
                //   alignItems: "center",
                // }}
              >
                <Grid item lg={5}>
                  <CustomDatePicker
                    label={language == 1 ? "Ventas Desde" : "Sales From"}
                    sx={{ m: 1, width: "100%" }}
                    //minDate={fromDate && dayjs(fromDate)}
                    //maxDate={toDate && dayjs(toDate)}
                    value={salesFrom}
                    onChange={(newValue) => {
                      setSalesFrom(newValue);
                    }}
                  />
                  <ShowError id={"sales_from"} />
                </Grid>
                <Grid item lg={5}>
                  <CustomDatePicker
                    label={language == 1 ? "Ventas Hasta" : "Sales To"}
                    sx={{ m: 1, width: "100%" }}
                    //minDate={fromDate && dayjs(fromDate)}
                    //maxDate={toDate && dayjs(toDate)}
                    value={salesTo}
                    onChange={(newValue) => {
                      setSalesTo(newValue);
                    }}
                  />
                  <ShowError id={"sales_to"} />
                </Grid>
                {selectedCalcMethod.value == "supply" && (
                  <>
                    <Grid item lg={5}>
                      <MDInput
                        variant="outlined"
                        type="text"
                        label={language == 1 ? "Factor Histórico" : "History Factor"}
                        sx={{ m: 1, width: "100%" }}
                        value={historyFactor}
                        onChange={(e) => {
                          setHistoryFactor(e.target.value);
                        }}
                      />
                      <ShowError id={"history_factor"} />
                    </Grid>
                    <Grid item lg={5}>
                      <MDInput
                        variant="outlined"
                        type="text"
                        label={language == 1 ? "Días de Suministro" : "Days of Supply"}
                        sx={{ m: 1, width: "100%" }}
                        value={daysOfSupply}
                        onChange={(e) => {
                          setDaysOfSupply(e.target.value);
                        }}
                      />
                      <ShowError id={"days_of_supply"} />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* </Card> */}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="light"
            onClick={() => {
              setShowConfirm(false);
            }}
          >
            {language == 1 ? "Cancelar" : "Cancel"}
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={() => {
              //setShowConfirm(false);
              dynamicPurchase();
            }}
            autoFocus
          >
            {language == 1 ? "Someter" : "Submit"}
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
