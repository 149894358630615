import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import IP from "app/IP";
import {TextField} from "@mui/material";

const axios = require("axios");

function EditSale() {
  const current = new Date();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${
    month < 10 ? `0${month}` : `${month}`
  }-${current.getDate()}`;

  const [date, setDate] = useState(currentDate);
  const [subtotal, setSubtotal] = useState("");
  const [tax, setTax] = useState([{}]);
  const [selectedTax, setSelectedTax] = useState("");
  const [status, setStatus] = useState([{}]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();

  async function getSalesData() {
    await axios
      .get(IP + "/sales_orders/" + localStorage.getItem("salesId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let sales = response.data.data;
        console.log(response.data.data);
        setDate(sales.date),
          setSubtotal(sales.subtotal),
          setSelectedTax({ id: sales.tax.id, label: sales.tax.percent });
        setSelectedStatus({ id: sales.status.id, label: sales.status.name });
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetTax() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/taxes", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setTax(
          response.data.data.map((item) => {
            return { label: item.percent, id: item.id };
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetStatus() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/status/sales", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setStatus(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendSale() {
    setDisableButton(true);
    await axios
      .put(
        IP + "/sales_orders/" + localStorage.getItem("salesId"),
        {
          date: date,
          subtotal: subtotal,
          tax: selectedTax.id,
          status: selectedStatus.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          navigate("/sales");
        }, 3000);
        console.log(response);
        toggleSnackbar();
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    GetTax();
    GetStatus();
    getSalesData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Material Dashboard"
        content="Sale Created Successfully!"
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox>
                  <MDBox>
                    <MDBox lineHeight={0}>
                      <MDTypography variant="h5">Edit Sale</MDTypography>
                      <MDTypography variant="button" color="text">
                        Please enter sale information.
                      </MDTypography>
                    </MDBox>
                    <MDBox id="box1" mt={1.625}>
                      <Grid id="grid1" container spacing={3}>
                        <Grid id="grid1-1" item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            type="date"
                            label="Date"
                            fullWidth
                            value={date}
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}
                          />
                          <ShowError id={"name"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label="Subtotal"
                            fullWidth
                            value={subtotal}
                            onChange={(e) => {
                              setSubtotal(e.target.value);
                            }}
                          />
                          <ShowError id={"subtotal"} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disablePortal
                            value={selectedTax}
                            onChange={(event, newValue) => {
                              console.log(newValue);
                              setSelectedTax(newValue);
                            }}
                            options={tax}
                            renderInput={(params) => (
                              <TextField {...params} label="Tax" variant="standard" />
                            )}
                          />
                          <ShowError id={"tax"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disablePortal
                            value={selectedStatus}
                            onChange={(event, newValue) => {
                              console.log(newValue);
                              setSelectedStatus(newValue);
                            }}
                            options={status}
                            renderInput={(params) => (
                              <TextField {...params} label="Status" variant="standard" />
                            )}
                          />
                          <ShowError id={"status_id"} />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                  <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                    <MDButton variant="gradient" color="light" onClick={() => navigate("/sales")}>
                      Cancel
                    </MDButton>
                    <MDButton
                      type="button"
                      variant="gradient"
                      className="float-sm-right"
                      color="info"
                      onClick={sendSale}
                      disabled={disableButton}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditSale;
