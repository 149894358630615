/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { flushSync } from "react-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import EditableRow from "./EditableRow";
import ReadOnlyRow from "./ReadOnlyRow";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import MDSnackbar from "components/MDSnackbar";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";

import IP from "app/IP";
import swal from "sweetalert";

const axios = require("axios");

function EditPayment() {
  const [menu, setMenu] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [paymentData, setPaymentData] = useState({ columns: [], rows: [] });
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [monto, setMonto] = useState(0.0);
  const [paymentTotal, setPaymentTotal] = useState(0.0);
  const [totalPay, setTotalPay] = useState(0.0);
  const [received, setReceived] = useState(0.0);
  const [remainder, setRemainder] = useState(0.0);
  const [editIds, setEditIds] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [editInvoice, setEditInvoice] = useState(0);
  const [start, SetStart] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("CHECK");
  const [transactionNumber, setTransactionNumber] = useState("");
  const [paginateData, setPaginateData] = useState({
    columns: [
      { Header: "", accessor: "name" },

      { Header: "Invoice Number.", accessor: "invoice_number" },
      { Header: "Pending", accessor: "total" },
      { Header: "Payment Amount", accessor: "amount" },
      { Header: "Actions", accessor: "actions" },
    ],
    rows: [],
  });
  const [editFormData, setEditFormData] = useState({
    fullName: "",
    address: "",
    phoneNumber: "",
    pending: "",
    email: "",
  });

  let timer;

  const toggleSnackbar = () => setShow(!show);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  async function GetStores() {
    //console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setStores(
          response.data.data.map((item) => {
            return { value: item.id, label: item.name };
          })
        );
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  function DoCalc(event) {
    event.preventDefault();
    let inv = [];
    // inv.forEach((item) => {
    //   if (id == item.invoice_id) {
    //     item.amount = parseFloat(add);
    //   }
    // });
    console.log("do Calc");
    console.log(inv);
    var pay = parseFloat(0.0);
    // inv.forEach((item) => {
    //   console.log(item);
    //   if (item.amount > 0) {
    //     pay += parseFloat(item.amount);
    //   }
    // });
    //console.log("pay " + pay + "  add " + add + " id " + id);
    if (received >= 0 && pay >= 0) {
      setTotalPay(parseFloat(pay));
      setRemainder(parseFloat(received) - parseFloat(pay));
    } else {
      setTotalPay(0.0);
    }
    //setInvoices(inv);
  }

  async function GetInvoices(value) {
    await axios
      .get(IP + "/payments/" + localStorage.getItem("paymentId") + "/invoices", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(async function (response) {
        console.log("invoices");
        console.log(response.data.data);

        const invoiceCol = [
          { Header: "Check", accessor: "checkbox" },
          {
            Header: language == 1 ? "Número de Factura" : "Invoice Number",
            accessor: "invoice_number",
          },
          { Header: language == 1 ? "Pendiente" : "Pending", accessor: "total_amount" },
          { Header: language == 1 ? "Pago" : "Payment", accessor: "payment" },
        ];

        let invoiceVar = response.data.data.map((item) => {
          return {
            ...item,
            amount: 0,
          };
        });

        setPaymentData({
          rows: invoiceVar.map((item) => {
            return {
              ...item,
              payment:
                editInvoice == item.invoice_id ? (
                  <>
                    <MDInput
                      id={"input" + item.invoice_id}
                      defaultValue={0.0}
                      name="paymentInput"
                      onChange={DoCalc}
                      variant="standard"
                    />
                    <MDButton
                      variant="gradient"
                      style={{ marginBottom: "5%" }}
                      onClick={() => {
                        setEditInvoice(0);
                      }}
                      color="info"
                    >
                      {language == 1 ? "Guardar" : "Save"}
                    </MDButton>
                  </>
                ) : (
                  <>
                    <Grid container spacing={4} style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      <Grid item>
                        <text>{item.amount}</text>
                      </Grid>
                      <Grid item>
                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            console.log(item.invoice_id);
                            setEditInvoice(item.invoice_id);
                          }}
                          iconOnly
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                      </Grid>
                    </Grid>
                  </>
                ),
              checkbox: send(item),
            };
          }),
          columns: invoiceCol,
        });
        let mon = 0.0;
        response.data.data.forEach((item) => {
          mon += parseFloat(item.pending);
        });
        setMonto(mon);

        let invVar = response.data.data.map((item) => {
          return {
            invoice_id: item.invoice_id,
            invoice_number: item.invoice_number,
            total: item.pending,
            amount: 0,
            actions: false,
            detail_id: null,
          };
        });

        setPaginateData({
          columns: [
            { Header: "", accessor: "name" },

            {
              Header: language == 1 ? "Número de Factura" : "Invoice Number.",
              accessor: "invoice_number",
            },
            { Header: language == 1 ? "Pendiente" : "Pending", accessor: "total" },
            { Header: language == 1 ? "Cantidad de Pago" : "Payment Amount", accessor: "amount" },
            { Header: language == 1 ? "Acciones" : "Actions", accessor: "actions" },
          ],
          rows: response.data.data.map((item) => {
            return {
              invoice_id: item.invoice_id,
              invoice_number: item.invoice_number,
              total: item.total,
              amount: 0,
              actions: false,
            };
          }),
        });

        await axios
          .get(IP + "/payments/" + localStorage.getItem("paymentId") + "/payment_details", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
            },
          })
          .then(async function (response) {
            let payDetail = response.data.data;
            let payAmount = 0;

            console.log(payDetail);

            if (payDetail.length > 0) {
              payDetail.forEach((item) => {
                invVar.forEach((inv) => {
                  if (item.invoice.invoice_id == inv.invoice_id) {
                    payAmount = item.amount;
                    inv.amount = item.amount;
                    inv.detail_id = item.id;
                  }
                });
              });
            }
            setPaymentTotal(payAmount);

            setInvoices(invVar);
          })
          .catch(function (error) {
            // console.log(error.response.data.authenticated);
            if (!error?.response?.data?.authenticated) {
              navigate("/signIn");
            }
            setInvoices(invVar);
          });
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error?.response?.data?.authenticated) {
          navigate("/signIn");
        }
      });
  }

  function send(item) {
    return (
      <input
        defaultChecked={item.checked}
        onChange={(e) => {
          let x = paymentData;
          console.log(x);
          x.rows.map((xi) => {
            if (xi.id == item.id) {
              xi.checked = e.target.checked;
            }
            return xi;
          });
        }}
        type="checkbox"
      />
    );
  }

  const handleEditFormChange = (event) => {
    event.preventDefault();

    if (event.target.value >= 0) {
      const fieldName = event.target.getAttribute("name");
      const fieldValue = event.target.value;

      const newFormData = { ...editFormData };
      newFormData[fieldName] = fieldValue;

      setEditFormData(newFormData);
      console.log(newFormData);
    }
  };
  editFormData;

  const handleEditFormSubmit = (event) => {
    console.log(editFormData);
    event.preventDefault();

    const editedInvoice = {
      invoice_id: editFormData.invoice_id,
      fullName: editFormData.fullName,
      invoice_number: editFormData.address,
      total: editFormData.phoneNumber,
      amount: editFormData.email,
      actions: false,
      detail_id: editFormData.detail_id,
    };

    const newInvoice = [...invoices];

    const index = invoices.findIndex((invoice) => invoice.invoice_id === editFormData.invoice_id);

    newInvoice[index] = editedInvoice;

    let sumPay = 0.0;

    newInvoice.forEach((item) => {
      if (item.amount > 0) sumPay += parseFloat(item.amount);
    });

    if (received - sumPay >= 0) {
      setInvoices(newInvoice);
      setEditInvoice(null);
      setEditFormData(null);

      setTotalPay(sumPay);
      setRemainder(received - sumPay);
    } else {
      swal(
        language == 1
          ? "Alerta! La cantidad sometida es menor que las cantidades de pago. Favor validar el valor del pago. "
          : "Alert! The quantity entered is less than the sum of all payments. Please validate the payment value.",
        "",
        "warning"
      );
    }
  };

  const handleEditClick = (event, invoice) => {
    console.log(invoices);
    event.preventDefault();
    setEditInvoice(invoice.invoice_id);

    const formValues = {
      invoice_id: invoice.invoice_id,
      fullName: invoice.fullName,
      address: invoice.invoice_number,
      phoneNumber: invoice.total,
      pending: parseFloat(invoice.total) + parseFloat(invoice.amount),
      email: invoice.amount,
      actions: false,
      detail_id: invoice.detail_id,
    };

    if (invoice.amount != 0 && !editIds.includes(invoice.invoice_id)) {
      editIds.push(invoice.invoice_id);
    }

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditInvoice(null);
  };

  function handlePaginate(startPag, endPag) {
    if (start != startPag) {
      SetStart(startPag);
      setInvoices(paginateData.rows.slice(startPag, endPag));
    }

    console.log("paginate " + startPag + " " + start);
  }

  async function submitPayment() {
    let paymentinvoices = invoices.filter(
      (item) => item.amount > 0 || editIds.includes(item.invoice_id)
    );

    await axios
      .put(
        IP + "/payments/" + localStorage.getItem("paymentId"),
        {
          transaction_number: transactionNumber,
          active: 1,
          payment_method: paymentMethod,
          total: totalPay,
          store_id: selectedStore.value,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(async function (response) {
        //console.log(response.data.data);

        await axios
          .put(
            IP + "/payment_details/" + localStorage.getItem("paymentId"),
            {
              payments: paymentinvoices.map((item) => {
                return {
                  invoice_id: item.invoice_id,
                  payment_id: localStorage.getItem("paymentId"),
                  amount: item.amount,
                  detail_id: item.detail_id,
                };
              }),
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
              },
            }
          )
          .then(function (response) {
            //console.log(response.data.data);
            toggleSnackbar();
          })
          .catch(function (error) {
            // console.log(error.response.data.authenticated);
            if (!error.response.data.authenticated) {
              navigate("/signIn");
            }
          });
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  async function getPaymentDetails() {
    await axios
      .get(IP + "/payments/" + localStorage.getItem("paymentId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(async function (response) {
        console.log(response.data.data);

        let payment = response.data.data;

        setStores([{ label: payment.store.name, value: payment.store.store_id }]);
        setSelectedStore(
          payment.store.map((item) => {
            return { label: item.name, value: item.id };
          })
        );
        setPaymentMethod(String(payment.payment_method).toUpperCase());
        GetInvoices(
          payment.store.map((item) => {
            return { label: item.name, value: item.id };
          })
        );

        setTransactionNumber(payment.transaction_number);
        setReceived(payment.total);
        setTotalPay(payment.total);
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  useEffect(() => {
    getPaymentDetails();
    GetStores();
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres guardar estos pagos?"
            : "Are you sure you want to submit these payments?"
        }
        OkayFunction={submitPayment}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Pago Editado Exitosamente!" : "Payment Edited Successfully!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <Card>
        <MDBox p={2}>
          <MDTypography variant="h4" sx={{ mb: 3 }}>
            {language == 1 ? "Editar Pagos" : "Edit Payments"}
          </MDTypography>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Autocomplete
                multiple={true}
                disablePortal
                onChange={(event, newValue) => {
                  setSelectedStore(newValue);
                  GetInvoices(newValue);
                }}
                value={selectedStore}
                options={stores}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={language == 1 ? "Tiendas" : "Stores"}
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item lg={4}>
              &nbsp;&nbsp;
              <MDBox mt={-6}>
                <FormControl display="flex" alignItems="flex-start">
                  <h6>{language == 1 ? "Tipo de Pago" : "Payment Type"}: </h6>

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => {
                      setPaymentMethod(e.target.value);
                    }}
                    value={paymentMethod}
                  >
                    <FormControlLabel
                      value={"CASH"}
                      control={<Radio />}
                      label={language == 1 ? "Efectivo" : "Cash"}
                    />
                    <FormControlLabel
                      value={"CHECK"}
                      control={<Radio />}
                      label={language == 1 ? "Cheque" : "Check"}
                    />
                    <FormControlLabel value={"ACH"} control={<Radio />} label="ACH" />
                    <FormControlLabel value={"OTROS"} control={<Radio />} label="OTROS" />
                  </RadioGroup>
                </FormControl>
              </MDBox>
            </Grid>
            <Grid item lg={5}>
              <MDBox mt="15px">
                No.{" "}
                <MDInput
                  value={transactionNumber}
                  sx={{ width: "25%" }}
                  onChange={(e) => {
                    setTransactionNumber(e.target.value);
                  }}
                  variant="standard"
                />
              </MDBox>
            </Grid>
            {/* </Grid>
            <Grid
              container
              spacing={2}
              style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
            > */}
            <Grid item xs={1.5}>
              <MDInput
                label={language == 1 ? "Cantidad Recibida:" : "Quantity Received:"}
                placeholder="$0.00"
                value={received}
                onChange={(e) => {
                  if (
                    (e.target.value >= 0 || e.target.value == "") &&
                    e.target.value <= parseFloat(monto) + parseFloat(paymentTotal)
                  ) {
                    setReceived(e.target.value);
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      if (received >= 0 && totalPay >= 0)
                        setRemainder(parseFloat(e.target.value) - parseFloat(totalPay));
                      else setRemainder(0.0);
                    }, 1000);
                  }
                }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={1.5}>
              <MDInput
                label={language == 1 ? "Total Balance Pendiente:" : "Total Pending Amount:"}
                placeholder="$0.00"
                value={parseFloat(monto).toFixed(2)}
                variant="standard"
              />
            </Grid>{" "}
            <Grid item xs={1.5}>
              <MDInput
                label={language == 1 ? "Pago Total:" : "Total Payment:"}
                placeholder="$0.00"
                value={parseFloat(totalPay).toFixed(2)}
                variant="standard"
              />
            </Grid>{" "}
            <Grid item xs={1.5}>
              <MDInput
                placeholder="$0.00"
                label={language == 1 ? "Restante:" : "Remainder:"}
                value={parseFloat(remainder).toFixed(2)}
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid style={{ maxHeight: "400px", overflow: "auto" }}>
            <form onSubmit={handleEditFormSubmit}>
              <Table>
                <MDBox component="thead">
                  <TableRow
                    style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 2 }}
                  >
                    <DataTableHeadCell></DataTableHeadCell>
                    <DataTableHeadCell>Invoice Number</DataTableHeadCell>
                    <DataTableHeadCell>Pending</DataTableHeadCell>
                    <DataTableHeadCell>Payment Amount</DataTableHeadCell>
                    <DataTableHeadCell>Actions</DataTableHeadCell>
                  </TableRow>
                </MDBox>

                <TableBody>
                  {invoices.map((invoice) => (
                    <Fragment>
                      {editInvoice === invoice.invoice_id ? (
                        <EditableRow
                          editFormData={editFormData}
                          handleEditFormChange={handleEditFormChange}
                          handleCancelClick={handleCancelClick}
                        />
                      ) : (
                        <ReadOnlyRow invoice={invoice} handleEditClick={handleEditClick} />
                      )}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </form>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "3%",
            }}
          >
            <Grid style={{ marginRight: "3%" }}>
              <MDButton
                variant="gradient"
                style={{ marginBottom: "5%" }}
                disabled={remainder != 0.0}
                onClick={() => {
                  var lengthCheck = invoices.filter((item) => item.amount > 0).length;

                  if (lengthCheck > 0) {
                    setShowConfirm(true);
                  }
                }}
                color="success"
              >
                {language == 1 ? "Guardar Pago" : "Save Payment"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default EditPayment;
