/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import IP from "../../IP";
import { Icon } from "@mui/material";

const axios = require("axios");

function AccessDenied() {
  const navigate = useNavigate();

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <Grid>
          <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Icon fontSize="large" style={{ color: "red" }}>
              warning
            </Icon>
          </Grid>
          <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <MDTypography variant="h3" fontWeight="medium" color="black" mt={1}>
              Access Denied
            </MDTypography>
          </Grid>
          <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <MDTypography style={{ marginLeft: "3%" }} variant="h5" color="black" mt={1}>
              Your account does not have permission to view this webpage.
            </MDTypography>
          </Grid>
          <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <MDButton
              variant="gradient"
              style={{ width: "30%" }}
              onClick={() => {
                navigate("/dashboards/analytics");
              }}
              color="info"
              fullWidth
            >
              Return to Dashboard
            </MDButton>
          </Grid>
        </Grid>
      </Card>
    </BasicLayout>
  );
}

export default AccessDenied;
