import React from 'react'
import { useState, useEffect } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

export const ExportToExcel = ({ apiData, fileName }) => {
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <MDButton variant='outlined' color='dark' onClick={(e) => exportToCSV(apiData, fileName)}>
      <Icon>description</Icon>
      &nbsp;{language==1 ? "exportar csv" : "export csv"}
    </MDButton>
  );
};