import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { List } from "react-virtualized";

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;

  // Get the longest item string length
  const longestItem = Array.isArray(children)
    ? children.reduce((longest, child) => {
        const label = child.props?.children || ""; // Get the item's label
        return label.length > longest.length ? label : longest;
      }, "")
    : "";

  const approximateWidth = Math.max(longestItem.length * 8, 150); // Adjust factor (8) for font size

  // Dynamically calculate the height
  const height = Math.min(itemCount * itemSize, 250); // Maximum height: 250px

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={height} // Set dynamic height
          width={approximateWidth} // Dynamically set width
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(props) => {
            return React.cloneElement(children[props.index], {
              style: props.style,
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

export default function VirtualizedAutocomplete({
  style,
  sx,
  size,
  options,
  defaultValue,
  value,
  label,
  onChange,
  componentsProps,
  renderOption,
  getOptionLabel,
  renderInput,
  multiple,
  readOnly,
  disableCloseOnSelect,
  isOptionEqualToValue,
}) {
  return (
    <Autocomplete
      multiple={multiple}
      readOnly={readOnly}
      id="virtualize-demo"
      sx={sx}
      size={size}
      //style={style}
      //componentsProps={{ popper: { style: { width: "fit-content" } } }}
      componentsProps={componentsProps}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      disableListWrap
      disableCloseOnSelect={disableCloseOnSelect}
      ListboxComponent={ListboxComponent}
      options={options}
      getOptionLabel={getOptionLabel}
      //isOptionEqualToValue={isOptionEqualToValue}
      //renderOption={renderOption}
      renderInput={renderInput}
    />
  );
}
