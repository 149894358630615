/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";

const axios = require("axios")

function ReportSolicitedList() {

  const current = new Date();
  let day = current.getDate()
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${
  month < 10 ? `0${month}` : `${month}`
  }-${day < 10 ? `0${day}` : `${day}`}`;

  const [menu, setMenu] = useState(null);
  const [report, setReport] = useState({ columns: [], rows: [] })
  const [data, setData] = useState()
  const [date, setDate] = useState(false)
  const [startDate, setStartDate] = useState(currentDate)
  const [endDate, setEndDate] = useState(currentDate)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate()

  async function getSolicitedData() {
    await axios.get(IP + '/reports/best_seller', { headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
  }).then(response=> {
    let columns = [
      { Header: language==1 ? "Número de Orden" : "Order Number", accessor: "number" },
      { Header: language==1 ? "Fecha" : "Date", accessor: "date" },
      { Header: language==1 ? "Tienda" : "Store", accessor: "store" },
      { Header: language==1 ? "Cantidad" : "Amount", accessor: "amount" },
    ];
  
     let rows = response.data.data.map((item) => {
        return {
        number: '',
        date: '',
        store: '',
        amount: '',
      }
    });
  
    let tableData = { columns: columns, rows: rows };
     //console.log(tableData)
    setReport(tableData)
  }).catch((error)=>{
    if (!error.response.data.authenticated) {
      navigate("/signIn");
    }
    console.log(error)})
  }

  async function sendDates() {
    await axios.get(IP + '/reports/best_seller', {
      startDate: startDate,
      endDate: endDate
    }, { headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    }}).then(response=> {
      let columns = [
        { Header: language==1 ? "Nombre" : "Name", accessor: "name" },
        { Header: language==1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
        { Header: language==1 ? "Costo de Compra" : "Cost", accessor: "cost" },
        { Header: language==1 ? "Profit Margin" : "Profit Margin", accessor: "profit" },
      ];
    
       let rows = response.data.data.map((item) => {
          return {
          name: item.name,
          price: item.sales_price,
          cost: item.cost,
          profit: parseFloat(((item.sales_price-item.cost)/item.sales_price)*100).toFixed(2) + '%',
        }
      });
  
     let excelRows = response.data.data.map((item) => {
        return {
        "Name": item.name,
        "Sales Price": item.sales_price,
        "Cost": item.cost,
        "Profit Margin": parseFloat(((item.sales_price-item.cost)/item.sales_price)*100).toFixed(2) + '%',
        }
      });
  
      setData(excelRows)
      
      let tableData = { columns: columns, rows: rows };
       //console.log(tableData)
      setReport(tableData)
    }).catch((error)=>{
      // if (!error.response.data.authenticated) {
      //   navigate("/signIn");
      // }
      console.log(error)})
    }  

  useEffect(() => {
    // getSolicitedData()
   }, []);

   const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
      { !date
    ?  (
        <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox>
                  <MDBox>
                    <MDBox lineHeight={0}>
                      <MDTypography variant="h5">{language==1 ? "Mas Vendidos" : "Best Sellers" }</MDTypography>
                      <MDTypography variant="button" color="text">
                        {language== 1 ? "Favor entrar fechas" : "Please enter dates"}
                      </MDTypography>
                    </MDBox>
                    <MDBox id="box1" mt={2.5}>
                      <Grid id="grid1" container spacing={3}>
                        <Grid id="grid1-1" item xs={12} lg={6}>
                        <MDInput 
                          value={startDate} 
                          fullWidth
                          type='date'
                          onChange={(e)=>setStartDate(e.target.value)} 
                          variant='standard' 
                          label={language==1 ? "Desde" : "Since" }
                        />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                        <MDInput 
                          value={endDate} 
                          fullWidth
                          type='date'
                          onChange={(e)=>setEndDate(e.target.value)} 
                          variant='standard' 
                          label={language==1 ? "Hasta" : "Until" }
                        />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                  <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                  <MDButton variant="gradient" color="info" onClick={()=>sendDates()}>
                    {language==1 ? "Generar Reporte" : "Generate Report"}
                  </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        </MDBox>
    )
    : (
      <>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
            <ExportToExcel apiData={data} fileName='Most-Solicited' />
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
        <DataTable table={report} entriesPerPage={true} canSearch />
        </Card>
      </>
    )}
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportSolicitedList;
