/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DataTable2 from "app/components/shopperTable";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useNavigate, Navigate } from "react-router-dom";

import DataTable from "examples/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

import IP from "../IP";

const axios = require("axios");

function ViewVendors() {
  const [data, setData] = useState({ columns: [], rows: [] });
  const [index, setIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [storeToDelName, setStoreToDelName] = useState(false);
  const [active, setActive] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [show2, setShow2] = useState(false);
  const toggleSnackbar2 = () => setShow2(!show2);

  const navigate = useNavigate();

  async function changeStatus(id) {
    await axios
      .post(
        IP + "/vendors/setActive/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar();
        GetVendors();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetVendors() {
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        let storeColums = [
          {
            Header: language == 1 ? "Nombre de Vendedor" : "Vendor Name",
            accessor: "name",
            width: "10%",
          },
          {
            Header: language == 1 ? "Número de Vendedor" : "Vendor Number",
            accessor: "vendor_number",
          },
          { Header: language == 1 ? "Dirección" : "Address", accessor: "address1", width: "10%" },
          { Header: language == 1 ? "Ciudad" : "City", accessor: "city", width: "10%" },
          { Header: "Zipcode", accessor: "zipcode", width: "10%" },
          { Header: "Email", accessor: "email", width: "10%" },
          { Header: language == 1 ? "Teléfono" : "Phone Num.", accessor: "phone", width: "10%" },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active", width: "5%" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "20%" },
        ];
        let storeRows = response.data.data.map((item) => {
          return {
            name: item.name,
            address1: item.address1,
            city: item.city.name,
            vendor_number: item.vendor_number,
            zipcode: item.zipcode,
            email: item.contact_email,
            phone: item.contact_phone,
            active: item.active == "Active" ? 
              language==1 ? "Activo" : "Active" :
              language==1 ? "Inactivo" : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      EditStore(item.id);
                    }}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>{" "}
                {item.active == "Inactive" ? (
                  <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(false);
                      }}
                      iconOnly
                    >
                      <Icon>checkmark</Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={() => {
                        changeStatus(item.id);
                        setActive(true);
                      }}
                      iconOnly
                    >
                      <Icon>do_not_disturb</Icon>
                    </MDButton>
                  </Tooltip>
                )}{" "}
                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      console.log("this");
                      setShowConfirm(true);
                      setStoreToDelName(item.name);
                      localStorage.setItem("ventodel", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: storeColums, rows: storeRows };

        setData(tableData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function EditStore(storeid) {
    localStorage.setItem("VenId", storeid);
    setRedirect(true);
    console.log("hello");
  }

  async function DeleteVendor() {
    await axios
      .delete(IP + "/vendors/" + localStorage.getItem("ventodel"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        localStorage.removeItem("ventodel");
        GetVendors();
      })
      .catch(function (error) {
        if (language == 1 ? error.response.data?.message == 'El vendedor tiene productos, no se pudo borrar' : error.response.data?.message == 'Vendor has products, could not be deleted')
          toggleSnackbar2();
        setTimeout(() => {
          setShow2(false);
        }, 3000);
      });
  }

  useEffect(() => {
    GetVendors();
  }, []);

  return (
    <DashboardLayout>
      {redirect && <Navigate push={true} to="/editVendor" />}
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres borrar al Vendedor  ''" + storeToDelName + "'' ?"
            : "Are you sure you want to delete the Vendor ''" + storeToDelName + "'' ?"
        }
        OkayFunction={DeleteVendor}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1
            ? `Vendedor ${active ? "Desactivado" : "Activado"} Exitosamente!`
            : `Vendor ${active ? "Deactivated" : "Activated"} Succesfully!`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title=""
        content={
          language == 1
            ? `El Vendedor no se pudo borrar; tiene productos existentes.`
            : `Vendor could not be deleted; it has existing products.`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={toggleSnackbar2}
      />
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            navigate("/emos/createVendors");
          }}
        >
          {language == 1 ? "Crear Vendedor" : "Create Vendor"}
        </MDButton>
        <MDTypography variant="button" color="text"></MDTypography>
      </MDBox>
      <Card>
        <DataTable2
          entriesPerPage={true}
          index={index}
          setIndex={setIndex}
          pageEntries={10}
          table={data}
          canSearch
        />
      </Card>
    </DashboardLayout>
  );
}

export default ViewVendors;
