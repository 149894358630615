/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import Tooltip from "@mui/material/Tooltip";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function ViewOrder() {
  const [menu, setMenu] = useState(null);
  const [salesData, setSalesData] = useState({ columns: [], rows: [] });
  const [delivery, setDelivery] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [storeLabel, setStoreLabel] = useState()
  const [qtyTotal, setQtyTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [previousBalance, setPreviousBalance] = useState(0)
  const [status, setStatus] = useState(0)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function getProductImages() {
    await axios
      .get(IP + "/products/getImages", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(response=>{
        getSalesData(response.data.data)
      })
      .catch(error=>{
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      })
  }

  async function getSalesData() {
    await axios
      .get(IP + "/ecommerce/" + localStorage.getItem("viewOrderId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let salesColumns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "3%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upc_unit", width: "5%" },
          // { Header: language==1 ? "Nombre" : "Name", accessor: "name", width: '15%' },
          { Header: language == 1 ? "Descripción" : "Description", accessor: "name", width: "15%" },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
          {
            Header: language == 1 ? "Cantidad Elegida" : "Quantity Selected",
            accessor: "qty",
            width: "5%",
          },
          { Header: language == 1 ? "Impuesto %" : "Tax %", accessor: "tax", width: "5%" },
          { Header: "Total", accessor: "total", width: "5%" },
        ];

        let salesRows = response.data.data.selected
        .map((item) => {
          //console.log(item);
          return {
            image: <ProductCell name="" image={imageIP + item.product.image} />,
            upc: item.product.upc,
            upc_unit: item.product.upcUnit,
            name: <MDTypography variant="button">{item.product.name}</MDTypography>,
            package: item.product.packaging,
            price: item.status == 1 ? item.product.current_price : item.price,
            tax: item.tax_percent,
            qty: item.quantity,
            total: parseFloat(item.total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          };
        });

        setStatus(response.data.data.selected[0].sales_order.status)

        let qty = response.data.data.selected
          .map((item) => {
            return item.quantity;
          })
          .reduce((prev, current) => prev + current, 0);
        setQtyTotal(qty);

        setDelivery(parseFloat(response.data.data.selected[0].sales_order.delivery_fee))
        
        setTotal(parseFloat(response.data.data?.selected[0].sales_order.subtotal))

        let taxPrices = [];

        response.data.data.selected.map((item) => {
          taxPrices.push(
            (item.status == 1 ? item.product.current_price : item.price) * item.quantity * (item.product.tax.percent / 100)
          );
        });

        let totalTax = taxPrices.reduce((prev, current) => prev + current, 0);
        setTaxTotal(totalTax);

        setStoreLabel(response.data.data?.store?.name ?? "");
        setPreviousBalance(response.data.data?.store?.store_balance ?? 0.00)

        let tableData = { columns: salesColumns, rows: salesRows };
        //console.log(tableData)
        setSalesData(tableData);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  useEffect(() => {
    getSalesData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox mt={2} ml={2}>
            <Grid>
              <DataTable
                table={{
                  columns: status == 1 ? [
                    {Header: language == 1 ? "Tienda:" : "Store:", accessor: "store"},
                    {Header: language == 1 ? "Total de Bultos:" : "Qty Total:", accessor: "qty"},
                    {Header: "Sub-Total", accessor: "sub"},
                    {Header: language == 1 ? "Tarifa de Envío" : "Delivery Fee", accessor: "delivery"},
                    {Header: language == 1 ? "Total de Impuestos:" : "Tax Total:", accessor: "tax"},
                    {Header: "Total", accessor: "total"},
                    {Header: language==1 ? "Balance Previo" : "Previous Balance", accessor: "previous"},
                    {Header: language==1 ? "Balance Nuevo" : "New Balance", accessor: "new"},
                  ] : [
                    {Header: language == 1 ? "Tienda:" : "Store:", accessor: "store"},
                    {Header: language == 1 ? "Total de Bultos:" : "Qty Total:", accessor: "qty"},
                    {Header: "Sub-Total", accessor: "sub"},
                    {Header: language == 1 ? "Tarifa de Envío" : "Delivery Fee", accessor: "delivery"},
                    {Header: language == 1 ? "Total de Impuestos:" : "Tax Total:", accessor: "tax"},
                    {Header: "Total", accessor: "total"},
                  ],
                  rows: [
                    {
                      store: storeLabel,
                      qty: qtyTotal, 
                      sub: '$' + parseFloat(total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), 
                      delivery: '$' + parseFloat(delivery).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      tax: '$' + parseFloat(taxTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), 
                      total: '$' + parseFloat((taxTotal ?? 0) + (total ?? 0) + (delivery ?? 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      previous: '$' + parseFloat(previousBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      new: '$' + parseFloat(previousBalance + (taxTotal ?? 0) + (total ?? 0) + (delivery ?? 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    }
                  ]
                }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
              />
            </Grid>
          </MDBox>
          <DataTable table={salesData} entriesPerPage={true} />{" "}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewOrder;
