/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { flushSync } from "react-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";
import { Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
//import { styled } from "@mui/material/styles";
import VirtualizedAutocomplete from "app/components/VirtualizedAutocomplete";

import swal from "sweetalert";
// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import DataTable2 from "app/components/shopperTable";
import {
  MaterialReactTable,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
} from "material-react-table";

//import { MaterialReactTable } from "material-react-table";

import IP from "app/IP";

const axios = require("axios");

function createFamily() {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
  //const [familyName, setFamilyName] = useState("");
  const [familyCode, setFamilyCode] = useState("");
  const [familyUPC, setFamilyUPC] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [familyDescription, setFamilyDescription] = useState("");
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [productTableVisible, setProductTableVisible] = useState(true);
  const [matchingProductResults, setMatchingProductResults] = useState(false);
  const [selectedRelatedProduct, setSelectedRelatedProduct] = useState("");
  const isAllSelectedProducts =
    relatedProductList.length > 0 && setSelectedRelatedProduct.length === relatedProductList.length;
  const [index, setIndex] = useState(0);
  const [productData, setProductData] = useState({ columns: [], rows: [] });
  const [relatedProductData, setRelatedProductData] = useState({ columns: [], rows: [] });
  const [relatedProductOptions, setRelatedProductOptions] = useState([]);
  const [selectedRelatedProducts, setSelectedRelatedProducts] = useState([]);
  const [productPrice, setProductPrice] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({
    id: "",
    code: "",
    upc: "",
    name: "",
  });
  const [requirementsList, setRequirementsList] = useState([
    // { label: "sales price", value: 1 },
    // { label: "tax", value: 2 },
    // { label: "vendor", value: 3 },
    { label: "empaque", value: 1 },
    { label: "Tamaño", value: 3, disabled: true },
    { label: "Pesado", value: 6, disabled: true },
    { label: "Etiqueta ???", value: 7, disabled: true },
    { label: "Tarjeta", value: 8, disabled: true },
    { label: "Departamento", value: 9 }, //department is actually subcategory in our version of the database
    { label: "Suplidor", value: 10 },
    { label: "Costo Caja", value: 11, disabled: true },
    { label: "Precio Caja", value: 12, disabled: true },
    { label: "Costo Unidad", value: 13, disabled: true },
    { label: "Precio Unidad", value: 14, disabled: true },
    { label: "Especiales", value: 15, disabled: true },
    { label: "Impuestos", value: 16, disabled: true },
    { label: "Ofertas", value: 17, disabled: true },
    { label: "WIC", value: 19, disabled: true },
  ]); //Options for list of checkbox requirements
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const isAllSelected =
    requirementsList.length > 0 && selectedRequirements.length === requirementsList.length;
  const [menu, setMenu] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  //const [paymentData, setPaymentData] = useState({ columns: [], rows: [] });
  const [date, setDate] = useState(currentDate);
  const [errors, setErrors] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [monto, setMonto] = useState(0.0);
  const [totalPay, setTotalPay] = useState(0.0);
  const [received, setReceived] = useState(0.0);
  const [remainder, setRemainder] = useState(0.0);
  const [invoices, setInvoices] = useState([]);
  const [editInvoice, setEditInvoice] = useState(0);
  const [start, SetStart] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("CHECK");
  const [transactionNumber, setTransactionNumber] = useState("");
  // const [paginateData, setPaginateData] = useState({
  //   columns: [
  //     { Header: "Invoice Number.", accessor: "invoice_number" },
  //     { Header: "Store", accessor: "store_name" },
  //     { Header: "Pending", accessor: "total" },
  //     { Header: "Payment Amount", accessor: "amount" },
  //     { Header: "Actions", accessor: "actions" },
  //   ],
  //   rows: [],
  // });
  const [editFormData, setEditFormData] = useState({
    fullName: "",
    address: "",
    phoneNumber: "",
    email: "",
  });

  let timer;

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  //Get the Products that are related to the given fields of the base product selected
  async function getRelatedProducts(product_info) {
    console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/families/getRelatedProducts", product_info, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("list of products:", response);
        // let productColumns = [
        //   { Header: language == 1 ? "Código" : "Code", accessor: "code" },
        //   { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
        //   { Header: "UPC", accessor: "upc" },
        //   { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
        //   { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
        //   // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        // ];

        let productColumns = [
          { header: language == 1 ? "Código" : "Code", accessorKey: "code", size: 150 },
          { header: language == 1 ? "UPC Unidad" : "UPC Unit", accessorKey: "upcUnit", size: 150 },
          { header: "UPC", accessorKey: "upc", size: 150 },
          { header: language == 1 ? "Nombre" : "Name", accessorKey: "name", size: 150 },
          {
            header: language == 1 ? "Disponible" : "Available",
            accessorKey: "available",
            size: 150,
          },
          // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        // let productRows = response.data.data.map((item) => {
        //   return {
        //     id: item.product_id,
        //     code: item.code,
        //     upcUnit: item.upcUnit,
        //     upc: item.upc,
        //     name: item.name,
        //     available: item.available,
        //     //cost: item.cost,
        //     //price: item.sales_price,
        //   };
        // });

        let productRows = response.data.map((item) => {
          return {
            id: item.product_id,
            name: item.name,
          };
        });

        //setRelatedProductData({ columns: productColumns, rows: productRows });
        //setRelatedProductData(productRows);
        setRelatedProductOptions(productRows);
        if (productRows.length <= 0 && selectedRequirements.length > 0) {
          setMatchingProductResults(true);
        } else {
          setMatchingProductResults(false);
        }
      })
      .catch((error) => {
        console.log("product list error:", error);
      });
  }

  async function saveFamily(product_info) {
    //console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/families/saveFamily", product_info, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("saveFamily Response:", response);
        navigate("/families");
        // console.log("SELECTED RELATED PRODUCTS", selectedRelatedProducts);
        // console.log("SELECTED REQUIREMENTS", selectedRequirements);
        // let familyID = response.data.family_id;
        // //let selectedProductsArray = selectedRelatedProducts
        // let selectedProductsMap = selectedRelatedProducts.map((prod) => {
        //   return {
        //     products: prod.id,
        //     family_id: familyID,
        //   };
        // });
        // let selectedRequirementsMap = selectedRequirements
        //   .map((req) => {
        //     return {
        //       requirements: req,
        //       family_id: familyID,
        //     };
        //   })
        //   .filter((req) => req.requirement_id !== null);
        // console.log("SELECTED RELATED PRODUCTS 2", selectedProductsMap);
        // console.log("SELECTED REQUIREMENTS 2", selectedRequirementsMap);
        //saveProductFamily(selectedProductsMap);
        //saveFamilyRequirements(selectedRequirementsMap);
      })
      .catch((error) => {
        console.log("saveFamily Error:", error);
        setErrors(error.response.data.data);
      });
  }

  async function saveProductFamily(product_info) {
    console.log("PRODUCT_INFO:", product_info);
    //console.log("FAMILY_INFO:", family_id);
    await axios
      .post(IP + "/product_families/saveProductFamily", product_info, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("saveProductFamily Response:", response);
        //navigate("/families");
      })
      .catch((error) => {
        console.log("saveProductFamily Error:", error);
        setErrors(error.response.data.data);
      });
  }

  async function saveFamilyRequirements(requirements, family_id) {
    console.log("Requirements_INFO:", requirements);
    await axios
      .post(IP + "/product_families/saveFamilyRequirements", requirements, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Save Family Requirement response:", response);
        navigate("/families");
      })
      .catch((error) => {
        console.log("Save Family Requirement error:", error);
        setErrors(error.response.data.data);
      });
  }

  //Get list of products
  async function getProducts() {
    await axios
      .get(IP + "/products?all_products=true", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let productColumns = [
          //{ Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
          { Header: language == 1 ? "Empaque" : "Packaging", accessor: "packaging" },
          // { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          //{ Header: language == 1 ? "Sub-Categoría" : "Sub-Category", accessor: "subcategory" },
          //{ Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          //{ Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
          //{ Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
          //{ Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = response.data.data.map((item) => {
          //console.log(item);
          return {
            //image: <ProductCell name="" image={imageIP + item.image} />,
            code: item.code,
            upcunit: item.upcUnit,
            upc: item.upc,
            name: item.name,
            packaging: item.packaging,
            vendor: item.vendor.name,
            cost: item.cost,
            price: item.sales_price,
            tax: item.tax.percent + "%",
            subcategory: item.subcategory.name,
            active:
              item.active == "Active"
                ? language == 1
                  ? "Activo"
                  : "Active"
                : language == 1
                ? "Inactivo"
                : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Escojer" : "Select"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      console.log("Item fields:", item);
                      console.log("Item ID:", item.id);
                      console.log("Item code:", item.code);
                      console.log("Vendor id:", item.vendor.id);
                      console.log("requirements:", selectedRequirements);
                      setSelectedProduct({
                        id: item.id,
                        code: item.code,
                        upc: item.upc,
                        name: item.name,
                        packaging: item.packaging,
                        minimum: item.minimum,
                        maximum: item.maximum,
                        in_qty: item.in_qty,
                        out_qty: item.out_qty,
                        available: item.available,
                        diff: item.diff,
                        cost: item.cost,
                        sales_price: item.sales_price,
                        suggested_price: item.suggested_price,
                        is_food: item.is_food,
                        subcategory_id: item.subcategory.id,
                        tax_id: item.tax_id,
                        vendor_id: item.vendor.id,
                        warehouse_id: item.warehouse_id,
                      });
                      setProductTableVisible(false);
                      // getRelatedProducts({
                      //   requirements: selectedRequirements,
                      //   code: item.code,
                      //   upc: item.upc,
                      //   name: item.name,
                      //   packaging: item.packaging,
                      //   minimum: item.minimum,
                      //   maximum: item.maximum,
                      //   inQty: item.in_qty,
                      //   outQty: item.out_qty,
                      //   available: item.available,
                      //   diff: item.diff,
                      //   cost: item.cost,
                      //   salesPrice: item.sales_price,
                      //   suggestedPrice: item.suggested_price,
                      //   isFood: item.is_food,
                      //   subcategoryID: item.subcategory_id,
                      //   taxID: item.tax_id,
                      //   supplier: item.vendor_id,
                      //   warehouseID: item.warehouse_id,
                      // });
                      //localStorage.setItem("productId", item.id);
                      //navigate("/edit-product");
                    }}
                    iconOnly
                  >
                    <Icon>add</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProductData(tableData);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  //Inserts the checkbox values into the array of jsons
  const handleAllCheck = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values:", selectedRequirements);

    if (value === "all") {
      setSelectedRequirements(
        selectedRequirements.length === requirementsList.length
          ? []
          : requirementsList.map((val) => (!val.disabled ? val.value : null)) //This will not check any disabled checkboxes or checkboxes with disabled field.
        //.filter((val) => val !== null)
        //: requirementsList.map((val) => val.value)
      );
      return;
    }

    const list = [...selectedRequirements];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List:", list);
    setSelectedRequirements(list);
  };

  //Inserts the checkbox products values into the array of jsons
  const handleAllCheckProducts = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked 2:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values 2:", setSelectedRelatedProduct);

    if (value === "all") {
      setSelectedRelatedProduct(
        setSelectedRelatedProduct.length === relatedProductList.length
          ? []
          : relatedProductList.map((val) => val.value) //do NOT add ".value" to vendorList else you'll get undefined error
      );
      return;
    }

    const list = [...setSelectedRelatedProduct];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List 2:", list);
    setSelectedRelatedProduct(list);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox p={2}>
          <MDTypography variant="h5">
            {language == 1 ? "Crear Familia de Productos" : "Create Product Family"}
          </MDTypography>
          <Grid item lg={4}>
            <MDInput
              variant="standard"
              type="text"
              label={language == 1 ? "Family Name" : "Nombre de Familia"}
              sx={{ width: "100%" }}
              value={familyName}
              onChange={(e) => {
                setFamilyName(e.target.value);
                //setDate(e.target.value);
              }}
            />
            <ShowError id={"family_name"} />
          </Grid>
          <Grid item lg={4}>
            <MDInput
              variant="standard"
              type="text"
              label={language == 1 ? "Base Product" : "Producto"}
              sx={{ width: "100%" }}
              value={selectedProduct.name}
            />
            <ShowError id={"name"} />
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <MDInput
                variant="standard"
                type="text"
                label={language == 1 ? "Code" : "Codigo"}
                sx={{ width: "100%" }}
                value={selectedProduct.code}
                onChange={(e) => {
                  //setFamilyCode(e.target.value);
                  //setDate(e.target.value);
                }}
              />
              <ShowError id={"code"} />
            </Grid>
            <Grid item lg={4}>
              <MDInput
                variant="standard"
                type="text"
                label="UPC"
                sx={{ width: "100%" }}
                value={selectedProduct.upc}
                onChange={(e) => {
                  //setFamilyUPC(e.target.value);
                  //setDate(e.target.value);
                }}
              />
              <ShowError id={"upc"} />
            </Grid>
          </Grid>
          <Grid item lg={8} sx={{ marginBottom: "30px" }}>
            <MDInput
              variant="standard"
              type="text"
              label={language == 1 ? "Description" : "Descripcion"}
              sx={{ width: "100%" }}
              value={familyDescription}
              onChange={(e) => {
                setFamilyDescription(e.target.value);
                //setDate(e.target.value);
              }}
            />
            <ShowError id={"description"} />
          </Grid>
          {/* <Grid item lg={4}>
            <Autocomplete
              multiple={true}
              disablePortal
              onChange={(event, newValue) => {
                //setSelectedStore(newValue);
                setSelectedProduct(newValue);
                //GetInvoices(newValue);
                console.log("Selected Product:", newValue);
              }}
              value={selectedProduct}
              //options={stores}
              options={productList}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label={language == 1 ? "Producto" : "Base Product"}
                  fullWidth
                  variant="standard"
                />
              )}
            />
          </Grid> */}
          <Grid item lg={12} sx={{ marginBottom: "20px" }}>
            {productTableVisible ? (
              <>
                <MDTypography variant="h5">
                  {language == 1 ? "Seleccione un producto como base" : "Select a base product"}
                </MDTypography>
                <DataTable2
                  table={productData}
                  index={index}
                  setIndex={setIndex}
                  pageEntries={10}
                  entriesPerPage={true}
                  canSearch
                />
              </>
            ) : (
              <MDButton
                variant="gradient"
                style={{ marginBottom: "5%" }}
                disabled={remainder != 0}
                onClick={() => {
                  setProductTableVisible(true);
                  setSelectedProduct({
                    name: "",
                    code: "",
                    upc: "",
                  });
                }}
                color="success"
              >
                {language == 1 ? "Cambiar producto" : "Change base product"}
              </MDButton>
            )}
          </Grid>
          <Grid item lg={8} sx={{ marginBottom: "20px" }}>
            <MDTypography variant="h5">
              {language == 1 ? "Crear Familia de Productos" : "Requirements"}
            </MDTypography>
            <FormControlLabel
              label="All"
              control={
                <Checkbox
                  value="all"
                  // sx={{
                  //   color: grey[500],
                  // }}
                  onChange={handleAllCheck}
                  checked={isAllSelected}
                  disableRipple
                />
              }
            />
            <List
              sx={{
                display: "flex",
                flexFlow: "column wrap",
                //columnCount: 4,
                //gap: "0 10px",
                height: 300, // set the height limit to your liking
                width: 300,
                //overflow: "auto",
                //border: 1,
                //borderRadius: 2,
              }}
            >
              {requirementsList.map((option) => {
                return (
                  <ListItem key={option.value} disablePadding>
                    <ListItemButton
                      //onClick={handleToggle(value.value)}
                      // onClick={(e) => {
                      //   console.log("ListItemButton Click:", option.value);
                      //   handleAllCheck(option);
                      // }}
                      disabled={option.disabled}
                      dense
                    >
                      <Checkbox
                        // sx={{
                        //   color: grey[500],
                        // }}
                        disabled={option.disabled}
                        value={option.value}
                        onChange={handleAllCheck}
                        checked={
                          selectedRequirements.includes(option.value) //verify if the array contains the given value so that it'll appear checked in the list
                        }
                        //tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText id={option.value} primary={option.label} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <ShowError id={"requirements"} />
          </Grid>
          {/* {relatedProductData.rows.length > 0 && ( */}
          {relatedProductOptions.length > 0 && (
            <Grid item lg={12} sx={{ marginBottom: "20px" }}>
              <>
                {/* <MDTypography variant="h5">
                {language == 1
                  ? "Productos basados en los requerimientos"
                  : "Products based on requirements"}
              </MDTypography> */}
                {/* <DataTable2
                table={relatedProductData}
                index={index}
                setIndex={setIndex}
                pageEntries={10}
                entriesPerPage={true}
                canSearch
              /> */}
                {/* <MaterialReactTable
                //globalFilterFn="contains"
                columns={relatedProductData.columns}
                data={relatedProductData.rows}
                enableRowSelection
                // onRowSelectionChange={setRowSelection}
                // renderTopToolbarCustomActions={({ table }) => {
                //   //setSelectedProductID(
                //   selectedProductIDref.current = table.getSelectedRowModel().flatRows; //get selected row data (not just index)
                //   //)
                // }}
                renderToolbarInternalActions={({ table }) => (
                  <>
                    <MRT_ToggleGlobalFilterButton table={table} />
                    <MRT_ToggleFiltersButton table={table} />
                    <MRT_ToggleDensePaddingButton table={table} />
                  </>
                )}
                //autoResetAll={false}
              /> */}
                <VirtualizedAutocomplete
                  multiple
                  label="Related Products"
                  id="checkboxes-tags-demo"
                  style={{ width: 320 }}
                  options={relatedProductOptions}
                  onChange={(event, newValue) => {
                    console.log("Selected related product:", newValue);
                    setSelectedRelatedProducts(newValue);
                  }}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        //icon={icon}
                        //checkedIcon={checkedIcon}
                        //style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  //style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="standard-multiline-flexible"
                      multiline
                      maxRows="4"
                      variant="outlined"
                      label="Related Products"
                      //fullWidth
                    />
                  )}
                />
              </>
            </Grid>
          )}
          <ShowError id={"products"} />
          <Grid item lg={4}>
            {/* <FormControlLabel
              label="All"
              control={
                <Checkbox
                  value="all"
                  // sx={{
                  //   color: grey[500],
                  // }}
                  onChange={handleAllCheckProducts}
                  checked={isAllSelectedProducts}
                  disableRipple
                />
              }
            />
             <List
              sx={{
                overflow: "auto",
                border: 1,
                borderRadius: 2,
              }}
            >
              {relatedProductList.map((option) => {
                return (
                  <ListItem key={option.value} disablePadding>
                    <ListItemButton
                      //onClick={handleToggle(value.value)}
                      dense
                    >
                      <Checkbox
                        // sx={{
                        //   color: grey[500],
                        // }}
                        value={option.value}
                        onChange={handleAllCheckProducts}
                        checked={
                          selectedRelatedProduct.includes(option.value) //verify if the array contains the given value so that it'll appear checked in the list
                        }
                        //tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText id={option.value} primary={option.label} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List> */}
            {/* <Autocomplete
              multiple={true}
              disablePortal
              onChange={(event, newValue) => {
                //setSelectedStore(newValue);
                setSelectedProduct(newValue);
                //GetInvoices(newValue);
                console.log("Selected Product:", newValue);
              }}
              value={selectedProduct}
              //options={stores}
              options={relatedProductList}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label={language == 1 ? "Producto" : "Base Product"}
                  fullWidth
                  variant="standard"
                />
              )}
            /> */}
          </Grid>
          {matchingProductResults ? (
            <Grid item lg={4} style={{ marginRight: "3%" }}>
              <MDTypography variant="h5">
                {language == 1 ? "No hay " : "No matching products"}
              </MDTypography>
            </Grid>
          ) : null}
          <Grid style={{ marginRight: "3%" }}>
            <MDButton
              variant="gradient"
              style={{ marginBottom: "5%" }}
              disabled={remainder != 0}
              onClick={() => {
                setRelatedProductOptions([]);
                getRelatedProducts({
                  //requirements: selectedRequirements,
                  productID: selectedProduct.id,
                  code: selectedProduct.code,
                  upc: selectedProduct.upc,
                  name: selectedProduct.name,
                  packaging: selectedRequirements.includes(1) && selectedProduct.packaging,
                  //minimum: selectedRequirements.includes(2) && selectedProduct.minimum,
                  //maximum: selectedRequirements.includes(3) && selectedProduct.maximum,
                  //inQty: selectedRequirements.includes(4) && selectedProduct.in_qty,
                  //outQty: selectedRequirements.includes(5) && selectedProduct.out_qty,
                  // available: selectedRequirements.includes(6) && selectedProduct.available,
                  // diff: selectedRequirements.includes(7) && selectedProduct.diff,
                  // cost: selectedRequirements.includes(8) && selectedProduct.cost,
                  subcategoryID: selectedRequirements.includes(9) && selectedProduct.subcategory_id,
                  supplier: selectedRequirements.includes(10) && selectedProduct.vendor_id,
                  // isFood: selectedRequirements.includes(11) && selectedProduct.is_food,
                  // subcategoryID:
                  //   selectedRequirements.includes(12) && selectedProduct.subcategory_id,
                  // taxID: selectedRequirements.includes(13) && selectedProduct.tax_id,
                  // supplier: selectedRequirements.includes(14) && selectedProduct.vendor_id,
                  // warehouseID: selectedRequirements.includes(15) && selectedProduct.warehouse_id,
                });
              }}
              color="success"
            >
              {language == 1 ? "Buscar productos" : "Search related products"}
            </MDButton>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "3%",
            }}
          >
            <Grid style={{ marginRight: "3%" }}>
              <MDButton
                variant="gradient"
                style={{ marginBottom: "5%" }}
                disabled={remainder != 0}
                onClick={() => {
                  saveFamily({
                    family_name: familyName,
                    product_id: selectedProduct.id,
                    name: selectedProduct.name,
                    code: selectedProduct.code,
                    upc: selectedProduct.upc,
                    description: familyDescription,
                    supplier: selectedProduct.vendor_id,
                    packaging: selectedProduct.packaging,
                    subcategory_id: selectedProduct.subcategory_id,
                    products: selectedRelatedProducts,
                    requirements: selectedRequirements,
                    // supplier: selectedRequirements.includes(10) ? selectedProduct.supplier : null,
                    // packaging: selectedRequirements.includes(1) ? selectedProduct.packaging : null,
                    // department: selectedRequirements.includes(9)
                    //   ? selectedProduct.subcategoryID
                    //   : null,
                    //packaging_check: selectedRequirements.includes(1) ? 1 : null,
                    //supplier_check: selectedRequirements.includes(10) ? 10 : null,
                    //subcategory_check: selectedRequirements.includes(9) ? 9 : null,
                  });
                }}
                color="success"
              >
                {language == 1 ? "Guardar Familia" : "Save Family"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default createFamily;
