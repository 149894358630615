/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";

import IP from "app/IP";

const axios = require("axios");

function MeasurementList() {
  const [menu, setMenu] = useState(null);
  const [measurementData, setMeasurementData] = useState({ columns: [], rows: [] });
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function DeleteMeasurement() {
    await axios
      .delete(IP + "/measurements/" + localStorage.getItem("measurementDeleteId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        localStorage.removeItem("measurementDeleteId");
        getMeasurementData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getMeasurementData() {
    await axios
      .get(IP + "/measurements", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let measurementColumns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let measurementRows = response.data.data.map((item) => {
          //console.log(item);
          return {
            name: item.name,
            action: (
              <Grid>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("measurementName", item.name);
                    localStorage.setItem("measurementId", item.id);
                    navigate("/edit-measurement");
                  }}
                  iconOnly
                >
                  <Icon>edit</Icon>
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    localStorage.setItem("measurementDeleteId", item.id);
                    setShowConfirm(true);
                  }}
                  iconOnly
                >
                  <Icon>delete</Icon>
                </MDButton>
              </Grid>
            ),
          };
        });

        let tableData = { columns: measurementColumns, rows: measurementRows };
        //console.log(tableData)
        setMeasurementData(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    getMeasurementData();
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          {language == 1 ? "Remover Filtro" : "Remove Filter"}
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro de que quieres borrar esta medida?"
            : "Are you sure you want to delete the measurement?"
        }
        OkayFunction={DeleteMeasurement}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={() => navigate("/add-measurement")}>
            {language == 1 ? "Añadir Medida" : "add measurement"}
          </MDButton>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              {language == 1 ? "filtros" : "filters"}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;{language == 1 ? "exportar csv" : "export csv"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={measurementData} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default MeasurementList;
