import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function AlertDialogPurchase({
  Show,
  setShowConfirm,
  Message,
  OkayFunction,
  CancelFunction,
  selected,
  setSelected,
  fee,
  total,
  AdditionalFee,
  setFee,
}) {
  const [open, setOpen] = React.useState(false);
  const [deliveryFee, setDeliveryFee] = React.useState(fee);
  const [language, setLanguage] = React.useState(localStorage.getItem("Language"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/*  <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={Show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {" "}
          <p style={{ color: "black" }}>{language == 1 ? "Favor Leer." : "Please Read."}</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid>
              <p style={{ color: "black" }}>{Message}</p>
              {/* <Grid>
                <FormControl>
                  <h5 style={{ color: "gray" }}>
                    {language == 1 ? "Metodo de Transportación:" : "Transportation Method:"}{" "}
                  </h5>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={selected}
                    onChange={(e) => {
                      setSelected(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={"D"}
                      style={{ borderRadius: "100px" }}
                      control={<Radio />}
                      label="Delivery"
                    />
                    <FormControlLabel value={"P"} control={<Radio />} label="Pickup" />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
              <Grid>
                <MDInput
                  type="number"
                  label={language == 1 ? "Cargo Adicional" : "Additional Fee"}
                  variant="standard"
                  value={AdditionalFee}
                  onChange={(e) => {
                    if (e.target.value >= 0 || e.target.value == "") {
                      setFee(e.target.value);
                    }
                  }}
                  fullWidth
                />
              </Grid>

              <MDTypography variant="h6">
                Total: $
                {parseFloat(
                  parseFloat(total) +
                    // parseFloat(deliveryFee ?? 0.0) +
                    parseFloat(isNaN(parseInt(AdditionalFee)) ? 0.0 : AdditionalFee)
                ).toFixed(2)}
              </MDTypography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="light"
            onClick={() => {
              setShowConfirm(false);
            }}
          >
            {language == 1 ? "Cancelar" : "Cancel"}
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              OkayFunction();
              setShowConfirm(false);
            }}
            autoFocus
          >
            {language == 1 ? "Sí" : "Yes"}
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
