import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";

export default function AlertDialog({
  Show,
  setShowConfirm,
  Message,
  OkayFunction,
  CancelFunction,
}) {
  const [open, setOpen] = React.useState(false);
  const [language, setLanguage] = React.useState(localStorage.getItem("Language"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // console.log(Show);

  return (
    <div>
      {/*  <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={Show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {" "}
          <p style={{ color: "black" }}>{language == 1 ? "Confirmar" : "Confirm"}</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p style={{ color: "black" }}>{Message}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="light"
            onClick={() => {
              setShowConfirm(false);
            }}
          >
            {language == 1 ? "Cancelar" : "Cancel"}
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              OkayFunction();
              setShowConfirm(false);
            }}
            autoFocus
          >
            {language == 1 ? "Sí" : "Yes"}
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
