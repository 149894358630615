/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";
import "bootstrap/dist/css/bootstrap.min.css";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { item } from "examples/Sidenav/styles/sidenavItem";

const axios = require("axios");

function ReportProfit() {
  const [menu, setMenu] = useState(null);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [open, setOpen] = useState(false);
  const [download, setDownload] = useState(true)
  const [sortSelect, setSortSelect] = useState("");
  const [data, setData] = useState();
  const [vendors, setVendors] = useState([])
  const [selectedVendor, setSelectedVendor] = useState()
  const [allData, setAllData] = useState([])
  const [responseData, setResponseData] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [sortOption, setSortOption] = useState({id: 1, label: language ==1 ? "Precio de Venta" : "Sales Price"})

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const createPDF = () => {
    setDownload(false)
    const pdf = new jsPDF("landscape", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("profit.pdf");
      setDownload(true)
    });
  };

  async function GetVendors() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        let vendor = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            vendor.push({ label: item.name, id: item.id });
          }

          setVendors(vendor);
        });
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getProfit() {
    await axios
      .get(IP + "/reports/profit", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        response.data.data.map(item=>{
          responseData.push(item)
        })
        setAllData(response.data.data)
        let columns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          { Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
          { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          { Header: language == 1 ? "Ganancia" : "Profit", accessor: "profit" },
        ];

        let rows = response.data.data.map((item) => {
          return {
            name: item.name,
            vendor: item.vendor.name,
            price: '$' + item.sales_price,
            cost: '$' + item.cost,
            profit: parseFloat(item.profit).toFixed(2) + '%'
          };
        });

        let excelRows = response.data.data.map((item) => {
          if (language == 1) {
            return {
              Nombre: item.name,
              Vendedor: item.vendor.name,
              "Precio de Venta": '$' + item.sales_price,
              Costo: '$' + item.cost,
              Ganancia: parseFloat(item.profit).toFixed(2) + '%'
            };
          } else {
            return {
              Name: item.name,
              Vendor: item.vendor.name,
              "Sales Price": '$' + item.sales_price,
              Cost: '$' + item.cost,
              Profit: parseFloat(item.profit).toFixed(2) + '%'
            };
          }
        });

        setData(excelRows);

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function sortQuantityAscend() {
    let columns = [
      { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
      { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
      { Header: language == 1 ? "Ganancia" : "Profit", accessor: "profit" },
    ];

    let rows = (
        sortOption?.id == 1 ?
          responseData.sort((a, b) => (parseFloat(a.sales_price) > parseFloat(b.sales_price) ? 1 : -1)) :
        sortOption?.id == 2 ? 
          responseData.sort((a, b) => (parseFloat(a.cost) > parseFloat(b.cost) ? 1 : -1)) :
        sortOption?.id == 3 ?
          responseData.sort((a, b) => (parseFloat(a.profit) > parseFloat(b.profit) ? 1 : -1)) :
          responseData
      )
      .map((item) => {
        return {
          name: item.name,
          vendor: item.vendor.name,
          price: '$' + item.sales_price,
          cost: '$' + item.cost,
          profit: parseFloat(item.profit).toFixed(2) + '%'
        };
      });

    let excelRows = (
        sortOption?.id == 1 ?
          responseData.sort((a, b) => (parseFloat(a.sales_price) > parseFloat(b.sales_price) ? 1 : -1)) :
        sortOption?.id == 2 ? 
          responseData.sort((a, b) => (parseFloat(a.cost) > parseFloat(b.cost) ? 1 : -1)) :
        sortOption?.id == 3 ?
          responseData.sort((a, b) => (parseFloat(a.profit) > parseFloat(b.profit) ? 1 : -1)) :
          responseData
      )
      .map((item) => {
        if (language == 1) {
          return {
            Nombre: item.name,
            Vendedor: item.vendor.name,
            "Precio de Venta": '$' + item.sales_price,
            Costo: '$' + item.cost,
            Ganancia: parseFloat(item.profit).toFixed(2) + '%'
          };
        } else {
          return {
            Name: item.name,
            Vendor: item.vendor.name,
            "Sales Price": '$' + item.sales_price,
            Cost: '$' + item.cost,
            Profit: parseFloat(item.profit).toFixed(2) + '%'
          };
        }
      });

    setData(excelRows);

    let tableData = { columns: columns, rows: rows };

    setReport(tableData);
    handleClose();
  }

  function sortDescend() {
    let columns = [
      { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
      { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
      { Header: language == 1 ? "Ganancia" : "Profit", accessor: "profit" },
    ];

    let rows = (
        sortOption?.id == 1 ?
          responseData.sort((a, b) => (parseFloat(a.sales_price) < parseFloat(b.sales_price) ? 1 : -1)) :
        sortOption?.id == 2 ? 
          responseData.sort((a, b) => (parseFloat(a.cost) < parseFloat(b.cost) ? 1 : -1)) :
        sortOption?.id == 3 ?
          responseData.sort((a, b) => (parseFloat(a.profit) < parseFloat(b.profit) ? 1 : -1)) :
          responseData
      )
      .map((item) => {
        return {
          name: item.name,
          vendor: item.vendor.name,
          price: '$' + item.sales_price,
          cost: '$' + item.cost,
          profit: parseFloat(item.profit).toFixed(2) + '%'
        };
      });

    let excelRows = (
        sortOption?.id == 1 ?
          responseData.sort((a, b) => (parseFloat(a.sales_price) < parseFloat(b.sales_price) ? 1 : -1)) :
        sortOption?.id == 2 ? 
          responseData.sort((a, b) => (parseFloat(a.cost) < parseFloat(b.cost) ? 1 : -1)) :
        sortOption?.id == 3 ?
          responseData.sort((a, b) => (parseFloat(a.profit) < parseFloat(b.profit) ? 1 : -1)) :
          responseData
      )
      .map((item) => {
        if (language == 1) {
          return {
            Nombre: item.name,
            Vendedor: item.vendor.name,
            "Precio de Venta": '$' + item.sales_price,
            Costo: '$' + item.cost,
            Ganancia: parseFloat(item.profit).toFixed(2) + '%'
          };
        } else {
          return {
            Name: item.name,
            Vendor: item.vendor.name,
            "Sales Price": '$' + item.sales_price,
            Cost: '$' + item.cost,
            Profit: parseFloat(item.profit).toFixed(2) + '%'
          };
        }
      });

    setData(excelRows);

    let tableData = { columns: columns, rows: rows };

    setReport(tableData);
    handleClose();
  }

  function filterVendor(vendor) {
    let columns = [
      { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
      { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
      { Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
      { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
      { Header: language == 1 ? "Ganancia" : "Profit", accessor: "profit" },
    ];

    if (vendor) {
      setResponseData(allData.filter((item) => {
        return item.vendor.id == vendor.id
      }))

      let rows = allData.filter((item) => {
        return item.vendor.id == vendor.id
      }).map(item=>{
        return {
          name: item.name,
          vendor: item.vendor.name,
          price: '$' + item.sales_price,
          cost: '$' + item.cost,
          profit: parseFloat(item.profit).toFixed(2) + '%'
        };
      });

      let excelRows = allData.filter((item) => {
        return item.vendor.id == vendor.id
      }).map(item=>{
        if (language == 1) {
          return {
            Nombre: item.name,
            Vendedor: item.vendor.name,
            "Precio de Venta": '$' + item.sales_price,
            Costo: '$' + item.cost,
            Ganancia: parseFloat(item.profit).toFixed(2) + '%'
          };
        } else {
          return {
            Name: item.name,
            Vendor: item.vendor.name,
            "Sales Price": '$' + item.sales_price,
            Cost: '$' + item.cost,
            Profit: parseFloat(item.profit).toFixed(2) + '%'
          };
        }
      });

      setData(excelRows);

      let tableData = { columns: columns, rows: rows };

      setReport(tableData);
    }

    if (!vendor) {
      setResponseData(allData)

      let rows = allData.map((item) => {
        return {
          name: item.name,
          vendor: item.vendor.name,
          price: '$' + item.sales_price,
          cost: '$' + item.cost,
          profit: parseFloat(item.profit).toFixed(2) + '%'
        };
      });

      let excelRows = allData.map((item) => {
        if (language == 1) {
          return {
            Nombre: item.name,
            Vendedor: item.vendor.name,
            "Precio de Venta": '$' + item.sales_price,
            Costo: '$' + item.cost,
            Ganancia: parseFloat(item.profit).toFixed(2) + '%'
          };
        } else {
          return {
            Name: item.name,
            Vendor: item.vendor.name,
            "Sales Price": '$' + item.sales_price,
            Cost: '$' + item.cost,
            Profit: parseFloat(item.profit).toFixed(2) + '%'
          };
        }
      });

      setData(excelRows);

      let tableData = { columns: columns, rows: rows };
      //console.log(tableData)
      setReport(tableData);
    }
  }

  useEffect(() => {
    getProfit();
    GetVendors()
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <Autocomplete
              fullWidth
              disablePortal
              value={sortOption}
              onChange={(event, newValue) => {
                setSortOption(newValue)
              }}
              options={[
                {id: 1, label: language==1 ? "Precio de Venta" : "Sales Price"},
                {id: 2, label: language==1 ? "Costo" : "Cost"},
                {id: 3, label: language==1 ? "Ganancia" : "Profit"}
              ]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                />
              )}
            />
            <FormControl>
              <RadioGroup
                name="Sort Options"
                onChange={(e) => {
                  if (e.target.value == "ascending") {
                    setSortSelect(e.target.value);
                  } else if (e.target.value == "descending") {
                    setSortSelect(e.target.value);
                  }
                }}
              >
                <FormControlLabel
                  value="descending"
                  control={<Radio />}
                  label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                />
                <FormControlLabel
                  value="ascending"
                  control={<Radio />}
                  label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending") {
                  sortQuantityAscend();
                } else if (sortSelect == "descending") {
                  sortDescend();
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={4}>
          <MDBox display="flex">
            <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
              {language == 1 ? "Organizar" : "Sort"}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            <MDBox ml={1}>
              <ExportToExcel apiData={data} fileName="profit" />
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant='outlined' color='dark' onClick={createPDF}>Download PDF</MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
          <Card>
            <Autocomplete
              sx={{ width: "15%", mt: 2, ml: 2 }}
              disablePortal
              value={selectedVendor}
              onChange={(event, newValue) => {
                setSelectedVendor(newValue);
                filterVendor(newValue)
              }}
              options={vendors}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={language == 1 ? "Vendedor" : "Vendor"}
                  variant="standard"
                />
              )}
            />
            <DataTable table={report} entriesPerPage={true} canSearch />
          </Card>
          <div hidden={download}>
        <MDBox mt={50}>
        <div id="pdf">
          <h3>Profit Report</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{language == 1 ? "Nombre" : "Name"}</th>
                <th>{language == 1 ? "Vendedor" : "Vendor"}</th>
                <th>{language == 1 ? "Precio de Venta" : "Sales Price"}</th>
                <th>{language == 1 ? "Costo" : "Cost"}</th>
                <th>{language == 1 ? "Ganancia" : "Profit"}</th>
              </tr>
            </thead>
            <tbody>
               {responseData.map(item=>{
                  return <tr>
                    <td>{item.name}</td>
                    <td>{item.vendor.name}</td>
                    <td>{item.sales_price}</td>
                    <td>{item.cost}</td>
                    <td>{item.profit}</td>
                  </tr>
               })} 
            </tbody>
          </table>
        </div>
        </MDBox>
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportProfit;
