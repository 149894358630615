/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import IP from "app/IP";
import AlertDialog from "app/components/confirmBox";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";

const axios = require("axios");

function TaxList() {
  const [menu, setMenu] = useState(null);
  const [show, setShow] = useState(false);
  const [activeError, setActiveError] = useState(false)
  const [active, setActive] = useState(false);
  const [taxData, setTaxData] = useState({ columns: [], rows: [] });
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [showConfirm, setShowConfirm] = useState(false);
  const [taxToDelete, setTaxToDelete] = useState(0);

  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const toggleSnackbar2 = () => setShow2(!show2);
  const toggleSnackbar3 = () => setShow3(!show3);
  const toggleSnackbar4 = () => setActiveError(!activeError);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const toggleSnackbar = () => setShow(!show);

  async function setActiveStatus(id, percent, status) {
    await axios
      .put(
        IP + "/taxes/" + id,
        {
          percent: percent,
          active: status,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        getTaxData();
        toggleSnackbar();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        if (error.response.data)
          setActiveError(true)
        console.log(error);
      });
  }

  async function getTaxData() {
    await axios
      .get(IP + "/taxes", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let taxColumns = [
          { Header: language == 1 ? "Porciento de Impuesto" : "Tax Percent", accessor: "percent" },
          { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
          // { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let taxRows = response.data.data.map((item) => {
          console.log(item);
          return {
            percent: item.percent,
            active: item.active == "Active" ? 
              language==1 ? "Activo" : "Active" :
              language==1 ? "Inactivo" : "Inactive",
            action: (
              <Grid>
              <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("taxId", item.id);
                    navigate("/edit-tax");
                  }}
                  iconOnly
                >
                  <Icon>edit</Icon>
                </MDButton>
              </Tooltip>{"  "}
                {item.active == "Inactive" ? (
                  <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => {
                        setActiveStatus(item.id, item.percent, 1);
                        setActive(false);
                      }}
                      iconOnly
                    >
                      <Icon>check</Icon>
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                    <MDButton
                      variant="gradient"
                      color="warning"
                      onClick={() => {
                        setActiveStatus(item.id, item.percent, 0);
                        setActive(true);
                      }}
                      iconOnly
                    >
                      <Icon>do_not_disturb</Icon>
                    </MDButton>
                  </Tooltip>
                )}{" "}
              <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    //  deleteTax(item.id);
                    setTaxToDelete(item.id);
                    setShowConfirm(true);
                  }}
                  iconOnly
                >
                  <Icon>delete</Icon>
                </MDButton>
              </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: taxColumns, rows: taxRows };
        //console.log(tableData)
        setTaxData(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function nav() {
    navigate("/create-tax");
  }

  useEffect(() => {
    getTaxData();
  }, []);

  async function deleteTax() {
    await axios
      .delete(IP + "/taxes/" + taxToDelete, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.message == "Tax was not Deleted") {
          toggleSnackbar2();
          setTimeout(() => {
            setShow2(false);
          }, 3000);
        } else {
          getTaxData();
          toggleSnackbar3();
          setTimeout(() => {
            setShow3(false);
          }, 3000);
        }
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={language == 1 ? `Impuesto Borrado Exitosamente!` : `Tax Deleted Succesfully!`}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show3}
        close={toggleSnackbar3}
      />

      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1
            ? "El Impuesto no pudo ser borrado. Esta siendo usado por un producto."
            : "The Tax could not be deleted. It is being used by a product."
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={toggleSnackbar2}
      />

      <MDSnackbar
        color="info"
        icon="notifications"
        title=""
        content={
          language == 1
            ? `Impuesto ${active ? "Desactivado" : "Activado"} Exitosamente!`
            : `Tax ${active ? "Deactivated" : "Activated"} Succesfully!`
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />

      <MDSnackbar
        color="error"
        icon="notifications"
        title=""
        content={
          language == 1
            ? "Este impuesto actualmente se esta usando en una orden"
            : "This tax is currently being used in an order"
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={activeError}
        close={toggleSnackbar4}
      />

      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres borrar este impuesto?"
            : "Are you sure you want to delete this tax?"
        }
        OkayFunction={deleteTax}
      />
      <MDBox my={3}>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={nav}>
            {language == 1 ? "Crear Impuesto" : "Create Tax"}
          </MDButton>
        </MDBox> */}
        <Card>
          <DataTable table={taxData} entriesPerPage={true} canSearch />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default TaxList;
