/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import IP from "../IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function SignIn() {
  const [rememberMe, setRememberMe] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();

  async function Login() {
    // console.log("aqui");
    setError("");
    console.log(axios);

    await axios
      .post(
        IP + "/login",
        {
          //user_name: userName,
          username: username,
          //email_address: email,
          password: password,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("Language", response.data.data.lang);
        window.role = response.data?.data?.role?.id;
        setTimeout(() => {
          if (response.data?.data?.role?.id == 7) {
            navigate("/ecommerce", { state: { hideBackButton: true } });
          } else {
            navigate("/dashboards/analytics", { state: { hideBackButton: true } });
            window.location.reload(true);
          }
        }, 1000);
      })
      .catch(function (error) {
        console.log("Sign in Error:", error);
        setError(error.response.data.data.error);
      });
  }

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <BasicLayout image={imageIP + "images/background-image.png"}>
      {redirect && <Navigate push={true} to="/dashboards/analytics" />}
      <Card>
        <MDBox style={{ visibility: "none", height: "20px" }}></MDBox>
        {/* <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography fontWeight="medium" mt={1}>
            <MDBox
              component="img"
              src={"https://emos.utreee.net/images/logo_banner.png"}
              alt="Brand"
              width="6rem"
            />
          </MDTypography>
        
        </MDBox> */}
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                label="User Name"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      Login();
                    }
                  }}
                  onChange={(e) => {
                    // console.log(e);
                    setPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        color="white"
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <MDTypography style={{ color: "red" }} mb={0.5}>
                {error}
              </MDTypography>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" onClick={Login} color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Forgot password?{" "}
                <MDTypography
                  component={Link}
                  to="/forgotPassword"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Recover
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
