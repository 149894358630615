import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import MDInput from "components/MDInput";
import { useEffect } from "react";

export default function CarouselCard({
  open,
  showButton,
  subDept,
  upc,
  code,
  name,
  available,
  packaging,
  price,
  onSale,
  shopper,
  image,
  role,
  viewImage,
  minimum,
  prodId,
  reRun,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [sale, setSale] = useState([])
  const [currentShopper, setCurrentShopper] = useState([])
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const nav = useNavigate()

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();

  function setOnSale() {
    setSale(onSale.filter(item=>{ return item.product_id==prodId}))
  }

  function setShopper() {
    setCurrentShopper(shopper.filter(item=>{ return item.product_id==prodId}))
  }

  const setUnderline = {
    textDecoration: isHover ? "underline" : null,
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(()=>{
    setOnSale();
    setShopper();
    console.log('rerun')
  }, [reRun])

  // useEffect(()=>{
  //   if (sale.length>0)
  //   {console.log(sale)}
  // }, [sale])

  // useEffect(()=>{
  //   if (currentShopper.length>0)
  //   {console.log(currentShopper)}
  // }, [currentShopper])

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Dialog open={openDialog}>
          <DialogTitle>{name}</DialogTitle>
          <DialogContent>
            <MDBox component="img" sx={{ cursor: "pointer", width: "270px", height: '270px' }} src={image} alt="" ml={17} />
            <TextField
              margin="dense"
              label="Sub. Dept."
              type="text"
              fullWidth
              value={subDept}
              variant="standard"
            />
            <TextField
              margin="dense"
              label="UPC"
              type="text"
              fullWidth
              value={upc}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "UPC Unidad" : "UPC Unit"}
              type="text"
              fullWidth
              value={code}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Disponible" : "Available"}
              type="text"
              fullWidth
              value={available}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Empaque" : "Package"}
              type="text"
              fullWidth
              value={packaging}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={language == 1 ? "Precio" : "Price"}
              type="text"
              fullWidth
              value={"$" + price}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language==1 ? "Cancelar" : "Cancel"}</Button>
            {(role == 1) && 
              <Button onClick={()=>{nav('/edit-product'), localStorage.setItem('productId', prodId)}}>
                {language == 1 ? "Ver Producto" : "View Product"}
              </Button>
            }
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Grid item xs={12} md={6} lg={2.4}>
        <Card sx={{width: '100%', height: '100%'}}>
          <MDBox p={.2}>
          <Grid container spacing={3}>
            <Grid item className="text-center" sx={{ mt: 0.5, mb: 0.5 }} xs={12} sm={12}>
              <MDBox
                component="img"
                src={image}
                onClick={() => setOpenDialog(true)}
                mt={3}
                sx={{ cursor: "pointer", width: "135px", height: '135px' }}
              />
            </Grid>
          </Grid>
          <div className="text-center">
          <MDTypography
            sx={{ cursor: "pointer", objectFit: "cover" }}
            style={setUnderline}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            variant="h6"
            fontWeight="bold"
            onClick={() => setOpenDialog(true)}
          >
            {name}
          </MDTypography>
          {name.length < (width >= 1695 ? 34 : 25) ? <MDBox mb={3}/> : null}
          </div>
            {/* {available<=minimum ? (
                <MDTypography sx={{ml: 1}} color="error" variant='h6'>
                    Low Stock!
                </MDTypography>
                ): null} */}
            {/* {onSale.map(item=>{}) (
              <MDTypography sx={{ ml: 1 }} color="info" variant="h6">
                {language == 1 ? "En Especial!" : "On Sale!"}
              </MDTypography>
            ) : (
              <MDBox mb={1.5} />
            )} */}

            {sale.length > 0 ? (
              currentShopper.length > 0 ? (
            <Grid container spacing={3}>
            <Grid item xs={6} md={4} lg={5}>
            <MDBox display="flex" justifyContent="left" alignItems="flex-start">
                <MDTypography sx={{ ml: 1 }} color="info" variant="h6">
                {language == 1 ? "En Oferta!" : "On Sale!"}
              </MDTypography>
            </MDBox>
            </Grid>
            <Grid item xs={6} md={8} lg={7}>
            <MDBox display="flex" mr={1} justifyContent="right" alignItems="flex-start">
              <MDTypography color="error" variant='h6'>
                {currentShopper[0].isShopper ? "Regular" : ""} 
              </MDTypography>
              <MDTypography color="success" sx={{ml: currentShopper[0].isShopper ? 1 : 0}} variant='h6'>
                {currentShopper[0].isExpress ? "Express" : ""} 
              </MDTypography>
            </MDBox>
            </Grid>
            </Grid>
              ) : (
              <MDTypography sx={{ ml: 1 }} color="info" variant="h6">
                {language == 1 ? "En Oferta!" : "On Sale!"}
              </MDTypography>
              )
            ) : (
              currentShopper.length > 0 ? (
                <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                <MDBox display="flex" ml={1} justifyContent="left" alignItems="flex-start">
                  <MDTypography color="error" variant='h6'>
                    {currentShopper[0].isShopper ? "Regular" : ""} 
                  </MDTypography>
                  <MDTypography color="success" sx={{ml: currentShopper[0].isShopper ? 1 : 0}} variant='h6'>
                    {currentShopper[0].isExpress ? "Express" : ""} 
                  </MDTypography>
                </MDBox>
                </Grid>
                </Grid>
              ) : (
              <MDTypography color="white" variant='h6'>
                {"|"}
              </MDTypography>
              )
            )}
            {sale.length > 0 ? (
              sale.map(item=>{
                    return <> <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={12}>
                  <MDBox display="flex" justifyContent="left" alignItems="flex-start">
                    <MDTypography
                      sx={{ ml: 1, textDecoration: "line-through" }}
                      variant="body1"
                      color="error"
                    >
                      {"$" + price}
                    </MDTypography>
                    <MDTypography sx={{ ml: 1 }} variant="body1" color="success">
                      {"$" + item.specialPrice}
                    </MDTypography>
                  </MDBox>
                </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={12}>
                    <MDTypography sx={{ml: 1}} variant='h6' fontWeight="bold">{(language==1 ? "Empaque: " : "Package: ")  + packaging}</MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={5} md={5} lg={5}>
                  <MDBox
                    display="flex"
                    justifyContent="left"
                    alignItems="flex-start"
                    ml={1}
                  >
                    {available == 0 ?
                      <MDTypography variant="h6" color='error'>
                      {language == 1 ? "Agotado" : "Unavailable"}
                    </MDTypography>
                      :
                    <MDTypography variant="h6">
                      {language == 1 ? "Disponible" : "Available"} {available}
                    </MDTypography>}
                  </MDBox>
                  </Grid>
                  {showButton && <>
                  <Grid item xs={5} lg={5}>
                  <Grid container>
                  <Grid item xs={3} md={3} lg={3}>
                      <MDBox
                        display="flex"
                        justifyContent="right"
                        alignItems="flex-start"
                      >
                        <MDButton
                          variant="text"
                          size="large"
                          color="dark"
                          onClick={() => {
                            if (quantity > 0) {
                              setQuantity(quantity - 1);
                            }
                          }}
                          iconOnly
                        >
                          <Icon>remove</Icon>
                        </MDButton>
                      </MDBox>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                      <MDInput
                        variant="standard"
                        value={quantity}
                        onChange={(e) => {
                          e.target.value >= 0 && e.target.value <= available
                            ? setQuantity(e.target.value)
                            : null;
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} lg={3}>
                    <MDBox
                        display="flex"
                        justifyContent="left"
                        alignItems="flex-start"
                      >
                      <MDButton
                        variant="text"
                        size="large"
                        color="dark"
                        onClick={() => {
                          if (quantity < available) {
                            setQuantity(quantity + 1);
                          }
                        }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </MDButton>
                      </MDBox>
                    </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} lg={2}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      sx={{float: 'right', mr: 2}}
                      disabled={available == 0 || quantity == 0}
                      onClick={() => {
                        if (quantity != 0) {
                          open({
                            date: new Date().toISOString(),
                            subtotal: 0.0,
                            tax: 0.0,
                            store_id: 1,
                            status_id: 1,
                            active: 1,
                            product: {
                              product_id: prodId,
                              quantity: quantity,
                            },
                          });
                          setQuantity(0);
                        }
                      }}
                      iconOnly
                    >
                      <Icon>shopping_cart</Icon>
                    </MDButton>
                  </Grid>
                </>}
              </Grid>
              </>
              })
            ):( <>
                <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={12}>
                  <MDTypography sx={{ ml: 1 }} variant="body1" color="success">
                    {"$" + price} 
                  </MDTypography>
                </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={12}>
                    <MDTypography sx={{ml: 1}} variant='h6' fontWeight="bold">{(language==1 ? "Empaque: " : "Package: ")  + packaging}</MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                <Grid item xs={5} md={5} lg={5}>
                  <MDBox
                    display="flex"
                    justifyContent="left"
                    alignItems="flex-start"
                    ml={1}
                  >
                      {available == 0 ?
                      <MDTypography variant="h6" color='error'>
                      {language == 1 ? "Agotado" : "Unavailable"}
                    </MDTypography>
                      :
                    <MDTypography variant="h6">
                      {language == 1 ? "Disponible" : "Available"} {available}
                    </MDTypography>}
                  </MDBox>
                </Grid>
                {showButton && <>
                <Grid item xs={5} lg={5}>
                <Grid container>
                <Grid item xs={3} md={3} lg={3}>
                      <MDBox
                        display="flex"
                        justifyContent="right"
                        alignItems="flex-start"
                      >
                        <MDButton
                          variant="text"
                          size="large"
                          color="dark"
                          onClick={() => {
                            if (quantity > 0) {
                              setQuantity(quantity - 1);
                            }
                          }}
                          iconOnly
                        >
                          <Icon>remove</Icon>
                        </MDButton>
                      </MDBox>
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                      <MDInput
                        variant="standard"
                        value={quantity}
                        onChange={(e) => {
                          e.target.value >= 0 && e.target.value <= available
                            ? setQuantity(e.target.value)
                            : null;
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                    <MDBox
                        display="flex"
                        justifyContent="left"
                        alignItems="flex-start"
                      >
                      <MDButton
                        variant="text"
                        size="large"
                        color="dark"
                        onClick={() => {
                          if (quantity < available) {
                            setQuantity(quantity + 1);
                          }
                        }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </MDButton>
                      </MDBox>
                    </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={2}>
                <MDButton
                    variant="gradient"
                    color="info"
                    sx={{float: 'right', mr: 1}}
                    disabled={available == 0 || quantity == 0}
                    onClick={() => {
                      if (quantity != 0) {
                        open({
                          date: new Date().toISOString(),
                          subtotal: 0.0,
                          tax: 0.0,
                          store_id: 1,
                          status_id: 1,
                          active: 1,
                          product: {
                            product_id: prodId,
                            quantity: quantity,
                          },
                        });
                        setQuantity(0);
                      }
                    }}
                    iconOnly
                  >
                    <Icon>shopping_cart</Icon>
                  </MDButton>
                </Grid>
                </>}
              </Grid>
              </>
            )}

            {showButton ? (
                <MDBox mb={0} />
            ) : null}

            {showButton ? (
            <div className="text-center">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={12}>
                  <Grid container spacing={1}>
                    {/* <Grid item xs={12} md={3} lg={1}>
                      <MDButton
                        sx={{ mb: 4.5 }}
                        variant="gradient"
                        color="info"
                        disabled={available == 0}
                        onClick={() => {
                          if (quantity > 0) {
                            open(quantity, prodId);
                            setQuantity(0)
                          }
                        }}
                        iconOnly
                      >
                        <Icon>shopping_cart</Icon>
                      </MDButton>
                    </Grid> */}
                    {/* <Grid item xs={12} md={3} lg={3}>
                      <MDBox
                        display="flex"
                        justifyContent="right"
                        alignItems="flex-start"
                      >
                        <MDButton
                          variant="text"
                          size="large"
                          color="dark"
                          onClick={() => {
                            if (quantity > 0) {
                              setQuantity(quantity - 1);
                            }
                          }}
                          iconOnly
                        >
                          <Icon>remove</Icon>
                        </MDButton>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDInput
                        variant="standard"
                        value={quantity}
                        onChange={(e) => {
                          e.target.value >= 0 && e.target.value <= available
                            ? setQuantity(e.target.value)
                            : null;
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={4}>
                    <MDBox
                        display="flex"
                        justifyContent="left"
                        alignItems="flex-start"
                      >
                      <MDButton
                        variant="text"
                        size="large"
                        color="dark"
                        onClick={() => {
                          if (quantity < available) {
                            setQuantity(quantity + 1);
                          }
                        }}
                        iconOnly
                      >
                        <Icon>add</Icon>
                      </MDButton>
                      </MDBox>
                    </Grid> */}
                  </Grid>
                </Grid>
                </Grid>
                {/* <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={12}>
                  <MDButton
                    sx={{ mb: 4.5 }}
                    variant="gradient"
                    color="info"
                    disabled={available == 0}
                    onClick={() => {
                      if (quantity > 0) {
                        open(quantity, prodId);
                        setQuantity(0)
                      }
                    }}
                    iconOnly
                  >
                    <Icon>shopping_cart</Icon>
                  </MDButton>
                </Grid>
              </Grid> */}
              <MDBox mb={0} />
              </div>
            ) : (
              <MDBox mt={1} />
            )}
          </MDBox>
        </Card>
      </Grid>
    </>
  );
}
