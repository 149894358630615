import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// react-router components
import { useLocation, Link, useParams, useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import IP from "app/IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function ResetPassword() {
  const { userToken, userEmail } = useParams();
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [errors, setErrors] = useState([]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");
  const toggleSnackbar = () => setShow(!show);
  //   const [searchParams, setSearchParams] = useSearchParams();
  // searchParams.get("email")
  const navigate = useNavigate();

  async function ResetPassword() {
    console.log(IP)
    await axios
      .post(IP + "/reset-password", {
        token: userToken,
        email: email,
        password: newPassword,
        password_confirmation: confirmNewPassword,
      })
      .then(function (response) {
        toggleSnackbar()
        setTimeout(()=>{
          navigate("/login");
        }, 2500)
      })
      .catch(function (error) {
        setErrors(error.response.data.data);
        console.log(error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  return (
    <BasicLayout image={imageIP + "images/background-image.png"}>
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Notification"
        content={
          "Password Changed!"
        }
        dateTime="Now"
        open={show}
        close={toggleSnackbar}
      />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your new password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDBox mb={4}>
                <MDInput value={email} type="email" label="Email" fullWidth />
              </MDBox>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => {
                    // console.log(e);
                    setNewPassword(e.target.value);
                  }}
                  //Allows for pressing the enter key to sign in
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      AuthenticateUser();
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                <ShowError id={"password"} />
              </FormControl>
            </MDBox>
            <MDBox mb={4}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword2 ? "text" : "password"}
                  value={confirmNewPassword}
                  onChange={(e) => {
                    // console.log(e);
                    setConfirmNewPassword(e.target.value);
                  }}
                  //Allows for pressing the enter key to sign in
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      AuthenticateUser();
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword2(!showPassword2);
                        }}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
                <ShowError id={"password_confirmation"} />
              </FormControl>
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="success"
                onClick={() => {
                  console.log("ON CHANGE:");
                  console.log(userToken);
                  console.log(email);
                  ResetPassword();
                }}
                fullWidth
              >
                change password
              </MDButton>
            </MDBox>
            {/* <MDBox mt={2} mb={1}>
              <MDButton
                component={Link}
                to="/payroll-sign-in"
                variant="gradient"
                color="light"
                fullWidth
              >
                cancel
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;
