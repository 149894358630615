/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ImageViewer from 'react-simple-image-viewer';

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import Tooltip from "@mui/material/Tooltip";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import imageIP from "app/imageIP";

const axios = require("axios");

function ViewSale() {
  const [menu, setMenu] = useState(null);
  const [store, setStore] = useState('')
  const [salesData, setSalesData] = useState({ columns: [], rows: [] });
  const [images, setImages] = useState([])
  const [currentImage, setCurrentImage] = useState(0)
  const [imgViewer, setImgViewer] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false);
  const [delivery, setDelivery] = useState(0)
  const [qtyTotal, setQtyTotal] = useState();
  const [status, setStatus] = useState(0)
  const [total, setTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [previousBalance, setPreviousBalance] = useState(0)
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setImgViewer(false);
  };
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function getSalesData() {
    await axios
      .get(IP + "/sales_orders/" + localStorage.getItem("viewOrderId") + "/so_detail", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let salesColumns = [
          { Header: language == 1 ? "Imagen" : "Image", accessor: "image", width: "3%" },
          { Header: "UPC", accessor: "upc", width: "5%" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit", width: "5%" },
          // { Header: language==1 ? "Nombre" : "Name", accessor: "name", width: '15%' },
          { Header: language == 1 ? "Descripción" : "Description", accessor: "name", width: "15%" },
          { Header: language == 1 ? "Empaque" : "Package", accessor: "package", width: "6%" },
          { Header: language == 1 ? "Precio" : "Price", accessor: "price", width: "5%" },
          {
            Header: language == 1 ? "Cantidad Elegida" : "Quantity Selected",
            accessor: "qty",
            width: "5%",
          },
          {
            Header: "Subtotal",
            accessor: "subtotal",
            width: "5%"
          },
          { Header: language == 1 ? "Impuesto Estatal" : "State Tax", accessor: "tax", width: "4%" },
          { Header: language == 1 ? "Impuesto Municipal" : "Municipal Tax", accessor: "municipal", width: "4%" },
          { Header: "Total", accessor: "total", width: "5%" },
        ];

        setStore(response.data.data.store.name)
        if (response.data.data.sales_order.status.status_id != 10)
          setPreviousBalance(response.data.data?.store?.store_balance)
        else
          setPreviousBalance(response.data.data?.sales_order?.store_balance)

        let salesRows = response.data.data.selected
        .map((item) => {
          //console.log(item);
          return {
            image: <ProductCell name="" image={imageIP + item.product.image} view={()=>{
              if (item.product.image != '') {
                setImages([imageIP + item.product.image])
                setImgViewer(true)
              }
            }} />,
            upc: item.product.upc,
            upcunit: item.product.upcUnit,
            name: <MDTypography variant="button">{item.product.name}</MDTypography>,
            subtotal: item.price * item.quantity,
            package: item.product.packaging,
            price: item.price,
            tax: item.product.tax.percent + '%',
            municipal: '1%',
            qty: item.quantity,
            total: parseFloat(
              item.price * item.quantity +
                item.price * item.quantity * (item.product.tax.percent / 100)
            ).toFixed(2),
          };
        });

        let qty = response.data.data.selected
          .map((item) => {
            return item.quantity;
          })
          .reduce((prev, current) => prev + current, 0);
        setQtyTotal(qty);

        let totalPrice = parseFloat(response.data.data.sales_order.subtotal)
        setTotal(totalPrice);

        let totalTax = parseFloat(response.data.data.sales_order.tax)
        console.log("TAX", totalTax)
        setTaxTotal(totalTax);

        let deli = parseFloat(response.data.data.sales_order.delivery_fee)
        setDelivery(deli)
        setOrderTotal(totalTax + totalPrice + deli)
        setStatus(response.data.data.sales_order.status.status_id)

        let tableData = { columns: salesColumns, rows: salesRows };
        //console.log(tableData)
        setSalesData(tableData);
      })
      .catch((error) => {
        if (!error?.response?.data?.authenticated) {
          navigate("/signIn");
        } 
        console.log(error);
      });
  }

  useEffect(() => {
    getSalesData();
  }, []);

  return (
    <DashboardLayout>
      {!imgViewer && <DashboardNavbar />}
      <MDBox my={3}>
        <Card>
        {imgViewer && (
          <ImageViewer
            src={ images }
            currentIndex={ currentImage }
            disableScroll={ true }
            closeOnClickOutside={ true }
            onClose={ closeImageViewer }
          />
        )}
          <MDBox mt={2} ml={2}>
            <Grid>
            {/* <MDTypography variant="h6" sx={{mb: 1}}>
                {language == 1 ? "Tienda:" : "Store:"} {store ?? ''}
              </MDTypography>
              <MDTypography variant="h6">
                {language == 1 ? "Total de Bultos:" : "Qty Total:"} {qtyTotal ?? 0}
              </MDTypography>
              <MDTypography variant="h6">
                Sub-total: ${parseFloat(total ?? 0).toFixed(2)}
              </MDTypography>
              {delivery != 0 ?<MDTypography variant="h6">
                {language == 1 ? "Tarifa de Envío:" : "Delivery Fee:"} ${parseFloat(delivery).toFixed(2) ?? 0}
              </MDTypography> : null}
              <MDTypography variant="h6">
                {language == 1 ? "Total de Impuestos" : "Tax Total"}: $
                {parseFloat(taxTotal ?? 0).toFixed(2)}
              </MDTypography>
              <MDTypography variant="h6">
                Total: ${parseFloat(orderTotal).toFixed(2)}
              </MDTypography> */}
              <DataTable
                table={{
                  columns: [
                    {Header: language == 1 ? "Tienda:" : "Store:", accessor: "store"},
                    {Header: language == 1 ? "Total de Bultos:" : "Qty Total:", accessor: "qty"},
                    {Header: "Sub-Total", accessor: "sub"},
                    {Header: language == 1 ? "Tarifa de Envío" : "Delivery Fee", accessor: "delivery"},
                    {Header: language == 1 ? "Total de Impuestos:" : "Tax Total:", accessor: "tax"},
                    {Header: "Total", accessor: "total"},
                    {Header: language==1 ? "Balance Previo" : "Previous Balance", accessor: "previous"},
                    {Header: language==1 ? "Balance Nuevo" : "New Balance", accessor: "new"},
                  ],
                  rows: [
                    {
                      store: store,
                      qty: qtyTotal, 
                      sub: '$' + parseFloat(total).toFixed(2), 
                      delivery: '$' + parseFloat(delivery).toFixed(2),
                      tax: '$' + parseFloat(taxTotal).toFixed(2), 
                      total: '$' + parseFloat(orderTotal).toFixed(2),
                      new: '$' + parseFloat(parseFloat(previousBalance) + parseFloat(orderTotal)).toFixed(2),
                      previous: '$' + parseFloat(previousBalance).toFixed(2),
                    }
                  ]
                }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
              />
            </Grid>
          </MDBox>
          <DataTable table={salesData} entriesPerPage={true} />{" "}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewSale;
