import IP from "app/IP";

const axios = require("axios");

export default async function Logout() {
  await axios
    .post(
      IP + "/logout",
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      }
    )
    .then(function (response) {
      localStorage.removeItem("purchaseOrderDeleteId");
      localStorage.removeItem("UsId");
      localStorage.removeItem("Current User");
      localStorage.removeItem("purchaseOrderId");
      localStorage.removeItem("productId");
      localStorage.removeItem("RecInfo");
      localStorage.removeItem("StoreId");
      localStorage.removeItem("VenId");
      localStorage.removeItem("RecId");
      localStorage.removeItem("stotodel");
      localStorage.removeItem("POID");
      localStorage.removeItem("token");
      console.log("logout");
    })
    .catch(function (error) {
      localStorage.removeItem("purchaseOrderDeleteId");
      localStorage.removeItem("UsId");
      localStorage.removeItem("Current User");
      localStorage.removeItem("purchaseOrderId");
      localStorage.removeItem("productId");
      localStorage.removeItem("RecInfo");
      localStorage.removeItem("StoreId");
      localStorage.removeItem("VenId");
      localStorage.removeItem("RecId");
      localStorage.removeItem("stotodel");
      localStorage.removeItem("POID");
      localStorage.removeItem("token");
      localStorage.removeItem("Language");
      console.log(error);
    });
}
