/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { flushSync } from "react-dom";
import dayjs from "dayjs";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";
import { Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomDatePicker from "components/CustomDatePicker";
import VirtualizedAutocomplete from "app/components/VirtualizedAutocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import ListItemButton from "@mui/material/ListItemButton";
//import { styled } from "@mui/material/styles";

import swal from "sweetalert";
// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import DataTable2 from "app/components/shopperTable";

import IP from "app/IP";
import { options } from "dropzone";
import { Mode } from "@mui/icons-material";

import OfferModal from "./offerModal";

const axios = require("axios");

function Offers() {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
  const [openFamilyPopup, setOpenFamilyPopup] = useState(false);
  const [openProductList, setOpenProductList] = useState(false);
  const [show2, setShow2] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [familyID, setFamilyID] = useState();
  const [productList, setProductList] = useState([]);
  const [offerData, setOfferData] = useState({});
  const [upcList, setUpcList] = useState([]);
  const [selectedUpc, setSelectedUpc] = useState("");
  const [selectedSupplierFilter, setSelectedSupplierFilter] = useState("");
  const [offerId, setOfferId] = useState();
  const [selectedOfferType, setSelectedOfferType] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [families, setFamilies] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState("");
  const [isFamily, setIsFamily] = useState(false);
  const [code, setCode] = useState("");
  const [upc, setUPC] = useState("");
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");
  const [offers, setOffers] = useState("");
  const [offerList, setOfferList] = useState([]);
  const [netWorth, setNetWorth] = useState("");
  const [by, setBy] = useState({ id: 1, label: "Regular" });
  const [cg, setCg] = useState();
  const [c, setC] = useState(0);
  const [g, setG] = useState(0);
  const [percent, setPercent] = useState("");
  const [offer, setOffer] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [familyName, setFamilyName] = useState("");
  const [familyCode, setFamilyCode] = useState("");
  const [familyUPC, setFamilyUPC] = useState("");
  const [familyDescription, setFamilyDescription] = useState("");
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [selectedRelatedProduct, setSelectedRelatedProduct] = useState("");
  const isAllSelectedProducts =
    relatedProductList.length > 0 && setSelectedRelatedProduct.length === relatedProductList.length;
  const [index, setIndex] = useState(0);
  const [productData, setProductData] = useState({ columns: [], rows: [] });
  const [relatedProductData, setRelatedProductData] = useState({ columns: [], rows: [] });
  const [productPrice, setProductPrice] = useState(0);
  const [requirementsList, setRequirementsList] = useState([
    // { label: "sales price", value: 1 },
    // { label: "tax", value: 2 },
    // { label: "vendor", value: 3 },
    { label: "empaque", value: 1 },
    { label: "Tamaño", value: 3, disabled: true },
    { label: "Pesado", value: 6, disabled: true },
    { label: "Etiqueta ???", value: 7, disabled: true },
    { label: "Tarjeta", value: 8, disabled: true },
    { label: "Departamento", value: 9 }, //department is actually subcategory in our version of the database
    { label: "Suplidor", value: 10 },
    { label: "Costo Caja", value: 11, disabled: true },
    { label: "Precio Caja", value: 12, disabled: true },
    { label: "Costo Unidad", value: 13, disabled: true },
    { label: "Precio Unidad", value: 14, disabled: true },
    { label: "Especiales", value: 15, disabled: true },
    { label: "Impuestos", value: 16, disabled: true },
    { label: "Ofertas", value: 17, disabled: true },
    { label: "WIC", value: 19, disabled: true },
  ]); //Options for list of checkbox requirements
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const isAllSelected =
    requirementsList.length > 0 && selectedRequirements.length === requirementsList.length;
  const [menu, setMenu] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  //const [paymentData, setPaymentData] = useState({ columns: [], rows: [] });
  const [date, setDate] = useState(currentDate);
  const [errors, setErrors] = useState([]);
  const [monto, setMonto] = useState(0.0);
  const [totalPay, setTotalPay] = useState(0.0);
  const [received, setReceived] = useState(0.0);
  const [remainder, setRemainder] = useState(0.0);
  const [invoices, setInvoices] = useState([]);
  const [editInvoice, setEditInvoice] = useState(0);
  const [start, SetStart] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("CHECK");
  const [transactionNumber, setTransactionNumber] = useState("");
  // const [paginateData, setPaginateData] = useState({
  //   columns: [
  //     { Header: "Invoice Number.", accessor: "invoice_number" },
  //     { Header: "Store", accessor: "store_name" },
  //     { Header: "Pending", accessor: "total" },
  //     { Header: "Payment Amount", accessor: "amount" },
  //     { Header: "Actions", accessor: "actions" },
  //   ],
  //   rows: [],
  // });
  const [editFormData, setEditFormData] = useState({
    fullName: "",
    address: "",
    phoneNumber: "",
    email: "",
  });

  let timer;

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleFamilyPopupClose = () => {
    setOpenFamilyPopup(false);
    setErrors([]);
  };

  const handleProductListPopupClose = () => {
    setOpenProductList(!openProductList);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  //Get the Products that are related to the given fields of the base product selected
  async function getRelatedProducts(product_info) {
    console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/families/getRelatedProducts", product_info, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("list of products:", response);
        let productColumns = [
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
          { Header: "UPC", accessor: "upc" },
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
          { Header: language == 1 ? "Empaque" : "Packaging", accessor: "packaging" },
          // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = response.data.map((item) => {
          return {
            code: item.code,
            upcunit: item.upcUnit,
            upc: item.upc,
            name: item.name,
            packaging: item.packaging,
            cost: item.cost,
            price: item.sales_price,
            active:
              item.active == "Active"
                ? language == 1
                  ? "Activo"
                  : "Active"
                : language == 1
                ? "Inactivo"
                : "Inactive",
          };
        });

        setRelatedProductData({ columns: productColumns, rows: productRows });
      })
      .catch((error) => {
        console.log("product list error:", error);
      });
  }

  //Get products by vendor id
  async function getProducts(vendor) {
    await axios
      .post(IP + "/offers/getProductsByVendor", vendor, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Products Response:", response);
        let product_list = response.data.map((prod) => {
          return {
            id: prod.product_id,
            label: prod.product_id + " " + prod.name,
            name: prod.name,
            upc: prod.upc,
            code: prod.code,
            cost: prod.cost,
          };
        });
        console.log("Mapped product list:", product_list);
        setProducts(product_list);
        let upc_list = response.data.map((prod) => {
          return {
            id: prod.upc,
            label: prod.upc,
          };
        });
        setUpcList(upc_list);
      })
      .catch((error) => {
        console.log("products error:", error);
      });
  }

  //Check if product has a family
  async function findProductFamily(product) {
    console.log("FindProductFamily ID?", product);
    await axios
      .post(
        IP + "/product_families/findProductFamily",
        { product_id: product },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response Find Product Family:", response);
        //console.log("Contract Products Ids Array Hook:", contractProductIds);
        if (response.data.length > 0) {
          setFamilyID(response.data[0].family_id);
          //setContractProductData(product_data);
          setOpenFamilyPopup(!openFamilyPopup);
        } else {
          //addContractProducts(product_data);
          createOffer({
            vendor_id: selectedSupplier.id,
            product_id: selectedProduct ? selectedProduct.id : selectedFamily.product_id,
            family_id: selectedFamily ? selectedFamily.id : null,
            offer_type_id: selectedOfferType.id,
            code: code,
            upc: upc,
            description: description,
            cost: cost,
            offers: offers,
            net_worth: netWorth,
            by_id: 0,
            c: c,
            g: g,
            percent: percent,
            offer: offer,
            from_date: fromDate,
            to_date: toDate,
          });
        }
      })
      .catch((error) => {
        console.log("Error Find Product Family:", error);
      });
  }

  async function storeUniqueProductFamilyOffers(offer) {
    await axios
      .post(IP + "/offers/storeUniqueProductFamilyOffers", offer, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("storeUniqueProductFamilyOffers Response:", response);
        //setOpenFamilyPopup(!openFamilyPopup);
        getOffers();
      })
      .catch((error) => {
        console.log("storeUniqueProductFamilyOffers ERROR:", error);
      });
  }

  //Get list of families by vendor id
  async function getFamilies(vendor) {
    await axios
      .post(IP + "/families/getFamiliesByVendor", vendor, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Families:", response);
        let family_info = response.data.map((item) => {
          return {
            id: item.family_id,
            label: item.family_id + " " + item.family_name,
            name: item.family_name,
            product_id: item.product_id,
            code: item.product_code,
            upc: item.product_upc,
            //description: item.description,
          };
        });
        setFamilies(family_info);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Get list of families
  async function getProductOfFamily(product_id, offer_sum) {
    console.log("product id for get product of family:", product_id);
    await axios
      .post(IP + "/offers/getProductOfFamily", product_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("get Product of family:", response);
        let family_product_list = response.data.map((prod) => {
          return {
            id: prod.product_id,
            label: prod.product_id + " " + prod.name,
            name: prod.name,
            upc: prod.upc,
            code: prod.code,
            cost: prod.cost,
          };
        });
        console.log("Mapped product list from family:", family_product_list);
        setCost(family_product_list[0].cost);
        console.log("Offer Sum from Family:", offer_sum);
        console.log("Result of sum:", family_product_list[0].cost - offer_sum);
        //if (family_product_list[0].cost > 0 && offers > 0) {
        let result = family_product_list[0].cost - offer_sum;
        setNetWorth(result.toFixed(2));
        // } else {
        //   setNetWorth("");
        // }
        //setProducts(family_product_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getProductListFromOffer(offer_id) {
    await axios
      .post(IP + "/offers/getProductListFromOffer", offer_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Response getProductListFromOffer", response.data);

        let productListMap = response.data.map((prod) => {
          return {
            id: prod.product_id,
            upc: prod.product.upc,
            name: prod.product.name,
          };
        });

        setProductList(productListMap);
      })
      .catch((error) => {
        console.log("Error getProductListFromOffer", error);
      });
  }

  async function getOffers() {
    await axios
      .get(IP + "/offers/getOffers", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Offer Response:", response);
        let offersList = response.data.map((item) => {
          return {
            product_id: item.product_id,
            offer: item.offer,
          };
        });
        setOfferList(offersList);
        //navigate("/families");
        let productColumns = [
          //{ Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          { Header: language == 1 ? "Descripcion" : "Description", accessor: "description" },
          //{ Header: "UPC", accessor: "upc" },
          // { Header: language == 1 ? "Codigo" : "Code", accessor: "description" },
          // { Header: "Desc.", accessor: "vendor" },
          // { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          { Header: language == 1 ? "Oferta" : "Offers", accessor: "offer" },
          // { Header: language == 1 ? "Costo Neto" : "Net Cost", accessor: "tax" },
          // { Header: language == 1 ? "Por" : "By", accessor: "active" },
          // { Header: "C/G", accessor: "cg" },
          { Header: "%", accessor: "percent" },
          { Header: language == 1 ? "Desde" : "From", accessor: "from" },
          { Header: language == 1 ? "Hasta" : "To", accessor: "to" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = response.data.map((item) => {
          //console.log(item);
          return {
            //image: <ProductCell name="" image={imageIP + item.image} />,
            vendor: item.vendor.name,
            description: item.description,
            code: item.product_code,
            offer: item.c ? item.c + "/" + item.g : item.offer,
            percent: item.percent,
            from: dayjs(item.from_date).format("MM/DD/YYYY"),
            to: dayjs(item.to_date).format("MM/DD/YYYY"),
            // upcunit: item.upcUnit,
            // available: item.available,
            // vendor: item.vendor.name,
            // cost: item.cost,
            // price: item.sales_price,
            // tax: item.tax.percent + "%",
            // subcategory: item.subcategory.name,
            // active:
            //   item.active == "Active"
            //     ? language == 1
            //       ? "Activo"
            //       : "Active"
            //     : language == 1
            //     ? "Inactivo"
            //     : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Escojer" : "Select"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      console.log("EDIT");
                      //getOfferForEdit({ offer_id: item.offer_id });
                      let offerSum = offerList
                        .filter((value) => value.product_id == item.product_id)
                        .reduce(
                          (total, currentValue) => (total = total + parseFloat(currentValue.offer)),
                          0
                        );
                      setOfferData({
                        offer_id: item.offer_id,
                        selectedSupplier: {
                          id: item.vendor_id,
                          label: item.vendor_id + " " + item.vendor.name,
                        },
                        selectedProduct: {
                          id: item.product_id,
                          label: item.product_id + " " + item.product.name,
                          name: item.product.name,
                          code: item.code,
                          upc: item.upc,
                        },
                        selectedOfferType: {
                          id: item.offertype.offer_type_id,
                          label: item.offertype.offer_type_name,
                        },
                        code: item.code,
                        upc: item.upc,
                        description: item.description,
                        cost: item.cost,
                        offers: offerSum.toFixed(2),
                        netWorth: (item.cost - offerSum).toFixed(2),
                        offer: item.offer,
                        c: item.c,
                        g: item.g,
                        percent: item.percent,
                        fromDate: dayjs(item.from_date),
                        toDate: dayjs(item.to_date),
                      });
                      setOfferId(item.offer_id);
                      setSelectedSupplier({
                        id: item.vendor_id,
                        label: item.vendor_id + " " + item.vendor.name,
                      });
                      if (item.product_id) {
                        console.log("Product*");
                        getProductListFromOffer({ offer_id: item.offer_id });
                        setSelectedProduct({
                          id: item.product_id,
                          label: item.product_id + " " + item.product.name,
                          name: item.product.name,
                          code: item.code,
                          upc: item.upc,
                        });
                      }
                      if (item.family_id) {
                        console.log("Family*");
                        setSelectedFamily({
                          id: item.family_id,
                          label: item.family_id + " " + item.family.family_name,
                          name: item.family.family_name,
                          product_id: item.product_id,
                          code: item.code,
                          upc: item.upc,
                        });
                        setIsFamily(true);
                      }
                      setSelectedOfferType({
                        id: item.offertype.offer_type_id,
                        label: item.offertype.offer_type_name,
                      });
                      setCode(item.code);
                      setUPC(item.upc);
                      setDescription(item.description);
                      setCost(item.cost);
                      // let offerSum = offerList
                      //   .filter((value) => value.product_id == item.product_id)
                      //   .reduce(
                      //     (total, currentValue) => (total = total + parseFloat(currentValue.offer)),
                      //     0
                      //   );
                      console.log("OfferList during Edit:", offerList);
                      setOffers(offerSum.toFixed(2));
                      setNetWorth((item.cost - offerSum).toFixed(2));
                      setOffer(item.offer);
                      setBy({ id: item.by_id, label: "Regular" });
                      setC(item.c);
                      setG(item.g);
                      setPercent(item.percent);
                      setOffer(item.offer);
                      setFromDate(dayjs(item.from_date));
                      setToDate(dayjs(item.to_date));
                      handleClickOpen(true);
                    }}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProductData(tableData);
      })
      .catch((error) => {
        console.log("createOffer Error:", error);
        //setErrors(error.response.data.data);
        //setErrors(error);
      });
  }

  async function filterOffersByUPC(offer_filter) {
    console.log("Offer filter:", offer_filter);
    await axios
      .post(IP + "/offers/getFilteredOffers", selectedSupplierFilter.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("filterOffersByUPC Response:", response);
        // let offersList = response.data.map((item) => {
        //   return {
        //     product_id: item.product_id,
        //     offer: item.offer,
        //   };
        // });
        // setOfferList(offersList);
        const filteredOffers = response.data.filter((offer) => offer.upc == offer_filter.upc);
        console.log("Filtered offers by upc:", filteredOffers);
        let productColumns = [
          //{ Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          { Header: language == 1 ? "Descripcion" : "Description", accessor: "description" },
          //{ Header: "UPC", accessor: "upc" },
          // { Header: language == 1 ? "Codigo" : "Code", accessor: "description" },
          // { Header: "Desc.", accessor: "vendor" },
          // { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          { Header: language == 1 ? "Oferta" : "Offers", accessor: "offer" },
          // { Header: language == 1 ? "Costo Neto" : "Net Cost", accessor: "tax" },
          // { Header: language == 1 ? "Por" : "By", accessor: "active" },
          { Header: "C/G", accessor: "cg" },
          { Header: "%", accessor: "percent" },
          { Header: language == 1 ? "Desde" : "From", accessor: "from" },
          { Header: language == 1 ? "Hasta" : "To", accessor: "to" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = filteredOffers.map((item) => {
          //console.log(item);
          return {
            //image: <ProductCell name="" image={imageIP + item.image} />,
            vendor: item.vendor.name,
            description: item.description,
            code: item.product_code,
            offer: item.offer,
            percent: item.percent,
            from: dayjs(item.from_date).format("MM/DD/YYYY"),
            to: dayjs(item.to_date).format("MM/DD/YYYY"),
            // upcunit: item.upcUnit,
            // available: item.available,
            // vendor: item.vendor.name,
            // cost: item.cost,
            // price: item.sales_price,
            // tax: item.tax.percent + "%",
            // subcategory: item.subcategory.name,
            // active:
            //   item.active == "Active"
            //     ? language == 1
            //       ? "Activo"
            //       : "Active"
            //     : language == 1
            //     ? "Inactivo"
            //     : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Escojer" : "Select"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      //getOfferForEdit({ offer_id: item.offer_id });
                      setOfferId(item.offer_id);
                      setSelectedSupplier({
                        id: item.vendor_id,
                        label: item.vendor_id + " " + item.vendor.name,
                      });
                      setSelectedProduct({
                        id: item.product_id,
                        label: item.product_id + " " + item.product.name,
                        name: item.product.name,
                        code: item.code,
                        upc: item.upc,
                      });
                      setSelectedOfferType({
                        id: item.offertype.offer_type_id,
                        label: item.offertype.offer_type_name,
                      });
                      setCode(item.code);
                      setUPC(item.upc);
                      setDescription(item.description);
                      setCost(item.cost);
                      let offerSum = offerList
                        .filter((value) => value.product_id == item.product_id)
                        .reduce(
                          (total, currentValue) => (total = total + parseFloat(currentValue.offer)),
                          0
                        );
                      console.log("OfferList during Edit:", offerList);
                      setOffers(offerSum.toFixed(2));
                      setNetWorth((item.cost - offerSum).toFixed(2));
                      setBy(item.by_id);
                      setPercent(item.percent);
                      setOffer(item.offer);
                      setFromDate(dayjs(item.from_date));
                      setToDate(dayjs(item.to_date));
                      handleClickOpen(true);
                    }}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProductData(tableData);
      })
      .catch((error) => {
        console.log("getFilteredOffers Error:", error);
        setErrors(error.response.data.data);
      });
  }

  async function getFilteredOffers(offer_filter) {
    //console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/offers/getFilteredOffers", offer_filter, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("getFilteredOffers Response:", response);
        let offersList = response.data.map((item) => {
          return {
            product_id: item.product_id,
            offer: item.offer,
          };
        });
        let upcList = response.data.map((item) => {
          return {
            label: item.upc,
            value: item.upc,
          };
        });
        //getProducts({ vendor_id: newValue.id });
        setUpcList(upcList);
        setOfferList(offersList);
        let productColumns = [
          //{ Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
          { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
          { Header: language == 1 ? "Descripcion" : "Description", accessor: "description" },
          //{ Header: "UPC", accessor: "upc" },
          // { Header: language == 1 ? "Codigo" : "Code", accessor: "description" },
          // { Header: "Desc.", accessor: "vendor" },
          // { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
          { Header: language == 1 ? "Oferta" : "Offers", accessor: "offer" },
          // { Header: language == 1 ? "Costo Neto" : "Net Cost", accessor: "tax" },
          // { Header: language == 1 ? "Por" : "By", accessor: "active" },
          { Header: "C/G", accessor: "cg" },
          { Header: "%", accessor: "percent" },
          { Header: language == 1 ? "Desde" : "From", accessor: "from" },
          { Header: language == 1 ? "Hasta" : "To", accessor: "to" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
        ];

        let productRows = response.data.map((item) => {
          //console.log(item);
          return {
            //image: <ProductCell name="" image={imageIP + item.image} />,
            vendor: item.vendor.name,
            description: item.description,
            code: item.product_code,
            offer: item.offer,
            percent: item.percent,
            from: dayjs(item.from_date).format("MM/DD/YYYY"),
            to: dayjs(item.to_date).format("MM/DD/YYYY"),
            // upcunit: item.upcUnit,
            // available: item.available,
            // vendor: item.vendor.name,
            // cost: item.cost,
            // price: item.sales_price,
            // tax: item.tax.percent + "%",
            // subcategory: item.subcategory.name,
            // active:
            //   item.active == "Active"
            //     ? language == 1
            //       ? "Activo"
            //       : "Active"
            //     : language == 1
            //     ? "Inactivo"
            //     : "Inactive",
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Escojer" : "Select"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      //getOfferForEdit({ offer_id: item.offer_id });
                      setOfferId(item.offer_id);
                      setSelectedSupplier({
                        id: item.vendor_id,
                        label: item.vendor_id + " " + item.vendor.name,
                      });
                      setSelectedProduct({
                        id: item.product_id,
                        label: item.product_id + " " + item.product.name,
                        name: item.product.name,
                        code: item.code,
                        upc: item.upc,
                      });
                      setSelectedOfferType({
                        id: item.offertype.offer_type_id,
                        label: item.offertype.offer_type_name,
                      });
                      setCode(item.code);
                      setUPC(item.upc);
                      setDescription(item.description);
                      setCost(item.cost);
                      let offerSum = offerList
                        .filter((value) => value.product_id == item.product_id)
                        .reduce(
                          (total, currentValue) => (total = total + parseFloat(currentValue.offer)),
                          0
                        );
                      console.log("OfferList during Edit:", offerList);
                      setOffers(offerSum.toFixed(2));
                      setNetWorth((item.cost - offerSum).toFixed(2));
                      setBy(item.by_id);
                      setPercent(item.percent);
                      setOffer(item.offer);
                      setFromDate(dayjs(item.from_date));
                      setToDate(dayjs(item.to_date));
                      handleClickOpen(true);
                    }}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: productColumns, rows: productRows };
        //console.log(tableData)
        setProductData(tableData);
      })
      .catch((error) => {
        console.log("getFilteredOffers Error:", error);
        setErrors(error.response.data.data);
      });
  }

  //Create an offer
  async function createOffer(offer_data) {
    await axios
      .post(IP + "/offers/createOffer", offer_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("createOffer Response:", response);
        setShow2(!show2);
        handleClose();
        getOffers();
        //navigate("/families");
      })
      .catch((error) => {
        console.log("createOffer Error:", error);
        setErrors(error.response.data.data);
        //setErrors(error);
      });
  }

  async function editOffer(offer_data) {
    //console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/offers/editOffer", offer_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("editOffer Response:", response);
        setShow2(!show2);
        handleClose();
        getOffers();
        //navigate("/families");
      })
      .catch((error) => {
        console.log("editOffer Error:", error);
        setErrors(error.response.data.data);
        //setErrors(error);
      });
  }

  async function GetStores() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log("Get Stores:", response.data.data);
        setStores(
          response.data.data.map((item) => {
            return { id: item.id, label: item.id + " " + item.name };
          })
        );
      })
      .catch(function (error) {
        // console.log(error.response.data.authenticated);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
      });
  }

  async function GetVendors() {
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log("Get Vendors Response:", response.data.data);

        let vendor = response.data.data.map((item) => {
          return {
            id: item.id,
            label: item.vendor_number + " " + item.name,
          };
        });

        setSuppliers(vendor);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log("Get Vendors ERROR:", error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            {/* <label>{item}</label> */}
            <label>{language == 1 ? "El campo es requerido." : "The field is required."}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  //Inserts the checkbox values into the array of jsons
  const handleAllCheck = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values:", selectedRequirements);

    if (value === "all") {
      setSelectedRequirements(
        selectedRequirements.length === requirementsList.length
          ? []
          : requirementsList.map((val) => (!val.disabled ? val.value : null)) //This will not check any disabled checkboxes or checkboxes with disabled field.
        //: requirementsList.map((val) => val.value)
      );
      return;
    }

    const list = [...selectedRequirements];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List:", list);
    setSelectedRequirements(list);
  };

  //Inserts the checkbox products values into the array of jsons
  const handleAllCheckProducts = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked 2:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values 2:", setSelectedRelatedProduct);

    if (value === "all") {
      setSelectedRelatedProduct(
        setSelectedRelatedProduct.length === relatedProductList.length
          ? []
          : relatedProductList.map((val) => val.value) //do NOT add ".value" to vendorList else you'll get undefined error
      );
      return;
    }

    const list = [...setSelectedRelatedProduct];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List 2:", list);
    setSelectedRelatedProduct(list);
  };

  const handleClickOpen = (mode) => {
    setOpenDialog(true); //opens dialog model
    setEditMode(mode); //sets whether the dialog will be in create or edit mode.
  };

  const handleClose = () => {
    setOpenDialog(false); //close dialog box
    // setProductList([]);
    // setErrors([]);
    // setSelectedOfferType("");
    // setSelectedProduct("");
    // setSelectedFamily("");
    // setSelectedSupplier("");
    // setCode("");
    // setUPC("");
    // setDescription("");
    // setCost("");
    // setOffers("");
    // setNetWorth("");
    // setBy("");
    // setC("");
    // setG("");
    // setPercent("");
    // setOffer();
    // setFromDate();
    // setToDate();
    // setIsFamily(false);
  };

  //Clear basic fields
  const clearFields = () => {
    setCode("");
    setUPC("");
    setCost("");
    setOffers("");
    setNetWorth("");
    setC("");
    setG("");
    setPercent("");
    setOffer("");
  };

  useEffect(() => {
    GetStores();
    GetVendors();
    // getProducts();
    // getFamilies();
    getOffers();
  }, []);

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Oferta creado exitosamente!" : "Offer Created Successfully!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={show2}
        close={() => {
          setShow2(!show2);
        }}
      />
      <DashboardNavbar />
      <OfferModal
        open={openDialog}
        onClose={handleClose}
        language={language}
        editMode={editMode}
        vendors={suppliers}
        offer_data={offerData}
        runFunction={() => {
          getOffers();
        }}
      />
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDTypography variant="h5" fontWeight="medium">
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              handleClickOpen(false);
            }}
          >
            {language == 1 ? "Create Offer" : "Crear Oferta"}
          </MDButton>
        </MDTypography>
      </MDBox>
      <Card>
        <MDBox p={2}>
          <MDTypography variant="h5">{language == 1 ? "Offers" : "Ofertas"}</MDTypography>
          <Grid item lg={12} sx={{ marginBottom: "20px" }}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item lg={3}>
                <VirtualizedAutocomplete
                  sx={{ m: 1, width: "100%" }}
                  size="small"
                  options={suppliers}
                  getOptionsLabel={(options) => options.label || ""}
                  value={selectedSupplierFilter}
                  onChange={(event, newValue) => {
                    console.log("selected supplier:", newValue);
                    if (newValue == null) {
                      setSelectedSupplierFilter("");
                      setSelectedUpc("");
                      getOffers();
                      //filterOffersByUPC("");
                      //getFilteredOffers({ vendor_id: "" });
                    } else {
                      setSelectedSupplierFilter(newValue);
                      setSelectedUpc("");
                      //filterOffersByUPC(newValue.value);
                      getFilteredOffers({ vendor_id: newValue.id });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Suplidor" : "Supplier"}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item lg={3}>
                <VirtualizedAutocomplete
                  sx={{ m: 1, width: "100%" }}
                  size="small"
                  options={upcList}
                  getOptionsLabel={(options) => options.label || ""}
                  value={selectedUpc}
                  onChange={(event, newValue) => {
                    console.log("selected upc:", newValue);
                    if (newValue == null) {
                      setSelectedUpc("");
                      getFilteredOffers({ vendor_id: selectedSupplierFilter.id });
                    } else {
                      setSelectedUpc(newValue);
                      filterOffersByUPC({ upc: newValue.value });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="UPC" variant="outlined" />}
                />
              </Grid>
            </Grid>
            <DataTable2
              table={productData}
              index={index}
              setIndex={setIndex}
              pageEntries={10}
              entriesPerPage={true}
              canSearch
            />
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default Offers;
