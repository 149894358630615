/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";
import "bootstrap/dist/css/bootstrap.min.css";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { item } from "examples/Sidenav/styles/sidenavItem";

const axios = require("axios");

function ReportExcemption() {
  const [menu, setMenu] = useState(null);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [open, setOpen] = useState(false);
  const [download, setDownload] = useState(true)
  const [sortSelect, setSortSelect] = useState("");
  const [data, setData] = useState();
  const [allData, setAllData] = useState([])
  const [responseData, setResponseData] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [sortOption, setSortOption] = useState({id: 1, label: language ==1 ? "Precio de Venta" : "Sales Price"})

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const createPDF = () => {
    setDownload(false)
    const pdf = new jsPDF("landscape", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("profit.pdf");
      setDownload(true)
    });
  };

  async function getProfit() {
    await axios
      .get(IP + "/reports/excemption", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setAllData(response.data.data)
        let columns = [
          { Header: language == 1 ? "Tienda" : "Store", accessor: "store" },
          { Header: language == 1 ? "Fecha de Expiración" : "Expiration Date", accessor: "date" },
        ];

        let rows = response.data.data.map((item) => {
          return {
            store: item.store.name,
            date: item.date,
          };
        });

        let excelRows = response.data.data.map((item) => {
          if (language == 1) {
            return {
              Tienda: item.store.name,
              "Fecha de Expiración": item.date,
            };
          } else {
            return {
              Store: item.store.name,
              "Expiration Date": item.date,
            };
          }
        });

        setData(excelRows);

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    getProfit();
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={4}>
          <MDBox display="flex">
            <MDBox ml={1}>
              <ExportToExcel apiData={data} fileName="excemption" />
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant='outlined' color='dark' onClick={createPDF}>Download PDF</MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
          <Card>
            <DataTable table={report} entriesPerPage={true} canSearch />
          </Card>
          <div hidden={download}>
        <MDBox mt={50}>
        <div id="pdf">
          <h3>Profit Report</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{language == 1 ? "Tienda" : "Store"}</th>
                <th>{language == 1 ? "Fecha de Expiración" : "Expiration Date"}</th>
                <th>{language == 1 ? "Precio de Venta" : "Sales Price"}</th>
                <th>{language == 1 ? "Costo" : "Cost"}</th>
                <th>{language == 1 ? "Ganancia" : "Profit"}</th>
              </tr>
            </thead>
            <tbody>
              {allData.map(item=>{
                return <tr>
                  <td>{item.store.name}</td>
                  <td>{item.date}</td>
                </tr>
              })} 
            </tbody>
          </table>
        </div>
        </MDBox>
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportExcemption;
