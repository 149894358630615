/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";

const axios = require("axios");

function ReceivingsList() {
  const [menu, setMenu] = useState(null);
  const [receivingsData, setReceivingsData] = useState({ columns: [], rows: [] });
  const [showConfirm, setShowConfirm] = useState(false);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function DeleteReceivings() {
    await axios
      .delete(IP + "/receivings/" + localStorage.getItem("receivingsDeleteId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        localStorage.removeItem("receivingsDeleteId");
        getReceivingsData();
      })
      .catch(function (error) {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function getReceivingsData() {
    await axios
      .get(IP + "/receivings", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let receivingsColumns = [
          { Header: "Date", accessor: "date" },
          { Header: "Order Number", accessor: "order_number" },
          { Header: "Purchase Order", accessor: "purchase_id" },
          { Header: "Vendor", accessor: "vendor_id" },
          { Header: "Action", accessor: "action" },
        ];

        let receivingsRows = response.data.data[0].map((item) => {
          //console.log(item);
          return {
            date: item.date,
            order_number: item.vendor_order_number,
            purchase_id: item.purchase_order_id,
            vendor_id: item.vendor_id,
            action: (
              <Grid>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    localStorage.setItem("receivingsId", item.id);
                    navigate("/edit-receivings");
                  }}
                  iconOnly
                >
                  <Icon>edit</Icon>
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    localStorage.setItem("receivingsDeleteId", item.id);
                    setShowConfirm(true);
                  }}
                  iconOnly
                >
                  <Icon>delete</Icon>
                </MDButton>
              </Grid>
            ),
          };
        });

        let tableData = { columns: receivingsColumns, rows: receivingsRows };
        //console.log(tableData)
        setReceivingsData(tableData);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  function nav() {
    navigate("/create-receiving");
  }

  useEffect(() => {
    getReceivingsData();
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={"Are you sure you want to delete this receiving?"}
        OkayFunction={DeleteReceivings}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={nav}>
            create receiving
          </MDButton>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={receivingsData} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReceivingsList;
